<template>
  <v-card class="py-4 relative">
    <v-btn
      fab
      x-small
      depressed
      text
      @click="$emit('close-form')"
      style="position: absolute; right: 1vw; top: 1vh"
      ><v-icon small>mdi-close</v-icon></v-btn
    >
    <h4 class="text-center mb-3">Laporan Bulanan Dinas Kesehatan</h4>
    <div
      style="height: 1px; background-color: #E0E0E0; width: 100%; margin-bottom: 5vh;"
    ></div>
    <v-btn
      :to="{
        name: 'DinkesReport',
        query: { type: 'Jalan' },
      }"
      style="letter-spacing: 0.0001px; border-color: #E0E0E0; color: #616161; width: 25vw"
      class="text-capitalize mb-3"
      outlined
      depressed
      >Rawat Jalan</v-btn
    >
    <br />
    <v-btn
      :to="{
        name: 'DinkesReport',
        query: { type: 'Inap' },
      }"
      style="letter-spacing: 0.0001px; margin-bottom: 5vh; border-color: #E0E0E0; color: #616161; width: 25vw"
      class="text-capitalize"
      outlined
      depressed
      >Rawat Inap</v-btn
    >
  </v-card>
</template>

<script>
export default {
  name: 'DinkesMenu',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
