var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"py-4 relative"},[_c('v-btn',{staticStyle:{"position":"absolute","right":"1vw","top":"1vh"},attrs:{"fab":"","x-small":"","depressed":"","text":""},on:{"click":function($event){return _vm.$emit('close-form')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c('h4',{staticClass:"text-center mb-3"},[_vm._v("Laporan Bulanan Dinas Kesehatan")]),_c('div',{staticStyle:{"height":"1px","background-color":"#E0E0E0","width":"100%","margin-bottom":"5vh"}}),_c('v-btn',{staticClass:"text-capitalize mb-3",staticStyle:{"letter-spacing":"0.0001px","border-color":"#E0E0E0","color":"#616161","width":"25vw"},attrs:{"to":{
      name: 'DinkesReport',
      query: { type: 'Jalan' },
    },"outlined":"","depressed":""}},[_vm._v("Rawat Jalan")]),_c('br'),_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"letter-spacing":"0.0001px","margin-bottom":"5vh","border-color":"#E0E0E0","color":"#616161","width":"25vw"},attrs:{"to":{
      name: 'DinkesReport',
      query: { type: 'Inap' },
    },"outlined":"","depressed":""}},[_vm._v("Rawat Inap")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }