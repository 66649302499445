import Constant from '../../const';
const axios = require('axios');

const postResendPcareInpatient = async id => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat(`/inpatient/reassessment/pcare/${id}`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postResendPcareInpatient;
