import Constant from '@/const';
import axios from 'axios';

const postRegistrationPcare = async (idRegistration, isInpatient = false) => {
  try {
    const url = !isInpatient
      ? `/patient/reregistration/pcare/${idRegistration}`
      : `/inpatient/reregistration/pcare/${idRegistration}`;

    const response = await axios.post(Constant.apiUrl.concat(url));
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default postRegistrationPcare;
