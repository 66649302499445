<template>
  <v-card>
    <v-card-title class="d-flex justify-center">
      <h3 class="font-weight-medium mb-0">
        Ringkasan Pendaftaran Pasien Rawat Inap
      </h3>
      <v-btn absolute right icon @click="close()"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="mt-4 px-11">
      <v-row
        v-for="(item, index) of recapData"
        :key="index"
        class="my-4"
        no-gutters
      >
        <v-col cols="3" class="d-flex justify-left">
          <label for=""> {{ item.key }}</label>
        </v-col>
        <v-col class="d-flex text-left pl-3">
          <label for="">{{ item.value }}</label>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="back()"
        :disabled="loading"
        color="primary"
        class="text-none"
        dense
        depressed
        outlined
      >
        Kembali
      </v-btn>

      <v-btn
        @click="addRegist()"
        :loading="loading"
        color="primary"
        class="text-none"
        dense
        depressed
      >
        Lanjut
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import formatMixin from '@/mixin/formatMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'InpatientRegistrationStore',
);

import { postRegisterPatient } from '@/fetchApi/Inpatient/Registration';

export default {
  mixins: [formatMixin],
  props: {
    selectedPatient: {
      type: Object,
      default: null,
    },
    medicalRecord: {
      type: String,
      default: null,
    },
  },
  name: 'RegistrationRecap',
  computed: {
    ...mapGetters(['getBed', 'getPatient', 'getTtv', 'getInputTtv']),
    bed: {
      get() {
        return this.getBed;
      },
    },
    patient: {
      get() {
        return this.getPatient;
      },
    },
    ttv: {
      get() {
        return this.getTtv;
      },
    },
    inputTtv: {
      get() {
        return this.getInputTtv;
      },
    },
  },
  data() {
    return {
      loading: false,
      recapData: [
        {
          key: 'No. RM',
          value: this.medicalRecord,
        },
        {
          key: 'Nama',
          value: this.selectedPatient.meta.name,
        },
        {
          key: 'Tanggal Lahir (Usia)',
          value: `${moment(this.selectedPatient.meta.birth.date).format(
            'DD-MM-YYYY',
          )} (${this.selectedPatient.meta.age} Tahun)`,
        },
        {
          key: 'Alamat',
          value: this.formatAddress(this.selectedPatient.meta.address),
        },
        {
          key: 'Tipe Penjamin',
          value: this.selectedPatient.meta.guarantorType,
        },
        {
          key: 'Jenis Kasus',
          value: this.selectedPatient.caseType,
        },
        {
          key: 'No Bed',
          value: '',
        },
      ],
    };
  },
  mounted() {
    this.recapData[6].value = this.bed.name;
  },
  methods: {
    ...mapMutations(['clearPatient', 'clearTtv', 'setSnackbar']),
    back() {
      this.$emit('on-clicked', 1);
    },
    close() {
      Swal.fire({
        title: `Yakin ingin keluar dari formulir?`,
        text: 'Jika anda keluar, data pendaftaran akan hilang',
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Keluar',
        cancelButtonText: 'Batal',
        customClass: {
          cancelButton: 'button-cancel',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.clearPatient();
          this.clearTtv();
          this.$emit('close-form');
        }
      });
    },
    async addRegist() {
      try {
        this.loading = true;
        const data = {
          id_registration: this.selectedPatient.id_registration,
          id_emr: this.selectedPatient.id_emr,
          isRegistered: true,
          id_bed: this.bed.id, // make sure bed is empty/available
          patient: this.patient,
          ttv: this.inputTtv ? this.ttv : null,
          timestamps: {
            created_by: this.$store.getters.userLoggedIn.id,
          },
        };
        await postRegisterPatient(data);
        this.clearPatient();
        this.clearTtv();
        this.setSnackbar({
          show: true,
          color: 'success',
          text: 'Pasien berhasil didaftarkan',
        });
        this.$emit('close-form');
      } catch (error) {
        // this.showErrorAxios(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style></style>
