import moment from 'moment-timezone';
const _ = require('lodash');

import formatMixin from '../../../mixin/formatMixin';

export default {
  mixins: [formatMixin],
  methods: {
    mapDashboardData(source) {
      return source.map(item => {
        return {
          id_inpatient: item._id,
          id_registration: item.registration._id,
          id_emr: item.id_emr,
          case_type: item.letter?.case_type || null,
          name: _.get(item, 'social.name', ''),
          gender:
            _.get(item, 'social.gender', '') === true
              ? 'Laki-laki'
              : 'Perempuan',
          birthDate: _.get(item, 'social.birth.date', ''),
          birthPlace: _.get(item, 'social.birth.place', ''),
          noRegist: _.get(item, 'registration.registration_number', ''),
          guarantorType: _.get(item, 'assurance.type', ''),
          age: _.get(item, 'social.age', ''),
          isNew: _.get(item, 'registration.status.isNew'),
          date: moment(_.get(item, 'timestamps.created_at', null)).format(
            'DD/MM/YYYY',
          ),
          time: moment(_.get(item, 'timestamps.created_at', null)).format(
            'hh:mm [WIB]',
          ),
          bed: _.get(item, 'bed_number', ''),
          noRM: _.get(item, 'medical_record_number', ''),
          role: _.get(item, 'doctor_role.role', ''),
          doctor: _.get(item, 'doctor_dpjp', ''),
          doctorId: _.get(item, 'doctor', ''),
          noQueue: _.get(item, 'registration.queue.number', ''),
          address: this.formatAddress(item.social.address),
          identityType: _.get(item, 'social.identity.type', ''),
          identityNumber: _.get(item, 'social.identity.number', ''),
          id_poly: _.get(item, 'registration.id_unit', ''),
          profession: _.get(item, 'social.profession', ''),
          guarantorName: _.get(item, 'social.assurance.name', ''),
          guarantorNumber: _.get(item, 'social.assurance.number', ''),
          dateIn: moment(_.get(item, 'timestamps.created_at', '')).format(
            'DD-MM-YYYY',
          ),
          timeIn: moment(_.get(item, 'timestamps.created_at', '')).format(
            'HH:mm',
          ),
          activityStatus: {
            labStatus: _.get(
              item,
              'registration.activity_status.lab_status',
              'not_scheduled',
            ),
          },
          pcare: _.get(item, 'pcare', null),
        };
      });
    },
  },
};
