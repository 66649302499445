<template>
  <v-card flat>
    <v-tabs v-model="tabs" color="primary" slider-color="primary" grow>
      <v-tab
        style="letter-spacing:.1px"
        class="text-capitalize font-weight-bold"
        @click="scrollTo('nurse')"
        >Assesmen Perawat</v-tab
      >
      <v-tab
        style="letter-spacing:.1px"
        class="text-capitalize font-weight-bold"
        @click="scrollTo('doctor')"
        >Assesmen Dokter</v-tab
      >
    </v-tabs>
    <v-container
      class="container-layout"
      id="main-container"
      @scroll="onScroll()"
      fluid
    >
      <div class="title-container">
        <h3 ref="nurse" class="grey--text text-center">
          ASSESMEN PERAWAT
        </h3>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title class="title font-weight-bold primary--text">
          Data Subjektif
        </v-card-title>
        <v-row>
          <v-col cols="6" class="grid-display">
            <template v-for="(anamnesis, index) in input.anamnesis">
              <label
                :key="'anamnesis-label-' + index"
                :class="{ required: !index }"
                >{{ index ? '' : 'Anamnesis' }}</label
              >
              <div :key="'anamnesis-' + index">
                <v-textarea
                  no-resize
                  height="100"
                  outlined
                  dense
                  single-line
                  :placeholder="'Anamnesis ' + (index + 1)"
                  v-model="input.anamnesis[index]"
                  :rules="index === 0 && isEditable && !isEmr ? rules : []"
                  :id="'anamnesis-' + index"
                  :readonly="!isEditable || emrData !== null"
                />
                <div style="margin-top: -3vh" class="d-flex justify-end">
                  <v-btn
                    :disabled="
                      isEmr ? true : !isEditable || input.anamnesis.length < 2
                    "
                    @click="deleteAnamnesis(index)"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="isEmr ? true : !isEditable"
                    @click="addAnamnesis()"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-plus</v-icon></v-btn
                  >
                </div>
              </div>
            </template>
            <label>Riwayat Alergi</label>
            <div class="text-left">
              <p class="ma-0 pa-0">Alergi Obat</p>
              <v-combobox
                v-model="input.allergy.drug"
                :items="resource.allergy.drug"
                :loading="allergyLoading"
                placeholder="Pilih alergi obat"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="isEmr ? true : !isEditable"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
              <p class="ma-0 pa-0">Alergi Makanan</p>
              <v-combobox
                v-model="input.allergy.food"
                :items="resource.allergy.food"
                :loading="allergyLoading"
                placeholder="Pilih alergi makanan"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="isEmr ? true : !isEditable"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
              <p class="ma-0 pa-0">Alergi Udara</p>
              <v-combobox
                v-model="input.allergy.air"
                :items="resource.allergy.air"
                :loading="allergyLoading"
                placeholder="Pilih alergi udara"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="isEmr ? true : !isEditable"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
              <p class="ma-0 pa-0">Alergi Lainnya</p>
              <div
                v-for="(allergy, index) in input.allergy.others"
                :key="`other-allergy-${index}`"
                class="d-flex"
              >
                <v-text-field
                  dense
                  placeholder="Masukkan alergi lainnya"
                  v-model="input.allergy.others[index]"
                  :readonly="!isEditable || emrData !== null"
                />
                <v-btn
                  :disabled="
                    isEmr
                      ? true
                      : !isEditable || input.allergy.others.length < 2
                  "
                  @click="deleteAllergy(index)"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn
                  :disabled="isEmr ? true : !isEditable"
                  @click="addAllergy()"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <v-row class="grid-display">
              <template v-for="(disease, index) in input.priorDisease">
                <label :key="'disease-label' + index">{{
                  index ? '' : 'Riwayat Penyakit Sebelumnya'
                }}</label>
                <div :key="'disease-' + index" class="d-flex">
                  <v-text-field
                    dense
                    :placeholder="'Riwayat Penyakit ' + (index + 1)"
                    v-model="input.priorDisease[index]"
                    :readonly="!isEditable || emrData !== null"
                  />
                  <v-btn
                    :disabled="
                      isEmr
                        ? true
                        : !isEditable || input.priorDisease.length < 2
                    "
                    @click="deleteDisease(index)"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="isEmr ? true : !isEditable"
                    @click="addDisease()"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-plus</v-icon></v-btn
                  >
                </div>
              </template>
              <label>Riwayat Pengobatan</label>
              <v-text-field
                dense
                placeholder="Riwayat Pengobatan"
                v-model="input.treatmentHistory"
                :readonly="!isEditable || emrData !== null"
              />
              <label>Status Psikologi</label>
              <v-radio-group
                row
                :readonly="isEmr ? true : !isEditable"
                v-model="input.psychologyStatusCheckbox"
              >
                <v-radio label="Tidak Ada Kelainan" :value="false"></v-radio>
                <v-radio label="Ada" :value="true"></v-radio>
              </v-radio-group>
              <template v-if="input.psychologyStatusCheckbox">
                <label></label>
                <v-combobox
                  v-model="input.psychologyStatus"
                  :items="resource.psychologyStatus"
                  placeholder="Status Psikologis"
                  :readonly="isEmr ? true : !isEditable"
                  :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <p class="grey--text ma-4 text-start body-1">
                      Pilih atau Ketik Untuk Pilihan Lainnya
                    </p>
                  </template>
                </v-combobox>
              </template>
              <label>Sosial Ekonomi</label>
              <v-text-field
                v-model="input.socialEconomy"
                :readonly="!isEditable || emrData !== null"
                class="mb-5"
                hide-details
                dense
                single-line
                placeholder="Pendidikan/Pekerjaan/Penghasilan Pasien"
              />
              <label>Spiritual</label>
              <v-text-field
                v-model="input.spiritual"
                hide-details
                dense
                :readonly="!isEditable || emrData !== null"
                single-line
                placeholder="Agama/Keyakinan/Nilai-Nilai Pasien"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Data Objektif TTV
        </v-card-title>
        <service-assessment-objective-data
          :is-editable="isEmr ? false : isEditable"
          :is-kia="true"
          ref="objective"
          id="objective"
        />

        <v-card-title
          class="mt-4 title font-weight-bold primary--text text-left"
        >
          Skrining Gizi
        </v-card-title>
        <v-row v-if="!isAdult" class="mb-3">
          <v-col cols="11" class="grid-nutrition">
            <label>
              Apakah Pasien Tampak Kurus?
            </label>
            <div class="d-flex justify-center">
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.nutritionScreening.kid.isThin"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 1 }"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="text-start">
              <label>
                Apakah Terdapat Penurunan Berat Badan Selama 1 Bulan Terakhir?
              </label>
              <p>
                (berdasarkan penilaian objektif data berat badan bila ada, atau
                penilaian subyektif orang tua pasien atau untuk bayi &lt; 1
                tahun berat badan tidak naik selama 3 bulan terakhir)
              </p>
            </div>
            <div class="d-flex justify-center">
              <v-radio-group
                dense
                hide-details
                :readonly="!isEditable || isEmr"
                row
                v-model="input.nutritionScreening.kid.isLossWeight"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 1 }"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="text-start">
              <label>
                Apakah Terdapat Salah Satu dari Kondisi Tersebut?
              </label>
              <p>
                (diare >=5 per hari dan muntah >3 kali per hari dalam seminggu
                terakhir atau asupan makanan berkurang selama 1 minggu terakhir)
              </p>
            </div>
            <div class="d-flex justify-center">
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.nutritionScreening.kid.isHasCondition"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 1 }"
                ></v-radio>
              </v-radio-group>
            </div>
            <label>
              Apakah Terdapat Penyakit atau Keadaan yang Mengakibatkan Pasien
              Beresiko Mengalami Malnutrisi?
            </label>
            <div class="d-flex justify-center">
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.nutritionScreening.kid.isMalnutritionRisk"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 2 }"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <div class="d-flex justify-start align-center px-8">
            <h4 class="font-weight-bold primary--text mr-7">
              Total Skor : {{ nutritionScreeningResult?.score }}
            </h4>
            <h4 class="font-weight-bold primary--text mr-4">Hasil :</h4>
            <div :class="`chip-${nutritionScreeningResult?.color}`">
              {{ nutritionScreeningResult?.text }}
            </div>
          </div>
        </v-row>
        <v-row v-else class="mb-3">
          <v-col cols="11" class="grid-nutrition adult">
            <label>
              Apakah Pasien Mengalami Penurunan Berat badan yang Tidak
              Direncanakan?
            </label>
            <div>
              <div class="d-flex justify-end">
                <v-radio-group
                  dense
                  hide-details
                  row
                  :readonly="!isEditable || isEmr"
                  v-model="input.nutritionScreening.adult.isUnplannedWeightLoss"
                >
                  <v-radio
                    label="Tidak"
                    :value="{ check: 'no', score: 0 }"
                  ></v-radio>
                  <v-radio
                    label="Tidak Yakin"
                    :value="{ check: 'notSure', score: 2 }"
                  ></v-radio>
                  <v-radio label="Ya" :value="true"></v-radio>
                </v-radio-group>
              </div>
              <div class="d-flex justify-end">
                <v-radio-group
                  :disabled="
                    !isEditable ||
                      input.nutritionScreening.adult.isUnplannedWeightLoss !==
                        true
                  "
                  dense
                  hide-details
                  row
                  v-model="
                    input.nutritionScreening.adult.isUnplannedWeightLossAmount
                  "
                >
                  <v-radio
                    label="1-5 kg"
                    :value="{ check: 'oneToFive', score: 1 }"
                  ></v-radio>
                  <v-radio
                    label="6-10 kg"
                    :value="{ check: 'sixToTen', score: 2 }"
                  ></v-radio>
                  <v-radio
                    label="11-15 kg"
                    :value="{ check: 'elevenToFifteen', score: 3 }"
                  ></v-radio>
                  <v-radio
                    label=">15 kg"
                    :value="{ check: 'aboveFifteen', score: 4 }"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
            <div class="text-start">
              <label>
                Apakah Asupan Makanan Pasien Buruk Akibat Nafsu Makan yang
                Menurun?
              </label>
              <p>
                Misalnya asupan makanana hanya 1/4 dari biasanya
              </p>
            </div>
            <div class="d-flex justify-end">
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="
                  input.nutritionScreening.adult
                    .isFoodIntakePoorDueToDecreasedAppetite
                "
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 1 }"
                ></v-radio>
              </v-radio-group>
            </div>
            <label>
              Apakah Pasien Menderita Penyakit Berat?
            </label>
            <div class="d-flex justify-end">
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.nutritionScreening.adult.isHaveSeriousIllness"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 2 }"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <div class="d-flex justify-start align-center px-8">
            <h4 class="font-weight-bold primary--text mr-7">
              Total Skor : {{ nutritionScreeningResult?.score }}
            </h4>
            <h4 class="font-weight-bold primary--text mr-4">Hasil :</h4>
            <div :class="`chip-${nutritionScreeningResult?.color}`">
              {{ nutritionScreeningResult?.text }}
            </div>
          </div>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Assesmen Risiko Jatuh
        </v-card-title>
        <v-row v-if="!isAdult" class="mb-4">
          <v-col cols="6">
            <v-row no-gutters align="baseline" class="grid-display">
              <label>Usia</label>
              <p class="text-start">{{ kidAge.text }}</p>
              <label>Diagnosis</label>
              <v-combobox
                :clearable="isEmr ? false : isEditable"
                dense
                placeholder="Diagnosa"
                hide-details
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                return-object
                item-text="text"
                :items="resource.DiagnoseFallRisk"
                v-model="input.fallRisk.kid.diagnose"
                class="mb-5"
                :readonly="
                  !isEditable || emrData !== null || !isDoctor || isEmr
                "
              />
              <label>Gangguan Kognitif</label>
              <v-combobox
                dense
                :clearable="isEmr ? false : isEditable"
                placeholder="Gangguan Kognitif"
                class="mb-5"
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                :readonly="
                  !isEditable || emrData !== null || !isDoctor || isEmr
                "
                :items="resource.cogntiveImpairment"
                hide-details
                v-model="input.fallRisk.kid.cogntiveImpairment"
              />
              <label>Pembedahan/Sedasi/Anestesi</label>
              <v-combobox
                dense
                :clearable="isEmr ? false : isEditable"
                placeholder="Pembedahan/Sedasi/Anestesi"
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                :readonly="
                  !isEditable || emrData !== null || !isDoctor || isEmr
                "
                :items="resource.surgery"
                hide-details
                v-model="input.fallRisk.kid.surgery"
              />
            </v-row>
          </v-col>
          <v-col cols="6" class="grid-display">
            <label>Penggunaan Medikamentosa</label>
            <v-combobox
              dense
              :clearable="isEmr ? false : isEditable"
              placeholder="Penggunaan Medikamentosa"
              :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
              :readonly="!isEditable || emrData !== null || !isDoctor || isEmr"
              :items="resource.medicamentosa"
              hide-details
              v-model="input.fallRisk.kid.medicamentosa"
            />
            <h4 class="ml-5 text-start font-weight-bold primary--text">
              Total Skor :
            </h4>
            <h4 class="text-start font-weight-bold primary--text">
              {{ fallRiskAssesmentResult.score }}
            </h4>
            <h4 class="ml-5 text-start font-weight-bold primary--text">
              Hasil :
            </h4>
            <div :class="`chip-${fallRiskAssesmentResult?.color}`">
              {{ fallRiskAssesmentResult?.text }}
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="mb-4">
          <v-col cols="6">
            <v-row no-gutters align="baseline" class="grid-display">
              <label>Riwayat Jatuh (Baru saja atau dalam 3 bulan)</label>
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.fallRisk.adult.fallHistory"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 25 }"
                ></v-radio>
              </v-radio-group>
              <label>Diagnosis Sekunder</label>
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.fallRisk.adult.secDiagnose"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 25 }"
                ></v-radio>
              </v-radio-group>
              <label>Alat Bantu Jalan</label>
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.fallRisk.adult.walker"
              >
                <v-radio
                  label="Normal"
                  :value="{ check: 'normal', score: 0 }"
                ></v-radio>
                <v-radio
                  label="Penopang"
                  :value="{ check: 'crutch', score: 15 }"
                ></v-radio>
                <v-radio
                  label="Furnitur"
                  :value="{ check: 'furniture', score: 30 }"
                ></v-radio>
              </v-radio-group>
              <label>Memakai Terapi Heparin Lock/IV</label>
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.fallRisk.adult.heparinLockTherapy"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 20 }"
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-col>
          <v-col cols="6" class="grid-display">
            <label>Cara Berjalan</label>
            <v-radio-group
              dense
              hide-details
              row
              class="my-0"
              :readonly="!isEditable || isEmr"
              v-model="input.fallRisk.adult.walk"
            >
              <v-radio
                label="Normal"
                :value="{ check: 'normal', score: 0 }"
              ></v-radio>
              <v-radio
                label="Lemah"
                :value="{ check: 'weak', score: 10 }"
              ></v-radio>
              <v-radio
                label="Terganggu"
                :value="{ check: 'disturbed', score: 20 }"
              ></v-radio>
            </v-radio-group>
            <label>Status Mental</label>
            <v-radio-group
              dense
              hide-details
              row
              class="my-0"
              :readonly="!isEditable || isEmr"
              v-model="input.fallRisk.adult.mentalStatus"
            >
              <v-radio
                label="Sesuai Kemampuan"
                :value="{ check: 'accordingToAbility', score: 0 }"
              ></v-radio>
              <v-radio
                label="Lupa"
                :value="{ check: 'forget', score: 20 }"
              ></v-radio>
            </v-radio-group>
            <h4 class="ml-5 text-start font-weight-bold primary--text">
              Total Skor :
            </h4>
            <h4 class="text-start font-weight-bold primary--text">
              {{ fallRiskAssesmentResult?.score }}
            </h4>
            <h4 class="ml-5 text-start font-weight-bold primary--text">
              Hasil :
            </h4>
            <div :class="`chip-${fallRiskAssesmentResult?.color}`">
              {{ fallRiskAssesmentResult?.text }}
            </div>
          </v-col>
        </v-row>

        <pain-assessment :is-disabled="isEmr ? true : !isEditable" />

        <div class="title-container my-3">
          <h3 ref="doctor" class="grey--text text-center my-10">
            ASSESMEN DOKTER
          </h3>
        </div>

        <v-card-title
          class="title required font-weight-bold primary--text text-left"
        >
          Anamnesis
        </v-card-title>

        <v-row
          :key="'anamnesis-' + index"
          v-for="(anamnesis, index) in input.doctorAnamnesis"
        >
          <v-col cols="6" class="ml-4 py-0">
            <div>
              <v-textarea
                no-resize
                height="100"
                outlined
                dense
                single-line
                placeholder="Keluhan utama"
                v-model="input.doctorAnamnesis[index]"
                :rules="
                  index === 0 && isDoctor && isEditable && !isEmr ? rules : []
                "
                :id="'anamnesis-doctor-' + index"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
              <div style="margin-top: -3vh" class="d-flex justify-end">
                <v-btn
                  :disabled="
                    isEmr
                      ? true
                      : !isEditable ||
                        input.doctorAnamnesis.length < 2 ||
                        !isDoctor
                  "
                  @click="deleteDoctorAnamnesis(index)"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn
                  :disabled="isEmr ? true : !isEditable || !isDoctor"
                  @click="addDoctorAnamnesis()"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Data Objektif TTV
        </v-card-title>
        <service-assessment-objective-data
          :is-editable="false"
          :is-kia="true"
        />

        <v-card-title class="title font-weight-bold primary--text text-left">
          Pemeriksaan Fisik
        </v-card-title>
        <img src="@/assets/image/HumanBody.svg" alt="Human Body Image" />
        <v-row>
          <v-col cols="6" class="grid-display">
            <label>Kepala</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              hide-details
              dense
              v-model="input.physical.head"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Mata</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.eye"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Telinga</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.ears"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Hidung</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.nose"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Mulut</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.mouth"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Leher</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.neck"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Rambut</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.hair"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Bibir</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.lip"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Gigi Geligi</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.teeth"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Lidah</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.tongue"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Langit-langit</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.palate"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Tenggorokan</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.throat"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Tonsil</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.tonsils"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Dada</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.chest"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Payudara</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.breast"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
          </v-col>
          <v-col cols="6" class="grid-display">
            <label>Punggung</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.back"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Perut</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              v-model="input.physical.stomach"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
            <label>Genitalia</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.genitalia"
            />
            <label>Anus/Dubur</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.anus"
            />
            <label>Lengan Atas</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.upperArm"
            />
            <label>Lengan Bawah</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.forearm"
            />
            <label>Jari Tangan</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.handFingers"
            />
            <label>Kuku Tangan</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.handNails"
            />
            <label>Persendian Tangan</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.handJoints"
            />
            <label>Tungkai Atas</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.upperLimbs"
            />
            <label>Tungkai Bawah</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.lowerLimbs"
            />
            <label>Jari Kaki</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.toes"
            />
            <label>Kuku Kaki</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.toeNails"
            />
            <label>Persendian Kaki</label>
            <v-text-field
              placeholder="Contoh: Normal"
              class="mb-5"
              dense
              hide-details
              :readonly="!isEditable || emrData !== null || !isDoctor"
              v-model="input.physical.legJoints"
            />
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Hasil Penunjang Lainnya
        </v-card-title>
        <v-row>
          <v-col cols="6">
            <v-row no-gutters align="baseline" class="grid-display">
              <label>Hasil EKG</label>
              <v-textarea
                outlined
                dense
                placeholder="Masukkan Hasil EKG"
                v-model="input.ekg"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
              <label>Hasil Laboratorium</label>
              <v-textarea
                outlined
                dense
                placeholder="Masukkan Hasil Laboratorium"
                v-model="input.laboratorium"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
              <label>Pemeriksaan Leopold</label>
              <v-textarea
                outlined
                dense
                placeholder="Masukkan Hasil Pemeriksaan Leopold"
                v-model="input.ctg"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row class="grid-display">
              <label>Hasil Radiologi</label>
              <v-textarea
                outlined
                dense
                placeholder="Masukkan Hasil Radiologi"
                v-model="input.radiology"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
              <label>Catatan Lain</label>
              <v-textarea
                outlined
                dense
                placeholder="Masukkan Catatan Lainnya"
                v-model="input.othersNote"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Assesmen
        </v-card-title>
        <v-row>
          <v-col cols="6" class="grid-display mb-4">
            <label class="required">Diagnosa Utama</label>
            <v-autocomplete
              return-object
              :clearable="isEmr ? false : isEditable"
              dense
              :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
              placeholder="Pilih Diagnosa Utama"
              hide-details="auto"
              :items="resource.mainDiagnose"
              item-text="displayName"
              item-key="id"
              v-model="input.mainDiagnose.text"
              :loading="diagnoseLoading"
              :search-input.sync="searchDiagnose"
              :rules="isDoctor && isEditable && !isEmr ? rules : []"
              id="mainDiagnose"
              :readonly="!isEditable || emrData !== null || !isDoctor"
              @change="
                getDiagnose('', true, false, false);
                onClickActionButton('open-referral', 0);
              "
            />
          </v-col>
          <v-col cols="6" class="grid-display">
            <label>Informasi Keterangan Klinis</label>
            <v-text-field
              dense
              placeholder="Masukkan Informasi"
              hide-details
              v-model="input.mainDiagnose.detail"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
          </v-col>
          <template v-for="(diagnose, index) in input.secondaryDiagnose">
            <v-col
              cols="6"
              class="py-0 grid-display"
              :key="'diagnoseLabel-' + index"
            >
              <label v-if="index === 0">Diagnosa Sekunder</label>
              <v-col cols="5" v-else></v-col>
              <div class="d-flex pa-0 mb-4">
                <v-autocomplete
                  dense
                  return-object
                  placeholder="Pilih Diagnosa Sekunder"
                  hide-details
                  :items="resource.secondaryDiagnose[index].options"
                  :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                  item-text="displayName"
                  item-key="id"
                  @focus="secDiagnoseActvIdx = index"
                  :loading="secDiagnoseLoading"
                  v-model="input.secondaryDiagnose[index].text"
                  :search-input.sync="searchDiagnoseSecondary[index]"
                  :clearable="isEmr ? false : isEditable"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                />
                <v-btn
                  :disabled="
                    isEmr
                      ? true
                      : !isEditable || !isDoctor
                      ? true
                      : input.secondaryDiagnose.length < 2
                  "
                  icon
                  @click="deleteSecDiagnose(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  :disabled="isEmr ? true : !isEditable || !isDoctor"
                  icon
                  @click="addDiagnose()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="py-0 mb-4 grid-display"
              :key="'diagnoseInfo-' + index"
            >
              <label>Informasi Keterangan Klinis</label>
              <v-text-field
                dense
                placeholder="Masukkan Informasi"
                hide-details
                v-model="input.secondaryDiagnose[index].detail"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
            </v-col>
          </template>

          <v-col cols="6" class="grid-display">
            <label class="required">Prognosa</label>
            <v-autocomplete
              dense
              single-line
              return-object
              class="ma-0 pa-0"
              :rules="isDoctor && isEditable && !isEmr ? rules : []"
              v-model="input.prognosa"
              :items="resource.prognosa"
              :loading="prognosaLoading"
              :clearable="isEmr ? false : isEditable"
              item-text="name"
              placeholder="Pilih prognosa"
              append-icon="mdi-chevron-down"
              :readonly="isEmr ? true : !isEditable"
            />
          </v-col>

          <v-col cols="6" class="grid-display">
            <label class="required">Status Pulang</label>
            <v-select
              item-value="code"
              item-text="name"
              @change="onClickActionButton('open-referral', 0)"
              dense
              single-line
              id="returnstatus"
              placeholder="Pilih Status Pulang"
              :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
              :rules="isDoctor && isEditable && !isEmr ? rules : []"
              :items="resource.return_status"
              v-model="input.returnStatus"
              :clearable="isEmr ? false : isEditable"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
          </v-col>
          <v-col cols="6">
            <v-row no-gutters align="baseline" class="grid-display">
              <label>Pasien Kronis</label>
              <v-radio-group
                row
                hide-details
                class="mt-0 pt-0 mb-6"
                v-model="input.isChronic"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              >
                <v-radio label="Ya" :value="true"></v-radio>
                <v-radio label="Tidak" :value="false"></v-radio>
              </v-radio-group>
              <template
                v-if="
                  input.returnStatus == 4 &&
                    input.mainDiagnose.text?.isNonSpecialist &&
                    isBPJS
                "
              >
                <label>Jenis Rujukan</label>
                <v-radio-group
                  hide-details
                  row
                  class="my-4 pt-0"
                  v-model="input.referralType"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                >
                  <v-radio label="Rujuk dengan TACC" :value="true"></v-radio>
                  <v-radio
                    class="mt-lg-4 mt-xl-0"
                    label="Rujuk Tanpa TACC"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </template>
              <template
                v-if="input.returnStatus == 4 && input.referralType && isBPJS"
              >
                <label>Faktor Rujukan</label>
                <v-radio-group
                  hide-details
                  row
                  class="my-4 pt-0"
                  v-model="input.referralFactor"
                  @change="selectedReferralFactor()"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                >
                  <v-radio dense light label="Time" :value="1"></v-radio>
                  <v-radio
                    dense
                    light
                    label="Complication"
                    :value="2"
                  ></v-radio>
                  <v-radio dense light label="Age" :value="3"></v-radio>
                  <v-radio
                    dense
                    light
                    label="Comorbidity"
                    :value="4"
                    class="mt-3"
                  ></v-radio>
                </v-radio-group>
                <label>Alasan</label>
                <v-combobox
                  dense
                  single-line
                  v-if="input.referralFactor == 3"
                  outlined
                  v-model="input.referralReason"
                  :items="resource.referralReason"
                  :search-input.sync="searchComplicationDiagnose"
                  :loading="complicationDiagnoseLoading"
                >
                </v-combobox>
                <v-combobox
                  v-else
                  dense
                  single-line
                  outlined
                  v-model="input.referralReason"
                  :items="resource.referralReason"
                >
                </v-combobox>
              </template>
              <template v-if="input.returnStatus == 4 && isBPJS">
                <label>Surat Rujukan</label>
                <v-col class="text-left">
                  <v-btn
                    @click="openReferralLetter(true, 0)"
                    color="primary"
                    class="font-weight-bold white--text text-capitalize"
                    :class="{ bounce: bounce }"
                    depressed
                    ><v-icon left>mdi-email-outline</v-icon> Surat
                    Rujukan</v-btn
                  >
                </v-col>
              </template>
            </v-row>
          </v-col>

          <v-col cols="6" class="grid-display">
            <template v-for="(cause, index) in input.deathCause">
              <label :key="'causeLabel-' + index" v-if="index === 0"
                >Penyebab Kematian</label
              >
              <v-col v-else cols="5" :key="'causeLabel' + index"></v-col>
              <v-combobox
                :key="'causeInput-' + index"
                dense
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                placeholder="Pilih Penyebab Kematian"
                :items="resource.diagnose"
                item-text="text"
                @focus="deathCauseActvIdx = index"
                :search-input.sync="searchDeathCause[index]"
                :loading="diagnoseLoading"
                v-model="input.deathCause[index]"
                @change="addDeathCause($event)"
                :clearable="isEmr ? false : isEditable"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
            </template>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text">
          Planning
        </v-card-title>
        <v-row>
          <v-col class="grid-display">
            <label>Rencana Pelayanan / KIE</label>
            <template v-for="(action, index) in input.actions">
              <div v-if="index" :key="'therapi' + index"></div>
              <v-col :key="'action-' + index" class="ma-0 pa-0">
                <v-textarea
                  dense
                  outlined
                  height="100"
                  placeholder="Rencana Pelayanan / KIE"
                  v-model="input.actions[index]"
                  :id="'action-' + index"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                />
                <div style="margin-top: -3vh" class="d-flex justify-end">
                  <v-btn
                    :disabled="
                      isEmr
                        ? true
                        : !isEditable || !isDoctor
                        ? true
                        : input.actions.length < 2
                    "
                    @click="deleteAction(index)"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="isEmr ? true : !isEditable || !isDoctor"
                    @click="addAction()"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-plus</v-icon></v-btn
                  >
                </div>
              </v-col>
            </template>
            <label>Rencana Terapi</label>
            <template v-for="(therapy, index) in input.therapies">
              <div v-if="index" :key="'therapi' + index"></div>
              <v-col :key="'therapy' + index" class="ma-0 pa-0">
                <div class="d-flex pa-0 mb-4">
                  <v-text-field
                    dense
                    :placeholder="'Terapi ' + (index + 1)"
                    :id="'therapy-' + index"
                    v-model="input.therapies[index]"
                    :readonly="!isEditable || emrData !== null || !isDoctor"
                  />
                  <v-btn
                    :disabled="
                      isEmr
                        ? true
                        : !isEditable || !isDoctor
                        ? true
                        : input.therapies.length < 2
                    "
                    icon
                    @click="deleteTherapy(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="isEmr ? true : !isEditable || !isDoctor"
                    icon
                    @click="addTherapy()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div></v-col
              ></template
            >
          </v-col>
          <v-col class="grid-display">
            <label>ICD-9</label>
            <v-col class="ma-0 pa-0">
              <v-combobox
                v-for="(procedure, index) in input.procedure"
                class="icd-input"
                :clearable="isEmr ? false : isEditable"
                dense
                :readonly="
                  !isEditable || emrData !== null || !isDoctor || isEmr
                "
                :key="'item' + index"
                hide-details="auto"
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                :items="resource.procedure"
                item-text="displayName"
                v-model="input.procedure[index]"
                :loading="procedureLoading"
                :search-input.sync="searchProcedure[index]"
                :id="'procedure-' + index"
              />
            </v-col>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-card-actions
      v-bind:class="{
        'd-flex': true,
        'justify-space-between': isEmr,
        'justify-end': !isEmr,
      }"
    >
      <v-btn
        v-if="isEmr"
        width="108"
        height="36"
        class="text-none"
        text
        @click="onClickActionButton('close-form')"
      >
        Kembali
      </v-btn>
      <v-btn
        @click="onClickActionButton('open-action', 1)"
        width="138"
        height="36"
        color="success"
        class="text-none"
        depressed
        :disabled="!isEditable"
        v-if="!isEmr && !isIhs"
        :loading="saveLoading"
      >
        <!-- :disabled="!isEditable || !valid" -->
        Input Tindakan
      </v-btn>
      <v-btn
        width="130"
        height="36"
        class="text-capitalize ml-15"
        color="primary"
        outlined
        depressed
        @click="openICare"
        v-if="!isEmr && isBPJS"
        :disabled="!isEditable"
        :loading="saveLoading"
      >
        I-Care Pasien
      </v-btn>
      <v-btn
        v-if="!isEmr"
        width="108"
        height="36"
        class="text-none ml-15"
        text
        @click="onClickActionButton('close-form')"
      >
        Kembali
      </v-btn>
      <v-btn
        @click="onClickActionButton('save')"
        width="108"
        height="36"
        color="primary"
        depressed
        :disabled="!isEditable"
        v-if="!emrData"
        :loading="saveLoading"
      >
        Simpan
      </v-btn>
      <v-btn
        @click="handlePrint()"
        width="108"
        height="36"
        color="primary"
        depressed
        class="text-none ml-5"
        v-if="isEmr && isInputed"
      >
        Print
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialog" persistent max-width="1200px">
      <ReferralLetter
        v-if="selectedForm === 0 && dialog"
        :patient-data="patientData"
        :letter-data="referralLetterData"
        @close-form="dialog = false"
        @save="saveReferralLetter"
      />
      <BHPandAction
        v-if="selectedForm === 1 && dialog"
        :patient-data="selectedPatient"
        @close-form="dialog = false"
      />
    </v-dialog>
    <v-dialog v-model="iCareDialog" :max-width="1280">
      <div style="height: 90vh; overflow: hidden">
        <i-care-bpjs
          v-if="iCareDialog"
          :bpjs-code="patientData.meta.bpjs.number"
          @close-dialog="iCareDialog = false"
        />
      </div>
    </v-dialog>
    <alert-card :data="{ ...alertData, remove: removeAlert }" rightPos="26vw" />
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import BHPandAction from '../BHPandAction.vue';
import AlertMixin from '@/mixin/alertMixin';
import jwtMixin from '@/mixin/jwtMixin';
import calculationMixin from '@/mixin/calculationMixin';
import printMixin from '@/mixin/printMixin';
import assestmentMixin from '@/mixin/assestmentMixin';
import ReferralLetter from '../../../SharedComponent/Dialog/ReferralLetter.vue';
import { createNamespacedHelpers } from 'vuex';
import PainAssessment from '../../../SharedComponent/Assesment/PainAssessment.vue';
import ServiceAssessmentObjectiveData from '../AssessmentComponents/ServiceAssessmentObjectiveData.vue';
const { scrollToEmptyInput } = require('@/helper');
import { getPrognosa, getAllergies } from '@/fetchApi/MasterData/Allergy';
import { AuthenticationCheck } from '@/fetchApi/Setting/PcarePassword';
import AlertCard from '../../../SharedComponent/Snackbar/AlertCard.vue';
import ICareBpjs from '../../../SharedComponent/Assesment/ICareBpjs.vue';

const {
  mapGetters: mapPainAssessmentGetters,
  mapMutations: mapPainAssessmentMutations,
} = createNamespacedHelpers('PainAssessment');
const {
  mapGetters: mapServiceAssessmentObjectiveDataGetters,
  mapMutations: mapServiceAssessmentObjectiveDataMutations,
} = createNamespacedHelpers('ServiceAssessmentObjectiveData');
const axios = require('axios');
const _ = require('lodash');
const moment = require('moment-timezone');
let count = 0;
let watchCount = 0;

export default {
  name: 'InputAssessment',
  mixins: [AlertMixin, jwtMixin, calculationMixin, printMixin, assestmentMixin],
  components: {
    ReferralLetter,
    BHPandAction,
    ICareBpjs,
    PainAssessment,
    ServiceAssessmentObjectiveData,
    AlertCard,
  },
  props: {
    isIhs: {
      type: Boolean,
      default: null,
    },
    patientData: {
      type: Object,
      default: null,
    },
    isEmr: {
      type: Boolean,
    },
    emrData: {
      type: Object,
      default: null,
    },
    tab: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      saveLoading: false,
      iCareDialog: false,
      dialog: false,
      scrollValue: 0,
      bounce: false,
      referralLetterData: '',
      formHasChanged: false,
      selectedForm: null,
      psychologyStatusCheckbox: '',
      psychologyStatus: [],
      alertData: {
        show: false,
        title: '',
        text: '',
      },
      input: {
        prognosa: '',
        referralType: '',
        referralReason: '',
        referralFactor: '',
        anamnesis: [''],
        doctorAnamnesis: [''],
        priorDisease: [''],
        allergy: {
          drug: [],
          food: [],
          air: [],
          others: [''],
        },
        fallRisk: {
          kid: {
            surgery: '',
            diagnose: '',
            cogntiveImpairment: '',
            medicamentosa: '',
          },
          adult: {
            heparinLockTherapy: { check: false, score: 0 },
            mentalStatus: { check: 'accordingToAbility', score: 0 },
            fallHistory: { check: false, score: 0 },
            secDiagnose: { check: false, score: 0 },
            walker: { check: 'normal', score: 0 },
            walk: { check: 'normal', score: 0 },
          },
        },
        nutritionScreening: {
          kid: {
            isThin: { check: false, score: 0 },
            isLossWeight: { check: false, score: 0 },
            isHasCondition: { check: false, score: 0 },
            isMalnutritionRisk: { check: false, score: 0 },
          },
          adult: {
            isUnplannedWeightLoss: { check: 'no', score: 0 },
            isUnplannedWeightLossAmount: '',
            isFoodIntakePoorDueToDecreasedAppetite: { check: false, score: 0 },
            isHaveSeriousIllness: { check: false, score: 0 },
          },
        },
        ekg: '',
        radiology: '',
        laboratorium: '',
        othersNote: '',
        ctg: '',
        mainDiagnose: {
          text: '',
          detail: '',
        },
        procedure: [''],
        secondaryDiagnose: [
          {
            text: '',
            detail: '',
          },
        ],
        isChronic: null,
        deathCause: [''],
        returnStatus: '',
        actions: [''],
        therapies: [''],
        physical: {
          head: '',
          eye: '',
          ears: '',
          nose: '',
          hair: '',
          lip: '',
          teeth: '',
          tongue: '',
          palate: '',
          throat: '',
          tonsils: '',
          mouth: '',
          neck: '',
          stomach: '',
          genitalia: '',
          chest: '',
          breast: '',
          back: '',
          anus: '',
          upperArm: '',
          upperLimbs: '',
          lowerLimbs: '',
          forearm: '',
          handFingers: '',
          toes: '',
          toeNails: '',
          handNails: '',
          handJoints: '',
          legJoints: '',
        },
      },
      resource: {
        mainDiagnose: [],
        secondaryDiagnose: [{ options: [] }],
        prognosa: [],
        allergy: {
          drug: [],
          food: [],
          air: [],
        },
        medicamentosa: [
          {
            text:
              'Penggunaan Multiple Sedative, Obat Hypnosis, Barbiturate, Fenotiazi, Antidepressan, Pencahar, Diuretic, Narkose',
            score: 3,
            value: 'useOfMultipleSedative',
          },
          {
            text: 'Penggunaan Obat Salah Satu di Atas',
            score: 2,
            value: 'useOfAnyOfAboveDrug',
          },
          {
            text: 'Penggunaan Medikasi Lainnya / Tidak Ada Medikasi',
            score: 1,
            value: 'useOfOtherMedication',
          },
        ],
        surgery: [
          {
            text: 'Dalam 24 Jam',
            value: 'oneDay',
            score: 3,
          },
          {
            text: 'Dalam 48 Jam',
            value: 'twoDay',
            score: 2,
          },
          {
            text: '>48 jam dan Tidak Mengalami Pembedahan/Sedasi/Anestesi',
            value: 'aboveTwoDay',
            score: 1,
          },
        ],
        cogntiveImpairment: [
          {
            text: 'Tidak Menyadari Keterbatasan Lainnya',
            score: 3,
            value: 'notAwareOfOtherLimitation',
          },
          {
            text: 'Lupa Akan Adannya Keterbatasan',
            score: 2,
            value: 'forgetAboutLimitations',
          },
          {
            text: 'Orientasi Baik Terhadap Diri Sendiri',
            score: 1,
            value: 'goodSelfOriented',
          },
        ],
        DiagnoseFallRisk: [
          {
            text: 'Diagnosis Neurologi',
            score: 4,
            value: 'neurologi',
          },
          {
            text:
              'Perubahan Okisgenasi (diagnosis respiratorik, dehidrasi, anemia, anoreksia, sinkop, dll)',
            score: 3,
            value: 'oxygenationChange',
          },
          {
            text: 'Gangguan Perilaku/Psikiatri',
            score: 2,
            value: 'behaviorDisorder',
          },
          {
            text: 'Diagnosis Lainnya',
            score: 1,
            value: 'other',
          },
        ],
        psychologyStatus: ['Cemas', 'Takut', 'Marah', 'Sedih'],
        awareness: [],
        return_status: [],
        diagnose: [],
        referralReason: [],
        procedure: [],
      },
      factorReferral: {
        complication: [],
        time: ['< 3 Hari', '>= 3 - 7 Hari', '>= 7 Hari'],
        age: [
          '< 1 Bulan',
          '>= 1 Bulan s/d < 12 Bulan',
          '>= 1 Tahun s/d < 5 Tahun',
          '>= 5 Tahun s/d < 12 Tahun',
          '>= 12 Tahun s/d < 55 Tahun',
          '>= 55 Tahun',
        ],
        comorbidity: ['< 3 Hari', '>= 3 - 7 Hari', '>= 7 Hari'],
      },
      diagnoseLoading: false,
      secDiagnoseLoading: false,
      deathCauseLoading: false,
      procedureLoading: false,
      prognosaLoading: false,
      allergyLoading: false,
      searchDiagnose: '',
      searchComplicationDiagnose: '',
      secDiagnoseActvIdx: 0,
      deathCauseActvIdx: 0,
      searchProcedure: [],
      searchDiagnoseSecondary: [],
      searchDeathCause: [],
      valid: true,
      rules: [v => !!v || 'Wajib diisi'],
      isInputed: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.runApiCalls(() => {
        this.getAwarness();
        this.getProcedure();
        this.getDataAssessment();
        this.getReturnStatus();
        this.resolveGetPrognosa();
        this.resolveGetAllergies();
        this.checkPcarePassword();
      });
    });
  },
  beforeDestroy() {
    watchCount = 0;
  },
  computed: {
    ...mapPainAssessmentGetters([
      'getScore',
      'getPainFactor',
      'getPainLike',
      'getPainRadiation',
      'getSeverity',
      'getPainFrequency',
      'getPainType',
      'getPainDuration',
      'getDescription',
    ]),
    ...mapServiceAssessmentObjectiveDataGetters([
      'getSistol',
      'getDiastol',
      'getPulse',
      'getWeight',
      'getHeight',
      'getBellyCircumference',
      'getRespirationRate',
      'getSaturation',
      'getTemperature',
      'getAwareness',
      'getDjj',
    ]),
    // Obective data (TTV) state
    sistole: {
      get() {
        return this.getSistol;
      },
      set(value) {
        this.setSistol(value);
      },
    },
    diastole: {
      get() {
        return this.getDiastol;
      },
      set(value) {
        this.setDiastol(value);
      },
    },
    weight: {
      get() {
        return this.getWeight;
      },
      set(value) {
        this.setWeight(value);
      },
    },
    height: {
      get() {
        return this.getHeight;
      },
      set(value) {
        this.setHeight(value);
      },
    },
    bellyCircumference: {
      get() {
        return this.getBellyCircumference;
      },
      set(value) {
        this.setBellyCircumference(value);
      },
    },
    respiration: {
      get() {
        return this.getRespirationRate;
      },
      set(value) {
        this.setRespirationRate(value);
      },
    },
    saturation: {
      get() {
        return this.getSaturation;
      },
      set(value) {
        this.setSaturation(value);
      },
    },
    temperature: {
      get() {
        return this.getTemperature;
      },
      set(value) {
        this.setTemperature(value);
      },
    },
    pulse: {
      get() {
        return this.getPulse;
      },
      set(value) {
        this.setPulse(value);
      },
    },
    awareness: {
      get() {
        return this.getAwareness;
      },
      set(value) {
        this.setAwareness(value);
      },
    },
    djj: {
      get() {
        return this.getDjj;
      },
      set(value) {
        this.setDjj(value);
      },
    },
    // Pain assessment state
    score: {
      get() {
        return this.getScore;
      },
      set(value) {
        this.setScore(value);
      },
    },
    pain_factor: {
      get() {
        return this.getPainFactor;
      },
      set(value) {
        this.setPainFactor(value);
      },
    },
    pain_like: {
      get() {
        return this.getPainLike;
      },
      set(value) {
        this.setPainLike(value);
      },
    },
    pain_radiation: {
      get() {
        return this.getPainRadiation;
      },
      set(value) {
        this.setPainRadiation(value);
      },
    },
    severity: {
      get() {
        return this.getSeverity;
      },
      set(value) {
        this.setSeverity(value);
      },
    },
    pain_frequency: {
      get() {
        return this.getPainFrequency;
      },
      set(value) {
        this.setPainFrequency(value);
      },
    },
    pain_duration: {
      get() {
        return this.getPainDuration;
      },
      set(value) {
        this.setPainDuration(value);
      },
    },
    pain_type: {
      get() {
        return this.getPainType;
      },
    },
    description: {
      get() {
        return this.getDescription;
      },
      set(value) {
        this.setDescription(value);
      },
    },
    isAdult() {
      const birthDate = moment(this.patientData.meta.birthDate, 'DD MMMM YYYY');
      const diff = moment().diff(birthDate, 'month');
      return diff > 228;
    },
    kidAge() {
      let result,
        age = this.patientData.meta.age;
      if (age < 3) {
        result = {
          text: '<3 Tahun',
          score: 4,
        };
      }
      if (age >= 3 && age <= 7) {
        result = {
          text: '3-7 Tahun',
          score: 3,
        };
      }
      if (age > 7 && age <= 13) {
        result = {
          text: '7-13 Tahun',
          score: 2,
        };
      }
      if (age > 13) {
        result = {
          text: '>13 Tahun',
          score: 1,
        };
      }
      return result;
    },
    nutritionScreeningResult() {
      let score, result;
      if (this.isAdult) {
        if (
          this.input?.nutritionScreening?.adult.isUnplannedWeightLoss !== true
        ) {
          score =
            (this.input?.nutritionScreening?.adult?.isUnplannedWeightLoss
              ?.score || 0) +
            (this.input?.nutritionScreening?.adult
              ?.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
            (this.input?.nutritionScreening?.adult?.isHaveSeriousIllness
              ?.score || 0);
        } else {
          score =
            (this.input?.nutritionScreening?.adult?.isUnplannedWeightLossAmount
              ?.score || 0) +
            (this.input?.nutritionScreening?.adult
              ?.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
            (this.input?.nutritionScreening?.adult?.isHaveSeriousIllness
              ?.score || 0);
        }
        if (isNaN(score)) {
          result = null;
        }
        if (score <= 1) {
          result = {
            text: 'Tidak Berisiko Malnutrisi',
            color: 'green',
            score,
          };
        }
        if (score === 2) {
          result = {
            text: 'Risiko Malnutrisi',
            color: 'yellow',
            score,
          };
        }
        if (score >= 3) {
          result = {
            text: 'Malnutrisi',
            color: 'red',
            score,
          };
        }
      } else {
        score =
          (this.input?.nutritionScreening?.kid?.isThin?.score || 0) +
          (this.input?.nutritionScreening?.kid?.isLossWeight?.score || 0) +
          (this.input?.nutritionScreening?.kid?.isHasCondition?.score || 0) +
          (this.input?.nutritionScreening?.kid?.isMalnutritionRisk?.score || 0);

        if (isNaN(score)) {
          result = null;
        }
        if (score === 0) {
          result = {
            text: 'Berisiko Rendah',
            color: 'green',
            score,
          };
        }
        if (score >= 1 && score <= 3) {
          result = {
            text: 'Berisiko Sedang',
            color: 'yellow',
            score,
          };
        }
        if (score >= 4 && score <= 5) {
          result = {
            text: 'Berisiko Tinggi',
            color: 'red',
            score,
          };
        }
      }
      return result;
    },
    fallRiskAssesmentResult() {
      let score, result;
      if (this.isAdult) {
        score =
          (this.input?.fallRisk?.adult?.fallHistory?.score || 0) +
          (this.input?.fallRisk?.adult?.secDiagnose?.score || 0) +
          (this.input?.fallRisk?.adult?.walker?.score || 0) +
          (this.input?.fallRisk?.adult?.heparinLockTherapy?.score || 0) +
          (this.input?.fallRisk?.adult?.walk?.score || 0) +
          (this.input?.fallRisk?.adult?.mentalStatus?.score || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score <= 24) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
            score,
          };
        }
        if (score > 24 && score <= 50) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
            score,
          };
        }
        if (score > 50) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
            score,
          };
        }
      } else {
        score =
          (this.patientData.meta.gender === 'Laki-laki' ||
          this.patientData.meta.gender === true
            ? 2
            : 1) +
          this.kidAge?.score +
          (this.input.fallRisk?.kid?.diagnose?.score || 0) +
          (this.input.fallRisk?.kid?.cogntiveImpairment?.score || 0) +
          (this.input.fallRisk?.kid?.surgery?.score || 0) +
          (this.input.fallRisk?.kid?.medicamentosa?.score || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score < 7) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
            score,
          };
        }
        if (score >= 7 && score <= 11) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
            score,
          };
        }
        if (score > 11) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
            score,
          };
        }
      }
      return result;
    },
    tabs() {
      return this.scrollValue > 1250 ? 1 : 0;
    },
    isEditable() {
      const differenceBetweenRegistrationDateAndToday = this.differenceBetweenTwoDates(
        this.$store.getters?.selectedPatient?.registered_at,
        moment(),
      );
      return differenceBetweenRegistrationDateAndToday <= 30;
    },
    isDoctor() {
      return this.$store.getters.userLoggedIn.role.some(
        item =>
          item.toLowerCase() === 'dokter' ||
          item.toLowerCase() === 'bidan' ||
          item.toLowerCase() === 'fisioterapis',
      );
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
    isBPJS() {
      return this.patientData.meta.guarantorType.toLowerCase() == 'bpjs';
    },
  },
  watch: {
    input: {
      handler(val) {
        if (val) {
          watchCount += 1;
          if (watchCount > 1) {
            this.formHasChanged = true;
          }
        }
      },
      deep: true,
    },
    tab(val) {
      if (val === 1) {
        if (this.isDoctor) {
          setTimeout(() => {
            this.scrollTo('doctor');
          }, 500);
        }
      }
    },
    searchProcedure(val) {
      if (val) {
        const oldValues = this.input.procedure.map(procedure => {
          return procedure.displayName;
        });
        const index = val.findIndex((v, i) => {
          return v !== oldValues[i];
        });
        this.getProcedure(val[index]);
      } else {
        this.getProcedure('');
      }
    },
    searchDiagnose(val) {
      if (val) {
        this.searchMainDiagnose(this, val);
      }
    },
    searchDiagnoseSecondary(val) {
      if (!val) return;
      this.searchSecondaryDiagnose(this);
    },
    searchDeathCause(val) {
      if (!val) return;
      this.srchDeathCause(this);
    },
    searchComplicationDiagnose(val) {
      if (val) {
        this.getComplicationDiagnose(val);
      } else {
        this.getComplicationDiagnose('');
      }
    },
  },
  methods: {
    ...mapPainAssessmentMutations([
      'clearPainAssessmentState',
      'setScore',
      'setPainFactor',
      'setPainLike',
      'setPainRadiation',
      'setSeverity',
      'setPainFrequency',
      'setPainDuration',
      'setDescription',
    ]),
    ...mapServiceAssessmentObjectiveDataMutations([
      'clearObjectiveState',
      'setSistol',
      'setDiastol',
      'setPulse',
      'setWeight',
      'setHeight',
      'setBellyCircumference',
      'setRespirationRate',
      'setSaturation',
      'setTemperature',
      'setDjj',
      'setAwareness',
    ]),
    removeAlert() {
      this.alertData = {
        ...this.alertData,
        show: false,
      };
    },
    async checkPcarePassword() {
      if (
        this.$store.getters.selectedPatient?.meta?.guarantorType?.toLowerCase() !==
          'bpjs' &&
        this.isEditable &&
        !this.isEmr
      )
        return;
      try {
        await AuthenticationCheck();
      } catch (error) {
        if (error.response?.data?.code === 401) {
          this.alertData = {
            show: true,
            title: 'Password PCare Tidak Sesuai',
            text:
              'Silakan sesuaikan password PCare di menu Setting untuk dapat melakukan bridging',
          };
        }
        if (error?.response?.status >= 500) {
          this.alertData = {
            show: true,
            title: 'Bridging BPJS Error',
            text:
              'Anda masih bisa melanjutkan pendaftaran. Silahkan kirim ulang setelah menyelesaikannya',
          };
        }
      }
    },
    searchMainDiagnose: _.debounce((v, keyword) => {
      v.getDiagnose(keyword, true, false, false);
    }, 600),
    searchSecondaryDiagnose: _.debounce(v => {
      v.getDiagnose(
        v.searchDiagnoseSecondary[v.secDiagnoseActvIdx],
        false,
        true,
        false,
      );
    }, 600),
    srchDeathCause: _.debounce(v => {
      v.getDiagnose(
        v.searchDeathCause[v.deathCauseActvIdx],
        false,
        false,
        true,
      );
    }, 600),
    changeForm(val) {
      this.selectedForm = val;
      this.dialog = true;
    },
    openICare() {
      this.iCareDialog = true;
    },
    rangeRules(value, type) {
      switch (type) {
        case 'sistole':
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'diastole':
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'heart-rate':
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          }
          break;
        case 'rr':
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          }
          break;
        case 'temperature':
          if ((value < 20 || value > 45) && value !== '') {
            return 'Angka suhu tidak valid';
          }
          break;
        case 'height':
          if (value <= 1) {
            return 'Angka tinggi badan tidak valid';
          }
          break;
      }
    },
    async onClickActionButton(type, val) {
      switch (type) {
        case 'close-form':
          if (this.formHasChanged) {
            Swal.fire({
              title: 'Data yang Anda Masukkan Belum Tersimpan',
              text: 'Apakah Anda Ingin Menyimpan Terlebih Dahulu?',
              icon: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak',
            }).then(result => {
              if (!result.value) {
                this.$emit('close-form');
              }
            });
          } else {
            this.$emit('close-form');
          }
          break;
        case 'save':
          await this.saveData();
          break;
        case 'open-action':
          if (!this.$refs.form.validate()) {
            Swal.fire({
              title: 'Data Assesmen Poli Umum Ada yang Belum Diisi',
              text:
                'Silakan lengkapi terlebih dahulu sebelum menginput Tindakan',
              icon: 'warning',
            });
            this.valid = false;
            scrollToEmptyInput();
            return;
          }
          if (this.isInputed) {
            if (this.formHasChanged) {
              Swal.fire({
                title: 'Apakah Anda yakin ingin menyimpan perubahan ini?',
                icon: 'question',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
              }).then(result => {
                if (result.isConfirmed) {
                  this.saveData(true);
                }
              });
              return;
            }
            this.changeForm(1);
          } else {
            this.saveData(true);
          }
          break;
        case 'open-referral':
          this.openReferralLetter(false, val);
      }
    },
    selectedReferralFactor() {
      const selectedFactor = this.input.referralFactor;
      if (selectedFactor == 1) {
        this.resource.referralReason = this.factorReferral.time;
      } else if (selectedFactor == 3) {
        this.getComplicationDiagnose('');
      } else if (selectedFactor == 2) {
        this.resource.referralReason = this.factorReferral.age;
      } else if (selectedFactor == 4) {
        this.resource.referralReason = this.factorReferral.comorbidity;
      }
    },
    saveReferralLetter(data) {
      this.referralLetterData = data;
      this.dialog = false;
      this.bounce = true;
      const timeout = setTimeout(() => {
        this.bounce = false;
      }, 200);
      timeout;
      count += 1;
    },
    openReferralLetter(click = false, val) {
      if (click) {
        this.changeForm(val);
        return;
      }
      if (
        !this.input.mainDiagnose.text.isNonSpecialist &&
        this.input.returnStatus == 4 &&
        count < 1
      ) {
        this.changeForm(val);
      }
      if (
        this.input.mainDiagnose.text.isNonSpecialist &&
        this.input.returnStatus == 4
      ) {
        Swal.fire({
          title:
            'Apakah anda yakin akan merujuk pasien ke fasilitas rujukan tingkat lanjut?',
          text: 'Pasien termasuk ke dalam kategori non spesialistik',
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: 'Batal',
          confirmButtonText: 'Ya, saya yakin',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          icon: 'warning',
        }).then(result => {
          if (result.isConfirmed) {
            this.changeForm(val);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.input.returnStatus = '';
          }
        });
      }
    },
    onScroll() {
      const main = document.getElementById('main-container');
      this.scrollValue = main.scrollTop;
    },
    scrollTo(staff) {
      if (staff === 'doctor') {
        const el = this.$refs.doctor;
        if (el) {
          el.scrollIntoView();
        }
      }
      if (staff === 'nurse') {
        const el = this.$refs.nurse;
        if (el) {
          el.scrollIntoView();
        }
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    deleteSecDiagnose(index) {
      if (index !== 0) {
        this.resource.secondaryDiagnose.splice(index, 1);
        this.input.secondaryDiagnose.splice(index, 1);
      } else {
        this.input.secondaryDiagnose = [
          {
            text: '',
            detail: '',
          },
        ];
      }
    },
    addAnamnesis() {
      this.input.anamnesis.push('');
    },
    deleteAnamnesis(index) {
      this.input.anamnesis.splice(index, 1);
    },
    addDoctorAnamnesis() {
      this.input.doctorAnamnesis.push('');
    },
    deleteDoctorAnamnesis(index) {
      this.input.doctorAnamnesis.splice(index, 1);
    },
    addDisease() {
      this.input.priorDisease.push('');
    },
    deleteDisease(index) {
      this.input.priorDisease.splice(index, 1);
    },
    addAllergy() {
      this.input.allergy.push('');
    },
    deleteAllergy(index) {
      this.input.allergy.splice(index, 1);
    },
    addAction() {
      this.input.procedure.push('');
      this.input.actions.push('');
    },
    deleteAction(index) {
      this.input.procedure.splice(index, 1);
      this.input.actions.splice(index, 1);
    },
    addTherapy() {
      this.input.therapies.push('');
    },
    deleteTherapy(index) {
      this.input.therapies.splice(index, 1);
    },
    getReturnStatus() {
      axios
        .get(Constant.apiUrl.concat('/master/returnstatus/outpatient'))
        .then(response => {
          const data = response.data.data;
          this.resource.return_status = data.map(v => {
            return {
              code: v.code,
              name: v.description,
            };
          });
          this.resource.return_status.push({
            code: '2',
            name: 'Rawat Inap',
          });
        });
    },
    async resolveGetPrognosa() {
      try {
        this.prognosaLoading = true;
        const response = await getPrognosa();
        this.resource.prognosa = response.data.data.map(item => ({
          code: item.code,
          name: item.name,
        }));
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.prognosaLoading = false;
      }
    },
    async resolveGetAllergies() {
      try {
        this.allergyLoading = true;
        ['food', 'air', 'drug'].forEach(async item => {
          const response = await getAllergies(item);
          this.resource.allergy[item] = response.data.data.map(v => v.name);
        });
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.allergyLoading = false;
      }
    },
    getAwarness() {
      axios.get(Constant.apiUrl.concat('/master/awarness')).then(response => {
        const data = response.data.data;
        this.resource.awareness = data.map(v => {
          return {
            code: v.code,
            name: v.description,
          };
        });
      });
    },
    getDiagnose(
      keyword,
      isPrimer = true,
      isSecondary = true,
      isDeathCause = true,
    ) {
      if (keyword === '' || keyword === null) return;
      if (isPrimer) this.diagnoseLoading = true;
      if (isSecondary) this.secDiagnoseLoading = true;
      if (isDeathCause) this.deathCauseLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/diagnose?page=1&itemCount=20&search=${
                keyword === null ? '' : keyword
              }&sort=-code`,
            ),
          )
          .then(response => {
            let allDiagnose = response.data.data;
            if (isPrimer) {
              this.resource.mainDiagnose = allDiagnose.map(diagnose => {
                return {
                  id: diagnose._id.toString(),
                  code: diagnose.code,
                  text: diagnose.description,
                  isNonSpecialist:
                    diagnose?.nonSpesialis === undefined
                      ? ''
                      : diagnose?.nonSpesialis,
                  displayName: `${diagnose.code} - ${diagnose.description}`,
                };
              });
              this.diagnoseLoading = false;
            }
            if (isSecondary) {
              this.resource.secondaryDiagnose[
                this.secDiagnoseActvIdx
              ].options = allDiagnose.map(diagnose => {
                return {
                  id: diagnose._id.toString(),
                  code: diagnose.code,
                  text: diagnose.description,
                  isNonSpecialist:
                    diagnose?.nonSpesialis === undefined
                      ? ''
                      : diagnose?.nonSpesialis,
                  displayName: `${diagnose.code} - ${diagnose.description}`,
                };
              });
              this.secDiagnoseLoading = false;
            }
            if (isDeathCause) {
              this.resource.deathCauseDiagnose = allDiagnose.map(diagnose => {
                return {
                  id: diagnose._id.toString(),
                  code: diagnose.code,
                  text: diagnose.description,
                  isNonSpecialist:
                    diagnose?.nonSpesialis === undefined
                      ? ''
                      : diagnose?.nonSpesialis,
                  displayName: `${diagnose.code} - ${diagnose.description}`,
                };
              });
              this.deathCauseLoading = false;
            }
          })
          .catch(() => {
            if (isPrimer) this.diagnoseLoading = false;
            if (isSecondary) this.secDiagnoseLoading = false;
            if (isDeathCause) this.deathCauseLoading = false;
          });
      });
    },
    getComplicationDiagnose(keyword) {
      this.complicationDiagnoseLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/diagnose?page=1&itemCount=100&search=${
                keyword === null ? '' : keyword
              }&sort=-code`,
            ),
          )
          .then(response => {
            let allDiagnose = response.data.data;
            this.resource.referralReason = allDiagnose.map(diagnose => {
              return `${diagnose.code} - ${diagnose.description}`;
            });
            this.complicationDiagnoseLoading = false;
          })
          .catch(() => {
            this.complicationDiagnoseLoading = false;
          });
      });
    },
    getProcedure(keyword) {
      this.procedureLoading = true;
      axios
        .get(
          `${
            Constant.apiUrl
          }/master/procedure?page=1&itemCount=50&&search=${keyword || ''}`,
        )
        .then(response => {
          const { data } = response.data;
          this.resource.procedure = data.map(procedure => {
            return {
              id: procedure._id.toString(),
              code: procedure.code.toString(),
              text: procedure.description,
              displayName: `${procedure.code} ${procedure.description}`,
            };
          });
        })
        .catch(() => {
          this.resource.procedure = [];
        })
        .finally(() => {
          this.procedureLoading = false;
        });
    },
    addDiagnose() {
      this.resource.secondaryDiagnose.push({ options: [] });
      this.input.secondaryDiagnose.push({
        text: null,
        detail: '',
      });
    },
    changeDiagnose(event) {
      this.$nextTick(() => {
        if (event) {
          this.addDiagnose();
        } else {
          this.input.secondaryDiagnose.pop();
        }
      });
    },
    addDeathCause(event) {
      this.$nextTick(() => {
        if (event) {
          this.input.deathCause.push('');
        } else {
          this.input.deathCause.pop();
        }
      });
    },
    deleteItem(index, type) {
      switch (type) {
        case 1:
          if (index !== 0) {
            this.input.secondaryDiagnose.splice(index, 1);
          } else {
            this.input.secondaryDiagnose = [
              {
                text: '',
                detail: '',
              },
            ];
          }
          break;
        case 2:
          if (index !== 0) {
            this.input.deathCause.splice(index, 1);
          } else {
            this.input.deathCause = [''];
          }
          break;
        default:
          break;
      }
      // this.getDiagnose('');
    },
    // remapDiagnoseProcedure(main, secondary, procedures) {
    //   let mainDiagnose = { text: '', description: '' };
    //   let secondaryDiagnose = [{ text: '', description: '' }];
    //   let procedure = [''];

    //   if (main) {
    //     if (main?.diagnose) {
    //       mainDiagnose = {
    //         text: {
    //           id: main.diagnose._id,
    //           code: main.diagnose.code,
    //           text: main.diagnose.description,
    //           isNonSpecialist: main.diagnose?.nonSpesialis,
    //           displayName: `${main.diagnose.code} ${main.diagnose.description}`,
    //         },
    //         detail: main.description,
    //       };
    //     }
    //   }
    //   if (secondary.length > 0) {
    //     secondaryDiagnose = secondary.map(v => {
    //       return {
    //         text: {
    //           id: v.diagnose._id,
    //           code: v.diagnose.code,
    //           text: v.diagnose.description,
    //           displayName: `${v.diagnose.code} ${v.diagnose.description} `,
    //         },
    //         detail: v.description,
    //       };
    //     });
    //   }
    //   if (procedures.length > 0) {
    //     procedure = procedures.map(v => {
    //       return {
    //         id: v.id._id,
    //         code: v.id.code,
    //         text: v.id.description,
    //         displayName: `${v.id.code} ${v.id.description}`,
    //       };
    //     });
    //   }
    //   this.input = {
    //     ...this.input,
    //     mainDiagnose,
    //     secondaryDiagnose,
    //     procedure,
    //   };
    // },
    filterVariable(data) {
      let result;
      if (data === undefined) {
        result = null;
      } else {
        result = data;
      }
      return result;
    },
    async saveData(openAction) {
      this.$refs.objective.$refs.objectiveForm.validate();
      this.$refs.form.validate();

      const isValidated =
        this.$refs.form.validate() &&
        this.$refs.objective.$refs.objectiveForm.validate();

      if (await !isValidated) {
        this.valid = false;
        scrollToEmptyInput();
      } else {
        this.saveLoading = true;
        let payload;
        // payload for doctor user and non adult patient
        if (this.isDoctor && !this.isAdult) {
          payload = {
            isAdult: this.isAdult,
            checkup: {
              subjective: {
                anamnesis: this.input.anamnesis.filter(x => x !== ''),
                disease_history: this.input.priorDisease.filter(x => x !== ''),
                allergy_history: this.input.allergy.others.filter(
                  x => x !== '',
                ),
                allergy_air: this.input.allergy.air,
                allergy_food: this.input.allergy.food,
                allergy_drug: this.input.allergy.drug,
                treatment_history: this.input.treatmentHistory || '',
                psychological_status: this.input.psychologyStatusCheckbox
                  ? this.input.psychologyStatus || ''
                  : ['Tidak Ada Kelainan'],
                social_economy: this.input.socialEconomy || '',
                spiritual: this.input.spiritual || '',
              },
              nutrition_screening: {
                isThin: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isThin?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isThin?.score,
                  ),
                },
                isWeightLossLastMonth: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isLossWeight?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isLossWeight?.score,
                  ),
                },
                isThereOfTheseCondition: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isHasCondition?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isHasCondition?.score,
                  ),
                },
                isThereCausesMalnourished: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isMalnutritionRisk
                      ?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isMalnutritionRisk
                      ?.score,
                  ),
                },
              },
              objective: {
                heart_rate: this.pulse,
                sistol: this.sistole,
                diastol: this.diastole,
                respiration_rate: this.respiration,
                temperature: this.temperature,
                saturation: this.saturation,
                awarness: this.awareness,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.bellyCircumference,
                djj: this.djj,
              },
              assesment: {
                prognosis: this.input.prognosa,
                fallRisk: {
                  ageScore: this.kidAge.score,
                  genderScore:
                    this.patientData.meta.gender === 'Laki-laki' ||
                    this.patientData.meta.gender === true
                      ? 2
                      : 1,
                  diagnosis: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.diagnose?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.diagnose?.score,
                    ),
                  },
                  cognitiveImpairment: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.cogntiveImpairment?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.cogntiveImpairment?.score,
                    ),
                  },
                  surgery: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.surgery?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.surgery?.score,
                    ),
                  },
                  medicinalUse: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.medicamentosa?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.medicamentosa?.score,
                    ),
                  },
                },
                pain_assesment: {
                  type: this.pain_type,
                  detail: {
                    score: this.score,
                    pain_factor: this.pain_factor,
                    pain_like: this.pain_like,
                    pain_radiation: this.pain_radiation,
                    severity: this.severity,
                    pain_frequency: this.pain_frequency,
                    pain_duration: this.pain_duration,
                    description: this.description,
                  },
                },
                doctorAnamnesis: this.input.doctorAnamnesis.filter(
                  x => x !== '',
                ),
                main_diagnose: this.input.mainDiagnose,
                other_diagnose: this.input.secondaryDiagnose
                  .filter(x => x?.text?.id !== '' && x.text !== null)
                  .map(arr => {
                    return {
                      detail: arr.detail,
                      text: {
                        id: arr.text.id,
                        text: arr.text.text,
                        value: arr.text.code,
                      },
                    };
                  }),
                death_cause: this.input.deathCause
                  .filter(x => x)
                  .map(i => {
                    return {
                      detail: i.text,
                      text: {
                        id: i.id,
                        text: i.text,
                        value: i.code,
                      },
                    };
                  }),
                isChronic: this.input.isChronic,
                return_status: this.input.returnStatus,
                physical: {
                  head: this.input.physical.head || '',
                  eye: this.input.physical.eye || '',
                  ear: this.input.physical.ears || '',
                  nose: this.input.physical.nose || '',
                  mouth: this.input.physical.mouth || '',
                  neck: this.input.physical.neck || '',
                  hair: this.input.physical.hair || '',
                  lips: this.input.physical.lip || '',
                  teeth: this.input.physical.teeth || '',
                  tongue: this.input.physical.tongue || '',
                  palate: this.input.physical.palate || '',
                  throat: this.input.physical.throat || '',
                  tonsil: this.input.physical.tonsils || '',
                  chest: this.input.physical.chest || '',
                  breast: this.input.physical.breast || '',
                  back: this.input.physical.back || '',
                  stomach: this.input.physical.stomach || '',
                  genitalia: this.input.physical.genitalia || '',
                  anus: this.input.physical.anus || '',
                  upper_arm: this.input.physical.upperArm || '',
                  forearm: this.input.physical.forearm || '',
                  finger: this.input.physical.handFingers || '',
                  hand_nail: this.input.physical.handNails || '',
                  hand_joint: this.input.physical.handJoints || '',
                  upper_limb: this.input.physical.upperLimbs || '',
                  lower_limb: this.input.physical.lowerLimbs || '',
                  toe: this.input.physical.toes || '',
                  toe_nail: this.input.physical.toeNails || '',
                  leg_joint: this.input.physical.legJoints || '',
                },
              },
              planning: {
                treatment: this.input.actions.filter(x => x !== ''),
                therapy: this.input.therapies.filter(x => x !== ''),
                procedure: this.input.procedure
                  .filter(x => x !== '' && x !== null)
                  .map(procedure => {
                    return {
                      id: procedure.id,
                      text: procedure.text,
                    };
                  }),
              },
              other: {
                ekg: this.input.ekg,
                laboratory: this.input.laboratorium,
                radiology: this.input.radiology,
                ctg: this.input.ctg,
                other: this.input.othersNote,
              },
              timestamps: {
                doctor_by: this.$store.getters.userLoggedIn.id,
                doctor_created_at: moment().format(),
              },
            },
          };
        }
        // payload for doctor user and adult patient
        if (this.isDoctor && this.isAdult) {
          payload = {
            isAdult: this.isAdult,
            checkup: {
              subjective: {
                anamnesis: this.input.anamnesis.filter(x => x !== ''),
                disease_history: this.input.priorDisease.filter(x => x !== ''),
                allergy_history: this.input.allergy.others.filter(
                  x => x !== '',
                ),
                allergy_air: this.input.allergy.air,
                allergy_food: this.input.allergy.food,
                allergy_drug: this.input.allergy.drug,
                treatment_history: this.input.treatmentHistory || '',
                psychological_status: this.input.psychologyStatusCheckbox
                  ? this.input.psychologyStatus || ''
                  : ['Tidak Ada Kelainan'],
                social_economy: this.input.socialEconomy || '',
                spiritual: this.input.spiritual || '',
              },
              nutrition_screening: {
                isUnplannedWeightLoss: {
                  check:
                    this.input.nutritionScreening?.adult
                      ?.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLossAmount?.check,
                        )
                      : this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLoss?.check,
                        ),
                  score:
                    this.input.nutritionScreening?.adult
                      ?.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLossAmount?.score,
                        )
                      : this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLoss?.score,
                        ),
                },
                isFoodIntakePoorDueToDecreasedAppetite: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.adult
                      ?.isFoodIntakePoorDueToDecreasedAppetite?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.adult
                      ?.isFoodIntakePoorDueToDecreasedAppetite?.score,
                  ),
                },
                isHaveSeriousIllness: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.adult?.isHaveSeriousIllness
                      ?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.adult?.isHaveSeriousIllness
                      ?.score,
                  ),
                },
              },
              objective: {
                heart_rate: this.pulse,
                sistol: this.sistole,
                diastol: this.diastole,
                respiration_rate: this.respiration,
                temperature: this.temperature,
                saturation: this.saturation,
                awarness: this.awareness,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.bellyCircumference,
                djj: this.djj,
              },
              assesment: {
                prognosis: this.input.prognosa,
                fallRisk: {
                  fallHistory: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.fallHistory?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.fallHistory?.score,
                    ),
                  },
                  secondaryDiagnosis: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.secDiagnose?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.secDiagnose?.score,
                    ),
                  },
                  walkTool: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.walker?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.walker?.score,
                    ),
                  },
                  useHeparinLockTherapy: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.heparinLockTherapy?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.heparinLockTherapy?.score,
                    ),
                  },
                  howToWalk: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.walk?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.walk?.score,
                    ),
                  },
                  mentalStatus: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.mentalStatus?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.mentalStatus?.score,
                    ),
                  },
                },
                pain_assesment: {
                  type: this.pain_type,
                  detail: {
                    score: this.score,
                    pain_factor: this.pain_factor,
                    pain_like: this.pain_like,
                    pain_radiation: this.pain_radiation,
                    severity: this.severity,
                    pain_frequency: this.pain_frequency,
                    pain_duration: this.pain_duration,
                    description: this.description,
                  },
                },
                doctorAnamnesis: this.input.doctorAnamnesis.filter(
                  x => x !== '',
                ),
                main_diagnose: this.input.mainDiagnose,
                other_diagnose: this.input.secondaryDiagnose
                  .filter(x => x?.text?.id !== '' && x.text !== null)
                  .map(arr => {
                    return {
                      detail: arr.detail,
                      text: {
                        id: arr.text.id,
                        text: arr.text.text,
                        value: arr.text.code,
                      },
                    };
                  }),
                death_cause: this.input.deathCause
                  .filter(x => x)
                  .map(i => {
                    return {
                      detail: i.text,
                      text: {
                        id: i.id,
                        text: i.text,
                        value: i.code,
                      },
                    };
                  }),
                isChronic: this.input.isChronic,
                return_status: this.input.returnStatus,
                physical: {
                  head: this.input.physical.head || '',
                  eye: this.input.physical.eye || '',
                  ear: this.input.physical.ears || '',
                  nose: this.input.physical.nose || '',
                  mouth: this.input.physical.mouth || '',
                  neck: this.input.physical.neck || '',
                  hair: this.input.physical.hair || '',
                  lips: this.input.physical.lip || '',
                  teeth: this.input.physical.teeth || '',
                  tongue: this.input.physical.tongue || '',
                  palate: this.input.physical.palate || '',
                  throat: this.input.physical.throat || '',
                  tonsil: this.input.physical.tonsils || '',
                  chest: this.input.physical.chest || '',
                  breast: this.input.physical.breast || '',
                  back: this.input.physical.back || '',
                  stomach: this.input.physical.stomach || '',
                  genitalia: this.input.physical.genitalia || '',
                  anus: this.input.physical.anus || '',
                  upper_arm: this.input.physical.upperArm || '',
                  forearm: this.input.physical.forearm || '',
                  finger: this.input.physical.handFingers || '',
                  hand_nail: this.input.physical.handNails || '',
                  hand_joint: this.input.physical.handJoints || '',
                  upper_limb: this.input.physical.upperLimbs || '',
                  lower_limb: this.input.physical.lowerLimbs || '',
                  toe: this.input.physical.toes || '',
                  toe_nail: this.input.physical.toeNails || '',
                  leg_joint: this.input.physical.legJoints || '',
                },
              },
              planning: {
                treatment: this.input.actions.filter(x => x !== ''),
                therapy: this.input.therapies.filter(x => x !== ''),
                procedure: this.input.procedure
                  .filter(x => x !== '' && x !== null)
                  .map(procedure => {
                    return {
                      id: procedure.id,
                      text: procedure.text,
                    };
                  }),
              },
              other: {
                ekg: this.input.ekg,
                laboratory: this.input.laboratorium,
                radiology: this.input.radiology,
                ctg: this.input.ctg,
                other: this.input.othersNote,
              },
              timestamps: {
                doctor_by: this.$store.getters.userLoggedIn.id,
                doctor_created_at: moment().format(),
              },
            },
          };
        }
        // payload for non doctor user and adult patient
        if (!this.isDoctor && this.isAdult) {
          payload = {
            isAdult: this.isAdult,
            checkup: {
              subjective: {
                anamnesis: this.input.anamnesis.filter(x => x !== ''),
                disease_history: this.input.priorDisease.filter(x => x !== ''),
                allergy_history: this.input.allergy.others.filter(
                  x => x !== '',
                ),
                allergy_air: this.input.allergy.air,
                allergy_food: this.input.allergy.food,
                allergy_drug: this.input.allergy.drug,
                treatment_history: this.input.treatmentHistory || '',
                psychological_status: this.input.psychologyStatusCheckbox
                  ? this.input.psychologyStatus || ''
                  : ['Tidak Ada Kelainan'],
                social_economy: this.input.socialEconomy || '',
                spiritual: this.input.spiritual || '',
              },
              nutrition_screening: {
                isUnplannedWeightLoss: {
                  check:
                    this.input.nutritionScreening?.adult
                      ?.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLossAmount?.check,
                        )
                      : this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLoss?.check,
                        ),
                  score:
                    this.input.nutritionScreening?.adult
                      ?.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLossAmount?.score,
                        )
                      : this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLoss?.score,
                        ),
                },
                isFoodIntakePoorDueToDecreasedAppetite: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.adult
                      ?.isFoodIntakePoorDueToDecreasedAppetite?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.adult
                      ?.isFoodIntakePoorDueToDecreasedAppetite?.score,
                  ),
                },
                isHaveSeriousIllness: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.adult?.isHaveSeriousIllness
                      ?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.adult?.isHaveSeriousIllness
                      ?.score,
                  ),
                },
              },
              objective: {
                heart_rate: this.pulse,
                sistol: this.sistole,
                diastol: this.diastole,
                respiration_rate: this.respiration,
                temperature: this.temperature,
                saturation: this.saturation,
                awarness: this.awareness,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.bellyCircumference,
                djj: this.djj,
              },
              assesment: {
                prognosis: this.input.prognosa,
                fallRisk: {
                  fallHistory: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.fallHistory?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.fallHistory?.score,
                    ),
                  },
                  secondaryDiagnosis: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.secDiagnose?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.secDiagnose?.score,
                    ),
                  },
                  walkTool: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.walker?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.walker?.score,
                    ),
                  },
                  useHeparinLockTherapy: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.heparinLockTherapy?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.heparinLockTherapy?.score,
                    ),
                  },
                  howToWalk: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.walk?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.walk?.score,
                    ),
                  },
                  mentalStatus: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.mentalStatus?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.mentalStatus?.score,
                    ),
                  },
                },
                pain_assesment: {
                  type: this.pain_type,
                  detail: {
                    score: this.score,
                    pain_factor: this.pain_factor,
                    pain_like: this.pain_like,
                    pain_radiation: this.pain_radiation,
                    severity: this.severity,
                    pain_frequency: this.pain_frequency,
                    pain_duration: this.pain_duration,
                    description: this.description,
                  },
                },
                physical: {
                  head: this.input.physical.head || '',
                  eye: this.input.physical.eye || '',
                  ear: this.input.physical.ears || '',
                  nose: this.input.physical.nose || '',
                  mouth: this.input.physical.mouth || '',
                  neck: this.input.physical.neck || '',
                  hair: this.input.physical.hair || '',
                  lips: this.input.physical.lip || '',
                  teeth: this.input.physical.teeth || '',
                  tongue: this.input.physical.tongue || '',
                  palate: this.input.physical.palate || '',
                  throat: this.input.physical.throat || '',
                  tonsil: this.input.physical.tonsils || '',
                  chest: this.input.physical.chest || '',
                  breast: this.input.physical.breast || '',
                  back: this.input.physical.back || '',
                  stomach: this.input.physical.stomach || '',
                  genitalia: this.input.physical.genitalia || '',
                  anus: this.input.physical.anus || '',
                  upper_arm: this.input.physical.upperArm || '',
                  forearm: this.input.physical.forearm || '',
                  finger: this.input.physical.handFingers || '',
                  hand_nail: this.input.physical.handNails || '',
                  hand_joint: this.input.physical.handJoints || '',
                  upper_limb: this.input.physical.upperLimbs || '',
                  lower_limb: this.input.physical.lowerLimbs || '',
                  toe: this.input.physical.toes || '',
                  toe_nail: this.input.physical.toeNails || '',
                  leg_joint: this.input.physical.legJoints || '',
                },
              },
              other: {
                ekg: this.input.ekg,
                laboratory: this.input.laboratorium,
                radiology: this.input.radiology,
                ctg: this.input.ctg,
                other: this.input.othersNote,
              },
              timestamps: {
                nurse_by: this.$store.getters.userLoggedIn.id,
                nurse_created_at: moment().format(),
              },
            },
          };
        }
        // payload for non doctor user and non adult patient
        if (!this.isDoctor && !this.isAdult) {
          payload = {
            isAdult: this.isAdult,
            checkup: {
              subjective: {
                anamnesis: this.input.anamnesis.filter(x => x !== ''),
                disease_history: this.input.priorDisease.filter(x => x !== ''),
                allergy_history: this.input.allergy.others.filter(
                  x => x !== '',
                ),
                allergy_air: this.input.allergy.air,
                allergy_food: this.input.allergy.food,
                allergy_drug: this.input.allergy.drug,
                treatment_history: this.input.treatmentHistory || '',
                psychological_status: this.input.psychologyStatusCheckbox
                  ? this.input.psychologyStatus || ''
                  : ['Tidak Ada Kelainan'],
                social_economy: this.input.socialEconomy || '',
                spiritual: this.input.spiritual || '',
              },
              nutrition_screening: {
                isThin: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isThin?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isThin?.score,
                  ),
                },
                isWeightLossLastMonth: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isLossWeight?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isLossWeight?.score,
                  ),
                },
                isThereOfTheseCondition: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isHasCondition?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isHasCondition?.score,
                  ),
                },
                isThereCausesMalnourished: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isMalnutritionRisk
                      ?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isMalnutritionRisk
                      ?.score,
                  ),
                },
              },
              objective: {
                heart_rate: this.pulse,
                sistol: this.sistole,
                diastol: this.diastole,
                respiration_rate: this.respiration,
                temperature: this.temperature,
                saturation: this.saturation,
                awarness: this.awareness,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.bellyCircumference,
                djj: this.djj,
              },
              assesment: {
                prognosis: this.input.prognosa,
                fallRisk: {
                  ageScore: this.kidAge.score,
                  genderScore:
                    this.patientData.meta.gender === 'Laki-laki' ||
                    this.patientData.meta.gender === true
                      ? 2
                      : 1,
                  diagnosis: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.diagnose?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.diagnose?.score,
                    ),
                  },
                  cognitiveImpairment: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.cogntiveImpairment?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.cogntiveImpairment?.score,
                    ),
                  },
                  surgery: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.surgery?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.surgery?.score,
                    ),
                  },
                  medicinalUse: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.medicamentosa?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.medicamentosa?.score,
                    ),
                  },
                },
                pain_assesment: {
                  type: this.pain_type,
                  detail: {
                    score: this.score,
                    pain_factor: this.pain_factor,
                    pain_like: this.pain_like,
                    pain_radiation: this.pain_radiation,
                    severity: this.severity,
                    pain_frequency: this.pain_frequency,
                    pain_duration: this.pain_duration,
                    description: this.description,
                  },
                },
                physical: {
                  head: this.input.physical.head || '',
                  eye: this.input.physical.eye || '',
                  ear: this.input.physical.ears || '',
                  nose: this.input.physical.nose || '',
                  mouth: this.input.physical.mouth || '',
                  neck: this.input.physical.neck || '',
                  hair: this.input.physical.hair || '',
                  lips: this.input.physical.lip || '',
                  teeth: this.input.physical.teeth || '',
                  tongue: this.input.physical.tongue || '',
                  palate: this.input.physical.palate || '',
                  throat: this.input.physical.throat || '',
                  tonsil: this.input.physical.tonsils || '',
                  chest: this.input.physical.chest || '',
                  breast: this.input.physical.breast || '',
                  back: this.input.physical.back || '',
                  stomach: this.input.physical.stomach || '',
                  genitalia: this.input.physical.genitalia || '',
                  anus: this.input.physical.anus || '',
                  upper_arm: this.input.physical.upperArm || '',
                  forearm: this.input.physical.forearm || '',
                  finger: this.input.physical.handFingers || '',
                  hand_nail: this.input.physical.handNails || '',
                  hand_joint: this.input.physical.handJoints || '',
                  upper_limb: this.input.physical.upperLimbs || '',
                  lower_limb: this.input.physical.lowerLimbs || '',
                  toe: this.input.physical.toes || '',
                  toe_nail: this.input.physical.toeNails || '',
                  leg_joint: this.input.physical.legJoints || '',
                },
              },
              other: {
                ekg: this.input.ekg,
                laboratory: this.input.laboratorium,
                radiology: this.input.radiology,
                ctg: this.input.ctg,
                other: this.input.othersNote,
              },
              timestamps: {
                nurse_by: this.$store.getters.userLoggedIn.id,
                nurse_created_at: moment().format(),
              },
            },
          };
        }

        axios
          .post(
            Constant.apiUrl.concat(
              `/patient/emr/checkup/${this.patientData.id_registration}`,
            ),
            payload,
          )
          .then(response => {
            if (response.status == 200) {
              Swal.fire('Data Asesmen Berhasil Disimpan!', '', 'success').then(
                result => {
                  if (
                    (result.isConfirmed || result.isDismissed) &&
                    openAction
                  ) {
                    this.changeForm(1);
                    this.formHasChanged = false;
                  }
                },
              );
              if (!openAction) {
                this.$emit('close-form');
              }
            } else {
              Swal.fire('Gagal', response.data.pesan, 'error');
            }
          })
          .catch(err => {
            this.showErrorAxios(err);
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    },
    isHasUnplannedWeightLoss(data) {
      return data !== 'no' && data !== 'notSure' && data;
    },
    getKidFallRisk(data, type) {
      if (!data) return '';
      let result;
      switch (type) {
        case 'diagnosis':
          result = this.resource.DiagnoseFallRisk.filter(
            i => i.score == data.score,
          );
          break;
        case 'cognitive':
          result = this.resource.cogntiveImpairment.filter(
            i => i.score == data.score,
          );
          break;
        case 'medicinal':
          result = this.resource.medicamentosa.filter(
            i => i.score == data.score,
          );
          break;
        case 'surgery':
          result = this.resource.surgery.filter(i => i.score == data.score);
          break;
      }
      return result[0];
    },
    remapDiagnoseProcedure(main, secondary, procedures) {
      let mainDiagnose = { text: '', description: '' };
      let secondaryDiagnose = [{ text: '', description: '' }];
      let procedure = [''];

      if (main) {
        if (main?.diagnose) {
          mainDiagnose = {
            text: {
              id: main.diagnose._id,
              code: main.diagnose.code,
              text: main.diagnose.description,
              isNonSpecialist: main.diagnose?.nonSpesialis,
              displayName: `${main.diagnose.code} ${main.diagnose.description}`,
            },
            detail: main.description,
          };

          this.resource.mainDiagnose = [
            {
              id: main.diagnose._id,
              code: main.diagnose.code,
              text: main.diagnose.description,
              isNonSpecialist: main.diagnose?.nonSpesialis,
              displayName: `${main.diagnose.code} ${main.diagnose.description}`,
            },
          ];
        }
      }

      if (secondary?.length > 0) {
        secondaryDiagnose = secondary.map((v, i) => {
          if (i != 0) this.resource.secondaryDiagnose.push({ options: [] });
          this.resource.secondaryDiagnose[i].options = [
            {
              id: v.diagnose._id,
              code: v.diagnose.code,
              text: v.diagnose.description,
              displayName: `${v.diagnose.code} ${v.diagnose.description} `,
            },
          ];
          return {
            text: {
              id: v.diagnose._id,
              code: v.diagnose.code,
              text: v.diagnose.description,
              displayName: `${v.diagnose.code} ${v.diagnose.description} `,
            },
            detail: v.description,
          };
        });
      }
      if (procedures?.length > 0) {
        procedure = procedures.map(v => {
          return {
            id: v.id._id,
            code: v.id.code,
            text: v.id.description,
            displayName: `${v.id.code} ${v.id.description}`,
          };
        });
      } else {
        procedure = [''];
      }
      this.input = {
        ...this.input,
        mainDiagnose,
        secondaryDiagnose,
        procedure,
      };
    },
    getDataAssessment() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/${
              this.isEmr ? 'history' : 'service'
            }`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          // Set objective data
          this.setSistol(data?.objective?.sistol || '');
          this.setDiastol(data?.objective?.diastol || '');
          this.setPulse(data?.objective?.heart_rate || '');
          this.setWeight(data?.objective?.weight || '');
          this.setHeight(data?.objective?.height || '');
          this.setBellyCircumference(
            data?.objective?.belly_circumference || '',
          );
          this.setRespirationRate(data?.objective?.respiration_rate || '');
          this.setSaturation(data?.objective?.saturation || '');
          this.setTemperature(data?.objective?.temperature || '');
          this.setAwareness(data?.objective?.awarness || '');
          this.setDjj(data?.objective?.djj || '');
          this.isInputed = data.timestamps.doctor_by !== undefined;
          this.input = {
            ...this.input,
            socialEconomy: data.subjective.social_economy,
            psychologyStatus:
              data.subjective?.psychological_status?.[0] ===
              'Tidak Ada Kelainan'
                ? []
                : data.subjective?.psychological_status || '',
            psychologyStatusCheckbox: !data.subjective
              ?.psychological_status?.[0]
              ? false
              : data.subjective?.psychological_status?.[0] !==
                'Tidak Ada Kelainan',
            spiritual: data.subjective.spiritual,
            anamnesis: data.subjective.anamnesis,
            priorDisease: data.subjective.disease_history,
            treatmentHistory: data.subjective.treatment_history,
            allergy: {
              drug: data.subjective?.allergy_drug || [],
              air: data.subjective?.allergy_air || [],
              food: data.subjective?.allergy_food || [],
              others:
                data.subjective?.allergy_history?.length > 0
                  ? data.subjective?.allergy_history
                  : [''],
            },
            doctorAnamnesis:
              data.assesment?.doctorAnamnesis?.length > 0
                ? data.assesment?.doctorAnamnesis
                : [''],
            returnStatus:
              data.assesment.return_status || this.patientData.returnStatus,
            ekg: data?.other?.ekg || '',
            ctg: data?.other?.ctg || '',
            radiology: data?.other?.radiology || '',
            laboratorium: data?.other?.laboratory || '',
            othersNote: data?.other?.other || '',
            prognosa: data.assesment?.prognosis || '',
            isChronic: data.assesment?.isChronic,
            deathCause:
              data.assesment?.death_cause.length > 0
                ? data.assesment?.death_cause.map(i => {
                    return {
                      code: i.diagnose?.code,
                      displayName: `${i.diagnose?.code} - ${i.diagnose?.description}`,
                      id: i.diagnose?._id,
                      text: i.diagnose?.description,
                    };
                  })
                : [''],
            actions:
              data.planning?.treatment?.length > 0
                ? data.planning?.treatment
                : [''],
            therapies:
              data.planning?.therapy?.length > 0 ? data.planning.therapy : [''],
            physical: {
              ...this.input.physical,
              head: data.assesment?.physical?.head || '',
              anus: data.assesment?.physical?.anus || '',
              back: data.assesment?.physical?.back || '',
              breast: data.assesment?.physical?.breast || '',
              chest: data.assesment?.physical?.chest || '',
              ears: data.assesment?.physical?.ear || '',
              nose: data.assesment?.physical?.nose || '',
              hair: data.assesment?.physical?.hair || '',
              teeth: data.assesment?.physical?.teeth || '',
              tongue: data.assesment?.physical?.tongue || '',
              palate: data.assesment?.physical?.palate || '',
              throat: data.assesment?.physical?.throat || '',
              toes: data.assesment?.physical?.toe || '',
              forearm: data.assesment?.physical?.forearm || '',
              toeNails: data.assesment?.physical?.toe_nail || '',
              handNails: data.assesment?.physical?.hand_nail || '',
              handJoints: data.assesment?.physical?.hand_joint || '',
              legJoints: data.assesment?.physical?.leg_joint || '',
              tonsils: data.assesment?.physical?.tonsil || '',
              upperArm: data.assesment?.physical?.upper_arm || '',
              upperLimbs: data.assesment?.physical?.upper_limb || '',
              lowerLimbs: data.assesment?.physical?.lower_limb || '',
              handFingers: data.assesment?.physical?.finger || '',
              lip: data.assesment?.physical?.lips || '',
              eye: data.assesment?.physical?.eye || '',
              mouth: data.assesment?.physical?.mouth || '',
              neck: data.assesment?.physical?.neck || '',
              stomach: data.assesment?.physical?.stomach || '',
              genitalia: data.assesment?.physical?.genitalia || '',
            },
            nutritionScreening: {
              kid: {
                isThin: data.nutrition_screening?.isThin || {
                  check: false,
                  score: 0,
                },
                isLossWeight: data.nutrition_screening
                  ?.isWeightLossLastMonth || { check: false, score: 0 },
                isHasCondition: data.nutrition_screening
                  ?.isThereOfTheseCondition || { check: false, score: 0 },
                isMalnutritionRisk: data.nutrition_screening
                  ?.isThereCausesMalnourished || { check: false, score: 0 },
              },
              adult: {
                isUnplannedWeightLoss: this.isHasUnplannedWeightLoss(
                  data.nutrition_screening?.isUnplannedWeightLoss?.check,
                )
                  ? true
                  : data.nutrition_screening?.isUnplannedWeightLoss || {
                      check: 'no',
                      score: 0,
                    },
                isUnplannedWeightLossAmount: this.isHasUnplannedWeightLoss(
                  data.nutrition_screening?.isUnplannedWeightLoss?.check,
                )
                  ? data.nutrition_screening?.isUnplannedWeightLoss || ''
                  : '',
                isFoodIntakePoorDueToDecreasedAppetite: data.nutrition_screening
                  ?.isFoodIntakePoorDueToDecreasedAppetite || {
                  check: false,
                  score: 0,
                },
                isHaveSeriousIllness: data.nutrition_screening
                  ?.isHaveSeriousIllness || { check: false, score: 0 },
              },
            },
            fallRisk: {
              kid: {
                diagnose: this.getKidFallRisk(
                  data.assesment?.fallRisk?.diagnosis,
                  'diagnosis',
                ),
                cogntiveImpairment: this.getKidFallRisk(
                  data.assesment?.fallRisk?.cognitiveImpairment,
                  'cognitive',
                ),
                medicamentosa: this.getKidFallRisk(
                  data.assesment?.fallRisk?.medicinalUse,
                  'medicinal',
                ),
                surgery: this.getKidFallRisk(
                  data.assesment?.fallRisk?.surgery,
                  'surgery',
                ),
              },
              adult: {
                heparinLockTherapy: data.assesment?.fallRisk
                  ?.useHeparinLockTherapy || { check: false, score: 0 },
                mentalStatus: data.assesment?.fallRisk?.mentalStatus || {
                  check: 'accordingToAbility',
                  score: 0,
                },
                fallHistory: data.assesment?.fallRisk?.fallHistory || {
                  check: false,
                  score: 0,
                },
                secDiagnose: data.assesment?.fallRisk?.secondaryDiagnosis || {
                  check: false,
                  score: 0,
                },
                walker: data.assesment?.fallRisk?.walkTool || {
                  check: 'normal',
                  score: 0,
                },
                walk: data.assesment?.fallRisk?.howToWalk || {
                  check: 'normal',
                  score: 0,
                },
              },
            },
          };
          this.setScore(data.assesment?.pain_assesment?.detail?.score || 0);
          this.setPainFactor(
            data.assesment?.pain_assesment?.detail?.pain_factor || [],
          );
          this.setPainLike(
            data.assesment?.pain_assesment?.detail?.pain_like || [],
          );
          this.setPainRadiation(
            data.assesment?.pain_assesment?.detail?.pain_radiation || false,
          );
          this.setSeverity(
            data.assesment?.pain_assesment?.detail?.severity || 0,
          );
          this.setPainFrequency(
            data.assesment?.pain_assesment?.detail?.pain_frequency || 0,
          );
          this.setPainDuration(
            data.assesment?.pain_assesment?.detail?.pain_duration || 0,
          );
          this.setDescription(
            data.assesment?.pain_assesment?.detail?.description || '',
          );
          this.remapDiagnoseProcedure(
            data.assesment?.main_diagnose,
            data.assesment?.other_diagnose,
            data.planning?.procedure,
          );
          if (this.input.anamnesis.length < 1) {
            this.addAnamnesis();
          }
          if (this.input.allergy.length < 1) {
            this.addAllergy();
          }
          if (this.input.priorDisease.length < 1) {
            this.addDisease();
          }
          if (
            this.input.actions.length < 1 &&
            this.input.procedure.length < 1
          ) {
            this.addAction();
          }
          if (this.input.therapies.length < 1) {
            this.addTherapy();
          }
          if (this.isBPJS && this.input.returnStatus == '4') {
            this.input = {
              ...this.input,
              referralType: data.pcare.tacc,
            };
            this.referralLetterData = data.pcare.referral;
          }
        })
        .catch(() => {
          this.input = {
            ...this.input,
          };
        });
    },

    handlePrint() {
      const doc = this.generateOutpatientSummary(this.patientData, {
        ...this.emrData,
        return_status_name: this.resource.return_status.find(
          item => item.code === this.input.returnStatus,
        ).name,
      });
      this.printDocument(doc);
    },
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin result-chip($fill, $outline) {
  background-color: $fill;
  border: solid $outline 1.5px;
  border-radius: 6px;
  color: $outline;
  font-weight: 450;
  padding: 0.3rem 0.5rem;
  width: max-content;
  height: max-content;
  font-size: calc($standard-font-size - 0.1vw);
}

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.icd-input {
  margin-bottom: 90px;
}
.referral-select {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
}
.container-layout {
  height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  .chip-green {
    @include result-chip(#e5fff0, #2d965a);
  }
  .chip-yellow {
    @include result-chip(#fff9e5, #e5a82e);
  }
  .chip-red {
    @include result-chip(#fff2f2, #eb4747);
  }
  .chip-grey {
    @include result-chip(#f6f6f6, #928f8f);
  }
  .title {
    font-size: 1.4rem;
  }
  .grid-display {
    @include grid-display(10vw minmax(16vw, 26vw), true);
    column-gap: 1vw;
  }

  .grid-nutrition {
    @include grid-display(80% 20%, true);

    &.adult {
      @include grid-display(60% 40%, true);
    }
    p {
      font-weight: 300;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
      color: #757575;
      margin-top: 0.4em;
    }
  }
  .dental-stage {
    display: flex;
    justify-content: center;
  }
  .dental-input {
    display: grid;
    grid-template-columns: 190px 250px;
  }
  .fixed-button {
    z-index: 10;
    width: 15vw;
    background-color: #deeef9 !important;
    position: fixed;
    bottom: 15vh;
    right: 5vw;
    color: #429ddb;
  }
  .bounce {
    animation: scale 0.2s infinite;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
}

.title-container {
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(246, 246, 246);
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
.required:after {
  content: ' *';
  color: red;
}
</style>
