<template>
  <div class="main">
    <v-row align="center" class="pb-0 pt-3 px-8">
      <v-col cols="9" class="pb-0">
        <h3 v-once class="text-start">Pendaftaran Pasien Rawat Inap</h3>
        <p v-if="!tabs" class="ma-0 text-start caption">
          {{ totalPatient.waiting }} pasien waiting list
        </p>
        <p v-else class="ma-0 text-start caption">
          {{ totalPatient.registered }} pasien terdaftar
        </p>
      </v-col>
      <v-col class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="refreshAndAutoUpdate"
              icon
              class="mr-3"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon icon="sync-alt" class="btn-refresh" />
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
        <v-text-field
          v-once
          class="input"
          background-color="grey lighten-3"
          placeholder="Masukkan nama atau no RM"
          solo
          dense
          hide-details
          flat
          prepend-inner-icon="mdi-magnify"
          single-line
          v-model="search"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-center px-8 pt-8">
      <v-tabs v-once v-model="tabs">
        <v-tab style="letter-spacing: 0.01px;" class="text-none">
          Waiting List
        </v-tab>
        <v-tab style="letter-spacing: 0.01px;" class="text-none">
          Terdaftar
        </v-tab>
      </v-tabs>
      <div align="right" class="d-flex">
        <v-autocomplete
          hide-details
          single-line
          dense
          light
          outlined
          append-icon="mdi-chevron-down"
          class="filter-input mr-2"
          v-model="filter.type"
          :items="resource.filterType"
        />
        <v-menu
          dense
          ref="menu_date"
          v-model="menu.date"
          :close-on-content-click="false"
          :return-value.sync="filter.date.formatted"
          v-if="filter.type === 'Per Tanggal'"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              single-line
              dense
              light
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              v-model="filter.date.formatted"
              placeholder="Pilih tanggal"
              class="filter-input mr-2"
              prepend-inner-icon="mdi-calendar"
            />
          </template>
          <v-date-picker
            v-model="filter.date.raw"
            no-title
            scrollable
            @input="$refs.menu_date.save(formatDate(filter.date.raw))"
          />
        </v-menu>
        <div v-else class="d-flex">
          <v-menu
            dense
            offset-y
            v-model="menu.start"
            :close-on-content-click="false"
            :return-value.sync="filter.start.formatted"
            transition="scale-transition"
            ref="menu_start"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                single-line
                dense
                light
                outlined
                readonly
                v-model="filter.start.formatted"
                v-bind="attrs"
                v-on="on"
                placeholder="Tanggal Awal"
                class="filter-input mr-2"
                prepend-inner-icon="mdi-calendar"
              />
            </template>
            <v-date-picker
              v-model="filter.start.raw"
              :max="filter.end.raw"
              @input="$refs.menu_start.save(formatDate(filter.start.raw))"
              no-title
              scrollable
            />
          </v-menu>
          <span class="mr-2 pt-2">s.d</span>
          <v-menu
            dense
            ref="menu_end"
            v-model="menu.end"
            :close-on-content-click="false"
            :return-value.sync="filter.end.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                single-line
                dense
                light
                outlined
                readonly
                v-model="filter.end.formatted"
                v-bind="attrs"
                v-on="on"
                placeholder="Tanggal Akhir"
                class="filter-input mr-2"
                prepend-inner-icon="mdi-calendar"
              />
            </template>
            <v-date-picker
              v-model="filter.end.raw"
              @input="$refs.menu_end.save(formatDate(filter.end.raw))"
              :min="filter.start.raw"
              no-title
              scrollable
            />
          </v-menu>
        </div>
        <v-autocomplete
          hide-details
          v-once
          single-line
          dense
          light
          outlined
          append-icon="mdi-chevron-down"
          class="filter-input mr-2"
          v-model="filter.status"
          :items="resource.status"
          v-if="tabs"
        />
      </div>
    </div>
    <v-divider></v-divider>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <waiting-list-table @refresh="refreshAndAutoUpdate" />
      </v-tab-item>
      <v-tab-item>
        <registered-patient-table
          @refresh="refreshAndAutoUpdate"
          :status="filter.status"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-footer color="white">
      <v-container fluid>
        <v-row align="center">
          <v-pagination
            v-if="pagination.total"
            v-model="pagination.page"
            :length="pagination.total"
            :total-visible="5"
            @input="refreshAndAutoUpdate"
          ></v-pagination>
        </v-row>
      </v-container>
    </v-footer>
    <Snackbar
      :show="snackbar.show"
      :color="snackbar.color"
      :text="snackbar.text"
      :close="
        () => {
          clearSnackbar();
        }
      "
    />
  </div>
</template>

<script>
// components
import WaitingListTable from './components/Inpatient/WaitingListTable';
import RegisteredPatientTable from './components/Inpatient/RegisteredPatientTable';
import { Snackbar } from '@/components/SharedComponent';

// mixins
import Letter from '@/mixin/Letter';
import jwtMixin from '@/mixin/jwtMixin';
import formatMixin from '@/mixin/formatMixin';
import alertMixin from '../../mixin/alertMixin';

// utils
import 'jspdf-autotable';
import Constant from '@/const';
import moment from 'moment-timezone';
const axios = require('axios');
const _ = require('lodash');

// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  'InpatientRegistrationStore',
);

axios.defaults.headers.post['Content-Type'] = 'application/json';

export default {
  name: 'InpatientRegist',
  mixins: [jwtMixin, alertMixin, formatMixin, Letter],
  components: {
    WaitingListTable,
    RegisteredPatientTable,
    Snackbar,
  },
  data() {
    return {
      selectedItem: null,
      tabs: 0,
      selectedForm: null,
      totalPatient: {
        waiting: 0,
        registered: 0,
      },
      menu: {
        date: false,
        start: false,
        end: false,
      },
      numberLetter: '',
      search: '',
      filter: {
        type: 'Per Tanggal',
        status: 'Masih Dirawat',
        date: {
          raw: moment().format('YYYY-MM-DD'),
          formatted: moment().format('DD-MM-YYYY'),
        },
        start: {
          raw: '',
          formatted: '',
        },
        end: {
          raw: '',
          formatted: '',
        },
      },
      resource: {
        filterType: ['Per Tanggal', 'Rentang Tanggal'],
        status: ['Masih Dirawat', 'Sudah Pulang'],
      },
    };
  },
  watch: {
    search() {
      this.searchData(this);
    },
    tabs(value) {
      this.clearState();
      this.filter = {
        type: 'Per Tanggal',
        status: 'Masih Dirawat',
        date: {
          raw: moment().format('YYYY-MM-DD'),
          formatted: moment().format('DD-MM-YYYY'),
        },
        start: {
          raw: '',
          formatted: '',
        },
        end: {
          raw: '',
          formatted: '',
        },
      };
      if (!value) {
        this.getAllWaitingListPatient();
      } else {
        this.getAllRegisteredPatients();
      }
    },
    'filter.type'() {
      this.filter = {
        ...this.filter,
        date: {
          raw: '',
          formatted: '',
        },
        start: {
          raw: '',
          formatted: '',
        },
        end: {
          raw: '',
          formatted: '',
        },
      };
    },
    'filter.date.raw'() {
      if (!this.tabs) {
        if (!this.filter.date.raw) return;
        this.pagination.page = 1;
        this.getAllWaitingListPatient();
      } else {
        if (!this.filter.date.raw) return;
        this.pagination.page = 1;
        this.getAllRegisteredPatients();
      }
    },
    'filter.start.raw'() {
      if (!this.tabs) {
        if (this.filter.start.raw && this.filter.end.raw) {
          this.pagination.page = 1;
          this.getAllWaitingListPatient();
        }
      } else {
        if (this.filter.start.raw && this.filter.end.raw) {
          this.pagination.page = 1;
          this.getAllRegisteredPatients();
        }
      }
    },
    'filter.end.raw'() {
      if (!this.tabs) {
        if (this.filter.start.raw && this.filter.end.raw) {
          this.pagination.page = 1;
          this.getAllWaitingListPatient();
        }
      } else {
        if (this.filter.start.raw && this.filter.end.raw) {
          this.pagination.page = 1;
          this.getAllRegisteredPatients();
        }
      }
    },
    'filter.status'() {
      this.pagination.page = 1;
      this.refreshAndAutoUpdate();
    },
  },
  mounted() {
    this.clearSnackbar();
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    ...mapGetters(['getPagination', 'getSnackbar']),
    pagination: {
      get() {
        return this.getPagination;
      },
      set(value) {
        this.setPage(value);
      },
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },
    selectedPatient() {
      return this.$store.getters?.selectedPatient || null;
    },
    itemsPerPage() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },
  methods: {
    ...mapActions([
      'resolveGetAllWaitingListPatient',
      'resolveGetAllRegisteredPatient',
    ]),
    ...mapMutations(['clearState', 'setPage', 'setTotalPage', 'clearSnackbar']),
    searchData: _.debounce(v => {
      if (v.tabs) {
        v.getAllRegisteredPatients();
      } else {
        v.getAllWaitingListPatient();
      }
    }, 600),
    closeForm() {
      this.dialog = false;
      this.getAllWaitingListPatient();
    },

    generateNumber() {
      axios
        .get(
          Constant.apiUrl.concat(`/letter/control-inpatient-letter/generate/`),
        )
        .then(response => {
          this.numberLetter = response.data.data;
        });
    },
    async getAllWaitingListPatient() {
      const params = {
        page: this.pagination.page,
        itemCount: this.itemsPerPage,
        search: this.search || '',
        date:
          this.filter.type === 'Per Tanggal' ? this.filter.date.raw || '' : '',
        startDate:
          this.filter.type === 'Rentang Tanggal'
            ? this.filter.start.raw || ''
            : '',
        endDate:
          this.filter.type === 'Rentang Tanggal'
            ? this.filter.end.raw || ''
            : '',
      };
      const { totalPatient } = await this.resolveGetAllWaitingListPatient(
        params,
      );
      this.totalPatient.waiting = totalPatient || 0;
      this.setTotalPage(
        totalPatient ? Math.ceil(totalPatient / this.itemsPerPage) : null,
      );
    },
    async getAllRegisteredPatients() {
      const params = {
        page: this.pagination.page,
        itemCount: this.itemsPerPage,
        search: this.search || '',
        treatment_status: this.filter?.status?.toLowerCase(),
        date:
          this.filter.type === 'Per Tanggal' ? this.filter.date.raw || '' : '',
        startDate:
          this.filter.type === 'Rentang Tanggal'
            ? this.filter.start.raw || ''
            : '',
        endDate:
          this.filter.type === 'Rentang Tanggal'
            ? this.filter.end.raw || ''
            : '',
      };
      const { totalPatient } = await this.resolveGetAllRegisteredPatient(
        params,
      );
      this.totalPatient.registered = totalPatient || 0;
      this.setTotalPage(
        totalPatient ? Math.ceil(totalPatient / this.itemsPerPage) : null,
      );
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      if (this.tabs) {
        this.getAllRegisteredPatients();
      } else {
        this.getAllWaitingListPatient();
      }
      this.interval = setInterval(this.getAllWaitingListPatient, 60000);
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
  },
  beforeDestroy() {
    this.clearSnackbar();
    this.clearState();
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.main {
  font-family: 'Roboto';

  .filter-input {
    width: 10vw !important;
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
