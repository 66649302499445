<template>
  <v-card style="position: relative;">
    <v-card-title class="justify-center">
      <h3>Pendaftaran Pasien Rawat Inap</h3>
      <v-btn class="close-btn" text fab small @click="() => $emit('close-form')"
        ><v-icon small>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-row no-gutters class="header-bed" justify="center">
      <label class="grey--text">No. RM</label>
      <label class="font-weight-bold">{{ medicalRecord }}</label>
      <label class="grey--text">Usia</label>
      <label class="font-weight-bold"
        >{{ selectedPatient.meta.age }} tahun</label
      >
      <label class="grey--text">Tipe Penjamin</label>
      <label class="font-weight-bold text-uppercase">{{
        selectedPatient.meta.guarantorType
      }}</label>

      <label class="grey--text">Nama Pasien</label>
      <label class="font-weight-bold">{{ selectedPatient.meta.name }}</label>
      <label class="grey--text">Jenis Kelamin</label>
      <label class="font-weight-bold">{{
        selectedPatient.meta.gender ? 'Laki-laki' : 'Perempuan'
      }}</label>
      <label class="grey--text">Jenis Kasus</label>
      <label class="font-weight-bold">{{ selectedPatient.caseType }}</label>
    </v-row>

    <v-divider></v-divider>
    <v-container class="px-15 pb-0">
      <div class="d-flex">
        <h3 class="black--text text-left mb-4 mr-4">
          Administrasi Ruang Rawat Inap
        </h3>
        <v-chip :class="`chip ${!totalAvailable ? 'empty' : ''}`">{{
          `${
            totalAvailable
              ? `${totalAvailable} bed tersedia`
              : 'Tidak ada bed tersedia'
          }`
        }}</v-chip>
      </div>

      <v-row no-gutters class="detail-beds">
        <div class="detail-bed">
          <v-icon color="green">mdi-bed</v-icon>
          <label class="font-weight-bold ml-3">Tersedia</label>
        </div>
        <div class="detail-bed">
          <v-icon color="#86bcdb">mdi-bed</v-icon>
          <label class="font-weight-bold ml-3"
            >Ditempati Pasien Laki-laki</label
          >
        </div>
        <div class="detail-bed">
          <v-icon color="#F78FB3">mdi-bed</v-icon>
          <label class="font-weight-bold ml-3"
            >Ditempati Pasien Perempuan</label
          >
        </div>
        <div class="detail-bed">
          <v-icon color="black">mdi-bed</v-icon>
          <label class="font-weight-bold ml-3">Tidak Tersedia (Rusak)</label>
        </div>
      </v-row>
      <v-row no-gutters>
        <v-col class="py-10">
          <v-row
            v-if="!loading.beds"
            no-gutters
            style="grid-column-gap: 3vw; grid-row-gap: 5vh;"
          >
            <v-btn
              width="120"
              height="90"
              class="bed-choose"
              :class="{
                active: input.bed === bed._id,
                notAvailable:
                  bed.availability === 'occupied' ||
                  bed.availability === 'broken',
              }"
              v-for="(bed, idx) in beds"
              :key="'bed-' + idx"
              @click="chooseBed(bed)"
            >
              <div style="display: grid;">
                <v-icon
                  x-large
                  color="green"
                  v-if="bed.availability === 'available'"
                  >mdi-bed</v-icon
                >
                <v-icon
                  x-large
                  color="#86bcdb"
                  v-else-if="
                    bed.availability === 'occupied' && bed.gender === true
                  "
                  >mdi-bed</v-icon
                >
                <v-icon
                  x-large
                  color="#F78FB3"
                  v-else-if="
                    bed.availability === 'occupied' && bed.gender === false
                  "
                  >mdi-bed</v-icon
                >
                <v-icon
                  x-large
                  color="black"
                  v-else-if="bed.availability === 'broken'"
                  >mdi-bed</v-icon
                >
                <v-icon x-large color="grey" v-else>mdi-bed</v-icon>
                <label class="font-weight-bold">{{ bed.bed_number }}</label>
              </div>
            </v-btn>
          </v-row>
          <v-row v-else>
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
            <v-skeleton-loader type="actions" />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions v-if="!isDetail" class="pt-0 mt-3">
      <v-spacer></v-spacer>
      <v-btn
        class="text-none font-weight-bold"
        :loading="loading.register"
        @click="$emit('on-clicked', 5)"
        outlined
        color="primary"
        >Kembali
      </v-btn>
      <v-btn
        depressed
        color="primary"
        class="text-none font-weight-bold"
        :disabled="!input.bed"
        @click="next"
        :loading="loading.register"
        >Lanjut
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else class="pt-0">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        color="primary"
        class="text-none font-weight-bold"
        v-if="!isEditing"
        @click="() => (isEditing = true)"
        >Ubah
      </v-btn>
      <v-btn
        depressed
        outlined
        color="error"
        v-if="isEditing"
        class="text-none font-weight-bold"
        @click="() => (isEditing = false)"
        :loading="loading.updateBed"
      >
        Batal
      </v-btn>

      <v-btn
        v-if="isEditing"
        depressed
        color="primary"
        class="text-none font-weight-bold"
        @click="updatePatientBed"
        :loading="loading.updateBed"
        >Simpan Perubahan
      </v-btn>
    </v-card-actions>
    <v-snackbar
      timeout="4000"
      content-class="d-flex justify-space-between white--text align-center"
      elevation="0"
      small
      light
      absolute
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn fab small text @click.native="() => (snackbar.show = false)"
        ><v-icon color="white" small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
// utils
// import Swal from 'sweetalert2';
import AlertMixin from '@/mixin/alertMixin';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'InpatientRegistrationStore',
);

// api
import {
  // postRegisterPatient,
  putInpatientBed,
} from '@/fetchApi/Inpatient/Registration';
import { getAllBed } from '@/fetchApi/MasterData/Bed';

export default {
  name: 'BedInpatient',
  mixins: [AlertMixin],
  props: {
    selectedPatient: {
      type: Object,
      default: null,
    },
    medicalRecord: {
      type: String,
      default: null,
    },
    isDetail: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      isEditing: false,
      rooms: [],
      beds: [],
      roomIndex: 0,
      totalAvailable: 0,
      input: {
        room: '',
        bed: this.bed,
        bedName: '',
      },
      snackbar: {
        text: '',
        show: false,
        color: '',
      },
      loading: {
        beds: false,
        register: false,
        updateBed: false,
      },
    };
  },
  async mounted() {
    await this.getBedList();
    this.setSelectedBed();
  },
  computed: {
    ...mapGetters(['getBed']),
    bed: {
      get() {
        return this.getBed;
      },
    },
  },
  methods: {
    ...mapMutations(['setBed']),
    chooseBed(bed) {
      if (
        bed.availability === 'occupied' ||
        bed.availability === 'broken' ||
        (!this.isEditing && this.isDetail)
      )
        return;
      this.input.bed = bed._id;
      this.input.bedName = bed.bed_number;
    },
    async next() {
      this.setBed({
        id: this.input.bed,
        name: this.input.bedName,
      });
      this.$emit('on-clicked', 4);
      // try {
      //   this.loading.register = true;
      //   const payload = {
      //     id_registration: this.selectedPatient.id_registration,
      //     id_emr: this.selectedPatient.id_emr,
      //     isRegistered: true,
      //     id_bed: this.input.bed, // make sure bed is empty/available
      //     timestamps: {
      //       created_by: this.$store.getters.userLoggedIn.id,
      //     },
      //   };
      //   await postRegisterPatient(payload);
      //   Swal.fire('Pendaftaran Pasien Rawat Inap Sukses!', '', 'success');
      //   this.$emit('close-form');
      // } catch (error) {
      //   this.showErrorAxios(error);
      // } finally {
      //   this.loading.register = false;
      // }
    },
    async updatePatientBed() {
      try {
        this.loading.updateBed = true;
        const payload = {
          id_registration: this.selectedPatient.id_registration,
          id_emr: this.selectedPatient.id_emr,
          id_bed: this.input.bed,
        };

        await putInpatientBed(this.selectedPatient.id, payload);
        this.showSnackbar(true);
      } catch {
        this.showSnackbar();
      } finally {
        this.loading.updateBed = false;
        this.isEditing = false;
        this.getBedList();
      }
    },
    async getBedList() {
      try {
        const params = {
          halaman: 1,
          itemCount: 10,
          sortBy: '',
          keyword: '',
        };
        const response = await getAllBed(params);
        this.beds = response.data;
        this.totalAvailable = response.data.filter(
          v => v.availability === 'available',
        ).length;
      } catch {
        this.beds = [];
      }
    },
    setSelectedBed() {
      this.input.bed = this.selectedPatient?.id_bed || '';
    },
    showSnackbar(isSuccess) {
      if (isSuccess) {
        this.snackbar = {
          show: true,
          text: 'Data bed berhasil diubah',
          color: '#31b057',
        };
      } else {
        this.snackbar = {
          show: true,
          text: 'Terjadi kesalahan, data bed gagal diubah',
          color: 'error',
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bed {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 6vw 10vw);
  grid-template-rows: repeat(2, 30px);
  row-gap: 10px;
  font-size: 0.9rem;
  text-align: left;
  height: 10vh;

  label {
    font-size: 0.8vw;
  }
}
.bed-choose {
  background-color: white !important;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  &:hover,
  &.active {
    border: 2px solid #137bc0;
    box-shadow: 0px 8px 16px rgba(62, 62, 62, 0.1);
  }

  &.notAvailable {
    background-color: #ededed !important;
    &:hover,
    &.active {
      border: none;
    }
  }
}
.detail-beds {
  grid-template-columns: 6vw 10vw 11vw 10vw;
  height: 6vh;
  display: grid;
  text-align: left;
  width: auto;

  label {
    color: #616161;
    font-size: 0.6vw;
  }
}

.detail-bed {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
}

.chip {
  background-color: #f0f9ff !important;
  color: #2f80ed;
  border: 1px solid #2f80ed;
  border-radius: 5px;
  font-weight: 500;

  &.empty {
    background-color: #fff2f2 !important;
    color: #eb4646;
    border: 1px solid #eb4646;
  }
}

.close-btn {
  position: absolute;
  right: 1vw;
  top: 0.5vh;
}

::v-deep .v-snack__content {
  padding: 0px 0px 0px 1vw;
}
</style>
