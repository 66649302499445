<template>
  <v-card flat>
    <v-tabs v-model="tabs" color="primary" slider-color="primary" grow>
      <v-tab
        style="letter-spacing:.1px"
        class="text-capitalize font-weight-bold"
        @click="scrollTo('nurse')"
        >Assesmen Perawat</v-tab
      >
      <v-tab
        style="letter-spacing:.1px"
        class="text-capitalize font-weight-bold"
        @click="scrollTo('doctor')"
        >Assesmen Dokter</v-tab
      >
    </v-tabs>
    <v-container
      class="container-layout"
      id="main-container"
      @scroll="onScroll()"
      fluid
    >
      <div class="title-container">
        <h3 ref="nurse" class="grey--text text-center">ASSESMEN PERAWAT</h3>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title class="title font-weight-bold primary--text">
          Data Subjektif
        </v-card-title>
        <v-row>
          <v-col cols="6" class="grid-ttv">
            <template v-for="(anamnesis, index) in input.anamnesis">
              <label
                :class="{ required: !index }"
                :key="'anamnesis-label' + index"
                >{{ index ? '' : 'Anamnesis' }}</label
              >
              <div :key="'anamnesis-' + index">
                <v-textarea
                  outlined
                  height="100"
                  no-resize
                  dense
                  :placeholder="'Anamnesis ' + (index + 1)"
                  :rules="index === 0 && isEditable && !isEmr ? rules : []"
                  v-model="input.anamnesis[index]"
                  :id="'anamnesis-' + index"
                  :readonly="!isEditable || emrData !== null"
                />
                <div style="margin-top: -3vh" class="d-flex justify-end">
                  <v-btn
                    :disabled="
                      isEmr ? true : !isEditable || input.anamnesis.length < 2
                    "
                    @click="deleteAnamnesis(index)"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="isEmr ? true : !isEditable"
                    @click="addAnamnesis()"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-plus</v-icon></v-btn
                  >
                </div>
              </div>
            </template>
            <label>Riwayat Alergi</label>
            <div class="text-left">
              <p class="ma-0 pa-0">Alergi Obat</p>
              <v-combobox
                v-model="input.allergy.drug"
                :items="resource.allergy.drug"
                :loading="allergyLoading"
                placeholder="Pilih alergi obat"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="isEmr ? true : !isEditable"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
              <p class="ma-0 pa-0">Alergi Makanan</p>
              <v-combobox
                v-model="input.allergy.food"
                :items="resource.allergy.food"
                :loading="allergyLoading"
                placeholder="Pilih alergi makanan"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="isEmr ? true : !isEditable"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
              <p class="ma-0 pa-0">Alergi Udara</p>
              <v-combobox
                v-model="input.allergy.air"
                :items="resource.allergy.air"
                :loading="allergyLoading"
                placeholder="Pilih alergi udara"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="isEmr ? true : !isEditable"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
              <p class="ma-0 pa-0">Alergi Lainnya</p>
              <div
                v-for="(allergy, index) in input.allergy.others"
                :key="`other-allergy-${index}`"
                class="d-flex"
              >
                <v-text-field
                  dense
                  placeholder="Masukkan alergi lainnya"
                  v-model="input.allergy.others[index]"
                  :readonly="!isEditable || emrData !== null"
                />
                <v-btn
                  :disabled="
                    isEmr
                      ? true
                      : !isEditable || input.allergy.others.length < 2
                  "
                  @click="deleteAllergy(index)"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn
                  :disabled="isEmr ? true : !isEditable"
                  @click="addAllergy()"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <v-row class="grid-ttv">
              <template v-for="(disease, index) in input.priorDisease">
                <label :key="'disease-label' + index">{{
                  index ? '' : 'Riwayat Penyakit Sebelumnya'
                }}</label>
                <div :key="'disease-' + index" class="d-flex">
                  <v-text-field
                    dense
                    :placeholder="'Riwayat Penyakit ' + (index + 1)"
                    v-model="input.priorDisease[index]"
                    :readonly="!isEditable || emrData !== null"
                  />
                  <v-btn
                    :disabled="isEmr ? true : input.priorDisease.length < 2"
                    @click="deleteDisease(index)"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="isEmr ? true : !isEditable"
                    @click="addDisease()"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-plus</v-icon></v-btn
                  >
                </div>
              </template>
              <label>Riwayat Pengobatan</label>
              <v-text-field
                dense
                placeholder="Riwayat Pengobatan"
                v-model="input.treatmentHistory"
                :readonly="!isEditable || emrData !== null"
              />
              <label>Status Psikologi</label>
              <v-radio-group
                row
                :readonly="isEmr ? true : !isEditable"
                v-model="input.psychologyStatusCheckbox"
              >
                <v-radio label="Tidak Ada Kelainan" :value="false"></v-radio>
                <v-radio label="Ada" :value="true"></v-radio>
              </v-radio-group>
              <template v-if="input.psychologyStatusCheckbox">
                <label></label>
                <v-combobox
                  v-model="input.psychologyStatus"
                  :items="resource.psychologyStatus"
                  :readonly="isEmr ? true : !isEditable"
                  placeholder="Status Psikologis"
                  append-icon="mdi-chevron-down"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <p class="grey--text ma-4 text-start body-1">
                      Pilih atau Ketik Untuk Pilihan Lainnya
                    </p>
                  </template>
                </v-combobox>
              </template>
              <label>Sosial Ekonomi</label>
              <v-text-field
                v-model="input.socialEconomy"
                :readonly="!isEditable || emrData !== null"
                class="mb-5"
                hide-details
                dense
                single-line
                placeholder="Pendidikan/Pekerjaan/Penghasilan Pasien"
              />
              <label>Spiritual</label>
              <v-text-field
                v-model="input.spiritual"
                :readonly="!isEditable || emrData !== null"
                hide-details
                dense
                single-line
                placeholder="Agama/Keyakinan/Nilai-Nilai Pasien"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Data Objektif <br />
          TTV
        </v-card-title>
        <service-assessment-objective-data
          :is-editable="isEmr ? false : isEditable"
          ref="objective"
          id="objective"
        />

        <v-card-title class="title font-weight-bold primary--text text-left">
          Skrining Gizi
        </v-card-title>

        <v-row v-if="!isAdult" class="mb-3">
          <v-col cols="11" class="grid-nutrition">
            <label> Apakah Pasien Tampak Kurus? </label>
            <div class="d-flex justify-center">
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || emrData !== null || isEmr"
                v-model="input.nutritionScreening.kid.isThin"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 1 }"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="text-start">
              <label>
                Apakah Terdapat Penurunan Berat Badan Selama 1 Bulan Terakhir?
              </label>
              <p>
                (berdasarkan penilaian objektif data berat badan bila ada, atau
                penilaian subyektif orang tua pasien atau untuk bayi &lt; 1
                tahun berat badan tidak naik selama 3 bulan terakhir)
              </p>
            </div>
            <div class="d-flex justify-center">
              <v-radio-group
                dense
                :readonly="!isEditable || emrData !== null || isEmr"
                hide-details
                row
                v-model="input.nutritionScreening.kid.isLossWeight"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 1 }"
                ></v-radio>
              </v-radio-group>
            </div>
            <div class="text-start">
              <label> Apakah Terdapat Salah Satu dari Kondisi Tersebut? </label>
              <p>
                (diare >=5 per hari dan muntah >3 kali per hari dalam seminggu
                terakhir atau asupan makanan berkurang selama 1 minggu terakhir)
              </p>
            </div>
            <div class="d-flex justify-center">
              <v-radio-group
                dense
                :readonly="!isEditable || emrData !== null || isEmr"
                hide-details
                row
                v-model="input.nutritionScreening.kid.isHasCondition"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 1 }"
                ></v-radio>
              </v-radio-group>
            </div>
            <label>
              Apakah Terdapat Penyakit atau Keadaan yang Mengakibatkan Pasien
              Berisiko Mengalami Malnutrisi?
            </label>
            <div class="d-flex justify-center">
              <v-radio-group
                dense
                hide-details
                :readonly="!isEditable || emrData !== null || isEmr"
                row
                v-model="input.nutritionScreening.kid.isMalnutritionRisk"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 2 }"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <div class="d-flex justify-start align-center px-8">
            <h4 class="font-weight-bold primary--text mr-7">
              Total Skor : {{ nutritionScreeningResult?.score }}
            </h4>
            <h4 class="font-weight-bold primary--text mr-4">Hasil :</h4>
            <div :class="`chip-${nutritionScreeningResult?.color}`">
              {{ nutritionScreeningResult?.text }}
            </div>
          </div>
        </v-row>
        <v-row v-else class="mb-3">
          <v-col cols="11" class="grid-nutrition adult">
            <label>
              Apakah Pasien Mengalami Penurunan Berat badan yang Tidak
              Direncanakan?
            </label>
            <div>
              <div class="d-flex justify-end">
                <v-radio-group
                  dense
                  hide-details
                  row
                  :readonly="!isEditable || emrData !== null || isEmr"
                  v-model="input.nutritionScreening.adult.isUnplannedWeightLoss"
                >
                  <v-radio
                    label="Tidak"
                    :value="{ check: 'no', score: 0 }"
                  ></v-radio>
                  <v-radio
                    label="Tidak Yakin"
                    :value="{ check: 'notSure', score: 2 }"
                  ></v-radio>
                  <v-radio label="Ya" :value="true"></v-radio>
                </v-radio-group>
              </div>
              <div class="d-flex justify-end">
                <v-radio-group
                  :disabled="
                    !isEditable ||
                      input.nutritionScreening.adult.isUnplannedWeightLoss !==
                        true
                  "
                  dense
                  hide-details
                  row
                  v-model="
                    input.nutritionScreening.adult.isUnplannedWeightLossAmount
                  "
                >
                  <v-radio
                    label="1-5 kg"
                    :value="{ check: 'oneToFive', score: 1 }"
                  ></v-radio>
                  <v-radio
                    label="6-10 kg"
                    :value="{ check: 'sixToTen', score: 2 }"
                  ></v-radio>
                  <v-radio
                    label="11-15 kg"
                    :value="{ check: 'elevenToFifteen', score: 3 }"
                  ></v-radio>
                  <v-radio
                    label=">15 kg"
                    :value="{ check: 'aboveFifteen', score: 4 }"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
            <div class="text-start">
              <label>
                Apakah Asupan Makanan Pasien Buruk Akibat Nafsu Makan yang
                Menurun?
              </label>
              <p>Misalnya asupan makanana hanya 1/4 dari biasanya</p>
            </div>
            <div class="d-flex justify-end">
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="
                  input.nutritionScreening.adult
                    .isFoodIntakePoorDueToDecreasedAppetite
                "
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 1 }"
                ></v-radio>
              </v-radio-group>
            </div>
            <label> Apakah Pasien Menderita Penyakit Berat? </label>
            <div class="d-flex justify-end">
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.nutritionScreening.adult.isHaveSeriousIllness"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 2 }"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <div class="d-flex justify-start align-center px-8">
            <h4 class="font-weight-bold primary--text mr-7">
              Total Skor : {{ nutritionScreeningResult?.score }}
            </h4>
            <h4 class="font-weight-bold primary--text mr-4">Hasil :</h4>
            <div :class="`chip-${nutritionScreeningResult?.color}`">
              {{ nutritionScreeningResult?.text }}
            </div>
          </div>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Assesmen Risiko Jatuh
        </v-card-title>
        <v-row v-if="!isAdult">
          <v-col class="px-6" cols="6">
            <v-row class="grid-ttv">
              <label>Usia</label>
              <p class="text-start">{{ kidAge.text }}</p>
              <label>Diagnosis</label>
              <v-combobox
                :clearable="!emrData || isEditable"
                dense
                placeholder="Diagnosa"
                hide-details
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                return-object
                item-text="text"
                :items="resource.DiagnoseFallRisk"
                v-model="input.fallRisk.kid.diagnose"
                class="mb-5"
                :readonly="
                  !isEditable || emrData !== null || !isDoctor || isEmr
                "
              />
              <label>Gangguan Kognitif</label>
              <v-combobox
                dense
                :clearable="!emrData || isEditable"
                placeholder="Gangguan Kognitif"
                return-object
                item-text="text"
                class="mb-5"
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                :readonly="
                  !isEditable || emrData !== null || !isDoctor || isEmr
                "
                hide-details
                v-model="input.fallRisk.kid.cogntiveImpairment"
                :items="resource.cogntiveImpairment"
              />
              <label>Pembedahan/Sedasi/Anestesi</label>
              <v-combobox
                dense
                :clearable="!emrData || isEditable"
                placeholder="Pembedahan/Sedasi/Anestesi"
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                :readonly="
                  !isEditable || emrData !== null || !isDoctor || isEmr
                "
                return-object
                item-text="text"
                hide-details
                v-model="input.fallRisk.kid.surgery"
                :items="resource.surgery"
              />
            </v-row>
          </v-col>
          <v-col cols="6" class="grid-ttv">
            <label>Penggunaan Medikamentosa</label>
            <v-combobox
              dense
              :clearable="!emrData || isEditable"
              placeholder="Penggunaan Medikamentosa"
              :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
              :readonly="!isEditable || emrData !== null || !isDoctor || isEmr"
              return-object
              item-text="text"
              hide-details
              v-model="input.fallRisk.kid.medicamentosa"
              :items="resource.medicamentosa"
            />
            <h4 class="ml-5 text-start font-weight-bold primary--text">
              Total Skor :
            </h4>
            <h4 class="text-start font-weight-bold primary--text">
              {{ fallRiskAssesmentResult.score }}
            </h4>
            <h4 class="ml-5 text-start font-weight-bold primary--text">
              Hasil :
            </h4>
            <div :class="`chip-${fallRiskAssesmentResult?.color}`">
              {{ fallRiskAssesmentResult?.text }}
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="px-6" cols="6">
            <v-row class="grid-ttv">
              <label>Riwayat Jatuh (Baru saja atau dalam 3 bulan)</label>
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.fallRisk.adult.fallHistory"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 25 }"
                ></v-radio>
              </v-radio-group>
              <label>Diagnosis Sekunder</label>
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.fallRisk.adult.secDiagnose"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 25 }"
                ></v-radio>
              </v-radio-group>
              <label>Alat Bantu Jalan</label>
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.fallRisk.adult.walker"
              >
                <v-radio
                  label="Normal"
                  :value="{ check: 'normal', score: 0 }"
                ></v-radio>
                <v-radio
                  label="Penopang"
                  :value="{ check: 'crutch', score: 15 }"
                ></v-radio>
                <v-radio
                  label="Furnitur"
                  :value="{ check: 'furniture', score: 30 }"
                ></v-radio>
              </v-radio-group>
              <label>Memakai Terapi Heparin Lock/IV</label>
              <v-radio-group
                dense
                hide-details
                row
                :readonly="!isEditable || isEmr"
                v-model="input.fallRisk.adult.heparinLockTherapy"
              >
                <v-radio
                  label="Tidak"
                  :value="{ check: false, score: 0 }"
                ></v-radio>
                <v-radio
                  label="Ya"
                  :value="{ check: true, score: 20 }"
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-col>
          <v-col cols="6" class="grid-ttv">
            <label>Cara Berjalan</label>
            <v-radio-group
              dense
              hide-details
              row
              class="my-0"
              :readonly="!isEditable || isEmr"
              v-model="input.fallRisk.adult.walk"
            >
              <v-radio
                label="Normal"
                :value="{ check: 'normal', score: 0 }"
              ></v-radio>
              <v-radio
                label="Lemah"
                :value="{ check: 'weak', score: 10 }"
              ></v-radio>
              <v-radio
                label="Terganggu"
                :value="{ check: 'disturbed', score: 20 }"
              ></v-radio>
            </v-radio-group>
            <label>Status Mental</label>
            <v-radio-group
              dense
              hide-details
              row
              class="my-0"
              :readonly="!isEditable || isEmr"
              v-model="input.fallRisk.adult.mentalStatus"
            >
              <v-radio
                label="Sesuai Kemampuan"
                :value="{ check: 'accordingToAbility', score: 0 }"
              ></v-radio>
              <v-radio
                label="Lupa"
                :value="{ check: 'forget', score: 20 }"
              ></v-radio>
            </v-radio-group>
            <h4 class="ml-5 text-start font-weight-bold primary--text">
              Total Skor :
            </h4>
            <h4 class="text-start font-weight-bold primary--text">
              {{ fallRiskAssesmentResult?.score }}
            </h4>
            <h4 class="ml-5 text-start font-weight-bold primary--text">
              Hasil :
            </h4>
            <div :class="`chip-${fallRiskAssesmentResult?.color}`">
              {{ fallRiskAssesmentResult?.text }}
            </div>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Pengkajian Rongga Mulut
        </v-card-title>
        <v-row class="mb-4">
          <v-col cols="11">
            <div class="grid-oral">
              <div
                class="grid-oral-cavity"
                v-for="(item, index) in input.oralCavity"
                :key="`oral-${index}`"
              >
                <label>{{ item.label }}</label>
                <div>
                  <v-radio-group
                    v-model="item.exist"
                    row
                    hide-details="false"
                    class="mt-0 pt-0 mb-3"
                    :readonly="!isEditable || emrData !== null"
                  >
                    <v-radio label="Tidak Ada" :value="false"></v-radio>
                    <v-radio label="Ada" :value="true"></v-radio>
                  </v-radio-group>
                  <v-textarea
                    outlined
                    :disabled="!item.exist"
                    height="100"
                    no-resize
                    dense
                    hide-details
                    v-model="item.description"
                    :readonly="!isEditable || emrData !== null"
                    :class="{ dark: !item.exist }"
                    placeholder="Tulis keterangan di sini"
                  >
                    <!-- :background-color="{ '#EDEDED': !item.exist }" -->
                  </v-textarea>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <pain-assessment :is-disabled="isEmr ? true : !isEditable" />

        <div class="title-container my-3">
          <h3 ref="doctor" class="grey--text text-center my-10">
            ASSESMEN DOKTER
          </h3>
        </div>
        <v-card-title
          class="title required font-weight-bold primary--text text-left"
        >
          Anamnesis
        </v-card-title>
        <v-row
          :key="'anamnesis-' + index"
          v-for="(anamnesis, index) in input.doctorAnamnesis"
        >
          <v-col cols="6" class="ml-4 py-0">
            <div>
              <v-textarea
                no-resize
                height="100"
                outlined
                :readonly="!isEditable || emrData !== null || !isDoctor"
                dense
                single-line
                placeholder="Keluhan utama"
                v-model="input.doctorAnamnesis[index]"
                :rules="
                  index === 0 && isDoctor && isEditable && !isEmr && !isEmr
                    ? rules
                    : []
                "
                :id="'anamnesis-doctor-' + index"
              />
              <div style="margin-top: -3vh" class="d-flex justify-end">
                <v-btn
                  :disabled="
                    !isDoctor ? true : input.doctorAnamnesis.length < 2
                  "
                  @click="deleteDoctorAnamnesis(index)"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn
                  :disabled="!isEditable || !isDoctor"
                  @click="addDoctorAnamnesis()"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text text-left">
          Data Objektif TTV
        </v-card-title>
        <service-assessment-objective-data :is-editable="false" />

        <v-card-title class="title font-weight-bold primary--text text-left">
          Odontogram
        </v-card-title>
        <div class="odontogram">
          <v-row class="px-3 pb-3">
            <v-col cols="6" align-self="center" class="text-left">
              <span class="subtitle font-weight-regular primary--text text-left"
                >Pilih Simbol untuk Menandai Gigi</span
              >
            </v-col>
            <v-col cols="6" align-self="center" class="text-right">
              <v-btn
                tile
                color="red"
                height="27"
                style="border-radius: 5px !important"
                @click="changeDeleteMode"
                :disabled="!isEditable || !isDoctor || isEmr"
                depressed
                class="font-weight-bold white--text text-capitalize caption"
                ><v-icon small class="mr-1">mdi-delete</v-icon>
                {{ isDeleteMode ? 'Matikan Mode Hapus' : 'Mode Hapus' }}
              </v-btn>
              <v-btn
                tile
                color="success"
                height="27"
                style="border-radius: 5px !important"
                @click="input.penType = null"
                :disabled="
                  !isEditable ||
                    input.penType === null ||
                    input.penType === undefined ||
                    isEmr
                "
                depressed
                class="font-weight-bold white--text text-capitalize caption ml-3"
                >Selesai
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="d-flex justify-center">
              <v-list
                :disabled="!isEditable || isEmr"
                flat
                dense
                max-width="250"
                style="border-radius: 8px !important;"
              >
                <v-list-item-group v-model="input.penType" color="primary">
                  <v-list-item v-for="(item, i) in resource.signList" :key="i">
                    <v-list-item-icon>
                      <img width="20" :src="item.icon" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.text"
                        class="text-left"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="9">
              <v-stage
                class="dental-stage"
                :class="{ 'delete-mode': isDeleteMode }"
                ref="dentalStage"
                :config="stageSize"
                @click="handleClick"
              >
                <v-layer ref="dental-layer">
                  <v-image :config="{ image: dentalBackground }" width="600" />
                  <v-text
                    v-for="(item, index) in input.dentalCoordinates"
                    :key="`text-${item.type}-${index}`"
                    :config="textConfig(item)"
                    @click="deleteSign(item)"
                  />
                  <v-circle
                    v-for="(item, index) in input.dentalCoordinates"
                    :key="`circle-${item.type}-${index}`"
                    :config="circleConfig(item)"
                    @click="deleteSign(item)"
                  />
                  <v-image
                    v-for="(item, index) in input.dentalCoordinates"
                    :key="`image-${item.type}-${index}`"
                    :config="imageConfig(item)"
                    @click="deleteSign(item)"
                  />
                </v-layer>
              </v-stage>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex justify-end" style="margin: auto;">
          <v-btn
            v-if="isEmr ? false : isEditable"
            @click="
              isOdontogramDetailOpen = false;
              changeForm(3);
            "
            :disabled="!isEditable"
            color="primary"
            depressed
            class="text-capitalize"
            ><v-icon class="mr-2">mdi-plus</v-icon>Isi Formulir
            Odontogram</v-btn
          >
        </v-row>
        <v-row v-if="input.odontogramTable.items.length > 0">
          <v-col class="px-15">
            <v-data-iterator
              :items="input.odontogramTable.items"
              :rows-per-page-items="input.odontogramTable.items.length"
              hide-default-footer
            >
              <template v-slot:header>
                <v-row no-gutters align="center" class="grid-display header">
                  <div class="column-table header no">
                    <span>No Gigi</span>
                  </div>
                  <div class="column-table header diagnose text-left pl-5">
                    <span>Diagnosa</span>
                  </div>
                  <div class="column-table header action text-left pl-5">
                    <span>Tindakan</span>
                  </div>
                </v-row>
              </template>
              <template v-slot:default="{ items }">
                <tr
                  v-for="(item, index) in items"
                  class="grid-display item"
                  :key="`tooth-${item.no}`"
                >
                  <td
                    class="column-table item no d-flex align-center justify-center"
                  >
                    {{ item.no }}
                  </td>
                  <td
                    class="text-left column-table item diagnose d-flex align-center"
                  >
                    {{ item.diagnose.displayName }}
                  </td>
                  <td
                    class="text-left column-table item action d-flex align-center"
                  >
                    {{ item.action }}
                  </td>
                  <td
                    class="d-flex justify-end align-center column-table item action-btn"
                  >
                    <v-btn
                      color="red"
                      @click="deleteOdontogramData(item)"
                      v-if="isEmr ? false : isEditable"
                      text
                      class="text-capitalize"
                      >Hapus</v-btn
                    >
                    <v-btn
                      color="primary"
                      width="50"
                      height="30"
                      @click="openOdontogramDetail(index)"
                      outlined
                      class="text-capitalize ml-3"
                      >Detail</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>
        <v-card-title class="title font-weight-bold primary--text text-left">
          Assesmen
        </v-card-title>
        <v-row class="pb-5">
          <v-col cols="6" class="grid-ttv">
            <label class="required">Diagnosa Utama</label>
            <v-autocomplete
              return-object
              dense
              placeholder="Pilih Diagnosa Utama"
              @change="onClickActionButton('open-referral', 1)"
              :items="resource.mainDiagnose"
              item-text="displayName"
              item-key="id"
              v-model="input.mainDiagnose.text"
              :loading="diagnoseLoading"
              :search-input.sync="searchDiagnose"
              :clearable="isEmr ? false : isEditable"
              :rules="isDoctor && isEditable && !isEmr ? rules : []"
              id="mainDiagnose"
              :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
          </v-col>
          <v-col cols="6" class="pa-0 grid-ttv">
            <label>Informasi Keterangan Klinis</label>
            <v-text-field
              dense
              placeholder="Masukkan Informasi"
              hide-details="false"
              v-model="input.mainDiagnose.detail"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
          </v-col>
          <template v-for="(diagnose, index) in input.secondaryDiagnose">
            <v-col
              cols="6"
              class="py-0 grid-ttv"
              :key="'diagnoseLabel-' + index"
            >
              <label v-if="index === 0">Diagnosa Sekunder</label>
              <v-col cols="5" v-else></v-col>
              <div class="d-flex pa-0 mb-4">
                <v-autocomplete
                  return-object
                  dense
                  placeholder="Pilih Diagnosa Sekunder"
                  hide-details="false"
                  :items="resource.secondaryDiagnose[index].options"
                  item-text="displayName"
                  item-key="id"
                  @focus="secDiagnoseActvIdx = index"
                  :loading="secDiagnoseLoading"
                  v-model="input.secondaryDiagnose[index].text"
                  @change="changeDiagnose($event)"
                  :search-input.sync="searchDiagnoseSecondary[index]"
                  :clearable="isEmr ? false : isEditable"
                  :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                />
                <v-btn
                  :disabled="
                    isEmr
                      ? true
                      : !isEditable || !isDoctor
                      ? true
                      : input.secondaryDiagnose.length < 2
                  "
                  icon
                  @click="deleteSecDiagnose(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  :disabled="isEmr ? true : !isEditable || !isDoctor"
                  icon
                  @click="addDiagnose()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="6" :key="'diagnoseInfo-' + index">
              <v-row class="grid-ttv">
                <label>Informasi Keterangan Klinis</label>
                <v-text-field
                  dense
                  placeholder="Masukkan Informasi"
                  hide-details="false"
                  v-model="input.secondaryDiagnose[index].detail"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                />
              </v-row>
            </v-col>
          </template>

          <v-col cols="6" class="grid-ttv">
            <label class="required">Prognosa</label>
            <v-autocomplete
              dense
              single-line
              return-object
              class="ma-0 pa-0"
              :rules="isDoctor && isEditable && !isEmr ? rules : []"
              v-model="input.prognosa"
              :items="resource.prognosa"
              :loading="prognosaLoading"
              :clearable="isEmr ? false : isEditable"
              item-text="name"
              placeholder="Pilih prognosa"
              append-icon="mdi-chevron-down"
              :readonly="isEmr ? true : !isEditable"
            />
          </v-col>

          <v-col cols="6" class="grid-ttv">
            <label class="required ml-1">Status Pulang</label>
            <v-select
              item-value="code"
              item-text="name"
              @change="onClickActionButton('open-referral', 1)"
              class="ml-n4"
              dense
              id="returnstatus"
              placeholder="Pilih Status Pulang"
              :rules="isDoctor && isEditable && !isEmr ? rules : []"
              :items="resource.return_status"
              v-model="input.returnStatus"
              :clearable="isEmr ? false : isEditable"
              :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            />
          </v-col>
          <v-col cols="6" class="grid-ttv">
            <label class="ml-4">Pasien Kronis</label>
            <v-radio-group
              row
              hide-details="false"
              class="mt-0 pt-0 mb-5"
              v-model="input.isChronic"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            >
              <v-radio label="Ya" :value="true"></v-radio>
              <v-radio label="Tidak" :value="false"></v-radio>
            </v-radio-group>

            <template
              v-if="
                input.returnStatus == 4 &&
                  input.mainDiagnose.text?.isNonSpecialist &&
                  isBPJS
              "
            >
              <label>Jenis Rujukan</label>
              <v-radio-group
                hide-details="false"
                row
                class="mt-0 pt-0 mb-5"
                v-model="input.referralType"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              >
                <v-radio label="Rujuk dengan TACC" :value="true"></v-radio>
                <v-radio label="Rujuk Tanpa TACC" :value="false"></v-radio>
              </v-radio-group>
            </template>
            <template
              v-if="input.returnStatus == 4 && input.referralType && isBPJS"
            >
              <label>Faktor Rujukan</label>
              <v-radio-group
                hide-details="false"
                row
                class="mt-0 pt-0 mb-5"
                v-model="input.referralFactor"
                @change="selectedReferralFactor()"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              >
                <v-radio label="Time" :value="1"></v-radio>
                <v-radio label="Complication" :value="2"></v-radio>
                <v-radio label="Age" :value="3"></v-radio>
                <v-radio label="Comorbidity" :value="4"></v-radio>
              </v-radio-group>
              <label>Alasan</label>
              <v-combobox
                dense
                single-line
                v-if="input.referralFactor == 3"
                outlined
                v-model="input.referralReason"
                :items="resource.referralReason"
                :search-input.sync="searchComplicationDiagnose"
                :loading="complicationDiagnoseLoading"
                :readonly="!isEditable"
              >
              </v-combobox>
              <v-combobox
                v-else
                :readonly="!isEditable"
                dense
                single-line
                outlined
                v-model="input.referralReason"
                :items="resource.referralReason"
              >
              </v-combobox>
            </template>
            <template v-if="input.returnStatus == 4 && isBPJS">
              <label>Surat Rujukan</label>
              <v-col class="text-left pa-0">
                <v-btn
                  @click="openReferralLetter(true, 1)"
                  :disabled="!isEditable"
                  color="primary"
                  class="font-weight-bold white--text text-capitalize"
                  :class="{ bounce: bounce }"
                  depressed
                  ><v-icon left>mdi-email-outline</v-icon> Surat Rujukan</v-btn
                >
              </v-col>
            </template>
          </v-col>

          <v-col cols="6" class="pa-0 grid-ttv">
            <template v-for="(cause, index) in input.deathCause">
              <label :key="'causeLabel-' + index" v-if="index === 0"
                >Penyebab Kematian</label
              >
              <v-col v-else :key="'causeLabel-' + index"></v-col>
              <v-autocomplete
                dense
                :key="'causeInput-' + index"
                placeholder="Pilih Penyebab Kematian"
                hide-details
                return-object
                :items="resource.deathCauseDiagnose"
                item-text="text"
                @focus="deathCauseActvIdx = index"
                :search-input.sync="searchDeathCause[index]"
                :loading="diagnoseLoading"
                v-model="input.deathCause[index]"
                @keyup.enter="addDeathCause()"
                :clearable="isEmr ? false : isEditable"
                :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                :readonly="!isEditable || emrData !== null || !isDoctor"
              />
            </template>
          </v-col>
        </v-row>

        <v-card-title class="title font-weight-bold primary--text mb-2">
          Planning
        </v-card-title>
        <v-row>
          <v-col class="grid-ttv">
            <label>Rencana Perawatan</label>
            <v-text-field
              dense
              v-model="input.treatmentPlan"
              placeholder="Masukkan Rencana Perawatan"
              id="Rencana Perawatan"
              :readonly="!isEditable || emrData !== null || !isDoctor"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="grid-ttv" cols="6">
            <label>Rencana Pelayanan / KIE</label>
            <template v-for="(action, index) in input.actions">
              <div v-if="index" :key="'therapi' + index"></div>
              <v-col :key="'action-' + index" class="ma-0 pa-0">
                <v-textarea
                  dense
                  outlined
                  height="100"
                  placeholder="Rencana Pelayanan / KIE"
                  v-model="input.actions[index]"
                  :id="'action-' + index"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                />
                <div style="margin-top: -3vh" class="d-flex justify-end">
                  <v-btn
                    :disabled="
                      isEmr
                        ? true
                        : !isEditable || !isDoctor
                        ? true
                        : input.actions.length < 2
                    "
                    @click="deleteAction(index)"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-delete</v-icon></v-btn
                  >
                  <v-btn
                    :disabled="isEmr ? true : !isEditable || !isDoctor"
                    @click="addAction()"
                    text
                    fab
                    small
                    ><v-icon color="grey">mdi-plus</v-icon></v-btn
                  >
                </div>
              </v-col>
            </template>
            <label>Rencana Terapi</label>
            <template v-for="(therapy, index) in input.therapies">
              <div v-if="index" :key="'therapi' + index"></div>
              <v-col :key="'therapy' + index" class="ma-0 pa-0">
                <div class="d-flex pa-0 mb-4">
                  <v-text-field
                    dense
                    :placeholder="'Terapi ' + (index + 1)"
                    :id="'therapy-' + index"
                    v-model="input.therapies[index]"
                    :readonly="!isEditable || emrData !== null || !isDoctor"
                  />
                  <v-btn
                    :disabled="
                      isEmr
                        ? true
                        : !isEditable || !isDoctor
                        ? true
                        : input.therapies.length < 2
                    "
                    icon
                    @click="deleteTherapy(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="isEmr ? true : !isEditable || !isDoctor"
                    icon
                    @click="addTherapy()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div></v-col
              ></template
            >
          </v-col>
          <v-col cols="6">
            <v-row class="grid-ttv">
              <v-col class="d-flex justify-start">
                <label for="icd-9" class="black--text font-weight-bold"
                  >ICD-9</label
                >
              </v-col>
              <v-col>
                <v-combobox
                  v-for="(procedure, index) in input.procedure"
                  :clearable="isEmr ? false : isEditable"
                  :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
                  dense
                  class="icd-input"
                  hide-details="auto"
                  :key="'item' + index"
                  :items="resource.procedure"
                  item-text="displayName"
                  v-model="input.procedure[index]"
                  :loading="procedureLoading"
                  :search-input.sync="searchProcedure[index]"
                  id="icd9"
                  :readonly="
                    !isEditable || emrData !== null || !isDoctor || isEmr
                  "
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-title class="title font-weight-bold primary--text text-left">
          Hasil Penunjang Lainnya
        </v-card-title>
        <v-row>
          <v-col cols="6">
            <v-row class="pl-7">
              <label
                for="Hasil Radiologi"
                class="black--text font-weight-bold mb-3"
                >Hasil Radiologi</label
              >
            </v-row>
            <v-row
              v-for="(item, index) in input.radiology"
              :key="`radiology-${index}`"
            >
              <v-col cols="4" class="text-left pl-7 d-flex justify-center">
                <v-icon
                  color="primary"
                  size="100"
                  @click="previewPdf(item.file.file)"
                  v-if="item.file.file.type === 'application/pdf'"
                  >mdi-file-document-outline</v-icon
                >
                <img
                  @click="changeForm(0, item.file.url, item.file.file.name)"
                  class="uploaded-img"
                  style="cursor: pointer"
                  v-if="
                    item.file.url && item.file.file.type !== 'application/pdf'
                  "
                  :src="item.file.url"
                  :alt="item.file.file.name"
                />
                <div
                  v-if="
                    item.file.file.type !== 'application/pdf' && !item.file.url
                  "
                  class="dropzone"
                  :class="{
                    active: input.radiology[index].isDrag,
                    forbidden: emrData !== null || !isDoctor,
                  }"
                  @dragenter.prevent="dragToggle('radiology', index)"
                  @dragleave.prevent="dragToggle('radiology', index)"
                  @dragover.prevent
                  @drop.prevent="drop($event, index, 'radiology')"
                  @click="addFiles(index)"
                >
                  <v-icon
                    :large="item.failedFiles.length < 1"
                    :x-large="item.failedFiles.length > 0"
                    :color="
                      input.radiology[index].isDrag ? '#ffffff' : '#c2c2c2'
                    "
                    >{{
                      item.failedFiles.length > 0
                        ? 'mdi-file-cancel-outline'
                        : 'mdi-image-outline'
                    }}</v-icon
                  >
                  <p
                    v-show="item.failedFiles.length < 1"
                    class="ma-0 pa-2 caption font-weight-medium"
                  >
                    {{
                      input.radiology[index].isDrag
                        ? 'Jatuhkan File'
                        : 'Unggah atau Seret Foto atau Dokumen Radiologi di sini'
                    }}
                  </p>
                  <input
                    type="file"
                    id="files"
                    :ref="`files${index}`"
                    accept="image/png, image/jpeg, application/pdf"
                    multiple
                    v-on:change="
                      handleFilesUpload(null, null, index, 'radiology')
                    "
                    v-show="false"
                  />
                </div>
              </v-col>
              <v-col class="px-0">
                <div class="d-flex justify-space-between align-center">
                  <label
                    class="d-flex justify-start mb-2"
                    :class="{ 'red--text': item.failedFiles.length > 0 }"
                    >{{
                      item.failedFiles.length > 0
                        ? item.failedFiles[0].message
                        : item.file.file.name
                    }}</label
                  >
                  <div class="d-flex">
                    <v-btn
                      max-height="35"
                      min-width="40"
                      width="30"
                      text
                      class="mb-2 pa-0"
                      :disabled="!isEditable"
                      v-show="item.file.file || item.failedFiles.length > 0"
                      @click="
                        removeFiles(
                          input.radiology[index].file,
                          'radiology',
                          index,
                        )
                      "
                      ><v-icon color="red">mdi-delete</v-icon></v-btn
                    >
                    <v-btn
                      max-height="35"
                      min-width="40"
                      color="primary"
                      :disabled="!isEditable"
                      class="mb-2 ml-2 pa-0 font-weight-bold"
                      :class="{ forbidden: emrData !== null || !isDoctor }"
                      @click="addRadiology($event, index)"
                      depressed
                      ><v-icon color="white">mdi-plus</v-icon></v-btn
                    >
                  </div>
                </div>
                <v-textarea
                  @dragenter.prevent="dragToggle('radiology', index)"
                  @dragleave.prevent="dragToggle('radiology', index)"
                  @drop.prevent="drop($event, index, 'radiology')"
                  @keyup="addRadiology($event, index, 'keyup')"
                  @dragover.prevent
                  outlined
                  dense
                  placeholder="Masukkan Hasil Radiologi"
                  v-model="input.radiology[index].text"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                  height="80"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row class="pl-7">
              <label for="Hasil EKG" class="black--text font-weight-bold mb-3"
                >Hasil EKG</label
              >
            </v-row>
            <v-row>
              <v-col cols="4" class="text-left pl-7 d-flex justify-center">
                <v-icon
                  color="primary"
                  size="100"
                  @click="previewPdf(input.ekg.file.file)"
                  v-if="input.ekg.file.file.type === 'application/pdf'"
                  >mdi-file-document-outline</v-icon
                >
                <img
                  @click="
                    changeForm(0, input.ekg.file.url, input.ekg.file.file.name)
                  "
                  class="uploaded-img"
                  style="cursor: pointer"
                  v-if="
                    input.ekg.file.url &&
                      input.ekg.file.file.type !== 'application/pdf'
                  "
                  :src="input.ekg.file.url"
                  :alt="input.ekg.file.file.name"
                />
                <div
                  v-if="
                    input.ekg.file.file.type !== 'application/pdf' &&
                      !input.ekg.file.url
                  "
                  class="dropzone"
                  :class="{
                    active: input.ekg.isDrag,
                    forbidden: emrData !== null || !isDoctor,
                  }"
                  @dragenter.prevent="dragToggle('ekg')"
                  @dragleave.prevent="dragToggle('ekg')"
                  @dragover.prevent
                  @drop.prevent="drop($event, null, 'ekg')"
                  @click="addFiles(null, 'ekg')"
                >
                  <v-icon
                    :large="input.ekg.failedFiles.length < 1"
                    :x-large="input.ekg.failedFiles.length > 0"
                    :color="input.ekg.isDrag ? '#ffffff' : '#c2c2c2'"
                    >{{
                      input.ekg.failedFiles.length > 0
                        ? 'mdi-file-cancel-outline'
                        : 'mdi-image-outline'
                    }}</v-icon
                  >
                  <p
                    v-show="input.ekg.failedFiles.length < 1"
                    class="ma-0 pa-2 caption font-weight-medium"
                  >
                    {{
                      input.ekg.isDrag
                        ? 'Jatuhkan File'
                        : 'Unggah atau Seret Foto atau Dokumen EKG di sini'
                    }}
                  </p>
                  <input
                    type="file"
                    id="files"
                    ref="files_ekg"
                    accept="image/png, image/jpeg, application/pdf"
                    multiple
                    v-on:change="handleFilesUpload(null, null, null, 'ekg')"
                    v-show="false"
                  />
                </div>
              </v-col>
              <v-col>
                <div
                  v-show="
                    input.ekg.file.file || input.ekg.failedFiles.length > 0
                  "
                  class="d-flex justify-space-between align-center"
                >
                  <label
                    class="d-flex justify-start mb-2 text-left"
                    :class="{ 'red--text': input.ekg.failedFiles.length > 0 }"
                    >{{
                      input.ekg.failedFiles.length > 0
                        ? input.ekg.failedFiles[0].message
                        : input.ekg.file.file.name
                    }}</label
                  >
                  <v-btn
                    max-height="35"
                    min-width="40"
                    width="30"
                    text
                    :disabled="!isEditable"
                    class="mb-2 pa-0"
                    v-show="
                      input.ekg.file.file || input.ekg.failedFiles.length > 0
                    "
                    @click="removeFiles(input.ekg.file, 'ekg')"
                    ><v-icon color="red">mdi-delete</v-icon></v-btn
                  >
                </div>
                <v-textarea
                  @dragenter.prevent="dragToggle('ekg')"
                  @dragleave.prevent="dragToggle('ekg')"
                  @dragover.prevent
                  @drop.prevent="drop($event, null, 'ekg')"
                  outlined
                  dense
                  placeholder="Masukkan Hasil EKG"
                  v-model="input.ekg.text"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                  height="90"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row class="pl-7">
              <label
                for="Foto Intra Oral"
                class="black--text font-weight-bold mb-2"
                >Foto Intra Oral</label
              >
            </v-row>
            <v-row class="photo-profile">
              <v-col
                v-for="(item, index) in input.profilePhoto"
                :key="`item-${index}`"
                class="text-left pa-0"
              >
                <div
                  @click="
                    changeForm(0, item.file.url, item.file.file.name);
                    isPreviewProfile = true;
                    carousel = index;
                  "
                  v-if="item.file.url"
                  class="img-container"
                >
                  <img
                    width="100"
                    height="80"
                    :src="item.file.url"
                    :alt="item.file.file.name"
                  />
                  <div class="overlay">
                    <v-icon
                      @click.stop="
                        removeFiles(
                          input.profilePhoto[index].file,
                          'intra',
                          index,
                        )
                      "
                      color="white"
                      size="20"
                      >mdi-delete</v-icon
                    >
                  </div>
                </div>
                <v-tooltip
                  v-else-if="item.failedFiles.length > 0"
                  top
                  color="#3F484A"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      @dragenter.prevent="dragToggle('profile', index)"
                      @dragleave.prevent="dragToggle('profile', index)"
                      @dragover.prevent
                      @drop.prevent="drop($event, index, 'profile')"
                      @click="addFiles(index, 'profile-reupload')"
                      class="img-container"
                      v-if="item.failedFiles[0].file.type !== 'application/pdf'"
                    >
                      <img
                        width="100"
                        height="80"
                        :src="item.failedFiles[0].url"
                        :alt="item.failedFiles[0].file.name"
                      />
                      <div class="overlay failed">
                        <v-icon
                          @click.stop="deletePicture('intra', index)"
                          color="white"
                          size="20"
                          >mdi-delete</v-icon
                        >
                        <p
                          class="pa-0 ma-0 font-weight-medium white--text caption"
                        >
                          Upload Ulang
                        </p>
                        <v-icon color="white">mdi-alert</v-icon>
                      </div>
                      <input
                        type="file"
                        id="files"
                        :ref="`files_profiles${index}`"
                        accept="image/png, image/jpeg, application/pdf"
                        multiple
                        v-on:change="
                          handleFilesUpload(
                            null,
                            null,
                            index,
                            'profile-reupload',
                          )
                        "
                        v-show="false"
                      />
                    </div>
                    <div
                      v-else
                      v-bind="attrs"
                      v-on="on"
                      class="dropzone profile failed pa-0"
                      @dragenter.prevent="dragToggle('profile', index)"
                      @dragleave.prevent="dragToggle('profile', index)"
                      @dragover.prevent
                      @drop.prevent="drop($event, index, 'profile')"
                      @click="addFiles(index, 'profile-reupload')"
                    >
                      <v-icon
                        @click.stop="deletePicture('intra', index)"
                        color="grey"
                        size="20"
                        >mdi-delete</v-icon
                      >
                      <v-icon color="red">
                        mdi-file-document-alert-outline
                      </v-icon>
                      <p class="pa-0 ma-0 primary--text font-weight-medium">
                        Upload Ulang
                      </p>
                      <input
                        type="file"
                        id="files"
                        :ref="`files_profiles${index}`"
                        accept="image/png, image/jpeg, application/pdf"
                        multiple
                        v-on:change="
                          handleFilesUpload(
                            null,
                            null,
                            index,
                            'profile-reupload',
                          )
                        "
                        v-show="false"
                      />
                    </div>
                  </template>
                  <span
                    ><v-icon small left color="white" class="mr-2 pb-1"
                      >mdi-alert</v-icon
                    >{{ item.failedFiles[0].message }}</span
                  >
                </v-tooltip>
                <div
                  v-else
                  class="dropzone profile"
                  :class="{
                    active: input.profilePhoto[index].isDrag,
                    forbidden: emrData !== null || !isDoctor,
                  }"
                  @dragenter.prevent="dragToggle('profile', index)"
                  @dragleave.prevent="dragToggle('profile', index)"
                  @dragover.prevent
                  @drop.prevent="drop($event, index, 'profile')"
                  @click="addFiles(index, 'profile')"
                >
                  <v-icon
                    :color="
                      input.profilePhoto[index].isDrag ? '#ffffff' : '#c2c2c2'
                    "
                    >{{
                      item.failedFiles.length > 0
                        ? 'mdi-file-cancel-outline'
                        : 'mdi-image-outline'
                    }}</v-icon
                  >
                  <p
                    v-show="item.failedFiles.length < 1"
                    class="ma-0 caption py-1 font-weight-light"
                  >
                    {{
                      input.profilePhoto[index].isDrag
                        ? 'Jatuhkan File'
                        : 'Unggah Foto'
                    }}
                  </p>
                  <input
                    type="file"
                    id="files"
                    :ref="`files_profiles${index}`"
                    accept="image/png, image/jpeg, application/pdf"
                    multiple
                    v-on:change="
                      handleFilesUpload(null, null, index, 'profile')
                    "
                    v-show="false"
                  />
                </div>
              </v-col>
              <v-col class="pa-0">
                <div
                  :class="{ forbidden: emrData !== null || !isDoctor }"
                  class="dropzone profile pa-0"
                  @click="addPhotoProfile()"
                >
                  <v-icon> mdi-plus </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="mb-3">
            <v-row class="pl-7">
              <label
                for="Hasil Laboratorium"
                class="black--text font-weight-bold"
                :class="{
                  'mb-3':
                    input.laboratorium.file.file.type !== 'application/pdf',
                }"
                >Hasil Laboratorium</label
              >
            </v-row>
            <v-row>
              <v-col
                v-if="input.laboratorium.file.file.type !== 'application/pdf'"
                cols="4"
                class="text-left pl-7"
              >
                <img
                  @click="
                    changeForm(
                      0,
                      input.laboratorium.file.url,
                      input.laboratorium.file.file.name,
                    )
                  "
                  class="uploaded-img"
                  style="cursor: pointer"
                  v-if="input.laboratorium.file.url"
                  :src="input.laboratorium.file.url"
                  :alt="input.laboratorium.file.file.name"
                />
                <div
                  v-else
                  class="dropzone"
                  :class="{
                    active: input.laboratorium.isDrag,
                    forbidden: emrData !== null || !isDoctor,
                  }"
                  @dragenter.prevent="dragToggle('lab')"
                  @dragleave.prevent="dragToggle('lab')"
                  @dragover.prevent
                  @drop.prevent="drop($event, null, 'lab')"
                  @click="addFiles(null, 'lab')"
                >
                  <v-icon
                    :large="input.laboratorium.failedFiles.length < 1"
                    :x-large="input.laboratorium.failedFiles.length > 0"
                    :color="input.laboratorium.isDrag ? '#ffffff' : '#c2c2c2'"
                    >{{
                      input.laboratorium.failedFiles.length > 0
                        ? 'mdi-file-cancel-outline'
                        : 'mdi-image-outline'
                    }}</v-icon
                  >
                  <p
                    v-show="input.laboratorium.failedFiles.length < 1"
                    class="ma-0 pa-2 caption font-weight-medium"
                  >
                    {{
                      input.laboratorium.isDrag
                        ? 'Jatuhkan File'
                        : 'Unggah atau Seret Foto atau Dokumen Catatan Laboratorium di sini'
                    }}
                  </p>
                  <input
                    type="file"
                    id="files"
                    ref="files_lab"
                    accept="image/png, image/jpeg, application/pdf"
                    multiple
                    v-on:change="handleFilesUpload(null, null, null, 'lab')"
                    v-show="false"
                  />
                </div>
              </v-col>
              <v-col
                :class="{
                  'pl-7':
                    input.laboratorium.file.file.type === 'application/pdf',
                }"
              >
                <div
                  v-show="
                    input.laboratorium.file.file ||
                      input.laboratorium.failedFiles.length > 0
                  "
                  class="d-flex justify-space-between align-center"
                >
                  <div
                    class="d-flex align-center"
                    @click="previewPdf(input.laboratorium.file.file)"
                    :class="{
                      pdf:
                        input.laboratorium.file.file.type === 'application/pdf',
                    }"
                  >
                    <v-icon
                      color="primary"
                      class="mr-2"
                      v-if="
                        input.laboratorium.file.file.type === 'application/pdf'
                      "
                      >mdi-file-document-outline</v-icon
                    >
                    <label
                      class="d-flex justify-start text-left"
                      :class="{
                        'red--text': input.laboratorium.failedFiles.length > 0,
                        'primary--text':
                          input.laboratorium.file.file.type ===
                          'application/pdf',
                        pdf:
                          input.laboratorium.file.file.type ===
                          'application/pdf',
                      }"
                      >{{
                        input.laboratorium.failedFiles.length > 0
                          ? input.laboratorium.failedFiles[0].message
                          : input.laboratorium.file.file.name
                      }}</label
                    >
                  </div>
                  <v-btn
                    max-height="35"
                    min-width="40"
                    width="30"
                    :disabled="!isEditable"
                    text
                    class="mb-2 pa-0"
                    v-show="
                      input.laboratorium.file.file ||
                        input.laboratorium.failedFiles.length > 0
                    "
                    @click="removeFiles(input.laboratorium.file, 'lab')"
                    ><v-icon color="red">mdi-delete</v-icon></v-btn
                  >
                </div>
                <v-textarea
                  @dragenter.prevent="dragToggle('lab')"
                  @dragleave.prevent="dragToggle('lab')"
                  @dragover.prevent
                  @drop.prevent="drop($event, null, 'lab')"
                  height="80"
                  outlined
                  dense
                  placeholder="Masukkan Hasil Laboratorium"
                  v-model="input.laboratorium.text"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6"> </v-col>
          <v-col cols="6" class="pl-7">
            <v-row class="pl-4 mb-2">
              <label for="Catatan Lain" class="black--text font-weight-bold"
                >Catatan Lain</label
              >
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  height="110"
                  outlined
                  dense
                  placeholder="Masukkan Catatan Lainnya"
                  v-model="input.othersNote"
                  :readonly="!isEditable || emrData !== null || !isDoctor"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog
      v-model="dialog"
      v-if="selectedForm != 0"
      persistent
      :max-width="selectedForm === 3 ? '1500' : '1200px'"
    >
      <ReferralLetter
        :patient-data="patientData"
        v-if="selectedForm == 1"
        :letter-data="referralLetterData"
        :therapy="input.therapies"
        @close-form="dialog = false"
        @save="saveReferralLetter"
      />
      <BHPandAction
        v-if="selectedForm === 2 && dialog"
        :patient-data="selectedPatient"
        @close-form="dialog = false"
      />
      <OdontogramForm
        v-if="selectedForm === 3 && dialog"
        :is-editable="isEmr ? false : isEditable"
        :patient-data="patientData"
        :inputted-tooth="inputtedTooth"
        :data-detail="odontogramDetailData"
        :is-detail="isOdontogramDetailOpen"
        @close-form="dialog = false"
        @save="saveOdontogram"
        @update="updateOdontogram"
      />
    </v-dialog>
    <v-dialog v-model="dialog" v-else light content-class="elevation-0">
      <v-btn
        width="100"
        text
        small
        @click="
          isPreviewProfile
            ? downloadImage(input.profilePhoto[carousel].file)
            : downloadImage(previewImage)
        "
        class="download-btn white--text text-capitalize"
        ><v-icon color="white" left>mdi-download</v-icon>Download</v-btn
      >
      <v-btn text @click="dialog = false" fab small class="close-btn"
        ><v-icon color="white">mdi-close</v-icon></v-btn
      >
      <div class="preview-image">
        <img
          :width="600"
          v-if="!isPreviewProfile"
          :src="previewImage.url"
          :alt="previewImage.name"
        />
        <v-carousel v-else hide-delimiters v-model="carousel">
          <v-carousel-item
            v-for="(item, index) in input.profilePhoto.filter(i => i.file.url)"
            :key="`img-${index}`"
          >
            <img :width="450" :src="item.file.url" :alt="item.file.file.name" />
          </v-carousel-item>
        </v-carousel>
      </div>
      <div v-if="isPreviewProfile" class="image-list">
        <v-slide-group active-class="success" v-model="carousel" show-arrows>
          <v-slide-item
            v-for="(item, index) in input.profilePhoto.filter(i => i.file.url)"
            :key="`img-${index}`"
          >
            <img
              width="75"
              height="65"
              class="mr-2"
              style="cursor: pointer"
              @click="carousel = index"
              :class="{
                'darken-image': index !== carousel,
              }"
              :src="item.file.url"
              :alt="item.file.file.name"
            />
          </v-slide-item>
        </v-slide-group>
      </div>
    </v-dialog>
    <v-dialog v-model="iCareDialog" :max-width="1280">
      <div style="height: 90vh; overflow: hidden">
        <i-care-bpjs
          v-if="iCareDialog"
          :bpjs-code="patientData.meta.bpjs.number"
          @close-dialog="iCareDialog = false"
        />
      </div>
    </v-dialog>

    <v-card-actions
      :class="{
        'pt-3': true,
        'd-flex': true,
        'justify-space-between': isEmr,
        'justify-end': !isEmr,
      }"
    >
      <v-btn
        width="108"
        height="36"
        color="secondary"
        text
        class="text-none mr-auto"
        @click="onClickActionButton('close-form')"
      >
        Kembali
      </v-btn>

      <div class="d-flex justify-space-between">
        <v-btn
          @click="onClickActionButton('open-action', 2)"
          width="138"
          height="36"
          color="success"
          class="text-none"
          depressed
          :disabled="!isEditable"
          v-if="!isEmr && !isIhs"
          :loading="saveLoading"
        >
          <!-- :disabled="!isEditable || !valid" -->
          Input Tindakan
        </v-btn>
        <v-btn
          width="130"
          height="36"
          class="text-capitalize ml-15"
          color="primary"
          outlined
          depressed
          @click="openICare"
          v-if="!isEmr && isBPJS"
          :disabled="!isEditable"
          :loading="saveLoading"
        >
          I-Care Pasien
        </v-btn>
        <v-btn
          @click="openEMR()"
          v-if="!isEmr"
          width="108"
          height="36"
          color="primary"
          outlined
          class="text-none ml-5"
        >
          EMR
        </v-btn>
        <v-btn
          @click="onClickActionButton('save')"
          width="108"
          height="36"
          color="primary"
          depressed
          tile
          class="text-none ml-3"
          :disabled="!isEditable"
          v-if="!emrData"
          :loading="saveLoading"
        >
          Simpan
        </v-btn>
        <v-btn
          @click="handlePrint()"
          width="108"
          height="36"
          color="primary"
          depressed
          class="text-none ml-5"
          v-if="isEmr && isInputed"
        >
          Print
        </v-btn>
      </div>
    </v-card-actions>
    <alert-card :data="{ ...alertData, remove: removeAlert }" rightPos="26vw" />
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import dentalBackgroundSvg from '@/assets/image/OdontogramNew.svg';
import radix from '@/assets/image/radix.svg';
import calculus from '@/assets/image/calculus.svg';
import eclipse from '@/assets/image/Ellipse.svg';
import cross from '@/assets/image/Cross.svg';
import belumtumbuh from '@/assets/image/BelumTumbuh.svg';
import goyang from '@/assets/image/Goyang.svg';
import eclipseFill from '@/assets/image/EllipseFill.svg';
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';
import jwtMixin from '@/mixin/jwtMixin';
import calculationMixin from '@/mixin/calculationMixin';
import printMixin from '@/mixin/printMixin';
import assestmentMixin from '@/mixin/assestmentMixin';
import BHPandAction from '../BHPandAction.vue';
import ReferralLetter from '../../../SharedComponent/Dialog/ReferralLetter.vue';
import OdontogramForm from './OdontogramForm.vue';
import PainAssessment from '../../../SharedComponent/Assesment/PainAssessment.vue';
import ServiceAssessmentObjectiveData from '../AssessmentComponents/ServiceAssessmentObjectiveData.vue';
import { createNamespacedHelpers } from 'vuex';
const { scrollToEmptyInput } = require('@/helper');
import { getPrognosa, getAllergies } from '@/fetchApi/MasterData/Allergy';
import { AuthenticationCheck } from '@/fetchApi/Setting/PcarePassword';
import AlertCard from '../../../SharedComponent/Snackbar/AlertCard.vue';
import ICareBpjs from '../../../SharedComponent/Assesment/ICareBpjs.vue';

const {
  mapGetters: mapServiceAssessmentObjectiveDataGetters,
  mapMutations: mapServiceAssessmentObjectiveDataMutations,
} = createNamespacedHelpers('ServiceAssessmentObjectiveData');
const {
  mapGetters: mapPainAssessmentGetters,
  mapMutations: mapPainAssessmentMutations,
} = createNamespacedHelpers('PainAssessment');
const axios = require('axios');
const _ = require('lodash');
const moment = require('moment-timezone');

let watchCount = 0;
export default {
  name: 'InputAssessment',
  mixins: [AlertMixin, jwtMixin, calculationMixin, printMixin, assestmentMixin],
  components: {
    ReferralLetter,
    BHPandAction,
    ICareBpjs,
    OdontogramForm,
    PainAssessment,
    ServiceAssessmentObjectiveData,
    AlertCard,
  },
  props: {
    isIhs: {
      type: Boolean,
      default: null,
    },
    patientData: {
      type: Object,
      default: null,
    },
    emrData: {
      type: Object,
      default: null,
    },
    tab: {
      type: Number,
      default: null,
    },
    isEmr: {
      type: Boolean,
    },
  },
  data() {
    return {
      count: 0,
      saveLoading: false,
      iCareDialog: false,
      previewImage: {
        name: '',
        url: '',
      },
      odontogramDetailData: '',
      carousel: '',
      onEditDataIndex: null,
      dialog: false,
      isOdontogramDetailOpen: false,
      valid: true,
      bounce: false,
      isDeleteMode: false,
      isPreviewProfile: false,
      selectedForm: null,
      scrollValue: 0,
      referralLetterData: '',
      formHasChanged: false,
      alertData: {
        show: false,
        title: '',
        text: '',
      },
      input: {
        prognosa: '',
        referralType: '',
        psychologyStatus: '',
        psychologyStatusCheckbox: '',
        socialEconomy: '',
        spiritual: '',
        referralReason: '',
        referralFactor: '',
        doctorAnamnesis: [''],
        anamnesis: [''],
        priorDisease: [''],
        allergy: {
          drug: [],
          food: [],
          air: [],
          others: [''],
        },
        nutritionScreening: {
          kid: {
            isThin: { check: false, score: 0 },
            isLossWeight: { check: false, score: 0 },
            isHasCondition: { check: false, score: 0 },
            isMalnutritionRisk: { check: false, score: 0 },
          },
          adult: {
            isUnplannedWeightLoss: { check: 'no', score: 0 },
            isUnplannedWeightLossAmount: '',
            isFoodIntakePoorDueToDecreasedAppetite: { check: false, score: 0 },
            isHaveSeriousIllness: { check: false, score: 0 },
          },
        },
        fallRisk: {
          kid: {
            surgery: '',
            diagnose: '',
            cogntiveImpairment: '',
            medicamentosa: '',
          },
          adult: {
            heparinLockTherapy: { check: false, score: 0 },
            mentalStatus: { check: 'accordingToAbility', score: 0 },
            fallHistory: { check: false, score: 0 },
            secDiagnose: { check: false, score: 0 },
            walker: { check: 'normal', score: 0 },
            walk: { check: 'normal', score: 0 },
          },
        },
        dentalCoordinates: [],
        penType: null,
        treatmentPlan: '',
        odontogramTable: {
          items: [],
          headers: [
            {
              text: 'No Gigi',
              align: 'center',
              sortable: false,
              value: 'no',
              width: '10%',
            },
            {
              text: 'Diagnosa',
              align: 'start',
              sortable: false,
              value: 'diagnose.displayName',
              width: '35%',
            },
            {
              text: 'Tindakan',
              align: 'start',
              sortable: false,
              value: 'action',
              maxwidth: '45%',
            },
            {
              text: '',
              align: 'end',
              sortable: false,
              value: 'button',
              maxwidth: '10%',
            },
          ],
        },
        dentalDetail: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ],
        ekg: {
          text: '',
          isNew: true,
          isDrag: false,
          file: {
            file: '',
            url: '',
          },
          failedFiles: [],
        },
        radiology: [
          {
            text: '',
            isNew: true,
            isDrag: false,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          },
        ],
        laboratorium: {
          text: '',
          isNew: true,
          isDrag: false,
          file: {
            file: '',
            url: '',
          },
          failedFiles: [],
        },
        othersNote: '',
        mainDiagnose: {
          text: '',
          detail: '',
        },
        procedure: [''],
        secondaryDiagnose: [
          {
            text: '',
            detail: '',
          },
        ],
        isChronic: null,
        deathCause: [''],
        returnStatus: '',
        actions: [''],
        therapies: [''],
        profilePhoto: [
          {
            isDrag: false,
            isNew: true,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          },
          {
            isNew: true,
            isDrag: false,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          },
          {
            isDrag: false,
            isNew: true,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          },
          {
            isDrag: false,
            isNew: true,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          },
        ],
        oralCavity: [
          {
            label: 'Palataum',
            value: 'palatum',
            exist: false,
            description: '',
          },
          {
            label: 'Mukosa',
            value: 'mucosa',
            exist: false,
            description: '',
          },
          {
            label: 'Lidah',
            value: 'tongue',
            exist: false,
            description: '',
          },
          {
            label: 'Bibir',
            value: 'lips',
            exist: false,
            description: '',
          },
          {
            label: 'Gusi',
            value: 'gum',
            exist: false,
            description: '',
          },
          {
            label: 'Dasar Mulut',
            value: 'bottom_of_mouth',
            exist: false,
            description: '',
          },
          {
            label: 'Oklusi',
            value: 'occlusion',
            exist: false,
            description: '',
          },
        ],
      },
      resource: {
        prognosa: [],
        allergy: {
          drug: [],
          food: [],
          air: [],
        },
        referralReason: [],
        awareness: [],
        return_status: [],
        mainDiagnose: [],
        secondaryDiagnose: [{ options: [] }],
        deathCauseDiagnose: [],
        procedure: [],
        medicamentosa: [
          {
            text:
              'Penggunaan Multiple Sedative, Obat Hypnosis, Barbiturate, Fenotiazi, Antidepressan, Pencahar, Diuretic, Narkose',
            score: 3,
            value: 'useOfMultipleSedative',
          },
          {
            text: 'Penggunaan Obat Salah Satu di Atas',
            score: 2,
            value: 'useOfAnyOfAboveDrug',
          },
          {
            text: 'Penggunaan Medikasi Lainnya / Tidak Ada Medikasi',
            score: 1,
            value: 'useOfOtherMedication',
          },
        ],
        surgery: [
          {
            text: 'Dalam 24 Jam',
            value: 'oneDay',
            score: 3,
          },
          {
            text: 'Dalam 48 Jam',
            value: 'twoDay',
            score: 2,
          },
          {
            text: '>48 jam dan Tidak Mengalami Pembedahan/Sedasi/Anestesi',
            value: 'aboveTwoDay',
            score: 1,
          },
        ],
        cogntiveImpairment: [
          {
            text: 'Tidak Menyadari Keterbatasan Lainnya',
            score: 3,
            value: 'notAwareOfOtherLimitation',
          },
          {
            text: 'Lupa Akan Adannya Keterbatasan',
            score: 2,
            value: 'forgetAboutLimitations',
          },
          {
            text: 'Orientasi Baik Terhadap Diri Sendiri',
            score: 1,
            value: 'goodSelfOriented',
          },
        ],
        DiagnoseFallRisk: [
          {
            text: 'Diagnosis Neurologi',
            score: 4,
            value: 'neurologi',
          },
          {
            text:
              'Perubahan Okisgenasi (diagnosis respiratorik, dehidrasi, anemia, anoreksia, sinkop, dll)',
            score: 3,
            value: 'oxygenationChange',
          },
          {
            text: 'Gangguan Perilaku/Psikiatri',
            score: 2,
            value: 'behaviorDisorder',
          },
          {
            text: 'Diagnosis Lainnya',
            score: 1,
            value: 'other',
          },
        ],
        psychologyStatus: ['Cemas', 'Takut', 'Marah', 'Sedih'],
        penType: [
          { text: 'Caries', value: 0 },
          { text: 'Tambalan', value: 1 },
          { text: 'Gigi Sudah Tidak Ada', value: 2 },
          { text: 'Gigi Belum Tumbuh', value: 3 },
          { text: 'Gigi Goyang', value: 4 },
          { text: 'Calculus', value: 5 },
          { text: 'Radix', value: 6 },
        ],
        signList: [
          {
            text: 'Caries',
            icon: eclipse,
            value: 0,
          },
          {
            text: 'Tambalan',
            icon: eclipseFill,
            value: 1,
          },
          {
            text: 'Gigi Sudah Tidak Ada',
            icon: cross,
            value: 2,
          },
          {
            text: 'Gigi Belum Tumbuh',
            icon: belumtumbuh,
            value: 3,
          },
          {
            text: 'Gigi Goyang',
            icon: goyang,
            value: 4,
          },
          {
            text: 'Calculus',
            icon: calculus,
            value: 5,
          },
          {
            text: 'Radix',
            icon: radix,
            value: 6,
          },
        ],
      },
      factorReferral: {
        complication: [],
        time: ['< 3 Hari', '>= 3 - 7 Hari', '>= 7 Hari'],
        age: [
          '< 1 Bulan',
          '>= 1 Bulan s/d < 12 Bulan',
          '>= 1 Tahun s/d < 5 Tahun',
          '>= 5 Tahun s/d < 12 Tahun',
          '>= 12 Tahun s/d < 55 Tahun',
          '>= 55 Tahun',
        ],
        comorbidity: ['< 3 Hari', '>= 3 - 7 Hari', '>= 7 Hari'],
      },
      dentalBackground: null,
      dentalShaking: null,
      dentalCalculus: null,
      dentalRadix: null,
      stageSize: {
        width: 600,
        height: 240,
      },
      diagnoseLoading: false,
      secDiagnoseLoading: false,
      deathCauseLoading: false,
      complicationDiagnoseLoading: false,
      procedureLoading: false,
      prognosaLoading: false,
      allergyLoading: false,
      searchDiagnose: '',
      searchComplicationDiagnose: '',
      secDiagnoseActvIdx: 0,
      deathCauseActvIdx: 0,
      searchProcedure: [''],
      searchDiagnoseSecondary: [],
      searchDeathCause: [],
      rules: [v => !!v || 'Wajib diisi'],
      isInputed: false,
    };
  },
  created() {
    const dentalBackground = new window.Image();
    const dentalShaking = new window.Image();
    const dentalCalculus = new window.Image();
    const dentalRadix = new window.Image();
    dentalBackground.src = dentalBackgroundSvg;
    dentalBackground.width = 800;
    dentalBackground.height = 225;
    dentalBackground.onload = () => {
      // set image only when it is loaded
      this.dentalBackground = dentalBackground;
    };
    dentalShaking.src = goyang;
    dentalShaking.width = 12;
    dentalShaking.height = 12;
    dentalShaking.onload = () => {
      this.dentalShaking = dentalShaking;
    };
    dentalCalculus.src = calculus;
    dentalCalculus.width = 20;
    dentalCalculus.height = 10;
    dentalCalculus.onload = () => {
      this.dentalCalculus = dentalCalculus;
    };
    dentalRadix.src = radix;
    dentalRadix.width = 10;
    dentalRadix.height = 20;
    dentalRadix.onload = () => {
      this.dentalRadix = dentalRadix;
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getDataAssessment();
      this.getProcedure();
      this.getAwarness();
      this.getReturnStatus();
      this.resolveGetPrognosa();
      this.resolveGetAllergies();
      this.checkPcarePassword();
    });
  },
  beforeDestroy() {
    watchCount = 0;
  },
  computed: {
    ...mapPainAssessmentGetters([
      'getScore',
      'getPainFactor',
      'getPainLike',
      'getPainRadiation',
      'getSeverity',
      'getPainFrequency',
      'getPainType',
      'getPainDuration',
      'getDescription',
    ]),
    ...mapServiceAssessmentObjectiveDataGetters([
      'getSistol',
      'getDiastol',
      'getPulse',
      'getWeight',
      'getHeight',
      'getBellyCircumference',
      'getRespirationRate',
      'getSaturation',
      'getTemperature',
      'getAwareness',
    ]),
    // Obective data (TTV) state
    sistole: {
      get() {
        return this.getSistol;
      },
      set(value) {
        this.setSistol(value);
      },
    },
    diastole: {
      get() {
        return this.getDiastol;
      },
      set(value) {
        this.setDiastol(value);
      },
    },
    weight: {
      get() {
        return this.getWeight;
      },
      set(value) {
        this.setWeight(value);
      },
    },
    height: {
      get() {
        return this.getHeight;
      },
      set(value) {
        this.setHeight(value);
      },
    },
    bellyCircumference: {
      get() {
        return this.getBellyCircumference;
      },
      set(value) {
        this.setBellyCircumference(value);
      },
    },
    respiration: {
      get() {
        return this.getRespirationRate;
      },
      set(value) {
        this.setRespirationRate(value);
      },
    },
    saturation: {
      get() {
        return this.getSaturation;
      },
      set(value) {
        this.setSaturation(value);
      },
    },
    temperature: {
      get() {
        return this.getTemperature;
      },
      set(value) {
        this.setTemperature(value);
      },
    },
    pulse: {
      get() {
        return this.getPulse;
      },
      set(value) {
        this.setPulse(value);
      },
    },
    awareness: {
      get() {
        return this.getAwareness;
      },
      set(value) {
        this.setAwareness(value);
      },
    },
    // Pain assessmment state
    score: {
      get() {
        return this.getScore;
      },
      set(value) {
        this.setScore(value);
      },
    },
    pain_factor: {
      get() {
        return this.getPainFactor;
      },
      set(value) {
        this.setPainFactor(value);
      },
    },
    pain_like: {
      get() {
        return this.getPainLike;
      },
      set(value) {
        this.setPainLike(value);
      },
    },
    pain_radiation: {
      get() {
        return this.getPainRadiation;
      },
      set(value) {
        this.setPainRadiation(value);
      },
    },
    severity: {
      get() {
        return this.getSeverity;
      },
      set(value) {
        this.setSeverity(value);
      },
    },
    pain_frequency: {
      get() {
        return this.getPainFrequency;
      },
      set(value) {
        this.setPainFrequency(value);
      },
    },
    pain_duration: {
      get() {
        return this.getPainDuration;
      },
      set(value) {
        this.setPainDuration(value);
      },
    },
    pain_type: {
      get() {
        return this.getPainType;
      },
    },
    description: {
      get() {
        return this.getDescription;
      },
      set(value) {
        this.setDescription(value);
      },
    },
    isAdult() {
      const birthDate = moment(this.patientData.meta.birthDate, 'DD MMMM YYYY');
      const diff = moment().diff(birthDate, 'month');
      return diff > 228;
    },
    kidAge() {
      let result,
        age = this.patientData.meta.age;
      if (age < 3) {
        result = {
          text: '<3 Tahun',
          score: 4,
        };
      }
      if (age >= 3 && age <= 7) {
        result = {
          text: '3-7 Tahun',
          score: 3,
        };
      }
      if (age > 7 && age <= 13) {
        result = {
          text: '7-13 Tahun',
          score: 2,
        };
      }
      if (age > 13) {
        result = {
          text: '>13 Tahun',
          score: 1,
        };
      }
      return result;
    },
    nutritionScreeningResult() {
      let score, result;
      if (this.isAdult) {
        if (
          this.input?.nutritionScreening?.adult.isUnplannedWeightLoss !== true
        ) {
          score =
            (this.input?.nutritionScreening?.adult?.isUnplannedWeightLoss
              ?.score || 0) +
            (this.input?.nutritionScreening?.adult
              ?.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
            (this.input?.nutritionScreening?.adult?.isHaveSeriousIllness
              ?.score || 0);
        } else {
          score =
            (this.input?.nutritionScreening?.adult?.isUnplannedWeightLossAmount
              ?.score || 0) +
            (this.input?.nutritionScreening?.adult
              ?.isFoodIntakePoorDueToDecreasedAppetite?.score || 0) +
            (this.input?.nutritionScreening?.adult?.isHaveSeriousIllness
              ?.score || 0);
        }
        if (isNaN(score)) {
          result = null;
        }
        if (score <= 1) {
          result = {
            text: 'Tidak Berisiko Malnutrisi',
            color: 'green',
            score,
          };
        }
        if (score === 2) {
          result = {
            text: 'Risiko Malnutrisi',
            color: 'yellow',
            score,
          };
        }
        if (score >= 3) {
          result = {
            text: 'Malnutrisi',
            color: 'red',
            score,
          };
        }
      } else {
        score =
          (this.input?.nutritionScreening?.kid?.isThin?.score || 0) +
          (this.input?.nutritionScreening?.kid?.isLossWeight?.score || 0) +
          (this.input?.nutritionScreening?.kid?.isHasCondition?.score || 0) +
          (this.input?.nutritionScreening?.kid?.isMalnutritionRisk?.score || 0);

        if (isNaN(score)) {
          result = null;
        }
        if (score === 0) {
          result = {
            text: 'Berisiko Rendah',
            color: 'green',
            score,
          };
        }
        if (score >= 1 && score <= 3) {
          result = {
            text: 'Berisiko Sedang',
            color: 'yellow',
            score,
          };
        }
        if (score >= 4 && score <= 5) {
          result = {
            text: 'Berisiko Tinggi',
            color: 'red',
            score,
          };
        }
      }
      return result;
    },
    fallRiskAssesmentResult() {
      let score, result;
      if (this.isAdult) {
        score =
          (this.input?.fallRisk?.adult?.fallHistory?.score || 0) +
          (this.input?.fallRisk?.adult?.secDiagnose?.score || 0) +
          (this.input?.fallRisk?.adult?.walker?.score || 0) +
          (this.input?.fallRisk?.adult?.heparinLockTherapy?.score || 0) +
          (this.input?.fallRisk?.adult?.walk?.score || 0) +
          (this.input?.fallRisk?.adult?.mentalStatus?.score || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score <= 24) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
            score,
          };
        }
        if (score > 24 && score <= 50) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
            score,
          };
        }
        if (score > 50) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
            score,
          };
        }
      } else {
        score =
          (this.patientData.meta.gender === 'Laki-laki' ||
          this.patientData.meta.gender === true
            ? 2
            : 1) +
          this.kidAge?.score +
          (this.input.fallRisk?.kid?.diagnose?.score || 0) +
          (this.input.fallRisk?.kid?.cogntiveImpairment?.score || 0) +
          (this.input.fallRisk?.kid?.surgery?.score || 0) +
          (this.input.fallRisk?.kid?.medicamentosa?.score || 0);
        if (isNaN(score)) {
          result = null;
        }
        if (score < 7) {
          result = {
            text: 'Tidak Berisiko Jatuh',
            color: 'green',
            score,
          };
        }
        if (score >= 7 && score <= 11) {
          result = {
            text: 'Risiko Jatuh Rendah',
            color: 'yellow',
            score,
          };
        }
        if (score > 11) {
          result = {
            text: 'Risiko Jatuh Tinggi',
            color: 'red',
            score,
          };
        }
      }
      return result;
    },
    isEditable() {
      const differenceBetweenRegistrationDateAndToday = this.differenceBetweenTwoDates(
        this.$store.getters.selectedPatient?.registered_at,
        moment(),
      );
      return differenceBetweenRegistrationDateAndToday <= 30;
    },
    inputtedTooth() {
      const data = this.input.odontogramTable.items.map(item => {
        return +item.no;
      });
      return data;
    },
    isDoctor() {
      return this.$store.getters.userLoggedIn.role.some(
        item =>
          item.toLowerCase() === 'dokter' ||
          item.toLowerCase() === 'fisioterapis',
      );
    },
    identifierPrefix() {
      return `${this.patientData.id_emr}`;
    },
    isBPJS() {
      return this.patientData.meta.guarantorType.toLowerCase() == 'bpjs';
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
    tabs() {
      return this.scrollValue > 2559 ? 1 : 0;
    },
  },
  watch: {
    input: {
      handler(val) {
        if (val) {
          watchCount += 1;
          if (watchCount > 1) {
            this.formHasChanged = true;
          }
        }
      },
      deep: true,
    },
    dialog(val) {
      if (!val) {
        this.isPreviewProfile = false;
      }
    },
    'input.penType'(val) {
      if (val !== undefined && this.isDeleteMode) {
        this.isDeleteMode = false;
      }
    },
    searchProcedure(val) {
      if (val) {
        const oldValues = this.input.procedure.map(procedure => {
          return procedure.displayName;
        });
        const index = val.findIndex((v, i) => {
          return v !== oldValues[i];
        });
        this.getProcedure(val[index]);
      } else {
        this.getProcedure('');
      }
    },
    tab(val) {
      if (val === 1) {
        if (this.isDoctor) {
          setTimeout(() => {
            this.scrollTo('doctor');
          }, 500);
        }
      }
    },
    searchDiagnose(val) {
      if (val !== '') {
        this.searchMainDiagnose(this, val);
      }
    },
    searchDiagnoseSecondary(val) {
      if (!val) return;
      this.searchSecondaryDiagnose(this);
    },
    searchDeathCause(val) {
      if (!val) return;
      this.srchDeathCause(this);
    },
  },
  methods: {
    ...mapServiceAssessmentObjectiveDataMutations([
      'clearObjectiveState',
      'setSistol',
      'setDiastol',
      'setPulse',
      'setWeight',
      'setHeight',
      'setBellyCircumference',
      'setRespirationRate',
      'setSaturation',
      'setTemperature',
      'setAwareness',
    ]),
    ...mapPainAssessmentMutations([
      'clearPainAssessmentState',
      'setScore',
      'setPainFactor',
      'setPainLike',
      'setPainRadiation',
      'setSeverity',
      'setPainFrequency',
      'setPainDuration',
      'setDescription',
    ]),
    removeAlert() {
      this.alertData = {
        ...this.alertData,
        show: false,
      };
    },
    async checkPcarePassword() {
      if (
        this.$store.getters.selectedPatient?.meta?.guarantorType?.toLowerCase() !==
          'bpjs' &&
        this.isEditable &&
        !this.isEmr
      )
        return;
      try {
        await AuthenticationCheck();
      } catch (error) {
        if (error.response?.data?.code === 401) {
          this.alertData = {
            show: true,
            title: 'Password PCare Tidak Sesuai',
            text:
              'Silakan sesuaikan password PCare di menu Setting untuk dapat melakukan bridging',
          };
        }
        if (error?.response?.status >= 500) {
          this.alertData = {
            show: true,
            title: 'Bridging BPJS Error',
            text:
              'Anda masih bisa melanjutkan pendaftaran. Silahkan kirim ulang setelah menyelesaikannya',
          };
        }
      }
    },
    searchMainDiagnose: _.debounce((v, keyword) => {
      v.getDiagnose(keyword, true, false, false);
    }, 600),
    searchSecondaryDiagnose: _.debounce(v => {
      v.getDiagnose(
        v.searchDiagnoseSecondary[v.secDiagnoseActvIdx],
        false,
        true,
        false,
      );
    }, 600),
    srchDeathCause: _.debounce(v => {
      v.getDiagnose(
        v.searchDeathCause[v.deathCauseActvIdx],
        false,
        false,
        true,
      );
    }, 600),
    openICare() {
      this.iCareDialog = true;
    },
    rangeRules(value, type) {
      switch (type) {
        case 'sistole':
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'diastole':
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'heart-rate':
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          }
          break;
        case 'rr':
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          }
          break;
        case 'temperature':
          if ((value < 20 || value > 45) && value !== '') {
            return 'Angka suhu tidak valid';
          }
          break;
        case 'height':
          if (value <= 1) {
            return 'Angka tinggi badan tidak valid';
          }
          break;
      }
    },
    filterVariable(data) {
      let result;
      if (data === undefined) {
        result = null;
      } else {
        result = data;
      }
      return result;
    },
    mapReferralLetterData() {
      const referral_data = {
        doctor: this.$store.getters.userLoggedIn?.code,
        referralPurpose: this.referralLetterData.referralPurpose || '',
        specialistReferralPurpose:
          this.referralLetterData.specialistReferralPurpose || '',
        specificReferralPurpose:
          this.referralLetterData.specificReferralPurpose || '',
        subspecialistReferralPurpose:
          this.referralLetterData.subspecialistReferralPurpose || '',
        date: {
          plan: this.referralLetterData.date.plan
            ? moment(this.referralLetterData.date.plan, 'YYYY-MM-DD').format(
                'DD-MM-YYYY',
              )
            : moment().format('DD-MM-YYYY'),
          schedule: this.referralLetterData.date.schedule,
        },
        medium: this.referralLetterData.medium || null,
        agency: this.referralLetterData.agency || '',
        therapy: this.referralLetterData.therapy || '',
        advice: this.referralLetterData.advice || '',
        note: this.referralLetterData.note || '',
        tacc:
          this.input.referralType == false
            ? -1
            : this.input.referralFactor || -1,
        referralReason: this.input.referralReason || null,
      };
      return referral_data;
    },
    async onClickActionButton(type, val) {
      switch (type) {
        case 'close-form':
          if (this.formHasChanged) {
            Swal.fire({
              title: 'Data yang Anda Masukkan Belum Tersimpan',
              text: 'Apakah Anda Ingin Menyimpan Terlebih Dahulu?',
              icon: 'warning',
              reverseButtons: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak',
            }).then(result => {
              if (!result.value) {
                this.$emit('close-form');
              }
            });
          } else {
            this.$emit('close-form');
          }
          break;
        case 'save':
          await this.saveData();
          break;
        case 'open-action':
          if (!this.$refs.form.validate()) {
            Swal.fire({
              title: 'Data Assesmen Poli Umum Ada yang Belum Diisi',
              text:
                'Silakan lengkapi terlebih dahulu sebelum menginput Tindakan',
              icon: 'warning',
            });
            this.valid = false;
            scrollToEmptyInput();
            return;
          }
          if (this.isInputed) {
            if (this.formHasChanged) {
              Swal.fire({
                title: 'Apakah Anda yakin ingin menyimpan perubahan ini?',
                icon: 'question',
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak',
              }).then(result => {
                if (result.isConfirmed) {
                  this.saveData(true);
                }
              });
              return;
            }
            this.changeForm(1);
          } else {
            this.saveData(true);
          }
          break;
        case 'open-referral':
          this.openReferralLetter(false, val);
      }
    },
    openOdontogramDetail(index) {
      this.odontogramDetailData = this.input.odontogramTable.items[index];
      this.isOdontogramDetailOpen = true;
      this.onEditDataIndex = index;
      this.changeForm(3);
    },
    deleteOdontogramData(item) {
      Swal.fire({
        title: 'Apakah Anda yakin ingin menghapus?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#2D9CDB',
        cancelButtonColor: '#EB5757',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          const index = this.input.odontogramTable.items.findIndex(
            x => x === item,
          );
          this.input.odontogramTable.items.splice(index, 1);
        }
      });
    },
    saveOdontogram(data) {
      Swal.fire({
        icon: 'success',
        title: 'Data Formulir Odontogram Berhasil Disimpan',
        showConfirmButton: false,
        timer: 900,
      }).then(() => {
        this.input.odontogramTable.items.push(data);
      });
    },
    updateOdontogram(data) {
      Swal.fire({
        icon: 'success',
        title: 'Data Formulir Odontogram Berhasil Diubah',
        showConfirmButton: false,
        timer: 900,
      }).then(() => {
        this.input.odontogramTable.items.splice(this.onEditDataIndex, 1, data);
        this.onEditDataIndex = '';
        this.odontogramDetailData = '';
      });
    },
    async resolveGetPrognosa() {
      try {
        this.prognosaLoading = true;
        const response = await getPrognosa();
        this.resource.prognosa = response.data.data.map(item => ({
          code: item.code,
          name: item.name,
        }));
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.prognosaLoading = false;
      }
    },
    async resolveGetAllergies() {
      try {
        this.allergyLoading = true;
        ['food', 'air', 'drug'].forEach(async item => {
          const response = await getAllergies(item);
          this.resource.allergy[item] = response.data.data.map(v => v.name);
        });
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.allergyLoading = false;
      }
    },
    getAwarness() {
      axios.get(Constant.apiUrl.concat('/master/awarness')).then(response => {
        const data = response.data.data;
        this.resource.awareness = data.map(v => {
          return {
            code: v.code,
            name: v.description,
          };
        });
      });
    },
    getReturnStatus() {
      axios
        .get(Constant.apiUrl.concat('/master/returnstatus/outpatient'))
        .then(response => {
          const data = response.data.data;
          this.resource.return_status = data.map(v => {
            return {
              code: v.code,
              name: v.description,
            };
          });
          this.resource.return_status.push({
            code: '2',
            name: 'Rawat Inap',
          });
        });
    },
    selectedReferralFactor() {
      const selectedFactor = this.input.referralFactor;
      if (selectedFactor == 1) {
        this.resource.referralReason = this.factorReferral.time;
      } else if (selectedFactor == 3) {
        this.getComplicationDiagnose('');
      } else if (selectedFactor == 2) {
        this.resource.referralReason = this.factorReferral.age;
      } else if (selectedFactor == 4) {
        this.resource.referralReason = this.factorReferral.comorbidity;
      }
    },
    saveReferralLetter(data) {
      this.referralLetterData = data;
      this.dialog = false;
      this.bounce = true;
      const timeout = setTimeout(() => {
        this.bounce = false;
      }, 200);
      timeout;
    },
    openReferralLetter(fromButton, val) {
      if (fromButton) {
        this.changeForm(val);
      }
      if (this.isBPJS) {
        if (
          this.input.mainDiagnose.text?.isNonSpecialist &&
          this.input.returnStatus == 4 &&
          this.count < 1
        ) {
          this.allowReferral = true;
          Swal.fire({
            title:
              'Apakah anda yakin akan merujuk pasien ke fasilitas rujukan tingkat lanjut?',
            text: 'Pasien termasuk ke dalam kategori non spesialistik',
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Ya, saya yakin',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            icon: 'warning',
          }).then(result => {
            if (result.isConfirmed) {
              this.changeForm(val);
              this.count = 1;
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.input.returnStatus = '';
            }
          });
        }
        if (
          this.count < 1 &&
          this.input.returnStatus == 4 &&
          !this.input.mainDiagnose.text?.isNonSpecialist
        ) {
          this.count += 1;
          this.allowReferral = true;
          this.changeForm(val);
        }
      }
    },
    imgUrl(path) {
      if (!path || path === '/') {
        return '';
      }
      {
        return Constant.apiUrl.concat(path);
      }
    },
    openEMR() {
      let routeData = this.$router.resolve({
        name: `/emr/patient/${this.patientData.id_emr}`,
      });
      window.open(routeData.location.name, '_blank');
    },
    async submitFiles(array) {
      if (array.length === 0) return;
      const formData = new FormData();
      const data = array.filter(i => i.file);

      for (let i = 0; i < data.length; i++) {
        const file = data[i].file;
        formData.append('files', file);
      }
      formData.append('identifier', data[0].id);
      formData.append('tag', this.patientData.id_emr);
      formData.append('created_by', this.$store.getters.userLoggedIn.id);

      await axios
        .post(Constant.apiUrl.concat('/upload'), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    addPhotoProfile() {
      if (this.emrData !== null || !this.isDoctor || !this.isEditable) return;
      this.input.profilePhoto.push({
        isDrag: false,
        file: {
          file: '',
          url: '',
        },
        failedFiles: [],
      });
    },
    previewPdf(file) {
      if (file.type === 'application/pdf') {
        const url = URL.createObjectURL(file);
        window.open(url);
      }
    },
    changeForm(value, url, name) {
      this.selectedForm = value;
      if (url && name) {
        this.previewImage = {
          name,
          url,
        };
      }
      this.dialog = true;
    },
    downloadImage(data) {
      fetch(data.url)
        .then(resp => resp.blob())
        .then(blobobject => {
          const blob = window.URL.createObjectURL(blobobject);
          const anchor = document.createElement('a');
          anchor.style.display = 'none';
          anchor.href = blob;
          anchor.download = `${
            this.isPreviewProfile ? data.file.name : data.name
          }`;
          document.body.appendChild(anchor);
          anchor.click();
          window.URL.revokeObjectURL(blob);
        }) // eslint-disable-next-line
        .catch(() => {
          Swal.fire('Gagal mengunduh gambar', '', 'error');
        });
    },
    async removeFiles(item, type, index) {
      if (this.isEmr ? true : !this.isEditable) return;
      if (!item.id) {
        this.deletePicture(type, index);
        return;
      }
      const payload = {
        tag: item.tag,
        identifier: item.identifier,
        file_id: [item.id],
      };
      await axios
        .delete(
          Constant.apiUrl.concat('/upload'),
          { data: payload },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(() => {
          this.deletePicture(type, index);
        })
        .catch(error => {
          Swal.fire(
            'Gagal menghapus gambar',
            error.response.data.pesan,
            'error',
          );
        });
    },
    deletePicture(type, index) {
      switch (type) {
        case 'radiology':
          this.input.radiology.splice(index, 1, {
            text: this.input.radiology[index].text,
            isDrag: false,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          });
          break;
        case 'ekg':
          this.input.ekg = {
            text: this.input.ekg.text,
            isDrag: false,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          };
          break;
        case 'lab':
          this.input.laboratorium = {
            text: this.input.laboratorium.text,
            isDrag: false,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          };
          break;
        case 'intra':
          this.input.profilePhoto.splice(index, 1, {
            isDrag: false,
            file: {
              file: '',
              url: '',
            },
            failedFiles: [],
          });
          break;
      }
    },
    changeDeleteMode() {
      this.isDeleteMode = !this.isDeleteMode;
      this.input.penType = undefined;
    },
    drop(e, index, type) {
      if (this.emrData !== null || !this.isDoctor) return;
      this.handleFilesUpload('drag', e.dataTransfer.files, index, type);
      this.input.radiology[index].isDrag = false;
      this.input.ekg.isDrag = false;
      this.input.laboratorium.isDrag = false;
      this.input.profilePhoto[index].isDrag = false;
    },
    addFiles(index, type = null) {
      if (this.emrData !== null || !this.isDoctor || !this.isEditable) return;
      if (!type) this.$refs[`files${index}`][0].click();
      if (type === 'profile' || type === 'profile-reupload')
        this.$refs[`files_profiles${index}`][0].click();
      if (type === 'ekg') this.$refs.files_ekg.click();
      if (type === 'lab') this.$refs.files_lab.click();
    },
    dragToggle(type, index) {
      if (this.emrData !== null || !this.isDoctor) return;
      switch (type) {
        case 'radiology':
          this.input.radiology[index].isDrag = !this.input.radiology[index]
            .isDrag;
          break;
        case 'ekg':
          this.input.ekg.isDrag = !this.input.ekg.isDrag;
          break;
        case 'lab':
          this.input.laboratorium.isDrag = !this.input.laboratorium.isDrag;
          break;
        case 'profile':
          this.input.profilePhoto[index].isDrag = !this.input.profilePhoto[
            index
          ].isDrag;
          break;
      }
    },
    handleFilesUpload(from = null, files, index, type) {
      let profileIndex;
      let filesUploaded;
      switch (type) {
        case 'radiology':
          filesUploaded =
            from === 'drag' ? files : this.$refs[`files${index}`][0].files;
          if (
            this.input.radiology[index].file.file &&
            this.input.radiology[index].file.file.name
          )
            return;
          for (let i = 0; i < filesUploaded.length; i++) {
            if (this.verify(filesUploaded[i]) === '') {
              if (i === 0) {
                this.input.radiology.splice(index, 1, {
                  text: this.input.radiology[index].text,
                  isNew: true,
                  index: i + index,
                  file: {
                    file: filesUploaded[i],
                    url: URL.createObjectURL(filesUploaded[i]),
                  },
                  failedFiles: [],
                });
              } else {
                this.input.radiology.push({
                  text: '',
                  isNew: true,
                  index: i + index,
                  file: {
                    file: filesUploaded[i],
                    url: URL.createObjectURL(filesUploaded[i]),
                  },
                  failedFiles: [],
                });
              }
            } else {
              if (i === 0) {
                this.input.radiology.splice(index, 1, {
                  text: this.input.radiology[index].text,
                  file: {
                    file: '',
                    url: '',
                  },
                  failedFiles: [
                    {
                      file: filesUploaded[i],
                      message: this.verify(filesUploaded[i]),
                    },
                  ],
                });
              } else {
                this.input.radiology.push({
                  text: '',
                  file: {
                    file: '',
                    url: '',
                  },
                  failedFiles: [
                    {
                      file: filesUploaded[i],
                      message: this.verify(filesUploaded[i]),
                    },
                  ],
                });
              }
            }
          }
          break;
        case 'ekg':
          filesUploaded = from === 'drag' ? files : this.$refs.files_ekg.files;
          if (this.input.ekg.file.file && this.input.ekg.file.file.name) return;
          for (let i = 0; i < filesUploaded.length; i++) {
            if (this.verify(filesUploaded[i]) === '') {
              this.input.ekg = {
                text: this.input.ekg.text,
                isDrag: false,
                isNew: true,
                file: {
                  file: filesUploaded[i],
                  url: URL.createObjectURL(filesUploaded[i]),
                },
                failedFiles: [],
              };
            } else {
              this.input.ekg = {
                text: this.input.ekg.text,
                isDrag: false,
                file: {
                  file: '',
                  url: '',
                },
                failedFiles: [
                  {
                    file: filesUploaded[i],
                    message: this.verify(filesUploaded[i]),
                  },
                ],
              };
            }
          }
          break;
        case 'lab':
          filesUploaded = from === 'drag' ? files : this.$refs.files_lab.files;
          if (
            this.input.laboratorium.file.file &&
            this.input.laboratorium.file.file.name
          )
            return;
          for (let i = 0; i < filesUploaded.length; i++) {
            if ((this.verify(filesUploaded[i]) === '', type)) {
              this.input.laboratorium = {
                text: this.input.laboratorium.text,
                isDrag: false,
                isNew: true,
                file: {
                  file: filesUploaded[i],
                  url: URL.createObjectURL(filesUploaded[i]),
                },
                failedFiles: [],
              };
            } else {
              this.input.laboratorium = {
                text: this.input.laboratorium.text,
                isDrag: false,
                file: {
                  file: '',
                  url: '',
                },
                failedFiles: [
                  {
                    file: filesUploaded[i],
                    message: this.verify(filesUploaded[i]),
                  },
                ],
              };
            }
          }
          break;
        case 'profile':
          filesUploaded =
            from === 'drag'
              ? files
              : this.$refs[`files_profiles${index}`][0].files;
          if (this.input.profilePhoto[index].file.file) return;
          for (let i = 0; i < filesUploaded.length; i++) {
            profileIndex = this.input.profilePhoto.findIndex(
              i => !i.file.file && i.failedFiles.length < 1,
            );
            if (this.verify(filesUploaded[i]) === '') {
              if (profileIndex != -1) {
                this.input.profilePhoto[profileIndex] = {
                  isDrag: false,
                  isNew: true,
                  file: {
                    file: filesUploaded[i],
                    url: URL.createObjectURL(filesUploaded[i]),
                  },
                  failedFiles: [],
                };
              } else {
                this.input.profilePhoto.push({
                  isDrag: false,
                  isNew: true,
                  file: {
                    file: filesUploaded[i],
                    url: URL.createObjectURL(filesUploaded[i]),
                  },
                  failedFiles: [],
                });
              }
            } else {
              if (profileIndex != -1) {
                this.input.profilePhoto[profileIndex] = {
                  isDrag: false,
                  file: {
                    file: '',
                    url: '',
                  },
                  failedFiles: [
                    {
                      file: filesUploaded[i],
                      url: URL.createObjectURL(filesUploaded[i]),
                      message: this.verify(filesUploaded[i]),
                    },
                  ],
                };
              } else {
                this.input.profilePhoto.push({
                  isDrag: false,
                  file: {
                    file: '',
                    url: '',
                  },
                  failedFiles: [
                    {
                      file: filesUploaded[i],
                      url: URL.createObjectURL(filesUploaded[i]),
                      message: this.verify(filesUploaded[i]),
                    },
                  ],
                });
              }
            }
          }
          break;
        case 'profile-reupload':
          filesUploaded =
            from === 'drag'
              ? files
              : this.$refs[`files_profiles${index}`][0].files;
          if (this.input.profilePhoto[index].file.file) return;
          for (let i = 0; i < filesUploaded.length; i++) {
            if (this.verify(filesUploaded[i]) === '') {
              this.input.profilePhoto.splice(index, 1, {
                isDrag: false,
                file: {
                  file: filesUploaded[i],
                  url: URL.createObjectURL(filesUploaded[i]),
                },
                failedFiles: [],
              });
            } else {
              this.input.profilePhoto.splice(index, 1, {
                isDrag: false,
                file: {
                  file: '',
                  url: '',
                },
                failedFiles: [
                  {
                    file: filesUploaded[i],
                    url: URL.createObjectURL(filesUploaded[i]),
                    message: this.verify(filesUploaded[i]),
                  },
                ],
              });
            }
          }
      }
    },
    verify(file) {
      const maxSize = 2000000;
      let allowedTypes;
      allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 2 MB';
      }

      const ext = file.name.split('.');

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`;
      }

      return '';
    },
    onScroll() {
      const main = document.getElementById('main-container');
      this.scrollValue = main.scrollTop;
    },
    scrollTo(staff) {
      if (staff === 'doctor') {
        const el = this.$refs.doctor;
        if (el) {
          el.scrollIntoView();
        }
      }
      if (staff === 'nurse') {
        const el = this.$refs.nurse;
        if (el) {
          el.scrollIntoView();
        }
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form');
    },
    addRadiology(event, index, type) {
      if (this.emrData !== null || !this.isDoctor) return;
      if (event.keyCode === 9) return;
      if (type === 'keyup') {
        if (
          this.input.radiology[index].text === '' &&
          this.input.radiology.length !== 1 &&
          !this.input.radiology[index].file.file
        ) {
          this.input.radiology.splice(index, 1);
          this.input.radiology.forEach((i, index) => {
            this.input.radiology[index] = {
              ...i,
              index,
            };
          });
        }
      } else {
        this.input.radiology.push({
          text: '',
          isDrag: false,
          file: {
            file: '',
            url: '',
          },
          failedFiles: [],
        });
        this.input.radiology.forEach((i, index) => {
          this.input.radiology[index] = {
            ...i,
            index,
          };
        });
      }
    },
    addAnamnesis() {
      this.input.anamnesis.push('');
    },
    deleteAnamnesis(index) {
      this.input.anamnesis.splice(index, 1);
    },
    addDoctorAnamnesis() {
      this.input.doctorAnamnesis.push('');
    },
    deleteDoctorAnamnesis(index) {
      this.input.doctorAnamnesis.splice(index, 1);
    },
    addDisease() {
      this.input.priorDisease.push('');
    },
    deleteDisease(index) {
      this.input.priorDisease.splice(index, 1);
    },
    addAllergy() {
      this.input.allergy.others.push('');
    },
    deleteAllergy(index) {
      this.input.allergy.splice(index, 1);
    },
    addAction() {
      this.input.procedure.push('');
      this.input.actions.push('');
    },
    deleteAction(index) {
      this.input.procedure.splice(index, 1);
      this.input.actions.splice(index, 1);
    },
    addTherapy() {
      this.input.therapies.push('');
    },
    deleteTherapy(index) {
      this.input.therapies.splice(index, 1);
    },
    handleClick(event) {
      if (this.isDoctor && this.isEditable) {
        const stage = event.target.getStage();
        const position = stage.getPointerPosition();
        const data = {
          type: this.input.penType,
          position,
        };
        this.input.dentalCoordinates.push(data);
      }
    },
    textConfig(item) {
      let text = '';
      switch (item.type) {
        case 0:
          text = 'O';
          break;
        case 2:
          text = 'X';
          break;
        case 3:
          text = 'H';
          break;
        default:
          break;
      }
      return {
        x: item.position.x - 5,
        y: item.position.y - 5,
        text,
        fontSize: 12,
        fill: 'black',
      };
    },
    imageConfig(item) {
      let image = null;
      switch (item.type) {
        case 4:
          image = this.dentalShaking;
          break;
        case 5:
          image = this.dentalCalculus;
          break;
        case 6:
          image = this.dentalRadix;
          break;
        default:
          break;
      }
      return {
        x: item.position.x - 6,
        y: item.position.y - 6,
        image,
      };
    },
    circleConfig(item) {
      return item.type === 1
        ? {
            x: item.position.x,
            y: item.position.y,
            radius: 5,
            fill: 'black',
          }
        : {};
    },
    deleteSign(item) {
      if (!this.isDeleteMode) return;
      const index = this.input.dentalCoordinates.findIndex(i => i === item);
      this.input.dentalCoordinates.splice(index, 1);
    },
    getDentalDetailLabel(index) {
      if (index < 5) {
        return `${index + 11} [${index + 51}]`;
      }
      if (index >= 8 && index < 13) {
        return `[${index + 53}] ${index + 13}`;
      }
      if (index >= 13 && index < 16) {
        return `${index + 13}`;
      }
      if (index >= 16 && index < 19) {
        return `${54 - index}`;
      }
      if (index >= 19 && index < 24) {
        return `[${94 - index}] ${54 - index}`;
      }
      if (index >= 24 && index < 27) {
        return `${72 - index}`;
      }
      if (index >= 27 && index < 32) {
        return `${72 - index} [${112 - index}]`;
      }
      return `${index + 11}`;
    },
    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    getDiagnose(
      keyword,
      isPrimer = true,
      isSecondary = true,
      isDeathCause = true,
    ) {
      if (keyword === '' || keyword === null) return;
      if (isPrimer) this.diagnoseLoading = true;
      if (isSecondary) this.secDiagnoseLoading = true;
      if (isDeathCause) this.deathCauseLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/diagnose?page=1&itemCount=20&search=${
                keyword === null ? '' : keyword
              }&sort=-code`,
            ),
          )
          .then(response => {
            let allDiagnose = response.data.data;
            if (isPrimer) {
              this.resource.mainDiagnose = allDiagnose.map(diagnose => {
                return {
                  id: diagnose._id.toString(),
                  code: diagnose.code,
                  text: diagnose.description,
                  isNonSpecialist:
                    diagnose?.nonSpesialis === undefined
                      ? ''
                      : diagnose?.nonSpesialis,
                  displayName: `${diagnose.code} - ${diagnose.description}`,
                };
              });
              this.diagnoseLoading = false;
            }
            if (isSecondary) {
              this.resource.secondaryDiagnose[
                this.secDiagnoseActvIdx
              ].options = allDiagnose.map(diagnose => {
                return {
                  id: diagnose._id.toString(),
                  code: diagnose.code,
                  text: diagnose.description,
                  isNonSpecialist:
                    diagnose?.nonSpesialis === undefined
                      ? ''
                      : diagnose?.nonSpesialis,
                  displayName: `${diagnose.code} - ${diagnose.description}`,
                };
              });
              this.secDiagnoseLoading = false;
            }
            if (isDeathCause) {
              this.resource.deathCauseDiagnose = allDiagnose.map(diagnose => {
                return {
                  id: diagnose._id.toString(),
                  code: diagnose.code,
                  text: diagnose.description,
                  isNonSpecialist:
                    diagnose?.nonSpesialis === undefined
                      ? ''
                      : diagnose?.nonSpesialis,
                  displayName: `${diagnose.code} - ${diagnose.description}`,
                };
              });
              this.deathCauseLoading = false;
            }
          })
          .catch(() => {
            if (isPrimer) this.diagnoseLoading = false;
            if (isSecondary) this.secDiagnoseLoading = false;
            if (isDeathCause) this.deathCauseLoading = false;
          });
      });
    },
    getComplicationDiagnose(keyword) {
      this.complicationDiagnoseLoading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/diagnose?page=1&itemCount=100&search=${
                keyword === null ? '' : keyword
              }&sort=-code`,
            ),
          )
          .then(response => {
            let allDiagnose = response.data.data;
            this.resource.referralReason = allDiagnose.map(diagnose => {
              return `${diagnose.code} - ${diagnose.description}`;
            });
            this.complicationDiagnoseLoading = false;
          })
          .catch(() => {
            this.complicationDiagnoseLoading = false;
          });
      });
    },
    getProcedure(keyword) {
      this.procedureLoading = true;
      axios
        .get(
          `${
            Constant.apiUrl
          }/master/procedure?page=1&itemCount=50&&search=${keyword || ''}`,
        )
        .then(response => {
          const { data } = response.data;
          this.resource.procedure = data.map(procedure => {
            return {
              id: procedure._id.toString(),
              code: procedure.code.toString(),
              text: procedure.description,
              displayName: `${procedure.code} ${procedure.description}`,
            };
          });
        })
        .catch(() => {
          this.resource.procedure = [];
        })
        .finally(() => {
          this.procedureLoading = false;
        });
    },
    addDiagnose() {
      this.resource.secondaryDiagnose.push({ options: [] });
      this.input.secondaryDiagnose.push({
        text: null,
        detail: '',
      });

      this.getDiagnose('', false);
    },
    deleteSecDiagnose(index) {
      if (index !== 0) {
        this.resource.secondaryDiagnose.splice(index, 1);
        this.input.secondaryDiagnose.splice(index, 1);
      } else {
        this.input.secondaryDiagnose = [
          {
            text: '',
            detail: '',
          },
        ];
      }
    },
    changeDiagnose(event) {
      this.$nextTick(() => {
        if (event) {
          this.addDiagnose();
        } else {
          this.input.secondaryDiagnose.pop();
        }
      });
    },
    addDeathCause(event) {
      this.$nextTick(() => {
        if (event) {
          this.input.deathCause.push('');
        } else {
          this.input.deathCause.pop();
        }
      });
    },
    deleteItem(index) {
      if (index !== 0) {
        this.input.secondaryDiagnose.splice(index, 1);
      } else {
        this.input.secondaryDiagnose = [
          {
            text: '',
            detail: '',
          },
        ];
      }

      this.getDiagnose('', false);
    },
    async saveData(openAction) {
      this.$refs.objective.$refs.objectiveForm.validate();
      this.$refs.form.validate();

      const isValidated =
        this.$refs.form.validate() &&
        this.$refs.objective.$refs.objectiveForm.validate();

      if (await !isValidated) {
        this.valid = false;
        scrollToEmptyInput();
      } else {
        this.saveLoading = true;
        let payload;
        // payload for doctor user and adult patient
        if (this.isDoctor && this.isAdult) {
          payload = {
            isAdult: this.isAdult,
            checkup: {
              subjective: {
                anamnesis: this.input.anamnesis.filter(x => x !== ''),
                disease_history: this.input.priorDisease.filter(x => x !== ''),
                allergy_history: this.input.allergy.others.filter(
                  x => x !== '',
                ),
                allergy_air: this.input.allergy.air,
                allergy_food: this.input.allergy.food,
                allergy_drug: this.input.allergy.drug,
                treatment_history: this.input.treatmentHistory || '',
                psychological_status: this.input.psychologyStatusCheckbox
                  ? this.input.psychologyStatus || ''
                  : ['Tidak Ada Kelainan'],
                social_economy: this.input.socialEconomy || '',
                spiritual: this.input.spiritual || '',
              },
              nutrition_screening: {
                isUnplannedWeightLoss: {
                  check:
                    this.input.nutritionScreening?.adult
                      ?.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLossAmount?.check,
                        )
                      : this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLoss?.check,
                        ),
                  score:
                    this.input.nutritionScreening?.adult
                      ?.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLossAmount?.score,
                        )
                      : this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLoss?.score,
                        ),
                },
                isFoodIntakePoorDueToDecreasedAppetite: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.adult
                      ?.isFoodIntakePoorDueToDecreasedAppetite?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.adult
                      ?.isFoodIntakePoorDueToDecreasedAppetite?.score,
                  ),
                },
                isHaveSeriousIllness: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.adult?.isHaveSeriousIllness
                      ?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.adult?.isHaveSeriousIllness
                      ?.score,
                  ),
                },
              },
              objective: {
                heart_rate: this.pulse,
                sistol: this.sistole,
                diastol: this.diastole,
                respiration_rate: this.respiration,
                temperature: this.temperature,
                saturation: this.saturation,
                awarness: this.awareness,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.bellyCircumference,
              },
              assesment: {
                prognosis: this.input.prognosa,
                fallRisk: {
                  fallHistory: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.fallHistory?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.fallHistory?.score,
                    ),
                  },
                  secondaryDiagnosis: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.secDiagnose?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.secDiagnose?.score,
                    ),
                  },
                  walkTool: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.walker?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.walker?.score,
                    ),
                  },
                  useHeparinLockTherapy: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.heparinLockTherapy?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.heparinLockTherapy?.score,
                    ),
                  },
                  howToWalk: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.walk?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.walk?.score,
                    ),
                  },
                  mentalStatus: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.mentalStatus?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.mentalStatus?.score,
                    ),
                  },
                },
                pain_assesment: {
                  type: this.pain_type,
                  detail: {
                    score: this.score,
                    pain_factor: this.pain_factor,
                    pain_like: this.pain_like,
                    pain_radiation: this.pain_radiation,
                    severity: this.severity,
                    pain_frequency: this.pain_frequency,
                    pain_duration: this.pain_duration,
                    description: this.description,
                  },
                },
                main_diagnose: {
                  detail: this.input.mainDiagnose.detail,
                  text: {
                    code: this.input.mainDiagnose.text.code,
                    id: this.input.mainDiagnose.text.id,
                    text: this.input.mainDiagnose.text.text,
                  },
                },
                doctorAnamnesis: this.input.doctorAnamnesis.filter(
                  x => x !== '',
                ),
                other_diagnose: this.input.secondaryDiagnose
                  .filter(x => x?.text?.id !== '' && x.text !== null)
                  .map(arr => {
                    return {
                      detail: arr.detail,
                      text: {
                        id: arr.text.id,
                        text: arr.text.text,
                        value: arr.text.code,
                      },
                    };
                  }),
                isChronic: this.input.isChronic,
                death_cause: this.input.deathCause
                  .filter(x => x)
                  .map(i => {
                    return {
                      detail: i.text,
                      text: {
                        id: i.id,
                        text: i.text,
                        value: i.code,
                      },
                    };
                  }),
                return_status: this.input.returnStatus,
              },
              planning: {
                treatment: this.input.actions.filter(x => x !== ''),
                therapy: this.input.therapies.filter(x => x !== ''),
                treatment_plan: this.input.treatmentPlan,
                procedure: this.input.procedure
                  .filter(x => x !== '' && x !== null)
                  .map(procedure => {
                    return {
                      id: procedure.id,
                      text: procedure.text,
                    };
                  }),
              },
              oral_cavity_assessment: {
                palatum: {
                  isActive: this.input.oralCavity[0].exist,
                  result: this.input.oralCavity[0].description,
                },
                mucosa: {
                  isActive: this.input.oralCavity[1].exist,
                  result: this.input.oralCavity[1].description,
                },
                tongue: {
                  isActive: this.input.oralCavity[2].exist,
                  result: this.input.oralCavity[2].description,
                },
                lips: {
                  isActive: this.input.oralCavity[3].exist,
                  result: this.input.oralCavity[3].description,
                },
                gum: {
                  isActive: this.input.oralCavity[4].exist,
                  result: this.input.oralCavity[4].description,
                },
                bottom_of_mouth: {
                  isActive: this.input.oralCavity[5].exist,
                  result: this.input.oralCavity[5].description,
                },
                occlusion: {
                  isActive: this.input.oralCavity[6].exist,
                  result: this.input.oralCavity[6].description,
                },
              },
              others: {
                ekg: [{ result: this.input.ekg.text }],
                laboratory: [{ result: this.input.laboratorium.text }],
                radiology: this.input.radiology.map((data, index) => {
                  return {
                    result: data.text,
                    tag: `${this.patientData.id_emr}rad${index}`,
                  };
                }),
                intraoral: [{ result: '' }],
                other: [{ result: this.input.othersNote }],
              },
              odontogram: [
                {
                  image: this.input.dentalCoordinates,
                  detail: this.input.dentalDetail.map(item =>
                    item === '' ? '' : item,
                  ),
                },
              ],
              odontogram_form: this.input.odontogramTable.items.map(item => {
                return {
                  tooth_number: item.no,
                  subjective_examination: item.subjective,
                  objective_examination: {
                    sondasi: {
                      isActive: item.objective.sondasi.plus,
                      result: item.objective.sondasi.description,
                    },
                    perkusi: {
                      isActive: item.objective.perkusi.plus,
                      result: item.objective.perkusi.description,
                    },
                    palpasi: {
                      isActive: item.objective.palpasi.plus,
                      result: item.objective.palpasi.description,
                    },
                    vitalitas: {
                      isActive: item.objective.vitalitas.plus,
                      result: item.objective.vitalitas.description,
                    },
                  },
                  main_diagnose: {
                    diagnose: item.diagnose.id,
                    description: item.description,
                  },
                  planning: {
                    treatment_plan: item.treatmentPlan,
                    treatment: item.action,
                    procedure: item.procedure.id,
                  },
                };
              }),
              timestamps: {
                nurse_by: this.input.timestamps?.nurse_by,
                nurse_created_at: this.input.timestamps?.nurse_created_at,
                doctor_by: this.$store.getters.userLoggedIn.id,
                doctor_created_at: moment().format(),
              },
            },
          };
          if (this.input.returnStatus == '4' && this.isBPJS) {
            const referral_data = this.mapReferralLetterData();
            payload = {
              ...payload,
              referral_data,
            };
          }
        }
        // payload for doctor user and non adult patient
        if (this.isDoctor && !this.isAdult) {
          payload = {
            isAdult: this.isAdult,
            checkup: {
              subjective: {
                anamnesis: this.input.anamnesis.filter(x => x !== ''),
                disease_history: this.input.priorDisease.filter(x => x !== ''),
                allergy_history: this.input.allergy.others.filter(
                  x => x !== '',
                ),
                allergy_air: this.input.allergy.air,
                allergy_food: this.input.allergy.food,
                allergy_drug: this.input.allergy.drug,
                treatment_history: this.input.treatmentHistory || '',
                psychological_status: this.input.psychologyStatusCheckbox
                  ? this.input.psychologyStatus || ''
                  : ['Tidak Ada Kelainan'],
                social_economy: this.input.socialEconomy || '',
                spiritual: this.input.spiritual || '',
              },
              nutrition_screening: {
                isThin: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isThin?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isThin?.score,
                  ),
                },
                isWeightLossLastMonth: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isLossWeight?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isLossWeight?.score,
                  ),
                },
                isThereOfTheseCondition: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isHasCondition?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isHasCondition?.score,
                  ),
                },
                isThereCausesMalnourished: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isMalnutritionRisk
                      ?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isMalnutritionRisk
                      ?.score,
                  ),
                },
              },
              objective: {
                heart_rate: this.pulse,
                sistol: this.sistole,
                diastol: this.diastole,
                respiration_rate: this.respiration,
                temperature: this.temperature,
                saturation: this.saturation,
                awarness: this.awareness,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.bellyCircumference,
              },
              assesment: {
                prognosis: this.input.prognosa,
                fallRisk: {
                  ageScore: this.kidAge.score,
                  genderScore:
                    this.patientData.meta.gender === 'Laki-laki' ||
                    this.patientData.meta.gender === true
                      ? 2
                      : 1,
                  diagnosis: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.diagnose?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.diagnose?.score,
                    ),
                  },
                  cognitiveImpairment: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.cogntiveImpairment?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.cogntiveImpairment?.score,
                    ),
                  },
                  surgery: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.surgery?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.surgery?.score,
                    ),
                  },
                  medicinalUse: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.medicamentosa?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.medicamentosa?.score,
                    ),
                  },
                },
                pain_assesment: {
                  type: this.pain_type,
                  detail: {
                    score: this.score,
                    pain_factor: this.pain_factor,
                    pain_like: this.pain_like,
                    pain_radiation: this.pain_radiation,
                    severity: this.severity,
                    pain_frequency: this.pain_frequency,
                    pain_duration: this.pain_duration,
                    description: this.description,
                  },
                },
                main_diagnose: {
                  detail: this.input.mainDiagnose.detail,
                  text: {
                    code: this.input.mainDiagnose.text.code,
                    id: this.input.mainDiagnose.text.id,
                    text: this.input.mainDiagnose.text.text,
                  },
                },
                doctorAnamnesis: this.input.doctorAnamnesis.filter(
                  x => x !== '',
                ),
                other_diagnose: this.input.secondaryDiagnose
                  .filter(x => x?.text?.id !== '' && x.text !== null)
                  .map(arr => {
                    return {
                      detail: arr.detail,
                      text: {
                        id: arr.text.id,
                        text: arr.text.text,
                        value: arr.text.code,
                      },
                    };
                  }),
                isChronic: this.input.isChronic,
                death_cause: this.input.deathCause
                  .filter(x => x)
                  .map(i => {
                    return {
                      detail: i.text,
                      text: {
                        id: i.id,
                        text: i.text,
                        value: i.code,
                      },
                    };
                  }),
                return_status: this.input.returnStatus,
              },
              planning: {
                treatment: this.input.actions.filter(x => x !== ''),
                therapy: this.input.therapies.filter(x => x !== ''),
                treatment_plan: this.input.treatmentPlan,
                procedure: this.input.procedure
                  .filter(x => x !== '' && x !== null)
                  .map(procedure => {
                    return {
                      id: procedure.id,
                      text: procedure.text,
                    };
                  }),
              },
              oral_cavity_assessment: {
                palatum: {
                  isActive: this.input.oralCavity[0].exist,
                  result: this.input.oralCavity[0].description,
                },
                mucosa: {
                  isActive: this.input.oralCavity[1].exist,
                  result: this.input.oralCavity[1].description,
                },
                tongue: {
                  isActive: this.input.oralCavity[2].exist,
                  result: this.input.oralCavity[2].description,
                },
                lips: {
                  isActive: this.input.oralCavity[3].exist,
                  result: this.input.oralCavity[3].description,
                },
                gum: {
                  isActive: this.input.oralCavity[4].exist,
                  result: this.input.oralCavity[4].description,
                },
                bottom_of_mouth: {
                  isActive: this.input.oralCavity[5].exist,
                  result: this.input.oralCavity[5].description,
                },
                occlusion: {
                  isActive: this.input.oralCavity[6].exist,
                  result: this.input.oralCavity[6].description,
                },
              },
              others: {
                ekg: [{ result: this.input.ekg.text }],
                laboratory: [{ result: this.input.laboratorium.text }],
                radiology: this.input.radiology.map((data, index) => {
                  return {
                    result: data.text,
                    tag: `${this.patientData.id_emr}rad${index}`,
                  };
                }),
                intraoral: [{ result: '' }],
                other: [{ result: this.input.othersNote }],
              },
              odontogram: [
                {
                  image: this.input.dentalCoordinates,
                  detail: this.input.dentalDetail.map(item =>
                    item === '' ? '' : item,
                  ),
                },
              ],
              odontogram_form: this.input.odontogramTable.items.map(item => {
                return {
                  tooth_number: item.no,
                  subjective_examination: item.subjective,
                  objective_examination: {
                    sondasi: {
                      isActive: item.objective.sondasi.plus,
                      result: item.objective.sondasi.description,
                    },
                    perkusi: {
                      isActive: item.objective.perkusi.plus,
                      result: item.objective.perkusi.description,
                    },
                    palpasi: {
                      isActive: item.objective.palpasi.plus,
                      result: item.objective.palpasi.description,
                    },
                    vitalitas: {
                      isActive: item.objective.vitalitas.plus,
                      result: item.objective.vitalitas.description,
                    },
                  },
                  main_diagnose: {
                    diagnose: item.diagnose.id,
                    description: item.description,
                  },
                  planning: {
                    treatment_plan: item.treatmentPlan,
                    treatment: item.action,
                    procedure: item.procedure.id,
                  },
                };
              }),
              timestamps: {
                nurse_by: this.input.timestamps?.nurse_by,
                nurse_created_at: this.input.timestamps?.nurse_created_at,
                doctor_by: this.$store.getters.userLoggedIn.id,
                doctor_created_at: moment().format(),
              },
            },
          };
          if (this.input.returnStatus == '4' && this.isBPJS) {
            const referral_data = this.mapReferralLetterData();
            payload = {
              ...payload,
              referral_data,
            };
          }
        }
        // payload for non doctor user and adult patient
        if (!this.isDoctor && this.isAdult) {
          payload = {
            isAdult: this.isAdult,
            checkup: {
              subjective: {
                anamnesis: this.input.anamnesis.filter(x => x !== ''),
                disease_history: this.input.priorDisease.filter(x => x !== ''),
                allergy_history: this.input.allergy.others.filter(
                  x => x !== '',
                ),
                allergy_air: this.input.allergy.air,
                allergy_food: this.input.allergy.food,
                allergy_drug: this.input.allergy.drug,
                treatment_history: this.input.treatmentHistory || '',
                psychological_status: this.input.psychologyStatusCheckbox
                  ? this.input.psychologyStatus || ''
                  : ['Tidak Ada Kelainan'],
                social_economy: this.input.socialEconomy || '',
                spiritual: this.input.spiritual || '',
              },
              nutrition_screening: {
                isUnplannedWeightLoss: {
                  check:
                    this.input.nutritionScreening?.adult
                      ?.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLossAmount?.check,
                        )
                      : this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLoss?.check,
                        ),
                  score:
                    this.input.nutritionScreening?.adult
                      ?.isUnplannedWeightLoss === true
                      ? this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLossAmount?.score,
                        )
                      : this.filterVariable(
                          this.input.nutritionScreening?.adult
                            ?.isUnplannedWeightLoss?.score,
                        ),
                },
                isFoodIntakePoorDueToDecreasedAppetite: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.adult
                      ?.isFoodIntakePoorDueToDecreasedAppetite?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.adult
                      ?.isFoodIntakePoorDueToDecreasedAppetite?.score,
                  ),
                },
                isHaveSeriousIllness: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.adult?.isHaveSeriousIllness
                      ?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.adult?.isHaveSeriousIllness
                      ?.score,
                  ),
                },
              },
              objective: {
                heart_rate: this.pulse,
                sistol: this.sistole,
                diastol: this.diastole,
                respiration_rate: this.respiration,
                temperature: this.temperature,
                saturation: this.saturation,
                awarness: this.awareness,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.bellyCircumference,
              },
              assesment: {
                prognosis: this.input.prognosa,
                fallRisk: {
                  fallHistory: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.fallHistory?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.fallHistory?.score,
                    ),
                  },
                  secondaryDiagnosis: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.secDiagnose?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.secDiagnose?.score,
                    ),
                  },
                  walkTool: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.walker?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.walker?.score,
                    ),
                  },
                  useHeparinLockTherapy: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.heparinLockTherapy?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.heparinLockTherapy?.score,
                    ),
                  },
                  howToWalk: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.walk?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.walk?.score,
                    ),
                  },
                  mentalStatus: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.adult?.mentalStatus?.check,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.adult?.mentalStatus?.score,
                    ),
                  },
                },
                pain_assesment: {
                  type: this.pain_type,
                  detail: {
                    score: this.score,
                    pain_factor: this.pain_factor,
                    pain_like: this.pain_like,
                    pain_radiation: this.pain_radiation,
                    severity: this.severity,
                    pain_frequency: this.pain_frequency,
                    pain_duration: this.pain_duration,
                    description: this.description,
                  },
                },
              },
              oral_cavity_assessment: {
                palatum: {
                  isActive: this.input.oralCavity[0].exist,
                  result: this.input.oralCavity[0].description,
                },
                mucosa: {
                  isActive: this.input.oralCavity[1].exist,
                  result: this.input.oralCavity[1].description,
                },
                tongue: {
                  isActive: this.input.oralCavity[2].exist,
                  result: this.input.oralCavity[2].description,
                },
                lips: {
                  isActive: this.input.oralCavity[3].exist,
                  result: this.input.oralCavity[3].description,
                },
                gum: {
                  isActive: this.input.oralCavity[4].exist,
                  result: this.input.oralCavity[4].description,
                },
                bottom_of_mouth: {
                  isActive: this.input.oralCavity[5].exist,
                  result: this.input.oralCavity[5].description,
                },
                occlusion: {
                  isActive: this.input.oralCavity[6].exist,
                  result: this.input.oralCavity[6].description,
                },
              },
              timestamps: {
                nurse_by: this.$store.getters.userLoggedIn.id,
                nurse_created_at: moment().format(),
              },
            },
          };
          if (this.input.returnStatus == '4' && this.isBPJS) {
            const referral_data = this.mapReferralLetterData();
            payload = {
              ...payload,
              referral_data,
            };
          }
        }
        // payload for non doctor user and non adult patient
        if (!this.isDoctor && !this.isAdult) {
          payload = {
            isAdult: this.isAdult,
            checkup: {
              subjective: {
                anamnesis: this.input.anamnesis.filter(x => x !== ''),
                disease_history: this.input.priorDisease.filter(x => x !== ''),
                allergy_history: this.input.allergy.others.filter(
                  x => x !== '',
                ),
                allergy_air: this.input.allergy.air,
                allergy_food: this.input.allergy.food,
                allergy_drug: this.input.allergy.drug,
                treatment_history: this.input.treatmentHistory || '',
                psychological_status: this.input.psychologyStatusCheckbox
                  ? this.input.psychologyStatus || ''
                  : ['Tidak Ada Kelainan'],
                social_economy: this.input.socialEconomy || '',
                spiritual: this.input.spiritual || '',
              },
              nutrition_screening: {
                isThin: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isThin?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isThin?.score,
                  ),
                },
                isWeightLossLastMonth: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isLossWeight?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isLossWeight?.score,
                  ),
                },
                isThereOfTheseCondition: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isHasCondition?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isHasCondition?.score,
                  ),
                },
                isThereCausesMalnourished: {
                  check: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isMalnutritionRisk
                      ?.check,
                  ),
                  score: this.filterVariable(
                    this.input.nutritionScreening?.kid?.isMalnutritionRisk
                      ?.score,
                  ),
                },
              },
              objective: {
                heart_rate: this.pulse,
                sistol: this.sistole,
                diastol: this.diastole,
                respiration_rate: this.respiration,
                temperature: this.temperature,
                saturation: this.saturation,
                awarness: this.awareness,
                weight: this.weight,
                height: this.height,
                belly_circumference: this.bellyCircumference,
              },
              assesment: {
                prognosis: this.input.prognosa,
                fallRisk: {
                  ageScore: this.kidAge.score,
                  genderScore:
                    this.patientData.meta.gender === 'Laki-laki' ||
                    this.patientData.meta.gender === true
                      ? 2
                      : 1,
                  diagnosis: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.diagnose?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.diagnose?.score,
                    ),
                  },
                  cognitiveImpairment: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.cogntiveImpairment?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.cogntiveImpairment?.score,
                    ),
                  },
                  surgery: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.surgery?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.surgery?.score,
                    ),
                  },
                  medicinalUse: {
                    check: this.filterVariable(
                      this.input?.fallRisk?.kid?.medicamentosa?.value,
                    ),
                    score: this.filterVariable(
                      this.input?.fallRisk?.kid?.medicamentosa?.score,
                    ),
                  },
                },
                pain_assesment: {
                  type: this.pain_type,
                  detail: {
                    score: this.score,
                    pain_factor: this.pain_factor,
                    pain_like: this.pain_like,
                    pain_radiation: this.pain_radiation,
                    severity: this.severity,
                    pain_frequency: this.pain_frequency,
                    pain_duration: this.pain_duration,
                    description: this.description,
                  },
                },
              },
              oral_cavity_assessment: {
                palatum: {
                  isActive: this.input.oralCavity[0].exist,
                  result: this.input.oralCavity[0].description,
                },
                mucosa: {
                  isActive: this.input.oralCavity[1].exist,
                  result: this.input.oralCavity[1].description,
                },
                tongue: {
                  isActive: this.input.oralCavity[2].exist,
                  result: this.input.oralCavity[2].description,
                },
                lips: {
                  isActive: this.input.oralCavity[3].exist,
                  result: this.input.oralCavity[3].description,
                },
                gum: {
                  isActive: this.input.oralCavity[4].exist,
                  result: this.input.oralCavity[4].description,
                },
                bottom_of_mouth: {
                  isActive: this.input.oralCavity[5].exist,
                  result: this.input.oralCavity[5].description,
                },
                occlusion: {
                  isActive: this.input.oralCavity[6].exist,
                  result: this.input.oralCavity[6].description,
                },
              },
              timestamps: {
                nurse_by: this.$store.getters.userLoggedIn.id,
                nurse_created_at: moment().format(),
              },
            },
          };
          if (this.input.returnStatus == '4' && this.isBPJS) {
            const referral_data = this.mapReferralLetterData();
            payload = {
              ...payload,
              referral_data,
            };
          }
        }
        axios
          .post(
            Constant.apiUrl.concat(
              `/patient/emr/checkup/${this.patientData.id_registration}`,
            ),
            payload,
          )
          .then(response => {
            const radiologyFiles = this.input.radiology
              .filter(item => item.isNew)
              .map(item => {
                return {
                  file: item.file.file,
                  id: `${this.identifierPrefix}rad${item.index}`,
                  type: `rad${item.index}`,
                };
              });
            radiologyFiles.forEach(item => {
              this.submitFiles([item]);
            });

            let ekgFiles, labFiles;
            if (this.input.ekg.isNew) {
              ekgFiles = [
                {
                  file: this.input.ekg.file.file,
                  id: `${this.identifierPrefix}ekg`,
                  type: 'ekg',
                },
              ];
            }
            if (this.input.laboratorium.isNew) {
              labFiles = [
                {
                  file: this.input.laboratorium.file.file,
                  id: `${this.identifierPrefix}lab`,
                  type: 'lab',
                },
              ];
            }
            const profileFiles = this.input.profilePhoto
              .filter(item => item.isNew)
              .map(item => {
                return {
                  file: item.file.file,
                  id: `${this.identifierPrefix}oral`,
                  type: 'oral',
                };
              });
            const allFiles = [].concat([ekgFiles], [labFiles], [profileFiles]);
            allFiles.forEach(array => {
              this.submitFiles(array);
            });
            if (response.status == 200) {
              Swal.fire('Data Asesmen Berhasil Disimpan!', '', 'success').then(
                result => {
                  if (
                    (result.isConfirmed || result.isDismissed) &&
                    openAction
                  ) {
                    this.changeForm(2);
                    this.formHasChanged = false;
                  }
                },
              );
              if (!openAction) {
                this.$emit('close-form');
              }
            } else {
              Swal.fire('Gagal', response.data.pesan, 'error');
            }
          })
          .catch(err => {
            this.showErrorAxios(err);
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    },
    isHasUnplannedWeightLoss(data) {
      return data !== 'no' && data !== 'notSure' && data;
    },
    getKidFallRisk(data, type) {
      if (!data) return '';
      let result;
      switch (type) {
        case 'diagnosis':
          result = this.resource.DiagnoseFallRisk.filter(
            i => i.score == data.score,
          );
          break;
        case 'cognitive':
          result = this.resource.cogntiveImpairment.filter(
            i => i.score == data.score,
          );
          break;
        case 'medicinal':
          result = this.resource.medicamentosa.filter(
            i => i.score == data.score,
          );
          break;
        case 'surgery':
          result = this.resource.surgery.filter(i => i.score == data.score);
          break;
      }
      return result[0];
    },
    getDataAssessment() {
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/${
              this.isEmr ? 'history' : 'service'
            }`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          this.isInputed = data.timestamps.doctor_by !== undefined;
          this.identifierPrefix = data._id;
          // Set objective data
          this.setSistol(data?.objective?.sistol || '');
          this.setDiastol(data?.objective?.diastol || '');
          this.setPulse(data?.objective?.heart_rate || '');
          this.setWeight(data?.objective?.weight || '');
          this.setHeight(data?.objective?.height || '');
          this.setBellyCircumference(
            data?.objective?.belly_circumference || '',
          );
          this.setRespirationRate(data?.objective?.respiration_rate || '');
          this.setSaturation(data?.objective?.saturation || '');
          this.setTemperature(data?.objective?.temperature || '');
          this.setAwareness(data?.objective?.awarness || '');
          this.input = {
            ...this.input,
            psychologyStatus:
              data.subjective?.psychological_status?.[0] ===
              'Tidak Ada Kelainan'
                ? []
                : data.subjective?.psychological_status || '',
            psychologyStatusCheckbox: !data.subjective
              ?.psychological_status?.[0]
              ? false
              : data.subjective?.psychological_status?.[0] !==
                'Tidak Ada Kelainan',
            socialEconomy: data.subjective?.social_economy || '',
            spiritual: data.subjective?.spiritual || '',
            anamnesis: data.subjective?.anamnesis || '',
            priorDisease: data.subjective?.disease_history || '',
            treatmentHistory: data.subjective?.treatment_history || '',
            doctorAnamnesis:
              data.assesment?.doctorAnamnesis?.length > 0
                ? data.assesment?.doctorAnamnesis
                : [''],
            allergy: {
              drug: data.subjective?.allergy_drug || [],
              air: data.subjective?.allergy_air || [],
              food: data.subjective?.allergy_food || [],
              others:
                data.subjective?.allergy_history?.length > 0
                  ? data.subjective?.allergy_history
                  : [''],
            },
            returnStatus:
              data.assesment.return_status || this.patientData.returnStatus,
            isChronic: data.assesment?.isChronic,
            prognosa: data.assesment?.prognosis || '',
            deathCause:
              data.assesment?.death_cause.length > 0
                ? data.assesment?.death_cause.map(i => {
                    return {
                      code: i.diagnose?.code,
                      displayName: `${i.diagnose?.code} - ${i.diagnose?.description}`,
                      id: i.diagnose?._id,
                      text: i.diagnose?.description,
                    };
                  })
                : [''],
            actions:
              data.planning?.treatment?.length > 0
                ? data.planning?.treatment
                : [''],
            therapies:
              data.planning?.therapy?.length > 0 ? data.planning.therapy : [''],
            nutritionScreening: {
              kid: {
                isThin: data.nutrition_screening?.isThin || {
                  check: false,
                  score: 0,
                },
                isLossWeight: data.nutrition_screening
                  ?.isWeightLossLastMonth || { check: false, score: 0 },
                isHasCondition: data.nutrition_screening
                  ?.isThereOfTheseCondition || { check: false, score: 0 },
                isMalnutritionRisk: data.nutrition_screening
                  ?.isThereCausesMalnourished || { check: false, score: 0 },
              },
              adult: {
                isUnplannedWeightLoss: this.isHasUnplannedWeightLoss(
                  data.nutrition_screening?.isUnplannedWeightLoss?.check,
                )
                  ? true
                  : data.nutrition_screening?.isUnplannedWeightLoss || {
                      check: 'no',
                      score: 0,
                    },
                isUnplannedWeightLossAmount: this.isHasUnplannedWeightLoss(
                  data.nutrition_screening?.isUnplannedWeightLoss?.check,
                )
                  ? data.nutrition_screening?.isUnplannedWeightLoss || ''
                  : '',
                isFoodIntakePoorDueToDecreasedAppetite: data.nutrition_screening
                  ?.isFoodIntakePoorDueToDecreasedAppetite || {
                  check: false,
                  score: 0,
                },
                isHaveSeriousIllness: data.nutrition_screening
                  ?.isHaveSeriousIllness || { check: false, score: 0 },
              },
            },
            fallRisk: {
              kid: {
                diagnose: this.getKidFallRisk(
                  data.assesment?.fallRisk?.diagnosis,
                  'diagnosis',
                ),
                cogntiveImpairment: this.getKidFallRisk(
                  data.assesment?.fallRisk?.cognitiveImpairment,
                  'cognitive',
                ),
                medicamentosa: this.getKidFallRisk(
                  data.assesment?.fallRisk?.medicinalUse,
                  'medicinal',
                ),
                surgery: this.getKidFallRisk(
                  data.assesment?.fallRisk?.surgery,
                  'surgery',
                ),
              },
              adult: {
                heparinLockTherapy: data.assesment?.fallRisk
                  ?.useHeparinLockTherapy || { check: false, score: 0 },
                mentalStatus: data.assesment?.fallRisk?.mentalStatus || {
                  check: 'accordingToAbility',
                  score: 0,
                },
                fallHistory: data.assesment?.fallRisk?.fallHistory || {
                  check: false,
                  score: 0,
                },
                secDiagnose: data.assesment?.fallRisk?.secondaryDiagnosis || {
                  check: false,
                  score: 0,
                },
                walker: data.assesment?.fallRisk?.walkTool || {
                  check: 'normal',
                  score: 0,
                },
                walk: data.assesment?.fallRisk?.howToWalk || {
                  check: 'normal',
                  score: 0,
                },
              },
            },
            oralCavity: this.input.oralCavity.map(item => {
              return {
                ...item,
                exist: data.oral_cavity_assessment[item.value].isActive,
                description: data.oral_cavity_assessment[item.value].result,
              };
            }),
            odontogramTable: {
              ...this.input.odontogramTable,
              items: data.odontogram_form.map(item => {
                return {
                  no: item.tooth_number,
                  subjective: item.subjective_examination,
                  diagnose: {
                    code: item.main_diagnose.diagnose?.code,
                    displayName: `${item.main_diagnose.diagnose?.code} ${item.main_diagnose.diagnose?.description}`,
                    id: item.main_diagnose.diagnose?._id,
                    isNonSpecialist:
                      item.main_diagnose.diagnose?.isNonSpecialis,
                    text: item.main_diagnose.diagnose?.description,
                  },
                  description: item.main_diagnose.description,
                  treatmentPlan: item.planning.treatment_plan,
                  action: item.planning.treatment,
                  procedure: item.planning.procedure
                    ? {
                        code: item.planning.procedure?.code,
                        displayName: `${item.planning.procedure?.code} ${item.planning.procedure?.description}`,
                        id: item.planning.procedure?._id,
                        text: item.planning.procedure?.description,
                      }
                    : '',
                  objective: {
                    sondasi: {
                      description: item.objective_examination.sondasi.result,
                      plus: item.objective_examination.sondasi.isActive,
                    },
                    palpasi: {
                      description: item.objective_examination.palpasi.result,
                      plus: item.objective_examination.palpasi.isActive,
                    },
                    perkusi: {
                      description: item.objective_examination.perkusi.result,
                      plus: item.objective_examination.perkusi.isActive,
                    },
                    vitalitas: {
                      description: item.objective_examination.vitalitas.result,
                      plus: item.objective_examination.vitalitas.isActive,
                    },
                  },
                };
              }),
            },
            dentalDetail: _.get(data, 'odontogram[0].detail', []),
            dentalCoordinates: _.get(data, 'odontogram[0].image', []),
            treatmentPlan: data.planning?.treatment_plan,
            radiology:
              data.others.radiology.length > 0
                ? data.others.radiology.map((item, index) => {
                    return {
                      text: item.result,
                      index,
                      isDrag: false,
                      isNew: false,
                      file: {
                        file: {
                          name: _.get(
                            item,
                            'files.files[0].original_file_name',
                            '',
                          ),
                        },
                        url: _.get(item, 'files.files[0].path', '')
                          ? this.imgUrl(`/${item.files.files[0].path}`)
                          : '',
                        id: _.get(item, 'files.files[0]._id', ''),
                        tag: _.get(item, 'files.tag', ''),
                        identifier: _.get(item, 'files.identifier', ''),
                      },
                      failedFiles: [],
                    };
                  })
                : [
                    {
                      text: '',
                      isDrag: false,
                      isNew: true,
                      file: {
                        file: '',
                        url: '',
                      },
                      failedFiles: [],
                    },
                  ],
            othersNote: _.get(data, 'others.other[0].result', ''),
            ekg: {
              text: _.get(data, 'others.ekg[0].result', ''),
              isDrag: false,
              isNew: false,
              file: {
                file: {
                  name: _.get(
                    data,
                    'others.ekg[0].files.files[0].original_file_name',
                    '',
                  ),
                },
                url: this.imgUrl(
                  `/${_.get(data, 'others.ekg[0].files.files[0].path', '')}`,
                ),
                id: _.get(data, 'others.ekg[0].files.files[0]._id', ''),
                tag: _.get(data, 'others.ekg[0].files.tag', ''),
                identifier: _.get(data, 'others.ekg[0].files.identifier', ''),
              },
              failedFiles: [],
            },
            laboratorium: {
              text: _.get(data, 'others.laboratory[0].result', ''),
              isDrag: false,
              isNew: false,
              file: {
                file: {
                  name: _.get(
                    data,
                    'others.laboratory[0].files.files[0].original_file_name',
                    '',
                  ),
                },
                url: this.imgUrl(
                  `/${_.get(
                    data,
                    'others.laboratory[0].files.files[0].path',
                    '',
                  )}`,
                ),
                id: _.get(data, 'others.laboratory[0].files.files[0]._id', ''),
                tag: _.get(data, 'others.laboratory[0].files.tag', ''),
                identifier: _.get(
                  data,
                  'others.laboratory[0].files.identifier',
                  '',
                ),
              },
              failedFiles: [],
            },
            profilePhoto: _.get(data, 'others.intraoral[0].files.files', '')
              ? data.others.intraoral[0].files.files.map(item => {
                  return {
                    isDrag: false,
                    isNew: false,
                    file: {
                      file: {
                        name: item.original_file_name,
                      },
                      url: this.imgUrl(`/${item.path}`),
                      id: item._id,
                      tag: data.others.intraoral[0].files.tag,
                      identifier: data.others.intraoral[0].files.identifier,
                    },
                    failedFiles: [],
                  };
                })
              : [
                  {
                    isDrag: false,
                    isNew: false,
                    file: {
                      file: '',
                      url: '',
                    },
                    failedFiles: [],
                  },
                  {
                    isDrag: false,
                    isNew: false,
                    file: {
                      file: '',
                      url: '',
                    },
                    failedFiles: [],
                  },
                  {
                    isDrag: false,
                    isNew: false,
                    file: {
                      file: '',
                      url: '',
                    },
                    failedFiles: [],
                  },
                  {
                    isDrag: false,
                    isNew: false,
                    file: {
                      file: '',
                      url: '',
                    },
                    failedFiles: [],
                  },
                ],
          };
          this.setScore(data.assesment?.pain_assesment?.detail?.score || 0);
          this.setPainFactor(
            data.assesment?.pain_assesment?.detail?.pain_factor || [],
          );
          this.setPainLike(
            data.assesment?.pain_assesment?.detail?.pain_like || [],
          );
          this.setPainRadiation(
            data.assesment?.pain_assesment?.detail?.pain_radiation || false,
          );
          this.setSeverity(
            data.assesment?.pain_assesment?.detail?.severity || 0,
          );
          this.setPainFrequency(
            data.assesment?.pain_assesment?.detail?.pain_frequency || 0,
          );
          this.setPainDuration(
            data.assesment?.pain_assesment?.detail?.pain_duration || 0,
          );
          this.setDescription(
            data.assesment?.pain_assesment?.detail?.description || '',
          );
          this.remapDiagnoseProcedure(
            data.assesment?.main_diagnose,
            data.assesment?.other_diagnose,
            data.planning?.procedure,
          );
          if (this.input.anamnesis.length < 1) {
            this.addAnamnesis();
          }
          if (this.input.allergy.length < 1) {
            this.addAllergy();
          }
          if (this.input.priorDisease.length < 1) {
            this.addDisease();
          }
          if (
            this.input.actions.length < 1 &&
            this.input.procedure.length < 1
          ) {
            this.addAction();
          }
          if (this.input.therapies.length < 1) {
            this.addTherapy();
          }
          if (this.isBPJS && this.input.returnStatus == '4') {
            this.input = {
              ...this.input,
              referralType: data.pcare.tacc,
            };
            this.referralLetterData = {
              referralPurpose: data.pcare.referral.isSpecialist
                ? 'specialist'
                : 'khusus',
              specialistReferralPurpose: data.pcare.referral?.specialist,
              specificReferralPurpose: data.pcare.referral?.specific,
              subspecialistReferralPurpose: data.pcare.referral.subSpecialist,
              medium: data.pcare.referral.medium,
              agency: data.pcare.referral.agency,
              therapy: data.pcare.referral.therapy,
              advice: data.pcare.referral.suggestion,
              note: data.pcare.referral.note,
              date: {
                plan: moment(data.pcare.referral.date, 'DD-MM-YYYY').format(
                  'YYYY-MM-DD',
                ),
                schedule: data.pcare.referral.agency.schedule[0],
              },
            };
            this.count = 1;
          }
        })
        .catch(() => {
          this.input = { ...this.input };
        });
    },

    remapDiagnoseProcedure(main, secondary, procedures) {
      let mainDiagnose = { text: '', description: '' };
      let secondaryDiagnose = [{ text: '', description: '' }];
      let procedure = [''];

      if (main) {
        if (main?.diagnose) {
          mainDiagnose = {
            text: {
              id: main.diagnose._id,
              code: main.diagnose.code,
              text: main.diagnose.description,
              isNonSpecialist: main.diagnose?.nonSpesialis,
              displayName: `${main.diagnose.code} ${main.diagnose.description}`,
            },
            detail: main.description,
          };

          this.resource.mainDiagnose = [
            {
              id: main.diagnose._id,
              code: main.diagnose.code,
              text: main.diagnose.description,
              isNonSpecialist: main.diagnose?.nonSpesialis,
              displayName: `${main.diagnose.code} ${main.diagnose.description}`,
            },
          ];
        }
      }

      if (secondary?.length > 0) {
        secondaryDiagnose = secondary.map((v, i) => {
          if (i != 0) this.resource.secondaryDiagnose.push({ options: [] });
          this.resource.secondaryDiagnose[i].options = [
            {
              id: v.diagnose._id,
              code: v.diagnose.code,
              text: v.diagnose.description,
              displayName: `${v.diagnose.code} ${v.diagnose.description} `,
            },
          ];
          return {
            text: {
              id: v.diagnose._id,
              code: v.diagnose.code,
              text: v.diagnose.description,
              displayName: `${v.diagnose.code} ${v.diagnose.description} `,
            },
            detail: v.description,
          };
        });
      }
      if (procedures?.length > 0) {
        procedure = procedures.map(v => {
          return {
            id: v.id._id,
            code: v.id.code,
            text: v.id.description,
            displayName: `${v.id.code} ${v.id.description}`,
          };
        });
      } else {
        procedure = [''];
      }
      this.input = {
        ...this.input,
        mainDiagnose,
        secondaryDiagnose,
        procedure,
      };
    },
    handlePrint() {
      const doc = this.generateOutpatientSummary(
        this.patientData,
        {
          ...this.emrData,
          return_status_name: this.resource.return_status.find(
            item => item.code === this.input.returnStatus,
          ).name,
        },
        this.$refs.dentalStage,
      );
      this.printDocument(doc);
    },
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin result-chip($fill, $outline) {
  background-color: $fill;
  border: solid $outline 1.5px;
  padding: 0.3rem 0.5rem;
  border-radius: 6px;
  color: $outline;
  font-weight: 450;
  width: max-content;
  height: max-content;
  font-size: calc($standard-font-size - 0.1vw);
}

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}
.grid-display {
  display: inline-grid;
  width: 100%;
  font-size: 0.9rem;

  &.header {
    grid-template-columns: 10% 40% 50%;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
  }
  &.item {
    grid-template-columns: 10% 40% 40% 10%;
    td {
      word-wrap: break-word;
      word-break: break-all;
      max-width: 100%;
    }
  }
}

.column-table {
  &.header {
    padding: 0.8rem;
    background-color: #f4f5f5;
    color: #757575;
    font-weight: 500;
    &.no {
      border-left: 1px solid #e0e0e0;
    }
    &.diagnose {
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
    }
    &.action {
      border-right: 1px solid #e0e0e0;
    }
  }
  &.item {
    padding: 0.5rem 0.7rem;

    &.diagnose {
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
    &.no {
      border-left: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }

    &.action-btn {
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
    }

    &.action {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.grid-oral {
  @include grid-display(repeat(2, 1fr), true);
  width: 100%;
  column-gap: 5rem;
  row-gap: 2rem;
  grid-auto-flow: column;
  grid-template-rows: repeat(4, 1fr);
}
.grid-oral-cavity {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 3fr;
  label {
    font-weight: 600;
    text-align: start;
  }
  .dark {
    background-color: #ededed;
  }
}
.uploaded-img {
  width: 100%;
  height: 9rem;
}
.treatment-plan {
  padding: 0px 7rem;
  &.text {
    padding: 0px 7.8rem;
  }
}
.img-container {
  position: relative;
  img {
    display: block;
    width: 100%;
  }

  .overlay {
    position: absolute;
    cursor: pointer;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: black;
    &.failed {
      background-color: rgba(247, 63, 84, 0.3);
      opacity: 1;
    }
    button {
      position: absolute;
      right: -25px;
      top: 3px;
    }
    p {
      transition: 0.3s ease;
      opacity: 1;
      position: absolute;
      right: 12%;
      top: -23%;
    }
    i {
      transition: 0.3s ease;
      opacity: 0.7;
      position: absolute;
      right: 37%;
      top: 36%;
    }
  }
}
.img-container:hover .overlay {
  opacity: 0.5;
  button {
    right: 3px;
  }
}
.img-container:hover .failed {
  opacity: 1;
  p {
    top: 37%;
  }
  i {
    top: 110%;
  }
  button {
    right: 3px;
  }
}
::v-deep .v-responsive__content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.darken-image {
  filter: brightness(50%);
}
.image-list {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  padding-bottom: 10px;
  padding-right: 3rem;
}
.photo-profile {
  display: grid;
  width: 90%;
  padding-top: 10px;
  padding-left: 27px;
  grid-template-columns: repeat(5, 100px);
  row-gap: 10px;
  column-gap: 10px;
}
.pdf {
  cursor: pointer;
}
.odontogram {
  background-color: #f4f5f5;
  border: 2px solid #e0e0e0;
  margin: 1rem auto;
  padding: 1rem;
  max-width: 85%;
}
.download-btn {
  position: absolute;
  top: 2vh;
  font-size: 16px;
}
.close-btn {
  position: absolute;
  top: 2vh;
  right: 10vw;
}
.preview-image {
  width: 90vw;
  height: 90vh;
  margin: 0px;
  padding-left: 7vw;
  padding-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-mode:hover {
  cursor: pointer;
  // cursor: url('../../../../assets/image/eraser.png') 10 18, auto;
}
.dropzone:hover {
  background-color: #f3f0f0;
  &.failed {
    i {
      top: 110%;
    }
    p {
      top: 40%;
    }
    button {
      right: 3px;
    }
  }
}
.dropzone {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 90%;
  height: 8rem;
  color: #86bcdb;
  border: 2px dashed #c2c2c2;
  border-radius: 4px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.3s ease all;
  &.active {
    background-color: #5eafe6;
    color: white;
    border: 1.5px solid #2e7aa7;
    transition: 0.3s ease all;
  }
  &.profile {
    width: 100px;
    height: 80px;
    border: 1px dashed #c2c2c2;
    padding-top: 0.5rem;
  }
  &.failed {
    i {
      position: absolute;
      top: 40%;
    }
    p {
      transition: 0.3s ease;
      opacity: 1;
      position: absolute;
      top: -25%;
    }
    button {
      position: absolute;
      right: -25px;
      top: 3px;
    }
  }
}

.grid-nutrition {
  @include grid-display(80% 20%, true);

  &.adult {
    @include grid-display(60% 40%, true);
  }
  p {
    font-weight: 300;
    text-align: start;
    margin-left: 1vw;
    font-size: 0.9vw;
    color: #757575;
    margin-top: 0.4em;
  }
}

.icd-input {
  margin-bottom: 90px;
}
.referral-select {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
}
.container-layout {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }

  .chip-green {
    @include result-chip(#e5fff0, #2d965a);
  }
  .chip-yellow {
    @include result-chip(#fff9e5, #e5a82e);
  }
  .chip-red {
    @include result-chip(#fff2f2, #eb4747);
  }
  .chip-grey {
    @include result-chip(#f6f6f6, #928f8f);
  }
  .title {
    font-size: 1.4rem;
  }
  .grid-ttv {
    @include grid-display(10vw minmax(16vw, 26vw), true);
    column-gap: 1vw;
  }
  .dental-stage {
    display: flex;
    justify-content: center;
  }
  .dental-input {
    display: grid;
    grid-template-columns: 5rem 28rem;
  }
  .fixed-button {
    z-index: 10;
    width: 15vw;
    background-color: #deeef9 !important;
    position: fixed;
    bottom: 15vh;
    right: 5vw;
    color: #429ddb;
  }
  .bounce {
    animation: scale 0.2s infinite;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
}

.title-container {
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(246, 246, 246);
}
.forbidden:hover {
  cursor: not-allowed;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
.required:after {
  content: ' *';
  color: red;
}
</style>
