const moment = require('moment-timezone');
// api
import {
  getWaitingListPatient,
  getRegisteredInpatient,
} from '@/fetchApi/Inpatient/Registration';

// mixins
import mapInpatientDashboardData from '@/mixin/MapperMixins/Registration/InpatientRegistration/mapInpatientDashboardData';

const state = {
  table: {
    waiting: [],
    registered: [],
  },
  loading: {
    waiting: false,
    registered: false,
  },
  pagination: {
    page: 1,
    total: 0,
  },
  registration: {
    bed: '',
  },
  patient: {
    name: '',
    nickname: '',
    phone_number: '',
    gender: '',
    birth: {
      date: '',
      place: '',
    },
    address: {
      district: '',
      province: '',
      sub_district: '',
      text: '',
      city: '',
    },
    identity: {
      type: '',
      number: '',
    },
    religion: '',
    blood_type: '',
    education: '',
    ethnicity: '',
    language_mastering: '',
    disabilities: '',
    communication_obstacles: '',
    profession: '',
    martial_status: '',
    father: '',
    mother: '',
    assurance: {
      type: '',
      name: '',
      number: '',
      isProlanis: '',
    },
  },
  ttv: {
    anamnesis: '',
    sistol: '',
    diastol: '',
    heart_rate: '',
    weight: '',
    height: '',
    belly_circumference: '',
    respiration_rate: '',
    saturation: '',
    temperature: '',
    awarness: '',
  },
  inputTtv: false,
  snackbar: {
    show: false,
    color: '',
    text: '',
  },
};

const getters = {
  getTableData: state => state.table,
  getLoading: state => state.loading,
  getPagination: state => state.pagination,
  getBed: state => state.registration.bed,
  getPatient: state => state.patient,
  getTtv: state => state.ttv,
  getInputTtv: state => state.inputTtv,
  getSnackbar: state => state.snackbar,
};

const mutations = {
  clearState(state) {
    state.table = {
      waiting: [],
      registered: [],
    };
    state.loading = {
      waiting: false,
      registered: false,
    };
    state.pagination = {
      descending: false,
      page: 1,
      rowsPerPage: 5,
      sortBy: '',
      total: 0,
    };
    state.registration = {
      bed: {
        id: '',
        name: '',
      },
    };
  },
  clearPatient(state) {
    state.patient = {
      name: '',
      nickname: '',
      phone_number: '',
      gender: '',
      birth: {
        date: '',
        place: '',
      },
      address: {
        district: '',
        province: '',
        sub_district: '',
        text: '',
        city: '',
      },
      identity: {
        type: '',
        number: '',
      },
      religion: '',
      blood_type: '',
      education: '',
      ethnicity: '',
      language_mastering: '',
      disabilities: '',
      communication_obstacles: '',
      profession: '',
      martial_status: '',
      father: '',
      mother: '',
      assurance: {
        type: '',
        name: '',
        number: '',
        isProlanis: '',
      },
    };
  },
  clearTtv(state) {
    state.ttv = {
      anamnesis: '',
      sistol: '',
      diastol: '',
      heart_rate: '',
      weight: '',
      height: '',
      belly_circumference: '',
      respiration_rate: '',
      saturation: '',
      temperature: '',
      awarness: '',
    };
  },
  setBed(state, payload) {
    state.registration.bed = payload;
  },
  setPatient(state, payload) {
    state.patient = payload;
  },
  setTtv(state, payload) {
    state.ttv = payload;
  },
  setInputTtv(state, payload) {
    state.inputTtv = payload;
  },
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },
  setTableData(state, { key, payload }) {
    state.table[key] = payload;
  },
  setLoading(state, { key, payload }) {
    state.loading[key] = payload;
  },
  setTotalPage(state, payload) {
    state.pagination.total = payload;
  },
  setPage(state, payload) {
    state.pagination.page = payload;
  },
  clearSnackbar(state) {
    state.snackbar = {
      show: false,
      color: '',
      text: '',
    };
  },
};

const actions = {
  async resolveGetAllWaitingListPatient({ commit }, params) {
    try {
      commit('setLoading', { key: 'waiting', payload: true });
      const response = await getWaitingListPatient(params);
      const { length: totalPatient } = response.data;
      commit('setTableData', {
        key: 'waiting',
        payload: mapInpatientDashboardData.methods.mapWaitingListData(
          response.data.data,
        ),
      });

      return { totalPatient };
    } catch (error) {
      commit('setTableData', {
        key: 'waiting',
        payload: [],
      });
      return { totalPatient: 0 };
    } finally {
      commit('setLoading', { key: 'waiting', payload: false });
    }
  },
  async resolveGetAllRegisteredPatient({ commit }, params) {
    try {
      commit('setLoading', { key: 'registered', payload: true });
      const response = await getRegisteredInpatient(params);
      const { length: totalPatient } = response.data;
      commit('setTableData', {
        key: 'registered',
        payload: mapInpatientDashboardData.methods.mapRegisteredData(
          response.data.data,
        ),
      });
      return { totalPatient };
    } catch (error) {
      commit('setTableData', {
        key: 'registered',
        payload: [],
      });
      return { totalPatient: 0 };
    } finally {
      commit('setLoading', { key: 'registered', payload: false });
    }
  },
  mapPatient({ commit }, params) {
    const data = {
      name: params.name.trim().replace(/  +/g, ' '),
      nickname: params.nickname,
      phone_number: params.phone_number,
      gender: params.gender,
      birth: {
        date: moment(params.birth.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        place: params.birth.place,
      },
      address: {
        district: params.address.district.name || '',
        province: params.address.province.name || '',
        sub_district: params.address.sub_district.name || '',
        text: params.address.text || '',
        city: params.address.city.name || '',
      },
      identity: {
        type: params.identity.type,
        number: params.identity.number,
      },
      religion: params.religion,
      blood_type: params.blood_type,
      education: params.education,
      ethnicity: params.ethnicity == null ? '' : params.ethnicity,
      language_mastering: params.language_mastering,
      disabilities: params.disabilities === null ? 0 : params.disabilities,
      communication_obstacles:
        params.communication_obstacles === null
          ? ''
          : params.communication_obstacles,
      profession: params.profession,
      martial_status: params.martial_status,
      father: params.father,
      mother: params.mother,
      assurance: {
        type: params.assurance.type,
        name: params.assurance.name,
        number: params.assurance.number,
        isProlanis: params.assurance.isProlanis,
      },
    };
    commit('setPatient', { ...data });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
