<template>
  <div>
    <v-toolbar flat outlined height="89">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="8">
            <v-toolbar-title class="font-weight-bold text-left">
              <h4>Rawat Inap</h4>
            </v-toolbar-title>
          </v-col>
          <v-col cols="2" class="px-0">
            <v-autocomplete
              placeholder="Nama DPJP"
              outlined
              dense
              :items="filter.doctor"
              item-value="doctorId"
              append-icon="mdi-chevron-down"
              item-text="doctor"
              v-model="chooseDoctor"
              @change="
                table.pagination.page = 1;
                getInpatient();
              "
              clearable
              :loading="loadingStaff"
              hide-details="false"
            ></v-autocomplete>
          </v-col>
          <v-col cols="1" class="px-0" style="max-width: fit-content">
            <v-btn color="grey" icon text @click="refresh()">
              <v-icon :class="{ refresh: isRefresh }">mdi-autorenew </v-icon>
            </v-btn>
          </v-col>
          <v-col class="px-0" style="max-width: fit-content">
            <v-text-field
              background-color="grey lighten-3"
              placeholder="Search"
              solo
              dense
              hide-details
              flat
              prepend-inner-icon="mdi-magnify"
              single-line
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :expanded="expanded"
        :loading="isLoading"
        @click:row="
          (item, slot) => {
            slot.expand(!slot.isExpanded);
          }
        "
        :items-per-page="itemsPerPage"
        :page.sync="table.pagination.page"
        :height="height"
        hide-default-footer
        item-key="noRegist"
        single-expand
      >
        <template v-slot:[`item.no`]="{ item }">
          <td class="text-left">
            {{
              (table.pagination.page - 1) * itemsPerPage +
                table.items.indexOf(item) +
                1
            }}
          </td>
        </template>
        <template v-slot:[`item.guarantorType`]="{ item }">
          <td class="text-left text-uppercase">{{ item.guarantorType }}</td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <td class="text-left mr-1">{{ item.name }}</td>
            <v-tooltip
              bottom
              v-if="item?.pcare?.visit && item?.pcare.visit?.isSuccess"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="success"
                  >mdi-check-circle</v-icon
                >
              </template>
              <span> Bridging PCare berhasil </span>
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="item?.pcare?.visit && !item.pcare?.visit?.isSuccess"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  style="background-color: #ff5252; border-radius: 4px; padding: 3px 3px 5px 5px"
                  class="d-flex justify-center align-center"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    style="transform: rotate(-45deg); cursor: pointer"
                    color="#fff"
                    @click="resendPcareData(item.id_inpatient)"
                  >
                    mdi-send
                  </v-icon>
                </div>
              </template>
              <span> {{ item?.pcare?.visit?.message }} </span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="px-0">
            <v-card flat tile>
              <v-row no-gutters class="expand">
                <v-col></v-col>
                <v-col class="text-left grey--text caption">
                  <span
                    >Terdaftar {{ item.time }} <br />
                    {{ item.date }}</span
                  ></v-col
                >
                <v-col class="text-left grey--text caption">
                  <span>{{ item.gender }} {{ item.age }} Tahun</span></v-col
                >
                <v-col class="text-left grey--text caption"
                  >Pasien {{ item.isNew ? 'Baru' : 'Lama' }}</v-col
                >
              </v-row>
            </v-card>

            <v-card flat tile color="grey lighten-4" class="text-right">
              <v-container class="text-">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(0, item)"
                        >
                          <font-awesome-icon icon="file-signature" />
                        </v-btn>
                      </template>
                      <span>EMR Rawat Inap</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(2, item)"
                        >
                          <font-awesome-icon icon="syringe" />
                        </v-btn>
                      </template>
                      <span>Tindakan dan BHP</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(3, item)"
                        >
                          <font-awesome-icon icon="prescription-bottle" />
                        </v-btn>
                      </template>

                      <span>E-Resep</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-on="{ ...tooltip }"
                          @click="selectItem(4, item)"
                        >
                          <font-awesome-icon icon="print" />
                        </v-btn>
                      </template>
                      <span>Pembuatan Surat</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectItem(6, item)"
                        >
                          <font-awesome-icon icon="flask" />
                        </v-btn>
                      </template>

                      <span>Permintaan Laboratorium</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="mx-2 hover"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectItem(5, item)"
                        >
                          <font-awesome-icon icon="clipboard-check" />
                        </v-btn>
                      </template>
                      <span>Selesai</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-main>
    <v-footer color="white">
      <v-pagination
        v-model="table.pagination.page"
        :length="pageLength"
        total-visible="5"
        @input="getInpatient"
      ></v-pagination>
    </v-footer>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      :max-width="selectedForm === null ? 1400 : widthMenu[selectedForm].width"
      :persistent="selectedForm !== 1"
      :fullscreen="
        selectedForm === 0 ||
          selectedForm === 1 ||
          selectedForm === 3 ||
          selectedForm === 6
      "
    >
      <InpatientEMR
        v-if="selectedForm === 0 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <MedicalResume
        v-else-if="selectedForm === 1 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <BHPandAction
        v-else-if="selectedForm === 2 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <ERecipe
        v-else-if="selectedForm === 3 && dialog"
        :patient-data="selectedPatient"
        :is-inpatient="true"
        @close-form="closeForm"
      />
      <Warrant
        v-else-if="selectedForm === 4 && dialog"
        :patient-data="selectedPatient"
        @close-form="closeForm"
      />
      <LabRequest
        v-else-if="selectedForm === 6 && dialog"
        @close-dialog="closeForm"
        :patient-data="selectedPatient"
        :activityStatus="activityStatusLab"
        confirmationState="order"
      />
      <PrintDocuments
        poly="inpatient"
        unit="inpatient"
        v-else-if="selectedForm === 7 && dialog"
        :props="{ generalConsent: generalConsentPatient }"
        @close-form="closeForm"
      />
      <FinishInpatient
        v-else-if="selectedForm === 5 && dialog"
        @close-form="closeForm"
        :patient-data="selectedPatient"
      />
    </v-dialog>
  </div>
</template>

<script>
// utils
import { createNamespacedHelpers } from 'vuex';
import Constant from '@/const';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
const axios = require('axios');
const _ = require('lodash');

// components
import Warrant from './components/Letters/Warrant';
import ERecipe from './components/ERecipe';
import BHPandAction from './components/BHPandAction';
import MedicalResume from './components/Inpatient/MedicalResume.vue';
import LabRequest from '../Laboratorium/components/ExaminationOrder/ExaminationDialogV2.vue';
import FinishInpatient from './components/FinishInpatient.vue';
import InpatientEMR from './components/InpatientEMR/InpatientEMR.vue';

// helper
import PrintDocuments from './components/PrintDocuments.vue';
import mapDashboardData from '@/mixin/MapperMixins/Inpatient/mapDashboardData';

// mixins
import jwtMixin from '@/mixin/jwtMixin';
import AlertMixin from '@/mixin/alertMixin';

// fetch api
import { getAllStaff } from '@/fetchApi/MasterData/Staff';
import { getInpatientPatients } from '@/fetchApi/Inpatient/Dashboard';
import getAllUnit from '@/fetchApi/MasterData/Unit/getAllUnit';
import { postResendPcareInpatient } from '@/fetchApi/PCare';

// vuex map
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ExaminationLaboratory',
);
const { mapMutations: icMutations } = createNamespacedHelpers(
  'InformedConsent',
);
const { mapMutations: medicalResumeMutations } = createNamespacedHelpers(
  'MedicalResumeStore',
);
const { mapMutations: cpptMutations } = createNamespacedHelpers('CPPTStore');
const {
  mapMutations: earlyAssessmentInpatientMutations,
} = createNamespacedHelpers('EarlyAssessmentInpatientStore');
const { mapMutations: neonatusMutations } = createNamespacedHelpers(
  'NeonatusAssessment',
);

export default {
  name: 'InpatientDashboard',
  mixins: [jwtMixin, AlertMixin, mapDashboardData],
  components: {
    Warrant,
    ERecipe,
    InpatientEMR,
    BHPandAction,
    MedicalResume,
    LabRequest,
    FinishInpatient,
    PrintDocuments,
  },
  data() {
    return {
      isLoading: false,
      loadingStaff: false,
      search: '',
      chooseDoctor: '',
      pageLength: 1,
      unit: '',
      expanded: [],
      isRefresh: false,
      selectedForm: null,
      activityStatusLab: '',
      dialog: false,
      generalConsentPatient: false,
      filter: {
        doctor: [],
      },
      totalInpatient: {
        registered: 0,
        nonRegistered: 0,
      },
      table: {
        headers: [
          { text: 'No', value: 'no' },
          { text: 'No. Bed', value: 'bed' },
          { text: 'No. Pendaftaran', value: 'noRegist' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'noRM' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Dokter', value: 'doctor' },
        ],
        items: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 5,
        },
      },
      widthMenu: [
        { text: 'CPO', width: 1400 },
        { text: 'ResumeMedis', width: 1400 },
        { text: 'BHP', width: 1400 },
        { text: 'ERecipe', width: 1400 },
        { text: 'Warrant', width: 1400 },
        { text: 'FinishInpatient', width: 450 },
        { name: 'LabRequest', width: 1400 },
        { name: 'PrintDocument', width: 346 },
      ],
    };
  },
  watch: {
    search() {
      this.table.pagination.page = 1;
      this.searchData(this);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
    this.clearSnackbar();
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.setSnackbar(value);
      },
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
    itemsPerPage() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '57vh';
          break;
        case 'lg':
          height = '64vh';
          break;
        case 'xl':
          height = '69vh';
          break;
      }
      return height;
    },
  },
  methods: {
    ...mapMutations(['setSnackbar', 'clearSnackbar', 'setShowDialog']),
    ...icMutations(['setDataPatient']),
    ...cpptMutations(['setCpptStoreId', 'setPatientData']),
    ...medicalResumeMutations(['setForm', 'setInitialState']),
    ...earlyAssessmentInpatientMutations(['setId']),
    ...neonatusMutations(['setNeonatusNeededIds']),
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 650),
    closeForm() {
      this.dialog = false;
      this.refreshAndAutoUpdate();
    },
    refresh() {
      this.isRefresh = true;
      this.refreshAndAutoUpdate();
    },
    async getUnit() {
      try {
        const params = {
          keyword: 'Unit Rawat Inap',
          sortBy: 'name',
          halaman: 1,
        };
        const response = await getAllUnit(params);
        const [unit] = response.data;
        this.unit = { name: unit.name, id: unit._id };
      } catch {
        this.unit = { name: '', id: '' };
      }
    },
    async resendPcareData(id) {
      try {
        postResendPcareInpatient(id);
        this.snackbar = {
          text: 'Kirim ulang bridging PCare berhasil',
          show: true,
          color: 'success',
        };
      } catch {
        Swal.fire({
          title: `<div style="font-family: Roboto, Serif; color: #404040; font-weight: 700; font-size: 24px; line-height: 30px;"><p>Bridging BPJS error</p></div>`,
          iconHtml:
            '<span class="mdi mdi-close-circle-outline" style="color:red;"></span>',
          html:
            '<div style="font-family: Roboto; font-style: normal; font-weight: 400; font-size: 18px; color: #616161; line-height: 150%; text-transform: none !important;"><p>Anda dapat mengirim ulang beberapa saat lagi</p></div>',
          reverseButtons: true,
          confirmButtonText: 'Kembali',
        });
      }
    },
    async getDoctor() {
      try {
        this.loadingStaff = true;
        const params = {
          halaman: 1,
          itemCount: '',
          sortBy: '',
          keyword: '',
        };
        const response = await getAllStaff(params);
        this.filter.doctor = response.data
          .filter(item => item.role.status)
          .map(item => {
            return {
              doctorId: item._id,
              doctor: item.detail.name,
            };
          });
      } catch {
        this.filter.doctor = [];
      } finally {
        this.loadingStaff = false;
      }
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getInpatient();
      this.getUnit();
      this.getInpatientNonRegister();
      this.getDoctor();
      this.interval = setInterval(this.getInpatient, 60000);
    },
    async getInpatient() {
      try {
        const params = {
          search: this.search || '',
          page: this.table.pagination.page || 1,
          itemCount: this.itemsPerPage,
          doctor: this.chooseDoctor || '',
          isAdmitted: true,
        };
        const response = await getInpatientPatients(params);
        const { data } = response.data;
        this.pageLength = Math.ceil(response.data.length / this.itemsPerPage);
        this.table.items = this.mapDashboardData(data);
      } catch {
        this.table.items = [];
      } finally {
        this.isLoading = false;
        this.isRefresh = false;
      }
    },

    async getInpatientNonRegister() {
      this.isLoading = true;
      const res = await axios.get(
        Constant.apiUrl.concat('/letter/control-inpatient-letter/all/'),
      );
      this.totalInpatient.nonRegistered = res.data.data.length;
    },
    changeSelectedPatient(item) {
      this.generalConsentPatient = item.hasGeneralConsent;
      const patientData = {
        id_emr: item.id_emr,
        id_registration: item.id_registration,
        rmNumber: item.noRM,
        noQueue: item.noQueue,
        id_inpatient: item.id_inpatient,
        case_type: item.case_type || null,
        meta: {
          name: item.name,
          birthDate: moment(item.birthDate).format('DD/MM/YYYY'),
          gender: item.gender,
          age: item.age,
          address: item.address,
          identityType: item.identityType,
          identityNumber: item.identityNumber,
          doctor: item.doctor,
          doctorId: item.doctorId,
          polyName: this.unit.name,
          role: item.role,
          id_poly: this.unit.id,
          profession: item.profession,
          guarantorType: item.guarantorType,
          guarantorName: item.guarantorName,
          guarantorNumber: item.guarantorNumber,
        },
      };
      this.$store.commit('changeSelectedPatient', patientData);
    },
    selectItem(index, item) {
      if (index === 0) {
        const data = {
          id_registration: item.id_registration,
          id_inpatient: item.id_inpatient,
          id_emr: item.id_emr,
          date: {
            in: item.dateIn,
            out: '',
          },
          time: {
            in: item.timeIn,
            out: '',
          },
        };
        this.setInpatientEmrNeededStore(data, item);
      }
      this.setDataPatient(item);
      this.changeSelectedPatient(item);
      this.activityStatusLab = item.activityStatus.labStatus;
      this.selectedForm = index;
      this.setShowDialog(true);
      this.dialog = true;
    },
    setInpatientEmrNeededStore(data, item) {
      // for medical resume form
      this.setForm(data);
      this.setInitialState(data);

      // for CPPT
      this.setCpptStoreId({ emr: data.id_emr, inpatient: data.id_inpatient });
      this.setPatientData({
        rmNumber: item.noRM,
        name: `${item.name} (${item.gender === 'Laki-laki' ? 'L' : 'P'})`,
        birthDate: `${moment(item.birthDate, 'YYYY-MM-DD').format(
          'DD MMMM YYYY',
        )} (${item.age} Tahun)`,
        address: item.address,
      });
      this.$store.commit('CPPTStore/setForm', {
        generalInformation: {
          ...this.$store.getters['CPPTStore/getForm'].generalInformation,
          bed_number: item.bed,
        },
      });

      // for Early Assessment Inpatient
      this.setId({
        emr: data.id_emr,
        registration: data.id_registration,
        inpatient: data.id_inpatient,
      });

      // for Neonatus Assessment
      this.setNeonatusNeededIds({
        emr: data.id_emr,
        registration: data.id_registration,
        inpatient: data.id_inpatient,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.expand {
  grid-template-columns: 0.6fr 0.7fr 1.1fr 1.2fr 1fr 1fr 1fr;
  display: grid;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
