<template>
  <v-container :class="`pt-10 d-block ${mini ? 'mini' : ''}`" fluid>
    <v-form ref="form" class="form-container pa-0 ma-0">
      <div class="mb-7">
        <v-row>
          <v-col>
            <skeleton :isLoading="isLoading.data">
              <label class="required">Tanggal dan Jam Masuk</label>
            </skeleton>
            <div class="grid-display mb-3">
              <skeleton :isLoading="isLoading.data">
                <v-menu
                  dense
                  ref="menu_in"
                  :disabled="disableInput"
                  v-model="menu.in"
                  :close-on-content-click="true"
                  :return-value.sync="form.date.in.formatted"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      v-model="form.date.in.formatted"
                      :rules="[...rules.default]"
                      :clearable="!disableInput"
                      readonly
                      dense
                      single-line
                      append-icon="mdi-chevron-down"
                      placeholder="00-00-0000"
                    />
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    v-model="form.date.in.raw"
                    @input="
                      $refs.menu_in.save(formatDate(form.date.in.raw));
                      onChangeTimeAndDate(true);
                    "
                  ></v-date-picker>
                </v-menu>
              </skeleton>
              <skeleton :isLoading="isLoading.data">
                <v-text-field
                  dense
                  single-line
                  type="time"
                  @keydown="preventDefaultBehaviour($event)"
                  placeholder="00:00"
                  @change="onChangeTimeAndDate(true)"
                  :readonly="disableInput"
                  :rules="[...rules.default]"
                  v-model="form.time.in"
                />
              </skeleton>
            </div>
            <skeleton :isLoading="isLoading.data">
              <label class="required">Tanggal dan Jam Keluar</label>
            </skeleton>
            <div class="grid-display">
              <skeleton :isLoading="isLoading.data">
                <v-menu
                  dense
                  ref="menu_out"
                  :disabled="disableInput"
                  v-model="menu.out"
                  :close-on-content-click="true"
                  :return-value.sync="form.date.out.formatted"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="[...rules.default, ...rules.date.default]"
                      v-bind="attrs"
                      v-on="on"
                      v-model="form.date.out.formatted"
                      append-icon="mdi-chevron-down"
                      readonly
                      dense
                      :clearable="!disableInput"
                      single-line
                      placeholder="00-00-0000"
                    />
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    :min="form.date.in.raw"
                    v-model="form.date.out.raw"
                    @click:date="
                      $refs.menu_out.save(formatDate(form.date.out.raw));
                      onChangeTimeAndDate(true);
                    "
                  ></v-date-picker>
                </v-menu>
              </skeleton>
              <skeleton :isLoading="isLoading.data">
                <v-text-field
                  dense
                  single-line
                  ref="timeout"
                  placeholder="00:00"
                  type="time"
                  @keydown="preventDefaultBehaviour($event)"
                  @change="onChangeTimeAndDate(true)"
                  :readonly="disableInput"
                  :rules="[...rules.default, ...rules.time.other]"
                  v-model="form.time.out"
              /></skeleton>
            </div>
          </v-col>
          <v-col>
            <skeleton :isLoading="isLoading.data">
              <label class="required"
                >Dokter/Bidan Penanggung Jawab</label
              ></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-combobox
                dense
                single-line
                :clearable="!disableInput"
                :readonly="disableInput"
                :items="resource.doctor"
                v-model="form.doctor"
                item-text="name"
                :rules="rules.default"
                append-icon="mdi-chevron-down"
                placeholder="Pilih dokter atau bidan"
                class="mb-6"
            /></skeleton>
            <skeleton :isLoading="isLoading.data">
              <label>Ruang Rawat</label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-text-field
                :readonly="disableInput"
                v-model="form.room"
                dense
                single-line
                placeholder="Masukkan nama ruang"
            /></skeleton>
          </v-col>
        </v-row>
      </div>
      <div class="line mb-10"></div>
      <div>
        <v-row>
          <v-col>
            <skeleton :isLoading="isLoading.data">
              <label>Alasan Masuk</label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-text-field
                dense
                single-line
                :readonly="disableInput"
                v-model="form.reasonIn"
                class="mb-6"
                placeholder="Masukkan alasan masuk"
            /></skeleton>
            <skeleton :isLoading="isLoading.data">
              <label>Diagnosa Masuk</label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-text-field
                dense
                class="mb-6"
                :readonly="disableInput"
                v-model="form.diagnoseIn"
                single-line
                placeholder="Masukkan diagnosa masuk"
            /></skeleton>
            <skeleton :isLoading="isLoading.data">
              <label>Ringkasan Riwayat Penyakit </label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-textarea
                :readonly="disableInput"
                v-model="form.diseaseHistory"
                height="130"
                placeholder="Masukkan riwayat penyakit"
                class="mb-6"
                no-resize
                dense
                outlined
            /></skeleton>
            <skeleton :isLoading="isLoading.data">
              <label>Pemeriksaan Fisik </label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-textarea
                v-model="form.physicalCheckup"
                :readonly="disableInput"
                class="mb-6"
                height="130"
                placeholder="Masukkan pemeriksaan fisik"
                no-resize
                dense
                outlined
            /></skeleton>
            <skeleton :isLoading="isLoading.data">
              <label>Pemeriksaan Penunjang/Diagnostik </label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-textarea
                :readonly="disableInput"
                v-model="form.supportCheckup"
                height="130"
                class="mb-6"
                placeholder="Masukkan penunjang/diagnostik"
                no-resize
                dense
                outlined
            /></skeleton>
          </v-col>
          <v-col>
            <skeleton :isLoading="isLoading.data">
              <label>Hasil Laboratorium</label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-textarea
                height="130"
                placeholder="Masukkan hasil laboratorium"
                class="mb-6"
                no-resize
                dense
                outlined
                :readonly="disableInput"
                v-model="form.labResult"
            /></skeleton>
            <skeleton :isLoading="isLoading.data">
              <label>Terapi/Pengobatan</label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <div
                class="d-flex"
                v-for="(item, index) in form.therapy"
                :key="`therapy-${index}`"
              >
                <v-text-field
                  :readonly="disableInput"
                  v-model="form.therapy[index]"
                  dense
                  class="mb-6"
                  single-line
                  placeholder="Masukkan terapi/pengobatan"
                />
                <v-btn
                  @click="onclickActionButton('therapy', 'delete', index)"
                  :disabled="form.therapy.length == 1 || disableInput"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn
                  @click="onclickActionButton('therapy', 'add', null, '')"
                  :disabled="disableInput"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <label>Hasil Konsultasi</label></skeleton
            >
            <skeleton :isLoading="isLoading.data">
              <v-textarea
                :readonly="disableInput"
                height="130"
                v-model="form.consultationResult"
                placeholder="Masukkan hasil konsultasi"
                class="mb-6"
                no-resize
                dense
                outlined
            /></skeleton>
          </v-col>
        </v-row>
      </div>
      <div class="line mb-10"></div>
      <v-row>
        <v-col cols="6">
          <skeleton :isLoading="isLoading.data">
            <label class="required">Diagnosa Utama</label></skeleton
          >
          <skeleton :isLoading="isLoading.data">
            <v-text-field
              dense
              class="mb-6"
              single-line
              :readonly="disableInput"
              :rules="form.mainDiagnose.id_diagnose ? rules.default : []"
              v-model="form.mainDiagnose.name"
              placeholder="Masukkan diagnosa utama"
          /></skeleton>
        </v-col>
        <v-col cols="6">
          <skeleton :isLoading="isLoading.data">
            <label class="required">ICD-10</label></skeleton
          >
          <skeleton :isLoading="isLoading.data">
            <v-combobox
              dense
              single-line
              :clearable="!disableInput"
              class="mb-6"
              item-text="name"
              item-value="id_diagnose"
              placeholder="Pilih ICD-10"
              append-icon="mdi-chevron-down"
              :readonly="disableInput"
              @change="showReferrenceConfirmation"
              :rules="rules.default"
              :loading="isLoading.mainDiagnose"
              :search-input.sync="form.mainDiagnose.search"
              :items="resource.diagnose"
              v-model="form.mainDiagnose.id_diagnose"
          /></skeleton>
        </v-col>
      </v-row>
      <div class="line mb-10"></div>
      <v-row>
        <v-col>
          <skeleton :isLoading="isLoading.data">
            <label>Diagnosa Sekunder</label></skeleton
          >
        </v-col>
        <v-col>
          <skeleton :isLoading="isLoading.data">
            <label>ICD-10</label></skeleton
          >
        </v-col>
      </v-row>
      <v-row
        v-for="(item, index) in form.secondaryDiagnose"
        :key="`secondary-diagnose-${index}`"
      >
        <v-col cols="6">
          <skeleton :isLoading="isLoading.data">
            <div class="d-flex">
              <v-text-field
                dense
                single-line
                :readonly="disableInput"
                v-model="form.secondaryDiagnose[index].name"
                :rules="
                  form.secondaryDiagnose?.[index]?.id_diagnose?.id_diagnose
                    ? rules.default
                    : []
                "
                class="mb-6"
                placeholder="Masukkan diagnosa sekunder"
              />
              <v-btn
                @click="
                  onclickActionButton('secondaryDiagnose', 'delete', index)
                "
                :disabled="form.secondaryDiagnose.length == 1 || disableInput"
                text
                fab
                small
                ><v-icon color="grey">mdi-delete</v-icon></v-btn
              >
              <v-btn
                @click="
                  onclickActionButton('secondaryDiagnose', 'add', index, {
                    name: '',
                    id_diagnose: '',
                    loading: false,
                  })
                "
                :disabled="disableInput"
                text
                fab
                small
                ><v-icon color="grey">mdi-plus</v-icon></v-btn
              >
            </div></skeleton
          >
        </v-col>
        <v-col cols="6">
          <skeleton :isLoading="isLoading.data">
            <v-combobox
              dense
              single-line
              hide-details
              :clearable="!disableInput"
              class="mb-6"
              item-text="name"
              item-value="id_diagnose"
              placeholder="Pilih ICD-10"
              append-icon="mdi-chevron-down"
              :readonly="disableInput"
              :loading="form.secondaryDiagnose[index].loading"
              @focus="activeIndex.secondaryDiagnose = index"
              :search-input.sync="search.secondaryDiagnose[index]"
              :items="resource.diagnose"
              v-model="form.secondaryDiagnose[index].id_diagnose"
          /></skeleton>
        </v-col>
      </v-row>
      <div class="line my-10"></div>
      <v-row>
        <v-col>
          <skeleton :isLoading="isLoading.data">
            <label>Tindakan Prosedur</label></skeleton
          >
        </v-col>
        <v-col>
          <skeleton :isLoading="isLoading.data"> <label>ICD-9</label></skeleton>
        </v-col>
      </v-row>
      <v-row
        v-for="(item, index) in form.procedure"
        :key="`procedure-${index}`"
      >
        <v-col cols="6">
          <skeleton :isLoading="isLoading.data">
            <div class="d-flex">
              <v-text-field
                dense
                single-line
                v-model="form.procedure[index].name"
                :rules="
                  form.procedure?.[index]?.id_procedure?.id_procedure
                    ? rules.default
                    : []
                "
                :readonly="disableInput"
                class="mb-6"
                placeholder="Masukkan tindakan prosedur"
              />
              <v-btn
                @click="onclickActionButton('procedure', 'delete', index)"
                :disabled="form.procedure.length == 1 || disableInput"
                text
                fab
                small
                ><v-icon color="grey">mdi-delete</v-icon></v-btn
              >
              <v-btn
                @click="
                  onclickActionButton('procedure', 'add', null, {
                    name: '',
                    id_procedure: '',
                    loading: false,
                  })
                "
                :disabled="disableInput"
                text
                fab
                small
                ><v-icon color="grey">mdi-plus</v-icon></v-btn
              >
            </div></skeleton
          >
        </v-col>
        <v-col cols="6">
          <skeleton :isLoading="isLoading.data">
            <v-combobox
              dense
              single-line
              hide-details
              :clearable="!disableInput"
              :loading="form.procedure[index].loading"
              item-text="name"
              item-value="id_procedure"
              :readonly="disableInput"
              @focus="activeIndex.procedure = index"
              :search-input.sync="search.procedure[index]"
              :items="resource.procedure"
              v-model="form.procedure[index].id_procedure"
              append-icon="mdi-chevron-down"
              class="mb-6"
              placeholder="Pilih ICD-9"
          /></skeleton>
        </v-col>
      </v-row>
      <div class="line my-10"></div>
      <v-row>
        <v-col>
          <skeleton :isLoading="isLoading.data">
            <label>Riwayat Alergi</label>
          </skeleton>
          <div class="pt-5">
            <skeleton :isLoading="isLoading.data">
              <span>Alergi Obat</span>
            </skeleton>
            <skeleton :isLoading="isLoading.data">
              <v-combobox
                v-model="form.allergy.drug"
                :items="resource.allergy.drug"
                :loading="allergyLoading"
                placeholder="Pilih alergi obat"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="disableInput"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
            </skeleton>
          </div>
          <div class="pt-3">
            <skeleton :isLoading="isLoading.data">
              <span>Alergi Makanan</span>
            </skeleton>
            <skeleton :isLoading="isLoading.data">
              <v-combobox
                v-model="form.allergy.food"
                :items="resource.allergy.food"
                :loading="allergyLoading"
                placeholder="Pilih alergi makanan"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="disableInput"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
            </skeleton>
          </div>
          <div class="pt-3">
            <skeleton :isLoading="isLoading.data">
              <span>Alergi Udara</span>
            </skeleton>
            <skeleton :isLoading="isLoading.data">
              <v-combobox
                v-model="form.allergy.air"
                :items="resource.allergy.air"
                :loading="allergyLoading"
                placeholder="Pilih alergi udara"
                append-icon="mdi-chevron-down"
                multiple
                single-line
                dense
                :readonly="disableInput"
              >
                <template v-slot:prepend-item>
                  <p class="grey--text ma-4 text-start body-1">
                    Pilih atau Ketik Untuk Pilihan Lainnya
                  </p>
                </template>
              </v-combobox>
            </skeleton>
          </div>
          <div class="pt-3">
            <skeleton :isLoading="isLoading.data">
              <span>Alergi Lainnya</span>
            </skeleton>
            <skeleton :isLoading="isLoading.data">
              <div
                class="d-flex"
                v-for="(item, index) in form.allergy.others"
                :key="`allergy-${index}`"
              >
                <v-text-field
                  dense
                  single-line
                  :readonly="disableInput"
                  v-model="form.allergy.others[index]"
                  class="mb-6"
                  placeholder="Masukkan alergi lainnya"
                />
                <v-btn
                  @click="onclickActionButton('allergy', 'delete', index)"
                  :disabled="form.allergy.others.length == 1 || disableInput"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-delete</v-icon></v-btn
                >
                <v-btn
                  @click="onclickActionButton('allergy', 'add', null, '')"
                  :disabled="disableInput"
                  text
                  fab
                  small
                  ><v-icon color="grey">mdi-plus</v-icon></v-btn
                >
              </div>
            </skeleton>
          </div>

          <skeleton :isLoading="isLoading.data"> <label>Diet</label></skeleton>
          <skeleton :isLoading="isLoading.data">
            <v-textarea
              height="130"
              placeholder="Masukkan keterangan diet"
              class="mb-6"
              :readonly="disableInput"
              v-model="form.diet"
              no-resize
              dense
              outlined
          /></skeleton>
          <skeleton :isLoading="isLoading.data">
            <label class="required">Prognosa</label>
          </skeleton>
          <skeleton :isLoading="isLoading.data">
            <v-combobox
              dense
              single-line
              :clearable="!disableInput"
              :readonly="disableInput"
              :items="resource.prognosa"
              :rules="rules.default"
              v-model="form.prognosa"
              append-icon="mdi-chevron-down"
              placeholder="Pilih prognosa"
              class="mb-3"
              item-text="name"
            />
          </skeleton>
        </v-col>
        <v-col>
          <skeleton :isLoading="isLoading.data">
            <label class="required">Status Pulang</label></skeleton
          >
          <skeleton :isLoading="isLoading.data">
            <v-autocomplete
              dense
              single-line
              return-object
              :clearable="!disableInput"
              :readonly="disableInput"
              :rules="rules.default"
              :items="resource.home"
              v-model="form.returnStatus"
              @change="showReferrenceConfirmation"
              append-icon="mdi-chevron-down"
              placeholder="Pilih status pulang"
              class="mb-3"
          /></skeleton>
          <skeleton :isLoading="isLoading.data">
            <v-text-field
              dense
              single-line
              v-show="form.returnStatus?.text?.toLowerCase() === 'lain-lain'"
              v-model="form.returnStatus.description"
              :readonly="disableInput"
              id="home"
              class="mb-6"
              placeholder="Masukkan keterangan"
            />
          </skeleton>
          <template v-if="form.returnStatus.code == 4 && isBpjs">
            <template v-if="form.mainDiagnose?.id_diagnose?.isNonSpecialist">
              <label>Jenis Rujukan</label>
              <v-radio-group row v-model="form.referralType">
                <v-radio label="Rujuk dengan TACC" :value="true"></v-radio>
                <v-radio label="Rujuk tanpa TACC" :value="false"></v-radio>
              </v-radio-group>
            </template>
            <template v-if="form.referralType">
              <label>Faktor Rujukan</label>
              <v-radio-group
                @change="onChangeReferralFactor"
                row
                v-model="form.referralFactor"
              >
                <v-radio label="Time" value="1"></v-radio>
                <v-radio label="Age" value="2"></v-radio>
                <v-radio label="Complication" value="3"></v-radio>
                <v-radio label="Comorbidity" value="4"></v-radio>
              </v-radio-group>
              <label>Alasan</label>
              <v-combobox
                dense
                single-line
                v-if="form.referralFactor == 3"
                outlined
                v-model="form.referralReason"
                :items="resource.referralReason"
                :search-input.sync="searchComplicationDiagnose"
                :loading="complicationDiagnoseLoading"
              />

              <v-combobox
                v-else
                dense
                single-line
                outlined
                v-model="form.referralReason"
                :items="resource.referralReason"
              />
            </template>

            <v-btn
              class="text-capitalize mb-3"
              style="letter-spacing: 0.01px; border-width: 2px"
              outlined
              color="primary"
              dense
              single-line
              @click="openReferralLetterForm"
            >
              <v-icon color="primary" class="mr-1" small
                >mdi-email-outline</v-icon
              >
              Surat Rujukan
            </v-btn>
          </template>
          <skeleton :isLoading="isLoading.data">
            <label>Obat Dibawa Pulang</label></skeleton
          >
          <skeleton :isLoading="isLoading.data">
            <div
              class="d-flex"
              v-for="(item, index) in form.takehomeDrug"
              :key="`drug-${index}`"
            >
              <v-text-field
                dense
                single-line
                v-model="form.takehomeDrug[index]"
                :readonly="disableInput"
                class="mb-6"
                placeholder="Masukkan obat"
              />
              <v-btn
                @click="onclickActionButton('takehomeDrug', 'delete', index)"
                :disabled="form.takehomeDrug.length == 1 || disableInput"
                text
                fab
                small
                ><v-icon color="grey">mdi-delete</v-icon></v-btn
              >
              <v-btn
                @click="onclickActionButton('takehomeDrug', 'add', null, '')"
                :disabled="disableInput"
                text
                fab
                small
                ><v-icon color="grey">mdi-plus</v-icon></v-btn
              >
            </div></skeleton
          >
          <skeleton :isLoading="isLoading.data">
            <label>Instruksi/Anjuran dan Edukasi</label></skeleton
          >
          <skeleton :isLoading="isLoading.data">
            <v-textarea
              height="130"
              placeholder="Masukkan instruksi"
              class="mb-6"
              v-model="form.instruction"
              :readonly="disableInput"
              no-resize
              dense
              outlined
          /></skeleton>
          <skeleton :isLoading="isLoading.data">
            <label>Pengobatan Dilanjutkan</label></skeleton
          >
          <skeleton :isLoading="isLoading.data">
            <v-combobox
              dense
              single-line
              :readonly="disableInput"
              :clearable="!disableInput"
              :items="resource.treatmentContinued"
              v-model="form.treatmentContinued.name"
              append-icon="mdi-chevron-down"
              placeholder="Pilih tujuan"
              class="mb-3"
          /></skeleton>
          <skeleton :isLoading="isLoading.data">
            <v-text-field
              dense
              single-line
              :readonly="disableInput"
              v-show="
                form.treatmentContinued?.name?.toLowerCase() === 'lain-lain'
              "
              v-model="form.treatmentContinued.description"
              id="drug"
              class="mb-6"
              placeholder="Masukkan keterangan"/></skeleton
        ></v-col>
      </v-row>
    </v-form>
    <div
      :class="
        `d-flex px-11 ${isEditable ? 'justify-space-between' : 'justify-end'}`
      "
    >
      <v-spacer v-if="isEMR === true" />
      <v-btn
        :disabled="isEdit"
        v-if="isEditable"
        @click="print()"
        color="primary"
        class="text-capitalize"
        :outlined="!isEMR"
        >Print</v-btn
      >
      <div class="d-flex">
        <v-btn
          color="error"
          outlined
          class="text-capitalize mr-3"
          dense
          light
          v-if="isEdit"
          @click="cancelEdit"
          >Batal</v-btn
        >
        <v-btn
          v-if="isEMR === false"
          @click="isEditable && !isEdit ? edit() : save()"
          color="primary"
          class="text-capitalize"
          depressed
          >{{
            isEditable && !isEdit
              ? 'Edit'
              : `Simpan ${(isEdit && 'Perubahan') || ''}`
          }}</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="1200px">
      <referral-letter
        :patient-data="patientData"
        :letter-data="form.referralLetterData"
        :isReadonly="disableInput"
        :therapy="form.therapy"
        v-if="dialog"
        @close-form="dialog = false"
        @save="saveReferralLetter"
      />
    </v-dialog>
    <v-snackbar
      :color="snackbar.color"
      light
      timeout="4000"
      v-model="snackbar.show"
      height="50"
      ><div class="d-flex justify-space-between align-center">
        <span class="white--text">{{ snackbar.text }}</span>
        <v-icon
          style="cursor: pointer"
          @click.native="snackbar.show = false"
          color="white"
          small
        >
          mdi-close
        </v-icon>
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { getAllStaff } from '@/fetchApi/MasterData/Staff';
import { getAllDiagnose } from '@/fetchApi/MasterData/Diagnose';
import { getAllProcedure } from '@/fetchApi/MasterData/Procedure';
import Skeleton from '../../../../../SharedComponent/Skeleton.vue';
import ReferralLetter from '../../../../../SharedComponent/Dialog/ReferralLetter.vue';
import { createNamespacedHelpers } from 'vuex';
import printMedicalResumeDocument from '@/mixin/InpatientMixin/printMedicalResumeDocument';
import PrintMixin from '@/mixin/printMixin';
const { scrollToEmptyInput } = require('@/helper');
import { getPrognosa, getAllergies } from '@/fetchApi/MasterData/Allergy';
import { getReturnStatusInpatient } from '@/fetchApi/MasterData/Others.js';

import Swal from 'sweetalert2';
const moment = require('moment-timezone');

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MedicalResumeStore',
);
const _ = require('lodash');

export default {
  name: 'MedicalResumeForm',
  components: {
    Skeleton,
    ReferralLetter,
  },
  mixins: [PrintMixin, printMedicalResumeDocument],
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
    isEMR: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTimeChanged: false,
      allergyLoading: false,
      prognosaLoading: false,
      complicationDiagnoseLoading: false,
      dialog: false,
      isEdit: false,
      snackbar: { show: false, text: '', color: '' },
      rules: {
        default: [v => !!v || v === false || 'Wajib diisi'],
        date: {
          default: [
            () => {
              if (this.form.date.in.raw === this.form.date.out.raw) return true;
              const datein = moment(this.form.date.in.raw);
              const dateout = moment(this.form.date.out.raw);
              return (
                dateout.isAfter(datein) ||
                'Tanggal keluar tidak boleh sebelum tanggal masuk'
              );
            },
          ],
        },
        time: {
          other: [
            () => {
              if (this.form.date.in.raw !== this.form.date.out.raw) return true;
              const timeIn = moment(this.form.time.in, 'HH:mm');
              const timeOut = moment(this.form.time.out, 'HH:mm');
              return (
                timeOut.isAfter(timeIn) || 'Jam tidak boleh sebelum jam masuk'
              );
            },
          ],
        },
      },
      resource: {
        referralReason: [],
        complication: [],
        doctor: [],
        diagnose: [],
        procedure: [],
        prognosa: [],
        allergy: {
          drug: [],
          food: [],
          air: [],
        },
        home: [],
        treatmentContinued: [
          'Poliklinik',
          'Rumah Sakit',
          'Puskesmas',
          'Lain-lain',
        ],
      },
      menu: {
        in: false,
        out: false,
      },
      search: {
        secondaryDiagnose: [],
        procedure: [],
      },
      activeIndex: {
        secondaryDiagnose: null,
        procedure: null,
      },
      searchComplicationDiagnose: '',
    };
  },
  watch: {
    searchComplicationDiagnose(val) {
      if (val) {
        this.getComplicationDiagnose(val);
      } else {
        this.getComplicationDiagnose('');
      }
    },
    'form.mainDiagnose.search'(val) {
      this.searchDiagnose(this, val, null, 'main');
    },
    'search.secondaryDiagnose'(val) {
      const index = this.activeIndex.secondaryDiagnose;
      this.searchDiagnose(this, val[index], index, 'secondary');
    },
    'search.procedure'(val) {
      const index = this.activeIndex.procedure;
      this.searchProcedure(this, val[index], index);
    },
    'form.date.out.formatted'() {
      this.$refs.timeout.validate();
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getLoading']),

    patientData: {
      get() {
        return this.$store.getters.selectedPatient;
      },
    },
    isBpjs: {
      get() {
        return this.patientData.meta?.guarantorType?.toLowerCase() === 'bpjs';
      },
    },
    disableInput: {
      get() {
        return this.isEditable && !this.isEdit;
      },
    },
    form: {
      get() {
        return this.getForm;
      },
    },
    isEditable: {
      get() {
        return this.getForm.isEditable;
      },
    },
    isLoading: {
      get() {
        return this.getLoading;
      },
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.resolveGetMedicalResume();
      this.resolveGetReturnStatus();
      this.getAllDoctors();
      this.resolveGetAllergies();
      this.resolveGetPrognosa();
      this.getProcedure();
      this.getDiagnose('', 'all');
    });
  },
  methods: {
    ...mapMutations([
      'mutateArrayVariable',
      'setForm',
      'setLoading',
      'setIsTimeChanged',
    ]),
    ...mapActions(['resolvePostMedicalResume', 'resolveGetMedicalResume']),

    preventDefaultBehaviour(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
    saveReferralLetter(data) {
      this.setForm({ referralLetterData: data });
      this.dialog = false;
    },
    onChangeReferralFactor() {
      const selectedFactor = this.form.referralFactor;
      if (selectedFactor == 1) {
        this.resource.referralReason = [
          '< 3 Hari',
          '>= 3 - 7 Hari',
          '>= 7 Hari',
        ];
      } else if (selectedFactor == 3) {
        this.getComplicationDiagnose('');
      } else if (selectedFactor == 2) {
        this.resource.referralReason = [
          '< 1 Bulan',
          '>= 1 Bulan s/d < 12 Bulan',
          '>= 1 Tahun s/d < 5 Tahun',
          '>= 5 Tahun s/d < 12 Tahun',
          '>= 12 Tahun s/d < 55 Tahun',
          '>= 55 Tahun',
        ];
      } else if (selectedFactor == 4) {
        this.resource.referralReason = [
          '< 3 Hari',
          '>= 3 - 7 Hari',
          '>= 7 Hari',
        ];
      }
    },
    async getComplicationDiagnose(keyword) {
      try {
        this.complicationDiagnoseLoading = true;
        const params = {
          search: keyword || '',
          page: 1,
          itemCount: 20,
          sort: '-code',
        };

        const response = await getAllDiagnose(params);
        this.resource.referralReason = response.data.map(diagnose => {
          return `${diagnose.code} - ${diagnose.description}`;
        });
      } catch {
        this.resource.referralReason = [];
      } finally {
        this.complicationDiagnoseLoading = false;
      }
    },
    showReferrenceConfirmation() {
      if (!this.isBpjs) return;
      if (
        this.form.mainDiagnose?.id_diagnose?.isNonSpecialist &&
        this.form.returnStatus?.code == 4
      ) {
        Swal.fire({
          title:
            'Apakah anda yakin akan merujuk pasien ke fasilitas rujukan tingkat lanjut?',
          text: 'Pasien termasuk ke dalam kategori non spesialistik',
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: 'Batal',
          confirmButtonText: 'Ya, saya yakin',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          icon: 'warning',
        }).then(result => {
          if (!result.isConfirmed) return;
          this.openReferralLetterForm();
        });
      }
    },
    openReferralLetterForm() {
      this.dialog = true;
    },
    async resolveGetReturnStatus() {
      try {
        const response = await getReturnStatusInpatient();
        const data = response.data.data;
        this.resource.home = data.map(v => {
          return {
            code: v.code,
            text: v.description,
          };
        });
      } catch {
        this.resource.home = [];
      }
    },
    async resolveGetAllergies() {
      try {
        this.allergyLoading = true;
        ['food', 'air', 'drug'].forEach(async item => {
          const response = await getAllergies(item);
          this.resource.allergy[item] = response.data.data.map(v => v.name);
        });
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.allergyLoading = false;
      }
    },
    async resolveGetPrognosa() {
      try {
        this.prognosaLoading = true;
        const response = await getPrognosa();
        this.resource.prognosa = response.data.data.map(item => ({
          code: item.code,
          name: item.name,
        }));
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.prognosaLoading = false;
      }
    },
    cancelEdit() {
      this.isEdit = false;
      this.resolveGetMedicalResume();
    },
    onChangeTimeAndDate(value) {
      this.setIsTimeChanged(value);
    },
    edit() {
      this.isEdit = true;
    },

    searchDiagnose: _.debounce((v, keyword, index, type) => {
      v.getDiagnose(keyword, type, index);
    }, 600),

    searchProcedure: _.debounce((v, keyword, index) => {
      v.getProcedure(keyword, index);
    }, 600),

    onclickActionButton(label, type, index, value) {
      this.mutateArrayVariable({ label, type, index, value });
    },
    async getDiagnose(keyword, type, index) {
      try {
        // Set loading to true
        if (type === 'main' || type === 'all') {
          this.setLoading({ mainDiagnose: true });
        }
        if (type === 'secondary' || type === 'all') {
          this.form.secondaryDiagnose[index || 0].loading = true;
        }

        const params = {
          search: keyword || '',
          page: 1,
          itemCount: 20,
          sort: '-code',
        };

        const response = await getAllDiagnose(params);
        this.resource.diagnose = response.data.map(item => {
          return {
            name: item.display_name,
            id_diagnose: item._id,
            isNonSpecialist: item.nonSpesialis,
          };
        });
      } catch {
        this.resource.diagnose = [];
      } finally {
        // Set loading to false
        if (type === 'main' || type === 'all') {
          this.setLoading({ mainDiagnose: false });
        }
        if (type === 'secondary' || type === 'all') {
          this.form.secondaryDiagnose[index || 0].loading = false;
        }
      }
    },
    async getAllDoctors() {
      try {
        const params = {
          halaman: 1,
          itemCount: '',
          sortBy: '',
          keyword: '',
        };
        const response = await getAllStaff(params);
        const { data } = response;
        this.resource.doctor = data
          .filter(
            item =>
              item.role.role.some(
                item =>
                  item.toLowerCase() === 'dokter' ||
                  item.toLowerCase() === 'bidan',
              ) && item.role.status,
          )
          .map(item => {
            return {
              name: item.detail.name,
              id: item._id,
            };
          });
      } catch (error) {
        this.resource.doctor = [];
      }
    },
    async getProcedure(keyword, index) {
      try {
        this.form.procedure[index || 0].loading = true;
        const params = {
          search: keyword || '',
          page: 1,
          itemCount: 20,
        };
        const response = await getAllProcedure(params);
        this.resource.procedure = response.data.map(item => {
          return {
            name: `${item.code} ${item.description}`,
            id_procedure: item._id,
          };
        });
      } catch {
        this.resource.procedure = [];
      } finally {
        this.form.procedure[index || 0].loading = false;
      }
    },
    async save() {
      try {
        if (await !this.$refs.form.validate()) {
          scrollToEmptyInput();
          return;
        }
        await this.resolvePostMedicalResume();
        await this.resolveGetMedicalResume();
        this.snackbar = {
          show: true,
          text: `Resume Medis berhasil ${this.isEdit ? 'diubah' : 'disimpan'}`,
          color: 'success',
        };
        this.isEdit = false;
        this.onChangeTimeAndDate(false);
      } catch {
        this.snackbar = {
          show: true,
          text: `Terjadi kesalahan, resume medis gagal ${
            this.isEdit ? 'diubah' : 'disimpan'
          }`,
          color: 'error',
        };
      }
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
    print() {
      const patient = this.$store.getters.selectedPatient;
      const patientData = {
        rmNumber: patient.rmNumber,
        name: patient.meta.name,
        birthDate: moment(patient.meta.birthDate, [
          'DD/MM/YYYY',
          'YYYY-MM-DD',
        ]).format('DD-MM-YYYY'),
        age: patient.meta.age,
        assurance: patient.meta.guarantorType.toUpperCase(),
        dateIn: this.form.date.in.formatted,
        dateOut: this.form.date.out.formatted,
        doctor: this.form.doctor.name,
        room: this.form.room,
      };
      const doc = this.printMedicalResumeDocument(patientData, this.form);

      this.printDocument(doc, {
        docName: 'Resume Medis',
        rmNumber: this.$store.getters.selectedPatient.rmNumber,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 80vw;

  &.mini {
    width: 95vw;
  }
}

.form-container {
  display: block !important;
  max-height: 77vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px !important;
    border-radius: 5px;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px !important;
    border-radius: 5px;
    background-color: #9e9e9e !important;
  }
}
.col {
  text-align: start;
  padding: 0.5vw 3vw;

  .grid-display {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 1vw;
    padding-top: 0.3vw;
  }

  label {
    font-weight: 600;
  }
}

.line {
  width: 75vw;
  height: 1.5px;
  margin: auto;
  background: #ededed;
}
.required:after {
  content: ' *';
  color: red;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

::v-deep .v-text-field__slot input[type='time']::-webkit-inner-spin-button,
::v-deep .v-text-field__slot input[type='time']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Hide the time input's arrow icon (for some browsers) */
::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
