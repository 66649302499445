<template>
  <div class="main">
    <v-layout xs12 align-center py-3 px-8>
      <v-flex xs9>
        <h2 class="title">Pendaftaran Pasien Rawat Jalan</h2>
        <h5 class="subtitle caption">
          {{ registPatient }} Pasien terdaftar hari ini
        </h5>
      </v-flex>
      <v-flex xs3>
        <div class="d-flex">
          <v-btn @click="refreshAndAutoUpdate" icon mt-3 mr-3>
            <font-awesome-icon icon="sync-alt" class="btn-refresh" />
          </v-btn>

          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="search"
          />
        </div>
      </v-flex>
    </v-layout>
    <v-row class="px-8">
      <v-col class="text-start">
        <label for="date" class="subtitle-2 mb-4">Tanggal</label>
        <Datepicker :show="menu" :date="date" @date-change="handleDateChange" />
      </v-col>
      <v-col class="text-start"
        ><label for="doctor" class="subtitle-2">Dokter</label
        ><v-combobox
          dense
          outlined
          :items="resource.doctor"
          v-model="filter.doctor"
          :loading="isDoctorLoading"
          @change="
            outpatient.pagination.page = 1;
            showAllPatients();
          "
          item-value="id"
          item-text="doctor"
          append-icon="mdi-chevron-down"
          clearable
          placeholder="Semua Dokter"
        >
        </v-combobox
      ></v-col>
      <v-col class="text-start"
        ><label for="poly" class="subtitle-2">Poli</label>
        <v-combobox
          dense
          outlined
          :items="resource.poly"
          :loading="isPolyLoading"
          v-model="filter.poly"
          @change="
            outpatient.pagination.page = 1;
            showAllPatients();
          "
          item-text="unitName"
          return-object
          append-icon="mdi-chevron-down"
          clearable
          placeholder="Semua Poli"
        >
        </v-combobox
      ></v-col>
      <v-col class="text-start"
        ><label for="assurance" class="subtitle-2">Tipe Penjamin</label>
        <v-combobox
          dense
          outlined
          :items="resource.assurance"
          v-model="filter.assurance"
          @change="
            outpatient.pagination.page = 1;
            showAllPatients();
          "
          item-text="text"
          return-object
          append-icon="mdi-chevron-down"
          clearable
          placeholder="Pilih Tipe Penjamin"
        >
        </v-combobox
      ></v-col>
    </v-row>
    <v-layout class="px-8">
      <v-data-table
        :headers="outpatient.headers"
        :items="outpatient.data"
        :items-per-page="itemsPerPage"
        :expanded="expanded"
        hide-default-footer
        item-key="idRegistration"
        single-expand
        fixed-header
        @click:row="
          (item, slot) => {
            slot.expand(!slot.isExpanded);
            selectItem(item);
            selectedForm = null;
          }
        "
        :loading="loading"
        class="data-table"
      >
        <template v-slot:[`item.no`]="{ item, index }">
          <tr class="text-left" :key="index">
            <td>
              {{ item.no + 1 }}
            </td>
          </tr>
        </template>
        <template v-slot:[`item.guarantorType`]="{ item, index }">
          <tr class="text-left" :key="index">
            <td>
              {{ item.guarantorType.toUpperCase() }}
            </td>
          </tr>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span>{{ item.name }}</span>
          <v-tooltip
            bottom
            v-if="item.pcare && !item.pcare?.registration?.isSuccess"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="red"
                light
                small
                class="send-btn"
                height="500"
                @click="reRegistrationPcare(item)"
                ><v-icon left class="icon" color="white" x-small
                  >mdi-send-outline</v-icon
                >
              </v-chip>
            </template>
            <span>{{ item?.pcare?.registration?.message }}</span>
          </v-tooltip>
          <v-icon
            v-if="item.pcare && item.pcare?.registration?.isSuccess"
            color="green"
            >mdi-check-circle</v-icon
          >
        </template>
        <template v-slot:[`item.isNew`]="{ item }">
          <v-chip
            label
            class="isnew-chip"
            :class="{ new: item.isNew }"
            :color="item.isNew ? '#fff9e5' : '#f0f9ff'"
            >{{ item.isNew ? 'Baru' : 'Lama' }}</v-chip
          >
        </template>
        <template v-slot:[`item.mjkn`]="{ item }">
          <v-icon>{{ item.isMjkn ? 'mdi-check' : 'mdi-minus-thick' }}</v-icon>
        </template>
        <template v-slot:[`item.isCheckIn`]="{ item }">
          <tr class="text-left">
            <td>
              <v-tooltip v-if="!item.isCheckIn" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="px-2 text-capitalize font-weight-regular action-btn"
                    @click.stop="checkInBooking(item)"
                    depressed
                    color="primary"
                    >Belum Check-in
                    <v-icon color="white">mdi-arrow-right</v-icon></v-btn
                  >
                </template>
                <span>Klik untuk check-in</span>
              </v-tooltip>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :class="
                      `${
                        !item.isCheckInCancelable && item.hover
                          ? 'btn-disabled'
                          : ''
                      } ${
                        item.isCheckInCancelable && item.hover
                          ? 'btn-cancel'
                          : ''
                      } px-2 text-capitalize font-weight-regular action-btn`
                    "
                    dark
                    @mouseover="item.hover = true"
                    @mouseleave="item.hover = false"
                    depressed
                    @click.stop="
                      checkInBooking(item);
                      item.hover = false;
                    "
                    >{{
                      item.hover && item.isCheckInCancelable
                        ? 'Batalkan'
                        : 'Sudah Check-in'
                    }}
                    <v-icon color="white">{{
                      item.hover && item.isCheckInCancelable
                        ? 'mdi-close'
                        : 'mdi-check'
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  item.isCheckInCancelable
                    ? 'Batalkan Check-in'
                    : 'Check-in pasien tidak dapat dibatalkan'
                }}</span></v-tooltip
              >
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" type="info" tile>
            Maaf tidak ada pasien yang terdaftar
          </v-alert>
        </template>
        <template v-slot:expanded-item="{ item, headers }">
          <td
            :colspan="headers.length"
            @click="selectItem(item)"
            class="expand px-0"
          >
            <v-card flat>
              <v-container class="pt-0">
                <v-row no-gutters>
                  <v-col cols="1" style="max-width: 70px"></v-col>
                  <v-col
                    cols="4"
                    class="pl-3 text-left grey--text caption"
                    style="max-width: 340px"
                  >
                    <span>
                      Terdaftar {{ item.registered_at.time }} WIB <br />{{
                        item.registered_at.date
                      }}
                    </span>
                  </v-col>
                  <v-col
                    cols="3"
                    class="text-left ml-3 pl-5 grey--text caption"
                  >
                    <span>{{ item.gender }} {{ item.age }} Tahun</span></v-col
                  >
                  <v-col cols="2" class="text-left pl-9 grey--text caption"
                    >Pasien {{ item.isNew ? 'Baru' : 'Lama' }}</v-col
                  >
                </v-row>
              </v-container>
            </v-card>

            <v-card flat color="grey lighten-4" class="text-right">
              <v-container class="py-1">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          class="hover mx-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="onClicked(1)"
                        >
                          <v-icon>mdi-account</v-icon>
                        </v-btn>
                      </template>
                      <span>Data Sosial</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template #activator="{ on: tooltip }">
                        <v-btn
                          v-show="item.isCheckIn"
                          icon
                          class="hover mx-2"
                          v-on="{ ...tooltip }"
                          @click="onClicked(2)"
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </template>
                      <span>Print</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
                          <v-icon @click="deletePatient(item)"
                            >mdi-delete</v-icon
                          >
                        </v-btn>
                      </template>

                      <span>Hapus</span>
                    </v-tooltip>

                    <v-menu
                      left
                      :offset-x="true"
                      :close-on-content-click="true"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-btn icon v-bind="attrs" v-on="on" class="mx-2">
                            <v-icon>
                              mdi-dots-vertical
                            </v-icon>
                          </v-btn>
                        </span>
                      </template>

                      <v-list style="padding: 0px; min-width: 12.5rem;">
                        <v-list-item @click="onClicked(8)" class="dropdown">
                          <v-list-item-title>
                            <span class="dropdown-item">
                              Identifikasi Pasien
                            </span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-layout>
    <v-footer color="white">
      <v-container fluid>
        <v-row align="center">
          <v-pagination
            v-model="outpatient.pagination.page"
            :length="OutpatientPages"
            v-if="outpatient.data.length > 0"
            :total-visible="5"
            @input="showAllPatients()"
          ></v-pagination>
          <v-spacer></v-spacer>
          <v-btn color="primary" fab dark @click="onClicked(0)" elevation="0">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
          <v-dialog
            elevation="0"
            height="100vw"
            v-model="dialog"
            :max-width="
              selectedForm === null ? 0 : widthDialog[selectedForm].width
            "
          >
            <OldNewDialog
              v-if="selectedForm === 0 && dialog"
              @on-clicked="getData"
              @close-form="closeForm"
              @to-regist="onClicked(3)"
            />
            <SearchDataPatient
              v-else-if="selectedForm === 4 && dialog"
              @close-form="closeForm"
              @on-clicked="getData"
              @back="
                selectedForm = 0;
                searchPatient = '';
              "
              @to-regist="
                patientSelected = null;
                onClicked(3);
              "
            />
            <SocialData
              v-else-if="selectedForm === 1 && dialog"
              @close-form="closeForm"
              :medical-record="selectedPatient.rmNumber"
            />
            <PrintDialog
              :hasGeneralConsent="selectedPatient.hasGeneralConsent"
              v-else-if="selectedForm === 2"
              :selected-patient="selectedPatient"
              @close-form="closeForm"
              state="rawat-jalan"
            />
            <SocialDataPatients
              v-else-if="selectedForm === 3"
              :selected-patient="patientSelected"
              :patient-status="'rawat jalan'"
              @close-form="closeForm"
              @update-data="refreshAndAutoUpdate"
              @back-to-form="dialog = true"
              @back-dialog="
                selectedForm = backVal === 0 ? 0 : 4;
                searchPatient = '';
              "
            />
            <social-data-mjkn
              v-else-if="selectedForm === 7 && dialog"
              @close-form="closeForm"
              @back="backToMjkn"
              @mjkn-next="mjknNext"
              :rm="selectedMjknPatientRmNumber"
            />
            <!-- <anamnesis-form
              @back="backToMjkn"
              @next="nextToCheckinMJKN"
              :data="ttvInput"
              v-else-if="selectedForm === 5 && dialog"
            /> -->
            <checkin-mjkn
              v-else-if="selectedForm === 6 && dialog"
              @close-form="closeForm"
              @back-to-ttv="backToTtv"
              @back-to-social-data="backToMjkn"
              @open-print-dialog="selectedForm = 2"
            />
            <identification-patient
              v-if="selectedForm === 8 && dialog"
              :patient-data="selectedPatient"
              @close-form="closeForm"
              isRegist
            />
            <div
              v-if="selectedForm === 9 && dialog"
              style="height: 100vh; padding-top: 45vh;"
            >
              <v-progress-circular
                indeterminate
                size="100"
                width="5"
              ></v-progress-circular>
            </div>
          </v-dialog>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
// utils
const axios = require('axios');
const _ = require('lodash');
axios.defaults.headers.post['Content-Type'] = 'application/json';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

// components
import SocialData from '../SharedComponent/SocialData.vue';
import SocialDataPatients from './components/SocialDataPatients';
import PrintDialog from './components/PrintDialog';
import SocialDataMjkn from './components/MJKN/SocialDataMJKN.vue';
import CheckinMjkn from './components/MJKN/CheckinMJKN.vue';
import OldNewDialog from './components/OldNewDialogV2.vue';
// import AnamnesisForm from './components/AnamnesisForm.vue';
import SearchDataPatient from './components/SearchDataPatient.vue';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';
import IdentificationPatient from '@/components/SharedComponent/Dialog/IdentificationPatient/IdentificationPatient.vue';

// mixins
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';
import OutpatientRegistDataMapper from '@/mixin/MapperMixins/Registration/OutpatientRegistDataMapper';

// fetch api
import { postRegistrationPcare } from '@/fetchApi/BPJS';
import { getAllStaff } from '@/fetchApi/MasterData/Staff';
import { getAllUnit } from '@/fetchApi/MasterData/Unit';
import { getUnitById } from '@/fetchApi/MasterData/Unit';
import {
  deletePatientRegistration,
  getPatientRegistrations,
  postCheckin,
} from '@/fetchApi/Registration';

export default {
  name: 'OutpatientRegist',
  mixins: [
    jwtMixin,
    alertMixin,
    formatMixin,
    SocialDataMjkn,
    OutpatientRegistDataMapper,
  ],
  components: {
    SocialDataPatients,
    PrintDialog,
    SocialData,
    OldNewDialog,
    SocialDataMjkn,
    // AnamnesisForm,
    CheckinMjkn,
    SearchDataPatient,
    IdentificationPatient,
    Datepicker,
  },
  data() {
    return {
      dialog: false,
      dialogPrint: false,
      clicked: false,
      expanded: [],
      loading: false,
      selectedMjknPatientRmNumber: '',
      isDoctorLoading: false,
      isPolyLoading: false,
      selectedItem: null,
      menu: false,
      OutpatientPages: 0,
      registPatient: 0,
      selectedForm: null,
      date: moment().format('YYYY-MM-DD'),
      backVal: 0,
      filter: {
        doctor: '',
        poly: '',
        assurance: '',
      },
      resource: {
        doctor: [],
        staff: [],
        poly: [],
        assurance: [
          { text: 'Umum', value: 'umum' },
          { text: 'BPJS', value: 'bpjs' },
          { text: 'Asuransi', value: 'asuransi' },
        ],
      },
      outpatient: {
        headers: [
          { text: 'No.', value: 'no' },
          { text: 'No. Pendaftaran', value: 'noRegist' },
          { text: 'No. Antrian', value: 'queue' },
          { text: 'Nama', value: 'name' },
          { text: 'No. RM', value: 'noRM' },
          { text: 'Tipe Penjamin', value: 'guarantorType' },
          { text: 'Poli', value: 'poly' },
          { text: 'Jenis Pasien', value: 'isNew' },
          { text: 'MJKN', value: 'mjkn' },
          { text: 'Check-in', value: 'isCheckIn' },
        ],
        data: [],
        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 7,
          totalItems: null,
        },
      },
      search: '',
      widthDialog: [
        { text: 'OldNewPatient', width: 600 },
        { text: 'SocialDataPatients', width: 1200 },
        { text: 'PrintDialog', width: 485 },
        { text: 'SocialData', width: 1200 },
        { text: 'SearchDataPatient', width: 1200 },
        { text: 'Anamnesis', width: 1200 },
        { text: 'Checkin', width: 500 },
        { text: 'SocialDataMjkn', width: 1200 },
        { text: 'IdentificationPatient', width: 600 },
        { text: 'LoadingState', width: '100vw' },
      ],
      ttvInput: {
        anamnesis: '',
        sistol: '',
        diastol: '',
        heart_rate: '',
        weight: '',
        height: '',
        belly_circumference: '',
        respiration_rate: '',
        saturation: '',
        temperature: '',
        awarness: '',
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      this.getDoctor();
      this.getPoly();
    });
  },
  watch: {
    selectedForm: {
      handler(newVal, oldVal) {
        this.backVal = oldVal;
      },
      deep: true,
    },
    search() {
      this.outpatient.pagination.page = 1;
      this.searchData(this);
    },
    date(val) {
      if (val === '' || !val) {
        this.showAllPatients();
      }
    },
  },
  computed: {
    itemsPerPage() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 10;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    selectedPatient() {
      if (this.$store.getters.selectedPatient !== null) {
        return this.$store.getters.selectedPatient;
      }
      return null;
    },
  },
  methods: {
    handleDateChange(updatedDate) {
      this.date = updatedDate;
      this.outpatient.pagination.page = 1;
      this.showAllPatients();
    },
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.refreshAndAutoUpdate();
    }, 650),
    backToMjkn() {
      this.selectedForm = 7;
    },
    backToTtv() {
      this.selectedForm = 5;
    },
    nextToCheckinMJKN(anamnesisData) {
      this.dialog = false;
      this.$store.commit('addMjknPatient', {
        ...this.$store.getters.mjknPatient,
        ttv: {
          ...anamnesisData,
        },
      });
      this.dialog = true;
      this.selectedForm = 6;
    },
    mjknNext(dataPatient) {
      this.dialog = false;
      Swal.fire({
        title: 'Ingin Menginput Anamnesis & TTV Terlebih Dahulu?',
        text:
          'Jika tidak, Anda bisa menginputnya di form asesmen setelah pendaftaran ini selesai',
        icon: 'warning',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then(result => {
        if (!result.value) {
          this.$store.commit('addMjknPatient', {
            ...dataPatient,
            isInputTTV: false,
          });
          this.dialog = true;
          this.selectedForm = 6;
        } else {
          this.$store.commit('addMjknPatient', {
            ...dataPatient,
            isInputTTV: true,
          });
          this.dialog = true;
          this.selectedForm = 5;
        }
      });
    },
    closeForm() {
      this.dialog = false;
      this.showAllPatients();
    },
    clickedRow(value) {
      if (this.expanded.length && this.expanded[0].number == value.number) {
        this.expanded = [];
      } else {
        this.expanded = [];
        this.expanded.push(value);
      }
    },
    onClicked(val) {
      this.selectedForm = val;
      this.dialog = true;
    },
    async getDoctor() {
      try {
        const params = {
          halaman: 1,
          itemCount: '',
          sortBy: '',
          keyword: '',
        };
        const response = await getAllStaff(params);
        this.resource.doctor = response.data
          .filter(
            doctor =>
              doctor.role.status &&
              doctor.role.role.some(item => item.toLowerCase() === 'dokter'),
          )
          .map(doctor => {
            return {
              id: doctor._id,
              doctor: doctor.detail.name,
            };
          });
      } catch {
        this.resource.doctor = [];
      } finally {
        this.isDoctorLoading = false;
      }
    },
    async getPoly() {
      try {
        this.isPolyLoading = true;
        const response = await getAllUnit({
          installation: 'Instalasi Rawat Jalan',
        });
        this.resource.poly = response.data.map(unit => {
          return {
            unitId: unit._id.toString(),
            unitName: unit.name,
            code: unit.code,
          };
        });
      } catch {
        this.resource.poly = [];
      } finally {
        this.isPolyLoading = false;
      }
    },
    async checkInBooking(item) {
      if (item.isCheckIn && !item.isCheckInCancelable) return;
      this.selectedMjknPatientRmNumber = item.noRM;
      const isToday = this.date
        ? moment(this.date).isSame(moment().format('YYYY-MM-DD'))
        : true;
      this.selectItem(item);
      if (item.isCheckIn) {
        Swal.fire({
          title: `Yakin Ingin Membatalkan Check-In Pasien ${item.name}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yakin',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then(async result => {
          if (result.value) {
            this.resolveCheckin(item);
          }
        });
        return;
      } else {
        if (!isToday) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Tidak Bisa CheckIn Di Hari Yang Berbeda',
          });
          return;
        }
        if (item.isMjkn && isToday) {
          this.onClicked(7);
          return;
        }
        if (!item.isMjkn) {
          this.resolveCheckin(item);
        }
      }
    },
    async resolveCheckin(item, isCancelation) {
      try {
        const payload = {
          id_emr: item.idPatient,
          id_registration: item.idRegistration,
          isCheckIn: !item.isCheckIn,
        };
        await postCheckin(payload);
        Swal.fire(
          `Check-in Pasien ${item.name} Berhasil ${
            isCancelation ? 'Dibatalkan' : ''
          }`,
          '',
          'success',
        );
        this.showAllPatients();
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    async reRegistrationPcare(item) {
      try {
        await postRegistrationPcare(item.idRegistration);
        Swal.fire('Registrasi Berhasil dikirim ulang', '', 'success');
      } catch (error) {
        this.showErrorAxios(error);
      } finally {
        this.showAllPatients();
      }
    },
    async showAllPatients() {
      try {
        this.loading = true;
        const itemCount = this.itemsPerPage;
        const page = this.outpatient.pagination.page;
        const date = moment(new Date())
          .locale('id')
          .format('YYYY-MM-DD');

        const params = {
          page,
          itemCount,
          search: this.search || '',
          type: 'rawat jalan',
          date: this.date === '' || !this.date ? date : this.date,
          doctor: this.filter?.doctor?.id || '',
          unit_name: this.filter?.poly?.unitName || '',
          assurance: this.filter?.assurance?.text || '',
        };

        const response = await getPatientRegistrations(params);
        const data = response.data.data;
        const { length: dataLength } = response.data;
        this.OutpatientPages = Math.ceil(dataLength / this.itemsPerPage);

        this.outpatient.data = this.mapDashboardData(data, itemCount, page);
        this.registPatient = dataLength;
      } catch {
        this.outpatient.data = [];
      } finally {
        this.loading = false;
      }
    },
    deletePatient(patient) {
      Swal.fire({
        title: `<div style="font-family: Roboto, Serif; color: #222222; font-weight: 700; font-size: 20px; line-height: 30px;"><p>Yakin Ingin Menghapus Data Pendaftaran ${patient.name}</p></div>`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        customClass: {
          cancelButton: 'button-cancel',
        },
      }).then(async result => {
        if (result.value) {
          try {
            this.dialog = true; // show loading state on screen
            this.selectedForm = 9; // show loading state on screen
            await deletePatientRegistration(patient.idRegistration);
            Swal.fire('Pasien berhasil dihapus!', '', 'success');
            this.showAllPatients();
          } catch (error) {
            if (error) {
              this.showDeleteRegistrationError(error);
            }
          } finally {
            this.dialog = false;
            this.selectedForm = null;
          }
        }
      });
    },
    async selectItem(patient) {
      let polyName = [];
      const response = await getUnitById(patient.polyId);
      polyName.push(response.name);

      const patientData = {
        isNew: patient.isNew,
        id_emr: patient.idPatient,
        hasGeneralConsent: patient.hasGeneralConsent,
        id_registration: patient.idRegistration,
        rmNumber: patient.noRM,
        noQueue: patient.noQueue,
        queue_code: patient.queue_code,
        queue: patient.queue,
        guarantorType: patient.guarantorType,
        noRegist: patient.noRegist,
        createdBy: patient.timestamps.created_by,
        meta: {
          name: patient.name,
          birthDate: patient.birthDate,
          birthPlace: patient.birthPlace,
          gender: patient.gender,
          age: patient.age,
          address: patient.address,
          identityType: patient.identityType,
          identityNumber: patient.identityNumber,
          doctor: patient.doctor,
          doctorId: patient.id,
          polyId: patient.polyId,
          poly: polyName,
          time: patient.time,
          profession: patient.profession,
          phoneNumber: patient.phoneNumber,
        },
      };
      this.$store.commit('changeSelectedPatient', patientData);
      this.selectedItem = patient;
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.showAllPatients('');
      this.interval = setInterval(this.showAllPatients, 60000);
    },
    getData(val) {
      if (val === null) {
        this.patientSelected = val;
        this.onClicked(3);
      } else {
        this.patientSelected = {
          status: 'old',
          rmNumber: val,
        };
        if (val !== null && this.selectedForm === 4) {
          this.onClicked(3);
        } else {
          this.onClicked(4);
        }
      }
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.main {
  font-family: 'Roboto';

  .title {
    text-align: left;
    font-weight: bold !important;
    padding-bottom: 0 !important;
  }
  .subtitle {
    text-align: left;
    color: #adb5bd !important;
    font-weight: 500;
  }
  .data-table {
    width: 100%;
    .send-btn {
      cursor: pointer;
      width: 25px !important;
      padding-bottom: 3px;
      padding-left: 14px;
      border-radius: 7px !important;
      margin-left: 5px;
      .icon {
        rotate: -45deg;
      }
    }
  }

  .btn-refresh {
    cursor: pointer;
  }

  ::v-deep .v-dialog--active.dialogPrint {
    width: 450px;
    box-shadow: none !important;
  }

  #print,
  #printQueue {
    display: none;
  }

  ::v-deep {
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > td:not(.v-data-table__mobile-row),
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:not(:last-child)
      > th:not(.v-data-table__mobile-row) {
      border: none;
    }
  }

  ::v-deep .v-data-table__wrapper {
    height: 54vh;
  }
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

.dropdown {
  min-height: 3rem;
  border: 1px solid black;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-align: left;
}
.dropdown:hover {
  background-color: #e6f6fe;
  cursor: pointer;
}
.dropdown-item {
  color: #404040;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.isnew-chip {
  &.new {
    border: 1px solid #e5a82e !important;
    color: #e5a82e;
  }
  border: 1px solid #2f80ed !important;
  color: #2f80ed;
}

.action-btn {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  background-color: #5ab55e !important;
}

.btn-disabled {
  background-color: #9ca2a5 !important;
  color: white;
}
.btn-cancel {
  background-color: #eb4134 !important;
  color: white;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
