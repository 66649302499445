<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="d-flex space-between justify-center px-5">
      <div class="d-flex flex-row my-2">
        <h1 for="">10 Besar Pemakaian Obat</h1>

        <v-tooltip color="#404040" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              style="cursor: pointer"
              class="ml-3"
              color="primary"
              >mdi-information</v-icon
            >
          </template>
          <p class="text-left">
            Jumlah pemakaian berdasarkan satuan <br />
            masing-masing obat, bukan resep yang<br />
            telah diberikan
          </p>
        </v-tooltip>
      </div>
      <v-spacer></v-spacer>
      <v-icon @click="getReportData()">mdi-reload</v-icon>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-5 px-6 " no-gutters>
      <v-col class="text-start d-flex">
        <v-tabs v-model="tab">
          <v-tab class="text-none">E-Resep</v-tab>
          <v-tab class="text-none">Penjualan Obat Bebas</v-tab>
        </v-tabs>
      </v-col>
      <v-col :cols="filter.type === 'Rentang Tanggal' ? 5 : 3" class="d-flex">
        <v-combobox
          single-line
          dense
          hide-details
          append-icon="mdi-chevron-down"
          class="mr-3"
          :items="resource.filterType"
          v-model="filter.type"
          outlined
        />
        <div>
          <v-menu
            dense
            v-if="
              filter.type === 'Per Tanggal' || filter.type === 'Rentang Tanggal'
            "
            ref="menu_startdate"
            v-model="menu.startDate"
            :close-on-content-click="false"
            :return-value.sync="startDate.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                flat
                single-line
                solo
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="startDate.formatted"
                prepend-inner-icon="mdi-calendar"
                :placeholder="'Tanggal Awal'"
              />
            </template>
            <v-date-picker
              v-model="startDate.raw"
              locale="id"
              :max="filter.type === 'Rentang Tanggal' ? endDate.raw : ''"
              scrollable
              @input="
                menu.startDate = false;
                $refs.menu_startdate.save(formatDate(startDate.raw));
              "
            ></v-date-picker>
          </v-menu>
        </div>
        <span v-if="filter.type === 'Rentang Tanggal'" class="pt-3 mx-3"
          >s.d
        </span>
        <div>
          <v-menu
            dense
            v-if="filter.type === 'Rentang Tanggal'"
            ref="menu_enddate"
            v-model="menu.endDate"
            :close-on-content-click="false"
            :return-value.sync="endDate.formatted"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                flat
                single-line
                solo
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
                v-model="endDate.formatted"
                prepend-inner-icon="mdi-calendar"
                placeholder="Tanggal Akhir"
              />
            </template>
            <v-date-picker
              v-model="endDate.raw"
              locale="id"
              :min="startDate.raw"
              :max="today"
              scrollable
              @input="
                menu.endDate = false;
                $refs.menu_enddate.save(formatDate(endDate.raw));
              "
            ></v-date-picker>
          </v-menu>
        </div>
        <v-menu
          dense
          v-if="filter.type === 'Per Bulan'"
          ref="menu_month"
          v-model="menu.month"
          :close-on-content-click="false"
          :return-value.sync="month.formatted"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              flat
              single-line
              solo
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              v-model="month.formatted"
              prepend-inner-icon="mdi-calendar"
              placeholder="Pilih Bulan"
            />
          </template>
          <v-date-picker
            v-model="month.raw"
            type="month"
            :max="today"
            locale="id"
            scrollable
            @input="
              menu.month = false;
              $refs.menu_month.save(formatMonth(month.raw));
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row
      class="mt-5 px-6 d-flex align-center justify-center"
      no-gutters
      v-if="chart.isLoading"
    >
      <v-progress-circular
        model-value="20"
        :size="128"
        :width="12"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <v-row id="chart-row" class="mt-5 px-6" no-gutters v-else>
      <apexchart
        v-if="!tab"
        type="bar"
        height="350"
        :width="chart.width"
        :options="chart.chartOptions"
        :series="chart.series"
      ></apexchart>
      <apexchart
        v-else
        type="bar"
        height="350"
        :width="chart.width"
        :options="chart.chartOptions"
        :series="chart.series"
      ></apexchart>
    </v-row>
    <v-row
      no-gutters
      class="table-section px-5 mb-5 d-flex flex-column align-right"
    >
      <label class="font-weight-medium"> Daftar 10 Besar Pemakaian Obat</label>
      <v-data-table
        :headers="headers"
        :items="dataChart"
        class="custom-table mt-3"
        disable-pagination
        hide-default-footer
        :item-class="itemClass"
      >
        <template v-slot:item.index="{ item, index }">
          <td :class="{ 'custom-row': index === 0 }">{{ index + 1 }}</td>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
// import VueApexCharts from 'vue-apexcharts';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers(
  'DrugUsageReportStore',
);

const moment = require('moment-timezone');

export default {
  components: {
    // apexcharts: VueApexCharts,
  },
  computed: {
    ...mapGetters(['getDataChart', 'getLabelChart', 'getSeriesChart']),
    dataChart: {
      get() {
        return this.getDataChart;
      },
    },
    seriesChart: {
      get() {
        return this.getSeriesChart;
      },
    },
    labelChart: {
      get() {
        return this.getLabelChart;
      },
    },
  },
  watch: {
    tab() {
      this.getReportData();
    },
    'filter.type'() {
      this.getReportData();
    },
    'month.formatted'() {
      this.getReportData();
    },
    'startDate.formatted'() {
      this.getReportData();
    },
    'endDate.formatted'() {
      this.getReportData();
    },
  },
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      formatDateQuery: 'YYYY-MM-DD',
      month: {
        raw: moment().format('YYYY-MM'),
        formatted: moment().format('MMM YYYY'),
      },
      menu: {
        month: false,
        endDate: false,
        startDate: false,
      },
      startDate: {
        raw: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        formatted: moment()
          .subtract(30, 'days')
          .format('DD-MM-YYYY'),
      },
      endDate: {
        raw: moment().format('YYYY-MM-DD'),
        formatted: moment().format('DD-MM-YYYY'),
      },
      resource: {
        filterType: ['Per Bulan', 'Rentang Tanggal'],
      },
      filter: {
        type: 'Per Bulan',
      },
      headers: [
        { text: 'No', value: 'index', width: '5%', align: 'right' },
        { text: 'Nama Obat', value: 'name', width: '50%' },
        { text: 'Jumlah', value: 'quantity', align: 'left' },
      ],
      tab: 0,
      chart: {
        isLoading: false,
        width: '530%',
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            background: '#FAFAFA',
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              return `
        <div style="background: #FAFAFA; padding: 10px; text-align: center;">
          <span style="font-size: 14px; color: #666;">${w.globals.labels[
            dataPointIndex
          ].join('')}</span>
          <br />
          </div>
          <span style="padding: 10px; font-size: 120%; color: #333; font-weight: bold;">${
            series[seriesIndex][dataPointIndex]
          }</span>
      `;
            },
          },
          xaxis: {
            labels: {
              rotate: 0,
              fontSize: '10px',
              formatter: function(value) {
                return typeof value === 'object' ? value.slice(0, 2) : value;
              },
            },
          },
          yaxis: {
            title: {},
          },
          legend: {
            show: false,
          },
          colors: [
            ({ value }) => {
              if (value === Math.max(...this.seriesChart)) {
                return '#D32F2F';
              }
              return !this.tab ? '#1976D2' : '#FBC02D';
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getReportData();
    this.updateChartWidth();
    window.addEventListener('resize', this.updateChartWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateChartWidth);
  },
  methods: {
    ...mapActions(['resolveGetReport']),
    async getReportData() {
      this.chart.isLoading = true;
      const isPerMonth = this.filter.type === 'Per Bulan';
      const isErecipe = !this.tab ? true : false;

      const startDate = isPerMonth
        ? moment(this.month.formatted, 'MMM YYYY')
            .startOf('month')
            .format(this.formatDateQuery)
        : moment(this.startDate.raw).format(this.formatDateQuery);
      const endDate = isPerMonth
        ? moment(this.month.formatted, 'MMM YYYY')
            .endOf('month')
            .format(this.formatDateQuery)
        : moment(this.endDate.raw).format(this.formatDateQuery);

      await this.resolveGetReport({
        startDate,
        endDate,
        type: isErecipe ? 'erecipe' : 'drugsale',
      });
      this.chart.series[0].data = this.seriesChart;
      this.chart.chartOptions.xaxis.categories = this.splitTextToArray(
        this.labelChart,
      );
      this.chart.isLoading = false;
    },
    updateChartWidth() {
      const chartRow = document.getElementById('chart-row');
      if (chartRow) {
        this.chart.width = chartRow.offsetWidth - 50;
      }
    },
    itemClass(item, index) {
      if (index === 0) {
        return 'first-row custom-row';
      }
      return 'custom-row';
    },
    splitTextToArray(text) {
      const labels = [];
      for (let item of text) {
        if (item && item?.length <= 14) {
          return [item];
        }

        const words = item.split(' ');
        let result = [];
        let currentLine = '';

        for (const word of words) {
          if (currentLine && word && (currentLine + word)?.length > 14) {
            result.push(currentLine.trim());
            currentLine = word + ' ';
          } else {
            currentLine += word + ' ';
          }
        }

        if (currentLine && currentLine.trim()?.length > 0) {
          result.push(currentLine.trim());
        }
        result = result.filter(v => v !== '');

        labels.push(result);
      }

      return labels;
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
    formatMonth(month) {
      return moment(month, 'YYYY-MM').format('MMM YYYY');
    },
  },
};
</script>

<style lang="scss">
.table-section {
  text-align: left !important;
  label {
    font-size: 20px;
    /* font */
  }

  .custom-table .v-data-table-header {
    background-color: #f5f5f5;
  }

  .custom-row {
    border-left: 4px solid #d32f2f;
  }
}
</style>
