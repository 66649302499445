<template>
  <div class="main-div">
    <main class="main">
      <v-container fluid>
        <v-layout align-start justify-start column fill-width>
          <v-flex class="big-header">
            <v-layout align-space-between justify-start row fill-height>
              <v-flex xs6 align-self-center class="header-text pl-10">
                <h1 class="primary-header">{{ serviceLabel }}</h1>
                <h3 class="secondary-header">
                  Cek seluruh informasi mengenai laporan pelayanan di sini.
                </h3>
              </v-flex>
              <v-flex xs1></v-flex>
              <v-flex xs5>
                <v-img
                  contain
                  class="scientist-image"
                  lazy-src
                  :src="resource.picLocation[0]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex class="text-submenu">
            <h3 class="text-head py-4">Kategori</h3>
          </v-flex>
          <v-flex class="menu-space">
            <v-layout align-start justify-start row fill-height>
              <v-flex
                v-for="(item, index) in resource.category"
                xs1
                class="menu-widget"
                :key="'service-report-' + index"
                @click="openMenu(item)"
              >
                <v-layout fill-height column>
                  <router-link
                    v-if="!item.hasMenu"
                    :to="item.route"
                    class="d-flex justify-center"
                  >
                    <v-card flat rounded="lg" class="logo-place pa-3">
                      <v-img
                        contain
                        lazy-src
                        :src="resource.picLocation[index + 1]"
                      />
                    </v-card>
                  </router-link>
                  <div v-else class="d-flex justify-center">
                    <v-card flat rounded="lg" class="logo-place pa-3">
                      <v-img
                        contain
                        lazy-src
                        :src="resource.picLocation[index + 1]"
                      />
                    </v-card>
                  </div>
                  <v-flex class="font-weight-medium item-text">
                    {{ item.text }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <v-dialog v-model="dialog" :width="dialogWidth" persistent>
        <dinkes-menu
          @close-form="closeMenu()"
          v-if="dialog && selectedMenu === 0"
        />
      </v-dialog>
    </main>
  </div>
</template>

<script>
import checklist from '@/assets/image/checklist.svg';
import graphic from '@/assets/image/report_graphic.svg';
import book from '@/assets/image/report_book.svg';
import building from '@/assets/image/report_building.svg';
import stethoscope from '@/assets/image/report_stethoscope.svg';
import calendar from '@/assets/image/monthly_report_graph.svg';
// import graphic2 from '@/assets/image/report_graphic2.svg';
// import bpjs from '@/assets/image/report_graphBPJS.svg';
import GeneralConsent from '@/assets/image/GeneralConsentIcon.svg';
import mjkn from '@/assets/image/mjkn_reporting_icon.svg';
import toptendrug from '@/assets/image/top_ten_drug_icon.svg';
import dinkes from '@/assets/image/dinkes.svg';

// menus
import DinkesMenu from './ReportMenu/DinkesMenu.vue';

export default {
  name: 'PharmacySubmenu',
  components: { DinkesMenu },
  mixins: [],
  data() {
    return {
      dialog: false,
      dialogWidth: 0,
      selectedMenu: '',
      resource: {
        picLocation: [
          checklist,
          graphic,
          book,
          building,
          stethoscope,
          calendar,
          mjkn,
          GeneralConsent,
          // graphic2,
          // bpjs,
          toptendrug,
          dinkes,
        ],
        category: [
          {
            route: `/reporting/laporan-pelayanan/jumlah-pasien`,
            text: 'Jumlah Kepesertaan Pasiennnn',
          },
          {
            route: `/reporting/laporan-pelayanan/pasien-prolanis`,
            text: 'Pasien Prolanis BPJS',
          },
          {
            route: `/reporting/laporan-pelayanan/kunjungan-pasien`,
            text: 'Kunjungan Pasien',
          },
          {
            route: `/reporting/laporan-pelayanan/kepatuhan-resep`,
            text: 'Kepatuhan Peresepan Dokter',
          },
          {
            route: `/reporting/reporting-pelayanan/laporan-BOR`,
            text: 'Laporan BOR, LOS, TOI',
          },
          {
            route: `/reporting/laporan-pelayanan/pasien-rujukan-bpjs`,
            text: 'Laporan Pasien Rujukan BPJS',
          },
          {
            route: '/reporting/laporan-pelayanan/laporan-bulanan',
            text: 'Laporan Data Kesakitan Bulanan',
          },
          {
            route: '/reporting/laporan-pelayanan/general-consent',
            text: 'General Consent',
          },
          {
            route: '/reporting/laporan-pelayanan/10-besar-pemakaian-obat',
            text: '10 Besar Pemakaian Obat',
          },
          {
            text: 'Laporan Bulanan Dinas Kesehatan',
            hasMenu: true,
            formNumber: 0,
            dialogWidth: '33vw',
          },
        ],
      },
    };
  },
  mounted() {
    if (this.routeController) {
      this.resource.category = [
        {
          route: `/reporting/laporan-pelayanan/jumlah-pasien`,
          text: 'Jumlah Kepesertaan Pasien',
        },
        {
          route: `/reporting/laporan-pelayanan/pasien-prolanis`,
          text: 'Pasien Prolanis BPJS',
        },
        {
          route: `/reporting/laporan-pelayanan/kunjungan-pasien`,
          text: 'Kunjungan Pasien',
        },
        {
          route: `/reporting/laporan-pelayanan/pasien-rujukan-bpjs`,
          text: 'Laporan Pasien Rujukan BPJS',
        },
        {
          route: `/reporting/laporan-pelayanan/pasien-mjkn`,
          text: 'Laporan Pasien MJKN',
        },
        {
          route: '/reporting/laporan-pelayanan/laporan-bulanan',
          text: 'Laporan Data Kesakitan Bulanan',
        },
        {
          route: '/reporting/laporan-pelayanan/general-consent',
          text: 'General Consent',
        },
        {
          route: '/reporting/laporan-pelayanan/10-besar-pemakaian-obat',
          text: '10 Besar Pemakaian Obat',
        },
        {
          route: '/reporting/laporan-pelayanan/laporan-bulanan-dinas-kesehatan',
          text: 'Laporan Bulanan Dinas Kesehatan',
          hasMenu: true,
          formNumber: 0,
          dialogWidth: '33vw',
        },
      ];
    }
  },
  watch: {},
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    serviceLabel() {
      switch (this.routeController) {
        case 'laporan-pelayanan':
          return 'Laporan Pelayanan';

        default:
          return '';
      }
    },
  },
  methods: {
    openQueue() {
      this.dialog = true;
    },
    openMenu(obj) {
      this.selectedMenu = obj.formNumber;
      this.dialogWidth = obj.dialogWidth;
      this.dialog = true;
    },
    closeMenu() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Roboto;
  font-style: normal;
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding: 0 25px 0 25px;

    .big-header {
      align-items: center;
      display: flex;
      width: 100%;
      min-height: 48vh;
      background: #f0f9ff;
      font-family: Roboto;
      font-style: normal;
      border-radius: 25px;
      .header-text {
        padding-left: 25px;
        text-align: left;
        .primary-header {
          font-size: 2.5em;
          color: #000000;
          font-weight: bold;
        }
        .secondary-header {
          font-size: 1.15em;
          font-weight: 500;
          color: #a6adb4;
        }
      }
      .scientist-image {
        z-index: 1;
        top: 50px;
        position: relative;
        width: 100%;
      }
    }
    .text-submenu {
      .text-head py-4 {
        // padding: 40px 0 30px 0;
        color: #000000;
        font-weight: bold;
      }
    }
    .menu-space {
      width: 100%;
      .menu-widget {
        margin: 1vw;
        .logo-place {
          background: #f0f9ff;
          display: flex;
          justify-content: center;
          width: 20vw;
          height: 85px;
          &:hover {
            border: 2px solid $primary-color;
            cursor: pointer;
          }
        }

        .item-text {
          font-size: 1vw;
        }
      }
    }
  }
}
</style>
