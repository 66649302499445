<template>
  <div class="master-data-laboratory">
    <v-toolbar flat>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="4">
            <v-toolbar-title class="font-weight-bold float-left">
              Laboratorium
            </v-toolbar-title>
          </v-col>
          <v-spacer />
          <v-combobox
            class="pt-7 mr-8"
            label="Semua Kategori"
            outlined
            dense
            item-value="categoryId"
            item-text="categoryName"
            clearable
            append-icon="mdi-chevron-down"
            :items="[
              'Pemeriksaan Hematologi',
              'Pemeriksaan Kimia Klinik',
              'Pemeriksaan Imuno - Serologi',
              'Pemeriksaan Urinalisa',
              'Pemeriksaan Faeces',
              'Pemeriksaan Elektrolit',
            ]"
            v-model="category"
          />
          <v-text-field
            class="input"
            placeholder="Cari Jenis Pemeriksaan"
            v-model="search"
            outlined
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          />
          <v-btn class="mr-2" icon text @click="refreshAndAutoUpdate">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="items.dataLaboratory.data"
              :loading="items.dataLaboratory.isLoading"
              single-expand
              item-key="id"
              class="expand"
              :items-per-page="itemsPerRow"
              hide-default-footer
            >
              <template v-slot:[`item.isActive`]="{ item }">
                <td>
                  <v-switch
                    v-model="item.isActive"
                    :label="`${item.isActive ? 'Aktif' : 'Nonaktif'}`"
                    color="info"
                    @click="handleChangeStatus(item.id, item.isActive)"
                    inset
                    dense
                  ></v-switch>
                </td>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        class="mx-2 hover"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="
                          {
                            (dialogDetail = true), selectItem(item);
                          }
                        "
                      >
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>
                    </template>
                    <span>Lihat Detail</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover>
                        <v-btn
                          slot-scope="{ hover }"
                          :color="hover ? 'error' : ''"
                          icon
                          class="mx-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            @click="handleDeleteMasterDataLaboratory(item.id)"
                          >
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-hover>
                    </template>
                    <span>Hapus Data</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <template>
          <v-dialog v-model="dialogDetail" max-width="1400px" persistent>
            <FormLaboratory
              :dataDetail="dataDetail"
              type="detail"
              v-if="dialogDetail"
              @close-form="closeForm"
            />
          </v-dialog>
        </template>
      </v-container>
    </v-main>

    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="laboratoryPages"
            :total-visible="10"
            @input="handleGetAllLab()"
          ></v-pagination>
          <v-spacer></v-spacer>
          <template>
            <v-dialog v-model="dialogAdd" max-width="1400px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark v-bind="attrs" v-on="on">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <FormLaboratory
                type="add"
                v-if="dialogAdd"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </v-row>
      </v-container>
    </v-footer>
    <v-snackbar
      absolute
      light
      content-class="font-weight-medium pr-0"
      :value="snackbar.show"
      elevation="0"
      :color="snackbar.color"
      height="15"
    >
      <div class="d-flex justify-space-between align-center">
        <span style="color: white">
          {{ snackbar.text }}
        </span>
        <v-btn
          style="color: white"
          text
          fab
          small
          right
          @click="snackbar.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-snackbar>
  </div>
</template>
<script>
import FormLaboratory from './components/Laboratory/FormLaboratory.vue';
import Swal from 'sweetalert2';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'MasterLaboratory',
);
const _ = require('lodash');

export default {
  name: 'MasterDataLaboratory',
  components: {
    FormLaboratory,
  },
  data() {
    return {
      dialogAdd: false,
      dialogEdit: false,
      dialogDetail: false,
      dataDetail: {},
      laboratoryPages: 0,
      table: {
        headers: [
          { text: 'No.', align: 'start', value: 'no' },
          { text: 'Nama Pemeriksaan', align: 'start', value: 'name' },
          { text: 'Kategori', value: 'category', sortable: false },
          { text: 'Kode LIS', value: 'lis_code', sortable: false },
          { text: 'Kode IHS', value: 'ihs_code', sortable: false },
          {
            text: 'Status Pemeriksaan',
            value: 'isActive',
            sortable: false,
            width: '10%',
          },
          { text: '', value: 'actions', sortable: false },
        ],
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 7,
          totalItems: null,
        },
      },
      search: '',
      category: '',
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },

  watch: {
    search() {
      this.searchData(this);
      this.table.pagination.page = 1;
    },
    category() {
      this.searchCatgory(this);
      this.table.pagination.page = 1;
    },
  },

  computed: {
    ...mapGetters(['getItems', 'getDialog', 'getForm', 'getSnackbar']),

    items: {
      get() {
        return this.getItems;
      },
    },

    dialog: {
      get() {
        return this.getDialog;
      },
      set(val) {
        return this.setDialog(val);
      },
    },

    snackbar: {
      get() {
        return this.getSnackbar;
      },
    },

    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
  },

  methods: {
    ...mapMutations(['setDialog', 'setForm', 'clearForm', 'setFilter']),
    ...mapActions([
      'resolveGetAllLaboratory',
      'resolveDeleteLaboratory',
      'resolvePutStatusLaboratory',
    ]),

    searchData: _.debounce(v => {
      v.handleGetAllLab();
    }, 650),

    searchCatgory: _.debounce(v => {
      v.handleGetAllLab();
    }, 650),

    closeForm() {
      this.dialogAdd = false;
      this.dialogEdit = false;
      this.dialogDetail = false;
      this.handleGetAllLab();
    },

    selectItem(item) {
      this.dataDetail = item;
    },

    handleChangeStatus(id, val) {
      this.resolvePutStatusLaboratory({
        _id: id,
        isActive: val,
      });
    },

    handleDeleteMasterDataLaboratory(id) {
      Swal.fire({
        title:
          '<div style="font-family: Roboto, Serif;">Apakah Anda yakin Ingin Menghapus Data Laboratorium Ini?</div>',
        icon: 'question',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            this.resolveDeleteLaboratory(id).then(res => {
              if (res.status === 200) {
                this.handleGetAllLab();
              } else {
                this.handleGetAllLab();
              }
            });
          } catch (error) {
            this.showErrorAxios(error);
          }
        }
      });
    },

    handleGetAllLab() {
      const itemCount = this.itemsPerRow;
      const keyword = this.search;
      const category = this.category;
      const halaman = this.table.pagination.page;

      this.setFilter({
        halaman,
        itemCount,
        category,
        keyword,
      });

      this.resolveGetAllLaboratory({
        halaman,
        itemCount,
        category,
        keyword,
      }).then(item => {
        this.laboratoryPages = Math.ceil(item / itemCount);
      });
    },

    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.handleGetAllLab();
      this.interval = setInterval(this.handleGetAllLab, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
$primary-color: #2d9cdb;
$secondary-color: #ffffff;

.swal2-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

button.swal2-confirm,
button.swal2-cancel {
  border-radius: 0.25em;
  padding: 10px 15px;
}

.swal2-confirm {
  background-color: $primary-color;
  color: white;
  border: none;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.swal2-cancel {
  background-color: $secondary-color;
  color: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 0.25em;

  &:hover {
    background-color: darken($secondary-color, 10%);
  }
}
</style>
