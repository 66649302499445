const moment = require('moment-timezone');

const mapReturnStatus = code => {
  switch (code) {
    case '0':
      return {
        code,
        text: 'Sembuh',
        description: '',
      };
    case '1':
      return {
        code,
        text: 'Meninggal',
        description: '',
      };
    case '2':
      return {
        code,
        text: 'Pulang Paksa',
        description: '',
      };
    case '4':
      return {
        code,
        text: 'Rujuk Vertikal',
        description: '',
      };
    case '6':
      return {
        code,
        text: 'Rujuk Horizontal',
        description: '',
      };
    case '9':
      return {
        code,
        text: 'Lain-lain',
        description: '',
      };
    default:
      return {
        code: '',
        text: '',
        description: '',
      };
  }
};

const mapReferralPurpose = data => {
  if (data !== false && data !== true) return '';
  return data ? 'specialist' : 'khusus';
};

const mapMedicalResumeData = (source, state) => {
  return {
    isEditable: Boolean(source.doctor),
    prognosa: source.prognosis,
    referralType: source.pcare?.tacc,
    referralFactor:
      source.pcare?.kdTacc === '-1' ? '' : source.pcare?.kdTacc || '',
    referralReason: source.pcare?.reasonTacc || '', // TODO
    referralLetterData: {
      referralPurpose: mapReferralPurpose(source.pcare?.referral?.isSpecialist),
      specialistReferralPurpose: source.pcare?.referral?.specialist || '',
      specificReferralPurpose: source.pcare?.referral?.specific || '',
      subspecialistReferralPurpose: source.pcare?.referral?.subSpecialist || '',
      medium: source.pcare?.referral?.medium || '',
      agency: source.pcare?.referral?.agency || '',
      therapy: source.pcare?.referral?.therapy || '',
      advice: source.pcare?.referral?.suggestion || '',
      note: source.pcare?.referral?.note || '',
      date: {
        plan: source.pcare?.referral?.date
          ? moment(source.pcare?.referral?.date, 'DD-MM-YYYY').format(
              'YYYY-MM-DD',
            )
          : '',
        schedule: source.pcare?.referral?.agency?.schedule?.[0],
      },
    },
    allergy: {
      drug: source.allergy_drug || [],
      air: source.allergy_air || [],
      food: source.allergy_food || [],
      others: source.allergy_history.length ? source.allergy_history : [''],
    },
    doctor: source.doctor || '',
    date: {
      in: {
        formatted: source.dateTime?.dateIn
          ? moment(source.dateTime?.dateIn).format('DD-MM-YYYY')
          : state.form.date.in.formatted,
        raw: source.dateTime?.dateIn
          ? moment(source.dateTime?.dateIn).format('YYYY-MM-DD')
          : state.form.date.in.raw,
      },
      out: {
        formatted: source.dateTime?.dateout
          ? moment(source.dateTime?.dateout).format('DD-MM-YYYY')
          : '',
        raw: source.dateTime?.dateout
          ? moment(source.dateTime?.dateout).format('YYYY-MM-DD')
          : '',
      },
    },
    time: {
      in: source.dateTime?.dateIn
        ? moment(source.dateTime?.dateIn).format('HH:mm')
        : state.form.time.in,
      out: source.dateTime?.dateout
        ? moment(source.dateTime?.dateout).format('HH:mm')
        : '',
    },
    mainDiagnose: {
      name: source.main_diagnose?.name || '',
      id_diagnose: source.main_diagnose?.id_diagnose
        ? {
            id_diagnose: source.main_diagnose?.id_diagnose?._id || '',
            name: `${source.main_diagnose.id_diagnose.code} ${source.main_diagnose.id_diagnose.description}`,
            isNonSpecialist:
              source.main_diagnose?.id_diagnose?.nonSpesialis || '',
          }
        : '',
      search: null,
    },
    secondaryDiagnose: source.secondary_diagnose?.map(item => {
      return {
        name: item.name,
        loading: false,
        id_diagnose: {
          id_diagnose: item.id_diagnose?._id || '',
          name: item.id_diagnose
            ? `${item.id_diagnose.code} ${item.id_diagnose.description}`
            : '',
        },
      };
    }) || [
      {
        name: '',
        id_diagnose: '',
        loading: false,
      },
    ],
    procedure: source.procedure?.map(item => {
      return {
        name: item.name,
        loading: false,
        id_procedure: {
          id_procedure: item.id_procedure?._id || '',
          name: item.id_procedure
            ? `${item.id_procedure.code} ${item.id_procedure.description}`
            : '',
        },
      };
    }) || [
      {
        name: '',
        id_procedure: '',
        loading: false,
      },
    ],
    // referralLetterData: source.referral_data,
    room: source.room || '',
    reasonIn: source.reasonIn || '',
    diagnoseIn: source.diagnoseIn || '',
    diseaseHistory: source.disease_history || '',
    physicalCheckup: source.physical_checkup || '',
    labResult: source.lab_result || '',
    supportCheckup: source.support_checkup || '',
    therapy: source.therapy || [''],
    consultationResult: source.consultation_result || '',
    takehomeDrug: source.takehome_drug || [''],
    diet: source.diet || '',
    instruction: source.instruction || '',
    returnStatus: mapReturnStatus(source.return_status),
    treatmentContinued: {
      name: source.treatment_continued?.name || '',
      description: source.treatment_continued?.description || '',
    },
  };
};

const mapReferralLetter = state => {
  return {
    // doctor: this.$store.getters.userLoggedIn?.code,
    referralPurpose: state.form.referralLetterData.referralPurpose || '',
    specialistReferralPurpose:
      state.form.referralLetterData.specialistReferralPurpose || '',
    specificReferralPurpose:
      state.form.referralLetterData.specificReferralPurpose || '',
    subspecialistReferralPurpose:
      state.form.referralLetterData.subspecialistReferralPurpose || '',
    date: {
      plan: state.form.referralLetterData.date.plan
        ? moment(state.form.referralLetterData.date.plan, 'YYYY-MM-DD').format(
            'DD-MM-YYYY',
          )
        : moment().format('DD-MM-YYYY'),
      schedule: state.form.referralLetterData.date.schedule,
    },
    medium: state.form.referralLetterData.medium || null,
    agency: state.form.referralLetterData.agency || '',
    therapy: state.form.referralLetterData.therapy || '',
    advice: state.form.referralLetterData.advice || '',
    note: state.form.referralLetterData.note || '',
  };
};

module.exports = { mapMedicalResumeData, mapReferralLetter };
