import JsPDF from 'jspdf';
import printMixin from '../printMixin';

export default {
  methods: {
    printMedicalResumeDocument(patient, data) {
      const doc = new JsPDF({
        orientation: 'potrait',
        unit: 'in',
        format: 'a4',
      });
      doc.setProperties({
        title: `Resume Medis-${this.$store.getters.selectedPatient.rmNumber}`,
      });

      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      this.pdfTextPrint(doc, 'RESUME MEDIS', 1.81);

      const patientData = {
        left: [
          ['No RM', ':', patient.rmNumber],
          ['Nama Pasien', ':', patient.name],
          ['Tanggal Lahir', ':', patient.birthDate],
          ['Umur', ':', `${patient.age} Tahun`],
          ['Tipe Penjamin', ':', patient.assurance],
        ],
        right: [
          ['Tanggal Masuk', ':', patient.dateIn],
          ['Tanggal Keluar', ':', patient.dateOut],
          ['Dokter/Bidan', ':', patient.doctor],
          ['Ruang Rawat', ':', patient.room || '-'],
        ],
      };
      doc.autoTable({
        body: patientData.left,
        startY: 2.2,
        styles: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellPadding: 0.04, cellWidth: 1.1 },
          1: { cellPadding: 0.04, cellWidth: 0.2 },
          2: { cellPadding: 0.04, cellWidth: 1 },
        },
        margin: {
          left: 0.8,
        },
        theme: 'plain',
      });
      const leftTable = doc.autoTable.previous;
      doc.autoTable({
        body: patientData.right,
        startY: 2.2,
        styles: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellPadding: 0.04, cellWidth: 1.1 },
          1: { cellPadding: 0.04, cellWidth: 0.2 },
          2: { cellPadding: 0.04, cellWidth: 2 },
        },
        margin: {
          left: 3.8,
        },
        theme: 'plain',
      });
      const rightTable = doc.autoTable.previous;
      let leftTableHeight = leftTable.body.reduce(
        (a, { height }) => a + height,
        0,
      );
      let rightTableHeight = rightTable.body.reduce(
        (a, { height }) => a + height,
        0,
      );
      doc.setLineWidth(0.00001);
      doc.rect(
        0.65,
        2,
        7,
        (leftTableHeight >= rightTableHeight
          ? leftTableHeight
          : rightTableHeight) + 0.4,
      );
      const lastTable =
        leftTableHeight >= rightTableHeight
          ? leftTable.finalY
          : rightTable.finalY;

      const generateSecondaryDiagnose = data.secondaryDiagnose.map(
        (item, index) => {
          return [
            !index ? 'Diagnosa Sekunder' : '',
            !index ? ':' : '',
            item.name || '-',
            'ICD-10',
            ':',
            item.id_diagnose?.name || '-',
          ];
        },
      );
      const generateProcedure = data.procedure.map((item, index) => {
        return [
          !index ? 'Tindakan/Prosedur' : '',
          !index ? ':' : '',
          item.name || '-',
          'ICD-9',
          ':',
          item.id_procedure?.name || '-',
        ];
      });
      const generateMultipleItems = array => {
        if (!array.length) return '-';
        return array.map(item => (item ? `• ${item}` : '-')).join('\n');
      };

      const dataResult = [
        ['Alasan Masuk', ':', { content: data.reasonIn || '-', colSpan: 4 }],
        [
          'Diagnosa Masuk',
          ':',
          { content: data.diagnoseIn || '-', colSpan: 4 },
        ],
        [
          'Riwayat Penyakit',
          ':',
          { content: data.diseaseHistory || '-', colSpan: 4 },
        ],
        [
          'Pemeriksaan Fisik',
          ':',
          { content: data.physicalCheckup || '-', colSpan: 4 },
        ],
        [
          'Pemeriksaan Penunjang',
          ':',
          { content: data.supportCheckup || '-', colSpan: 4 },
        ],
        [
          'Hasil Laboratorium',
          ':',
          { content: data.labResult || '-', colSpan: 4 },
        ],
        [
          'Terapi Pengobatan',
          ':',
          {
            content: generateMultipleItems(data.therapy),
            colSpan: 4,
          },
        ],
        ['Hasil Konsultasi', ':', data.consultationResult || '-'],
        [
          'Diagnosa Utama',
          ':',
          data.mainDiagnose.name || '-',
          'ICD-10',
          ':',
          data.mainDiagnose.id_diagnose.name || '-',
        ],
        ...generateSecondaryDiagnose,
        ...generateProcedure,
        [
          'Alergi',
          ':',
          {
            content: data.allergy.drug
              .concat(data.allergy.air, data.allergy.food, data.allergy.others)
              .filter(i => i)
              .join(', '),
            colSpan: 4,
          },
        ],
        ['Diet', ':', { content: data.diet || '-', colSpan: 4 }],
        [
          'Keadaan Pulang',
          ':',
          {
            content:
              data.returnStatus?.text?.toLowerCase() === 'lain-lain'
                ? `Lain-lain: ${data?.returnStatus?.description || '-'}`
                : data.returnStatus?.text || '-',
            colSpan: 4,
          },
        ],
        [
          'Obat Dibawa Pulang',
          ':',
          { content: generateMultipleItems(data.takehomeDrug), colSpan: 4 },
        ],
        [
          'Instruksi/Anjuran',
          ':',
          { content: data.instruction || '-', colSpan: 4 },
        ],
        [
          'Pengobatan Dilanjutkan',
          ':',
          {
            content:
              data.treatmentContinued?.name?.toLowerCase() === 'lain-lain'
                ? `Lain-lain: ${data.treatmentContinued?.description || '-'}`
                : data.treatmentContinued.name || '-',
            colSpan: 4,
          },
        ],
      ];
      let lastDidDrawPageData = null;
      doc.autoTable({
        body: dataResult,
        startY: lastTable + 0.5,
        styles: {
          halign: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellPadding: 0.04, cellWidth: 2 },
          1: { cellPadding: 0.04, cellWidth: 0.2 },
          2: { cellPadding: 0.04, cellWidth: 1.5 },
          3: { cellPadding: 0.04, cellWidth: 0.6 },
          4: { cellPadding: 0.04, cellWidth: 0.2 },
          5: { cellPadding: 0.04, cellWidth: 2.5 },
        },
        margin: {
          left: 0.65,
          top: 1.6,
        },
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
        theme: 'plain',
      });

      const table = doc.autoTable.previous;
      let lastTablePos = null;
      if (lastDidDrawPageData.cursor.y < 9.4) {
        lastTablePos = table.finalY;
      } else {
        doc.addPage();
        lastTablePos = 1.3;
      }

      doc.setFontSize(10);
      doc.setFont('helvetica', 'normal');
      this.pdfTextPrint(
        doc,
        `${this.$store.getters.userLoggedIn.clinic.clinicRegion},\nDokter Penanggung Jawab Pelayanan`,
        lastTablePos + 0.7,
        'halfCenterEnd',
        -1.5,
      );
      doc.setFontSize(9);
      this.pdfTextPrint(
        doc,
        'Tanda Tangan',
        lastTablePos + 1.95,
        'halfCenterEnd',
        0.7,
      );
      doc.autoTable({
        body: [[data?.doctor?.name]],
        startY: lastTablePos + 1.57,
        styles: {
          halign: 'left',
          fontSize: 9,
        },
        columnStyles: {
          0: { cellPadding: 0.04, cellWidth: 2 },
        },
        margin: {
          left: 5.07,
          top: 1.6,
        },
        theme: 'plain',
      });
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.0001);
      doc.line(5.1, lastTablePos + 1.8, 7.5, lastTablePos + 1.8);

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
          'in',
        );
      }

      return doc;
    },
  },
};
