import Constant from '@/const';
import axios from 'axios';

const getDinkesDataByDiagnose = async params => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/report/visitors/dinkes/diagnose'),
      {
        params,
      },
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getDinkesDataByDiagnose;
