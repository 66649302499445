<template>
  <v-container fluid class="px-0">
    <div
      style="border-bottom: 2px solid #E0E0E0;"
      class="d-flex justify-space-between pb-2 px-5 mb-2"
    >
      <h2>Laporan Bulanan Dinas Kesehatan Rawat {{ $route.query.type }}</h2>
      <div class="d-flex">
        <v-btn @click="resolveGetAllData" text fab small class="mr-2"
          ><v-icon color="grey">mdi-autorenew</v-icon>
        </v-btn>
        <v-menu
          dense
          ref="menu"
          :disabled="isNoData"
          v-model="menu.export"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="isNoData ? '#9E9E9E' : 'primary'"
              v-bind="attrs"
              v-on="on"
              depressed
              style="letter-spacing: 0.0001px; font-size: 1.08vw;"
              class="text-capitalize"
              dark
              :loading="loading.export"
            >
              <v-icon small class="mr-1">mdi-download</v-icon>
              Export
            </v-btn>
          </template>
          <v-card elevation="0" class="pt-4 text-start">
            <div @click="resolveGetDataExport('excel')" class="export-opt">
              <p>Excel</p>
            </div>
            <div
              v-if="filter.type !== 'Tahun'"
              @click="resolveGetDataExport('pdf')"
              class="export-opt"
            >
              <p>PDF</p>
            </div>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="d-flex pr-4 pl-2">
      <div class="tab-container">
        <v-tabs v-model="tab">
          <v-tab
            v-for="(item, index) in resource.tabs"
            :key="'tab' + index"
            class="text-capitalize"
            style="letter-spacing: 0.001px;"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <v-combobox
        dense
        single-line
        solo
        flat
        outlined
        class="mr-3"
        :items="resource.types"
        v-model="filter.type"
        append-icon="mdi-chevron-down"
      />
      <v-combobox
        v-if="filter.type === 'Tahun'"
        dense
        single-line
        solo
        flat
        outlined
        @change="resolveGetAllData"
        class="mr-3"
        :items="years"
        v-model="filter.year"
        append-icon="mdi-chevron-down"
      />
      <v-menu
        dense
        v-if="filter.type === 'Bulan'"
        ref="menu_month"
        v-model="menu.month"
        :close-on-content-click="false"
        :return-value.sync="filter.month.formatted"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            flat
            single-line
            solo
            outlined
            readonly
            v-bind="attrs"
            v-on="on"
            v-model="filter.month.formatted"
            prepend-inner-icon="mdi-calendar"
            placeholder="Pilih Bulan"
          />
        </template>
        <v-date-picker
          v-model="filter.month.raw"
          type="month"
          :max="today"
          locale="id"
          scrollable
          @input="
            $refs.menu_month.save(formatMonth(filter.month.raw));
            menu.month = false;
          "
        ></v-date-picker>
      </v-menu>
    </div>
    <v-progress-linear v-if="loading.data" indeterminate></v-progress-linear>
    <div class="table-header">
      <span>Kriteria</span>
      <span>Baru</span>
      <span>Lama</span>
      <span>Jumlah</span>
      <span>Laki-Laki</span>
      <span>Perempuan</span>
      <span>Jumlah</span>
    </div>
    <span style="color: #9e9e9e;" class="caption" v-if="loading.data"
      >Sedang mengambil data...</span
    >
    <div v-else class="table-container">
      <div class="px-4">
        <div class="section-header">
          <span>Jenis Pelayanan</span>
        </div>
        <div
          v-for="(item, index) in contents.serviceType"
          :key="'service-' + index"
          class="section-row"
        >
          <span>{{ item.name }}</span>
          <span>{{ item.newPatients }}</span>
          <span>{{ item.oldPatients }}</span>
          <span>{{ item.totalPatients }}</span>
          <span>{{ item.maleCount }}</span>
          <span>{{ item.femaleCount }}</span>
          <span>{{ item.totalPatients }}</span>
        </div>
        <div class="section-header">
          <span>Pasien yang dirujuk</span>
          <span>{{ contents.referralPatients.newPatients }}</span>
          <span>{{ contents.referralPatients.oldPatients }}</span>
          <span>{{ contents.referralPatients.totalPatients }}</span>
          <span>{{ contents.referralPatients.maleCount }}</span>
          <span>{{ contents.referralPatients.femaleCount }}</span>
          <span>{{ contents.referralPatients.totalPatients }}</span>
        </div>
        <div class="section-header">
          <span>Kriteria Bayar</span>
        </div>
        <div
          v-for="(item, index) in contents.paymentCriteria"
          :key="'payment-criteria-' + index"
          class="section-row"
        >
          <span>{{ item.name }}</span>
          <span>{{ item.newPatients }}</span>
          <span>{{ item.oldPatients }}</span>
          <span>{{ item.totalPatients }}</span>
          <span>{{ item.maleCount }}</span>
          <span>{{ item.femaleCount }}</span>
          <span>{{ item.totalPatients }}</span>
        </div>
        <div class="section-header">
          <span>Diagnosa Tertentu</span>
        </div>
        <div
          v-for="(item, index) in contents.diagnose"
          :key="'diagnose-' + index"
          class="section-row"
        >
          <span>{{ item.name }}</span>
          <span>{{ item.newPatients }}</span>
          <span>{{ item.oldPatients }}</span>
          <span>{{ item.totalPatients }}</span>
          <span>{{ item.maleCount }}</span>
          <span>{{ item.femaleCount }}</span>
          <span>{{ item.totalPatients }}</span>
        </div>
      </div>
    </div>
    <v-snackbar
      content-class="d-flex justify-space-between"
      v-model="snackbar.show"
      :color="snackbar.color"
      bottom
    >
      {{ snackbar.text }}
      <v-icon small color="white" @click="snackbar.show = false"
        >mdi-close</v-icon
      >
    </v-snackbar>
  </v-container>
</template>

<script>
const moment = require('moment-timezone');

import {
  getDinkesDataByServiceType,
  getDinkesDataByReferralPatient,
  getDinkesDataByPaymentCriteria,
  getDinkesDataByDiagnose,
  getDinkesDataExport,
} from '@/fetchApi/Reporting/Dinkes';

export default {
  name: 'DinkesReport',
  data() {
    return {
      loading: {
        data: false,
        export: false,
      },
      tab: 0,
      snackbar: {
        color: '',
        show: false,
        text: '',
      },
      contents: {
        serviceType: [],
        referralPatients: [],
        paymentCriteria: [],
        diagnose: [],
      },
      filter: {
        type: 'Bulan',
        month: {
          raw: moment().format('YYYY-MM'),
          formatted: moment().format('MM-YYYY'),
        },
        year: moment().format('YYYY'),
      },
      resource: {
        types: ['Bulan', 'Tahun'],
        tabs: [moment().format('MMMM, YYYY')],
      },
      menu: {
        month: false,
        export: false,
      },
    };
  },
  computed: {
    isNoData: {
      get() {
        return this.hasAllZeroNumbers([
          this.contents.diagnose,
          this.contents.paymentCriteria,
          [this.contents.referralPatients],
          this.contents.serviceType,
        ]);
      },
    },
    today() {
      return moment().format();
    },
    years: {
      get() {
        return this.getLastNYears(3);
      },
    },
  },
  watch: {
    tab() {
      this.resolveGetAllData();
    },
    'filter.type'(value) {
      if (value === 'Bulan') {
        this.resource.tabs = [
          moment(this.filter.month.formatted, 'MM-YYYY').format('MMMM, YYYY'),
        ];
      } else {
        this.resource.tabs = [
          'Januari',
          'Februari',
          'Maret',
          'April',
          'Mei',
          'Juni',
          'Juli',
          'Agustus',
          'September',
          'Oktober',
          'November',
          'Desember',
        ];
      }
      this.resolveGetAllData();
    },
    'filter.month.formatted'() {
      if (this.filter.type === 'Bulan') {
        this.resource.tabs = [
          moment(this.filter.month.formatted, 'MM-YYYY').format('MMMM, YYYY'),
        ];
      }
      this.resolveGetAllData();
    },
  },
  mounted() {
    this.resolveGetAllData();
  },
  methods: {
    hasAllZeroNumbers(arrays) {
      return arrays
        .map(array => {
          return array.every(obj => {
            // Get the values of the object, excluding "name"
            const values = Object.values(obj)
              .filter(val => typeof val !== 'string')
              .map(value => value);

            // Check if all values are zeros
            return values.every(value => value === 0);
          });
        })
        .every(v => v);
    },
    getFirstAndLastDate(monthYear) {
      // Step 1: Parse the input MM/YYYY
      const parsedDate = moment(monthYear, 'MM-YYYY');

      // Step 2: Check if the input is valid
      if (!parsedDate.isValid()) {
        throw new Error('Invalid date format. Please provide a valid MM-YYYY.');
      }

      // Step 3: Get the first date of the month
      const startDate = parsedDate.startOf('month').format('YYYY-MM-DD');

      // Step 4: Get the last date of the month
      const endDate = parsedDate.endOf('month').format('YYYY-MM-DD');

      // Return the result as an object
      return { startDate, endDate };
    },
    async resolveGetAllData() {
      this.loading.data = true;
      await Promise.all([
        this.resolveGetDataByServiceType(),
        this.resolveGetDataByReferalPatient(),
        this.resolveGetDataByPaymentCriteria(),
        this.resolveGetDataByDiagnose(),
      ]);
      this.loading.data = false;
    },
    async resolveGetDataByServiceType() {
      // try {
      const params = this.getFirstAndLastDate(
        this.filter.type === 'Bulan'
          ? this.filter.month.formatted
          : `${this.tab + 1}-${this.filter.year}`,
      );
      const response = await getDinkesDataByServiceType(params);
      this.contents.serviceType = response.data.data;
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async resolveGetDataByReferalPatient() {
      // try {
      const params = this.getFirstAndLastDate(
        this.filter.type === 'Bulan'
          ? this.filter.month.formatted
          : `${this.tab + 1}-${this.filter.year}`,
      );
      const response = await getDinkesDataByReferralPatient(params);
      this.contents.referralPatients = response.data.data[0];
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async resolveGetDataByPaymentCriteria() {
      // try {
      const params = this.getFirstAndLastDate(
        this.filter.type === 'Bulan'
          ? this.filter.month.formatted
          : `${this.tab + 1}-${this.filter.year}`,
      );
      const response = await getDinkesDataByPaymentCriteria(params);
      this.contents.paymentCriteria = response.data.data;
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async resolveGetDataByDiagnose() {
      // try {
      const params = this.getFirstAndLastDate(
        this.filter.type === 'Bulan'
          ? this.filter.month.formatted
          : `${this.tab + 1}-${this.filter.year}`,
      );
      const response = await getDinkesDataByDiagnose(params);
      this.contents.diagnose = response.data.data;
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async resolveGetDataExport(exportType) {
      try {
        this.loading.export = true;
        const params = {
          year:
            this.filter.type === 'Bulan'
              ? this.filter.month.formatted.split('-')[1]
              : this.filter.year,
          month:
            this.filter.type === 'Bulan'
              ? this.filter.month.formatted.split('-')[0]
              : this.tab + 1,
          exportType,
        };
        const res = await getDinkesDataExport(params);
        if (res.status === 200) {
          const { url } = res.config;
          window.open(
            `${url}?year=${params.year}&month=${params.month}&exportType=${exportType}`,
            '_blank',
          );
          this.snackbar = {
            show: true,
            color: 'success',
            text: 'Laporan berhasil diekspor',
          };
        }
      } catch (e) {
        this.snackbar = {
          show: true,
          color: 'error',
          text: 'Ekspor gagal, silakan coba lagi',
        };
      } finally {
        this.loading.export = false;
      }
    },
    formatMonth(month, text) {
      if (text) {
        return moment(month, 'YYYY-MM')
          .locale('id')
          .format('MMMM YYYY');
      }
      return moment(month, 'YYYY-MM').format('MM-YYYY');
    },
    getLastNYears(n) {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = 0; i < n; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-container {
  width: 60vw;
}

.table-header {
  display: grid;
  grid-template-columns: 40% repeat(6, 1fr);
  background-color: #fafafa;
  border-bottom: 1px solid #e0e0e0;
  color: #9e9e9e;
  padding: 2vh 2vw;
  font-size: 0.95vw;

  span:first-child {
    text-align: start;
  }
}

.table-container {
  padding: 0 1vw;
  overflow: auto;
  height: 55vh;
}

.section-header {
  background-color: #f0f8fe;
  display: grid;
  grid-template-columns: 40% repeat(6, 1fr);
  padding: 1.5vh 2vw;
  font-size: 1vw;
  border-bottom: 1px solid #ededed;
  color: #404040;

  span:first-child {
    text-align: start;
  }
}

.section-row {
  display: grid;
  grid-template-columns: 40% repeat(6, 1fr);
  padding: 1.5vh 2vw;
  font-size: 1vw;
  color: #404040;
  border-bottom: 1px solid #ededed;

  span:first-child {
    text-align: start;
    padding-left: 2.5vw;
  }
}

.export-opt {
  padding: 0.5vh 1vw;
  cursor: pointer;
}

.export-opt:hover {
  background-color: #fafafa;
}
</style>
